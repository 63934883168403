import styled from "styled-components"
import {primary} from "../../assets/colorPallet";


export const StyledSelectContainer = styled.div`
    .styled-select--label-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      
      label {
        cursor: pointer;
      }
      
      svg {
        margin-left: 10px;
        cursor: pointer;
        transform: ${props => props.active ? "rotate(180deg)" : "rotate(0)"};
        
        path {
          fill: ${primary};
        }
      }
    }
`

export const StyledSelectDropdownContainer = styled.div`
    padding: 16px;
    background-color: white;
    border-radius: 16px;
    width: 200px;
    z-index: 1000;
    margin-left: 80px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity .2s ease-in-out, margin-top .2s ease-in-out;
    position: absolute;
    margin-top: ${props => props.active ? "12px" : "-10px"};
    pointer-events: ${props => props.active ? "all" : "none"};
`

export const StyledSelectDropdownOption = styled.div`
  padding: 8px 12px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color .2s ease;

  label {
    cursor: pointer;
    font-weight: ${props => props.current ? 600 : null};
  }
  &:hover {
    background-color: #f4f4f4;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: transparent;
  pointer-events: ${props => props.active ? "all" : "none"};
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity .4s ease-in-out;
`
