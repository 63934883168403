// Libraries
import React from "react"
import { ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import MaterialTheme from "../../MaterialTheme";
import {StyledChipContainer} from "./styled"


export default function StyledChip({
    label,
    color = "primary",
    kind,
    onDelete = () => ({}),
    onClick = () => ({})
}) {
    // Methods
    const handleDelete = (e) => {
        onDelete()
    }

    const handleClick = (e) => {
        e.stopPropagation()
        onClick()
    }


    return (
        <ThemeProvider theme={MaterialTheme}>
            <StyledChipContainer onClick={handleClick}>
                {kind === "delete" ? (
                    <Chip
                    label={label}
                    color={color}
                    onDelete={handleDelete}/>
                ) : (
                    <Chip
                    label={label}
                    color={color}/>
                )}

            </StyledChipContainer>
        </ThemeProvider>
    )
}
