// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import {GeneralInfoLocationContainer, GeneralInfoLocationForm} from "./styled"

// Structure Componets
import {Container, Label, TitleH4} from "../../../../../structureComponents"

// Components
import StyledInput from "../../../../../components/StyledInput/StyledInput"
import StyledButton from "../../../../../components/StyledButton/StyledButton"
import ZipcodeSearch from "../../../../../businessComponents/ZipcodeSearch/ZipcodeSearch";
import Venture from "../../../../../store/modules/ventures/model";
import {updateVentureLocation} from "../../../../../store/modules/ventures/actions";
import {setSnackbarState, setUserHasChanges} from "../../../../../store/modules/global/actions";
import {AuthContext} from "../../../../../Auth";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";


function GeneralInfoLocation({
    currentVenture,
    dispatchUpdateVentureLocation,
    dispatchSnackbar,
    onUserChanges = () => ({}),
    dispatchSetUserHasChanges
}) {
    // Contexts
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [venture, setVenture] = useState(null)
    const [userHasChanges, setUserHasChanges] = useState(false)

    // Mounted
    useEffect(() => {
        onUserChanges(false)
    }, [])

    // Watchers
    useEffect(() => {
        if (currentVenture) {
            setVenture(new Venture({
                ...currentVenture
            }))
        }
    }, [currentVenture])

    // Methods
    const handleFormOnChange = (key, value) => {
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)

        onUserChanges(true)

        setVenture({
            ...venture,
            [key]: value
        })
    }

    const autocompleteVentureAddress = (ventureAddress) => {
        setVenture(new Venture({
            ...venture,
            countryState: ventureAddress.countryState,
            city: ventureAddress.city,
            neighborhood: ventureAddress.neighborhood,
            streetName: ventureAddress.streetName,
            streetNumber: ventureAddress.streetNumber,
            zipcode: ventureAddress.zipcode
        }))
    }

    const handleSaveVentureLocation = async () => {
        try {
            setLoading(true)

            const corporationId = venture.corporationId
            const ventureId = venture.id

            await dispatchUpdateVentureLocation({corporationId, ventureId}, venture)

            setLoading(false)
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)

            dispatchSnackbar({
                type: "success",
                message: "Empreendimento atualizado com sucesso",
                active: true
            })
        } catch (error) {
            setLoading(false)
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro",
                active: true
            })

            throw new Error(error)
        }
    }



    return (
        <GeneralInfoLocationContainer>
            <GeneralInfoLocationForm onSubmit={() => {}}>
                <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} flex alignCenter mt="20px" fluid maxWidth="80em">
                    <ZipcodeSearch
                    initialZipcode={venture?.zipcode}
                    onChange={() => handleFormOnChange("zipcode")}
                    onSearch={autocompleteVentureAddress}/>
                </Container>
                <Container mt="20px" flex alignCenter fluid maxWidth="80em">
                    <Container fluid>
                        <Label bold>Logradouro</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                            value={venture?.streetName}
                            helperText="Logradouro invalido"
                            onChange={(e) => handleFormOnChange("streetName", e.target.value)} />
                        </Container>
                    </Container>
                    <Container ml="20px">
                        <Label bold>Numero</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                            value={venture?.streetNumber}
                            helperText="Numero invalido"
                            onChange={(e) => handleFormOnChange("streetNumber", e.target.value)} />
                        </Container>
                    </Container>
                </Container>
                <Container mt="20px" flex alignCenter fluid maxWidth="80em">
                    <Container fluid>
                        <Label bold>Estado</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                            value={venture?.countryState}
                            disabled
                            helperText="Estado invalido"
                            onChange={(e) => handleFormOnChange("countryState", e.target.value)} />
                        </Container>
                    </Container>
                    <Container fluid ml="20px">
                        <Label bold>Cidade</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                            value={venture?.city}
                            disabled
                            helperText="Cidade invalida"
                            onChange={(e) => handleFormOnChange("city", e.target.value)} />
                        </Container>
                    </Container>
                    <Container fluid ml="20px">
                        <Label bold>Bairro</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                            value={venture?.neighborhood}
                            helperText="Bairro invalido"
                            onChange={(e) => handleFormOnChange("neighborhood", e.target.value)} />
                        </Container>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    {currentUser?.role === ADMIN_USER_ROLE && (
                        <Container disabled={!userHasChanges}>
                            <StyledButton
                            kind="primary"
                            loading={loading}
                            disabled={!userHasChanges}
                            onClick={handleSaveVentureLocation}>
                                Salvar
                            </StyledButton>
                        </Container>
                    )}
                </Container>
            </GeneralInfoLocationForm>
        </GeneralInfoLocationContainer>
    )
}


const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVentureLocation({corporationId, ventureId},venture) {
        await dispatch(updateVentureLocation({corporationId, ventureId}, {
            zipcode: venture.zipcode,
            streetNumber: venture.streetNumber,
            streetName: venture.streetName,
            neighborhood: venture.neighborhood,
            city: venture.city,
            countryState: venture.countryState
        }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoLocation)
