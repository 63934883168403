// Libraries
import styled from "styled-components"
import {textDefault} from "../../assets/colorPallet";


const ListItem = styled.li`
  width: ${props => props.fluid ? "100%" : null};
  max-width: ${props => props.maxWidth ? props.maxWidth : null};
  list-style: ${props => !props.dotStyle ? "none" : null};
  font-weight: ${props => props.bold ? "600" : "400"};
  cursor: pointer;
  line-height: ${props => props.lineHeight ? props.lineHeight : null} ;
  font-size: 16px;
  display: ${props => !props.dotStyle ? "flex" : null};
  align-items: ${props => !props.dotStyle ? "center" : null};
  color: ${props => props.color ? props.color : textDefault};
  
  &:not(:first-child) {
    margin-left: ${props => props.noMargin ? "0" : "30px"};
    margin-top: ${props => props.mt ? props.mt : null};
  }
  
  
  a {
    text-decoration: none;
  }
  
  @media only screen and (max-width: 600px) {
    max-width: ${props => props.fluidOnMobile ? "100%" : null};
    margin-top: ${props => props.noMargin ? "20px" : null};
  }
`


export default ListItem
