// Libraries
import styled from "styled-components"


export const SvgIconContainer = styled.div`
  svg {
    width: ${props => props.size ? props.size : null};
    height: auto;
    fill: ${props => props.color ? props.color : null};
    
    path {
      fill: ${props => props.color && props.fillPath ? props.color : null};
    }
  }
  
  .svg-icon {
    &:first-child {
      div:nth-child(1) {
        display: flex;
      }
    }
  }
`

