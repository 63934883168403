// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import queryString from 'query-string'

// Styles
import { LoginContainer, LoginForm } from "./styled"
import { Container, Label } from "../../structureComponents";
import StyledInput from "../../components/StyledInput/StyledInput";
import StyledButton from "../../components/StyledButton/StyledButton";

// Store actions
import {login} from "../../store/modules/authentication/actions";

// Components
import SvgIcon from "../../components/SvgIcon/SvgIcon";

// Icons
import imobproLogo from "../../assets/logos/imobpro-logo-secondary.svg"
import routes from "../../routes";
import {AuthContext} from "../../Auth";
import {ORULO_AUTH_URL, ORULO_END_USER_AUTH_TOKEN} from "../../config";
import UserHandler from "../../handlers/UserHandler";
import {primary} from "../../assets/colorPallet";
import PasswordRecoveryDialog from "../../businessComponents/PasswordRecoveryDialog/PasswordRecoveryDialog";



function Login(props) {
    const { currentUser, setCurrentUser } = useContext(AuthContext)

    // States
    const [showPasswordRecoveryPassword, setShowPasswordRecoveryPassword] = useState(false)
    const [hasOruloEndUserToken, setHasOruloEndUserToken] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState({
        email: null,
        password: null
    })

    // Watchers
    useEffect(() => {
        handleOruloLogin()
    }, [])


    // Methods
    const handleOruloLogin = async () => {
        try {
            const { code } = queryString.parse(window.location.search);
            const storedOruloEndUserAuthToken = localStorage.getItem(ORULO_END_USER_AUTH_TOKEN)

            if (storedOruloEndUserAuthToken && storedOruloEndUserAuthToken !== "undefined") {
                setHasOruloEndUserToken(true)
                return
            } else {
                setHasOruloEndUserToken(false)
                localStorage.removeItem(ORULO_END_USER_AUTH_TOKEN)
            }

            const token = await UserHandler.getOruloEndUserAuthToken(code)

            if ((code && code !== undefined) || (storedOruloEndUserAuthToken && storedOruloEndUserAuthToken !== "undefined")) {
                if (code) {
                    localStorage.setItem(ORULO_END_USER_AUTH_TOKEN, token)
                } else {
                    localStorage.setItem(ORULO_END_USER_AUTH_TOKEN, storedOruloEndUserAuthToken)
                }

                setHasOruloEndUserToken(true)
            } else {
                setHasOruloEndUserToken(false)
                localStorage.removeItem(ORULO_END_USER_AUTH_TOKEN)
            }
        } catch (error) {
            localStorage.removeItem(ORULO_END_USER_AUTH_TOKEN)
            setHasOruloEndUserToken(false)
        }
    }

    const handleEmailOnChange = (e) => {
        setFormState({ ...formState, email: e.target.value })
    }

    const handlePasswordOnChange = (e) => {
        setFormState({ ...formState, password: e.target.value })
    }

    const handleLogin = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const user = await props.login({
                email: formState.email,
                password: formState.password
            })

            setCurrentUser(user)
            props.history.push(routes.corporations.url)
        } catch (error) {
            setLoading(false)
        }
    }

    if (!!currentUser) {
        return <Redirect to={routes.corporations.url}/>
    }

    return (
        <LoginContainer>
            <Container flex column justifyCenter alignCenter>
                <SvgIcon src={imobproLogo} size={200}  />
                <Container mt="20px" fluid p="10px" flex alignCenter justifyCenter bgColor="#f4f4f4" radius="8px">
                    <Label large>Painel do Administrador</Label>
                </Container>
            </Container>
            <Container fluid mt="40px" flex alignCenter justifyCenter>
                    {!hasOruloEndUserToken ? (
                        <Container>
                            <a href={`${ORULO_AUTH_URL}`} target="_blank">
                                <StyledButton
                                    fluid
                                    kind="primary-outlined"
                                    type="submit"
                                    loading={loading}>
                                    Acesse sua conta no Órulo
                                </StyledButton>
                            </a>
                        </Container>
                    ) : (
                        <LoginForm onSubmit={handleLogin}>

                        <Container>
                            <Container>
                                <Label>Email</Label>
                                <Container mt="10px">
                                    <StyledInput
                                        placeholder="Add email"
                                        value={formState.email}
                                        required
                                        onChange={handleEmailOnChange}/>
                                </Container>
                            </Container>
                            <Container mt="20px">
                                <Container flex alignCenter justifyBetween>
                                    <Label>Password</Label>
                                    <Label
                                    small
                                    color={primary}
                                    pointer
                                    onClick={() => setShowPasswordRecoveryPassword(true)}>
                                        Esqueci minha senha
                                    </Label>
                                </Container>
                                <Container mt="10px">
                                    <StyledInput
                                        placeholder="Add email"
                                        type="password"
                                        value={formState.password}
                                        onChange={handlePasswordOnChange}/>
                                </Container>
                            </Container>
                            <Container mt="40px">
                                <StyledButton
                                    fluid
                                    kind="primary"
                                    type="submit"
                                    loading={loading}>
                                    Login
                                </StyledButton>
                            </Container>
                        </Container>
                        </LoginForm>

                    )}
            </Container>
            <PasswordRecoveryDialog
            active={showPasswordRecoveryPassword}
            onClose={() => setShowPasswordRecoveryPassword(false)} />
        </LoginContainer>
    )
}

const mapStateToProps = state => ({
    userIsLoggedIn: state.authentication.userIsLoggedIn
})

const mapDispatchToProps = dispatch => ({
    async login({ email, password }) {
        const user = await dispatch(login({ email, password }))
        return user
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
