import styled from "styled-components"


export const StyledImageContainer = styled.div`
    .MuiSkeleton-text {
      transform: unset !important;
    }
`

export const StyledImageSource = styled.img`
  object-fit: ${props => props.cover ? "cover" : "contain"};
  height: ${props => props.height ? props.height : "100%"};
  width: ${props => props.width ? props.width : "100%"};
  position: ${props => props.loading ? "absolute" : null};
  opacity: ${props => props.loading ? 0 : 1};
  transition: opacity .4s ease-in-out;
  border-radius: ${props => props.rounded ? "8px" : null};
`
