// Libraries
import React, {useEffect, useState} from "react"
import SvgIcon from "../SvgIcon/SvgIcon";
import arrowDownIcon from "../../assets/icons/arrow-down.svg"
import {
    StyledSelectContainer,
    StyledSelectDropdownContainer,
    StyledSelectDropdownOption,
    Overlay
} from "./styled";


export default function StyledSelectV2({
    placeholder,
    value,
    options = [],
    onChange = () => ({})
}) {
    // States
    const [state, setState] = useState({
        label: "",
        active: false,
        selectedOption: null
    })

    // Watchers
    useEffect(() => {
        if (placeholder) {
            setState({ ...state, label: placeholder })
        }
    }, [placeholder])

    useEffect(() => {
        if (value) {
            setState({
                ...state,
                selectedOption: value,
                label: placeholder
            })
        }
    }, [value, placeholder])

    // Methods
    const handleSelectedOptionOnChange = (selectedOption) => {
        setState({ ...state, selectedOption, active: false })
        onChange(selectedOption)
    }


    return (
        <>
            <Overlay active={state.active} onClick={() => setState({ ...state, active: false })}/>
            <StyledSelectContainer active={state.active}>
                <div className="styled-select--label-container" onClick={() => setState({ ...state, active: !state.active })}>
                    <label>{state.label}</label>
                    <SvgIcon size="12px" src={arrowDownIcon} />
                </div>
                <StyledSelectDropdownContainer active={state.active}>
                    {options.map((option, index) => (
                        <StyledSelectDropdownOption
                            key={index}
                            current={option.value === state.selectedOption?.value}
                            onClick={() => handleSelectedOptionOnChange(option)}>
                            <label>{option.label}</label>
                        </StyledSelectDropdownOption>
                    ))}
                </StyledSelectDropdownContainer>
            </StyledSelectContainer>
        </>
    )
}

