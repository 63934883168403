// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import {Divider} from "@material-ui/core";

// Styles
import { VentureLandingPageContainer } from "./styled"

// Structure components
import { Container } from "../../../../structureComponents"

// Store actions
import {AuthContext} from "../../../../Auth";
import InlineLoading from "../../../../components/Loading/Loading";
import TitleH2 from "../../../../structureComponents/modules/TitleH2";
import Label from "../../../../structureComponents/modules/Label";
import StyledInput from "../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../components/StyledButton/StyledButton";
import Text from "../../../../structureComponents/modules/Text";
import StyledColorPicker from "../../../../components/StyledColorPicker/StyledColorPicker";
import {updateVenture} from "../../../../store/modules/ventures/actions";
import {green500, neutral500, neutral800} from "../../../../assets/colorPallet";


function VentureLandingPage({
  history,
  currentVenture,
  dispatchUpdateVenture
}) {
    // States
    const [customDomain, setCustomDomain] = useState(null)
    const [primaryColor, setPrimaryColor] = useState(null)
    const [secondaryColor, setSecondaryColor] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)

    // Watchers
    useEffect(() => {
        if (history && history.location && history.location.state) {
            const historyState = history.location.state
        }
    }, [history])

    useEffect(() => {
        if (currentVenture) {
            setCustomDomain(currentVenture?.customDomain)
            setPrimaryColor(currentVenture?.primaryColor)
            setSecondaryColor(currentVenture?.secondaryColor)
        }
    }, [currentVenture])


    // Methods
    const handleCustomDomainOnChange = (e) => {
        setCustomDomain(e.target.value)
    }

    const handlePrimaryColorOnChange = (selectedColor) => {
        setPrimaryColor(selectedColor)
    }

    const handleSecondaryColorOnChange = (selectedColor) => {
        setSecondaryColor(selectedColor)
    }


    const handleSave = async () => {
        setLoadingSave(true)

        await dispatchUpdateVenture({
            corporationId: currentVenture?.corporationId,
            ventureId: currentVenture?.id
        }, {
            customDomain,
            primaryColor,
            secondaryColor
        })

        setLoadingSave(false)
    }

    if (loading) {
        return (
            <Container flex alignCenter justifyCenter>
                <InlineLoading />
            </Container>
        )
    }


    return (
        <>
            <VentureLandingPageContainer>
                <Container fluid flex justifyBetween alignCenter>
                    <Container>
                        <TitleH2 bold large>Landing page</TitleH2>
                        <Container mt="20px">
                            <Text medium>Nesta página você pode definir o domínio e o tema da landing page.</Text>
                        </Container>
                    </Container>
                    <StyledButton
                    kind="primary"
                    loading={loadingSave}
                    onClick={handleSave}>
                        Salvar
                    </StyledButton>
                </Container>

                <Container fluid mt="60px">
                    <Container>
                        <Label color={neutral800} bold medium>Configurações do site</Label>
                    </Container>
                    <Container mt="40px">
                        <Label bold>Domínio</Label>
                        <Container flex alignCenter mt="20px">
                            <Container fluid flex alignCenter maxWidth="40em">
                                <Label>https://</Label>
                                <Container fluid ml="10px">
                                    <StyledInput
                                        placeholder="Adicionar domínio"
                                        value={customDomain}
                                        onChange={handleCustomDomainOnChange} />
                                </Container>
                            </Container>
                            <Container ml="20px">
                                {currentVenture?.customDomain && currentVenture?.customDomainVerified ? (
                                    <Container radius="20em" p="10px 20px" bgColor="#52c77547">
                                        <Label>DNS configurado</Label>
                                    </Container>
                                ) : currentVenture?.customDomain && !currentVenture?.customDomainVerified ? (
                                    <Container radius="20em" p="10px" bgColor="#f500575e">
                                        <Label>DNS em validação</Label>
                                    </Container>
                                ) : null}
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container p="40px 0">
                    <Divider />
                </Container>
                <Container>
                    <Label color={neutral800} bold medium>Tema</Label>
                </Container>
                <Container fluid mt="40px">
                    <Container>
                        <Label bold>Cor primária</Label>
                        <Container mt="10px" maxWidth="40em">
                            <Text>
                                Esta cor será aplicada no botão "solicitar contato", tabs no menu âncora, tabs na
                                galeria, botões de navegação na galeria e play do video, assim como nos inputs ativos
                            </Text>
                        </Container>
                        <Container fluid flex alignCenter mt="20px" maxWidth="40em">
                            <StyledColorPicker value={primaryColor} onChange={handlePrimaryColorOnChange} />
                        </Container>
                    </Container>
                    <Container mt="30px">
                        <Label bold>Cor secundária</Label>
                        <Container mt="10px" maxWidth="40em">
                            <Text>
                                Esta cor será aplicada em todos os ícones, exceto os do menu âncora.
                            </Text>
                        </Container>
                        <Container fluid flex alignCenter mt="20px" maxWidth="40em">
                            <StyledColorPicker value={secondaryColor} onChange={handleSecondaryColorOnChange} />
                        </Container>
                    </Container>
                </Container>
            </VentureLandingPageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const dispatchMapToProps = (dispatch) => ({
    async dispatchUpdateVenture({ ventureId, corporationId }, { customDomain, primaryColor, secondaryColor }) {
        await dispatch(updateVenture({ ventureId, corporationId }, {
            customDomain,
            primaryColor,
            secondaryColor
        }))
    }
})

export default connect(mapStateToProps, dispatchMapToProps)(VentureLandingPage)
