// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../structureComponents";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Store actions
import {deleteVentureImage, updateVentureImage} from "../../../../../store/modules/images/actions"
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import {setSnackbarState} from "../../../../../store/modules/global/actions";


function UpdateVentureImageDialog({
    active = false,
    onClose = () => ({}),
    dispatchUpdateVentureName,
    ventureImage,
    currentVenture,
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)
    const [ventureImageName, setVentureImageName] = useState("")

    // Watchers
    useEffect(() => {
        if (ventureImage) {
            setVentureImageName(ventureImage.name)
        }
    }, [ventureImage])

    // Methods
    const handleVentureImageNameOnChange = (e) => {
        setVentureImageName(e.target.value)
    }

    const handleUpdateVentureImage = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchUpdateVentureName({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id,
                imageGroupId: ventureImage.imageGroupId,
                imageId: ventureImage.id
            }, {
                name: ventureImageName
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Imagem atualizada com sucesso"
            })
            onClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Atualizar imagem"
            active={active}
            onClose={() => onClose()} maxWidth="sm">
            <form onSubmit={handleUpdateVentureImage}>
                <Container>
                    <Label>Nome</Label>
                    <Container mt="10px">
                        <StyledInput value={ventureImageName} onChange={handleVentureImageNameOnChange} />
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                            kind="primary"
                            type="submit"
                            loading={loading}>
                            Atualizar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVentureName({ corporationId, ventureId, imageGroupId, imageId }, { name }) {
        await dispatch(updateVentureImage({
            corporationId,
            ventureId,
            imageGroupId,
            imageId
        }, {
            name
        }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(UpdateVentureImageDialog)
