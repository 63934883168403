// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import { VentureImageGroupContainer, VentureImage } from "./styled"

// Structure components
import { Container, TitleH2, Label } from "../../../../structureComponents"

// Assets
import fileIcon from "../../../../assets/icons/file.svg"
import downloadIcon from "../../../../assets/icons/download.svg"
// Store actions
import ImageGroup from "../../../../store/modules/imageGroups/model";
import StyledButton from "../../../../components/StyledButton/StyledButton";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import folderIcon from "../../../../assets/icons/folder.svg";
import editIcon from "../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../assets/icons/trash.svg";
import DeleteVentureImageDialog from "./DeleteVentureImageDialog/DeleteVentureImageDialog";
import UpdateVentureImageDialog from "./UpdateVentureImageDialog/UpdateVentureImageDialog";
import VentureImageDialog from "./VentureImageDialog/VentureImageDialog";
import UploadVentureDocumentsDialog from "./UploadVentureDocumentsDialog/UploadVentureDocumentsDialog";
import {fetchDocuments} from "../../../../store/modules/documents/actions";
import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
import {primary} from "../../../../assets/colorPallet";
import {AuthContext} from "../../../../Auth";
import {ADMIN_USER_ROLE} from "../../../../utils/constants";
import InlineLoading from "../../../../components/Loading/Loading";


function VentureDocuments({
    history,
    imageGroups,
    currentVenture,
    currentVentureDocuments,
    dispatchFetchDocuments
}) {
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [currentImageGroup, setCurrentImageGroup] = useState(new ImageGroup({}))
    const [showUploadVentureImageDialog, setShowUploadVentureImageDialog] = useState(false)
    const [showDeleteVentureImageDialog, setShowDeleteVentureImageDialog] = useState(false)
    const [selectedVentureImage, setSelectedVentureImage] = useState(null)
    const [showUpdateVentureImageDialog, setShowUpdateVentureImageDialog] = useState(false)
    const [showVentureImageDialog, setShowVentureImageDialog] = useState(false)

    // Mounted
    useEffect(() => {
        if (currentVenture) {
            handleFetchDocuments()
        }
    }, [currentVenture])

    // Watchers
    useEffect(() => {
        if (history && history.location && history.location.state) {
            const historyState = history.location.state
            const imageGroup = imageGroups.find(imageGroup => imageGroup.id === historyState?.imageGroup?.id)
            setCurrentImageGroup(imageGroup)
        }
    }, [history])

    useEffect(() => {
        if (imageGroups.length > 0) {
            const historyState = history.location.state

            const imageGroup = imageGroups.find(imageGroup => imageGroup.id === historyState?.imageGroup?.id)
            setCurrentImageGroup(imageGroup)
        }
    }, [imageGroups])

    // Methods
    const handleFetchDocuments = async () => {
        try {
            setLoading(true)
            await dispatchFetchDocuments({
                ventureId: currentVenture?.id,
                corporationId: currentVenture?.corporationId
            })
        } finally {
            setLoading(false)
        }
    }

    const handleShowDeleteImageDialog = (ventureImage) => {
        setShowDeleteVentureImageDialog(true)
        setSelectedVentureImage(ventureImage)
    }

    const handleShowUpdateVentureImageDialog = (ventureImage) => {
        setShowUpdateVentureImageDialog(true)
        setSelectedVentureImage(ventureImage)
    }

    if (loading) {
        return (
            <Container flex alignCenter justifyCenter>
                <InlineLoading />
            </Container>
        )
    }


    return (
        <>
            <VentureImageGroupContainer>
                <Container mt="40px" fluid flex column alignCenter>
                    {currentVentureDocuments?.length > 0 ? (
                        <Container flex column justifyCenter fluid maxWidth="800px">
                            {/*{currentUser?.role === ADMIN_USER_ROLE && (*/}
                            {/*    <Container flex fluid justifyEnd alignCenter>*/}
                            {/*        <StyledButton*/}
                            {/*            slimHeight*/}
                            {/*            kind="primary-outlined"*/}
                            {/*            onClick={() => setShowUploadVentureImageDialog(true)}>*/}
                            {/*            Upload*/}
                            {/*        </StyledButton>*/}
                            {/*    </Container>*/}
                            {/*)}*/}
                            <Container mt="40px" fluid column flex alignCenter justifyCenter>
                                {currentVentureDocuments?.map((document, index) => (
                                    <>
                                        <Container
                                            flex
                                            fluid
                                            p="10px 0"
                                            alignCenter
                                            justifyCenter>
                                            <SvgIcon src={fileIcon} size="24px" color={primary} />
                                            <Container flex fluid alignCenter justifyBetween ml="10px">
                                                <Container fluid>
                                                    <Label>{document.name}</Label>
                                                </Container>
                                                <Container flex alignCenter justifyEnd>
                                                    {/*<Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}>*/}
                                                    {/*    <StyledButton*/}
                                                    {/*        iconSize="16px"*/}
                                                    {/*        iconButton icon={{url: editIcon}}*/}
                                                    {/*        onClick={() => handleShowUpdateVentureImageDialog(document)}/>*/}
                                                    {/*</Container>*/}
                                                    {/*<Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} ml="10px">*/}
                                                    {/*    <StyledButton*/}
                                                    {/*        iconSize="16px"*/}
                                                    {/*        iconButton icon={{url: deleteIcon}}*/}
                                                    {/*        onClick={() => handleShowDeleteImageDialog(document)}/>*/}
                                                    {/*</Container>*/}
                                                    <Container ml="10px">
                                                        <a href={document.fileUrl} target="_blank">
                                                            <StyledButton
                                                            iconSize="16px"
                                                            iconButton icon={{url: downloadIcon}}
                                                            onClick={() => ({})}/>
                                                        </a>
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </Container>
                                        {index < currentVentureDocuments.length - 1 && (
                                            <Container m="10px 0" height="1px" bgColor="#eee" fluid />
                                        )}
                                    </>
                                ))}
                            </Container>
                        </Container>
                    ) : (
                        <Container mt="20px" fluid>
                            <Container
                                flex
                                alignCenter
                                justifyCenter
                                height="calc(100vh - 49px - 40px - 60px - 40px - 40px - 20px)">
                                <EmptyState
                                    noAction={currentUser?.role !== ADMIN_USER_ROLE}
                                    icon={folderIcon}
                                    text="Este empreendimento não possui nenhum documento"
                                    buttonText="Adicionar documento"
                                    onClick={() => setShowUploadVentureImageDialog(true)}
                                />
                            </Container>
                        </Container>
                    )}
                </Container>
            </VentureImageGroupContainer>
            <UploadVentureDocumentsDialog
                active={showUploadVentureImageDialog}
                imageGroup={currentImageGroup}
                onClose={() => setShowUploadVentureImageDialog(false)}/>
            <DeleteVentureImageDialog
                active={showDeleteVentureImageDialog}
                ventureImage={selectedVentureImage}
                onClose={() => setShowDeleteVentureImageDialog(false)}/>
            <UpdateVentureImageDialog
                active={showUpdateVentureImageDialog}
                ventureImage={selectedVentureImage}
                onClose={() => setShowUpdateVentureImageDialog(false)}/>
            <VentureImageDialog
                active={showVentureImageDialog}
                ventureImage={selectedVentureImage}
                onClose={() => setShowVentureImageDialog(false)}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVentureDocuments: state.documents.currentVentureDocuments,
    currentVenture: state.ventures.currentVenture,
    imageGroups: state.imageGroups.imageGroups
})

const dispatchMapToProps = (dispatch) => ({
    async dispatchFetchDocuments({ ventureId, corporationId }) {
        await dispatch(fetchDocuments({ ventureId, corporationId }))
    }
})

export default connect(mapStateToProps, dispatchMapToProps)(VentureDocuments)
