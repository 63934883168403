// LIBRARIES
import styled from 'styled-components'

// ASSETS
import {primary, lightGrey} from "../../../assets/colorPallet";


export const SideBarOptionContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: 5px solid transparent;
  cursor: pointer;
  padding: 15px 10px; 
  border-left: ${props => {
    if (props.active) {
        return `5px solid ${primary}`
    }
    
    return "5px solid transparent;"
  }};
  background-color: ${props => {
    if (props.active) {
        return "#f1f1f1"
    }    
    
    return "transparent"
  }};
  transition: color .4s ease;
 
  span {
    color: ${props => props.active ? "#333" : "#888"};
    font-size: 14px;
  }  
    
  svg{
    path{
        fill: ${primary};
    }
  }
  
  &:hover{
    border-left: 5px solid ${primary};
    background-color: #f1f1f1;
    
    span {
      color: #333;
    }
  }
`

