// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../structureComponents";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import {createImageGroup} from "../../../../../store/modules/imageGroups/actions";
import SelectFile from "../../../../../components/SelectFile/SelectFile";
import editIcon from "../../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../../assets/icons/trash.svg";
import closeIcon from "../../../../../assets/icons/close.svg";
import {ProgressBar} from "../../../../../components/ProgressBar";
import {setSnackbarState} from "../../../../../store/modules/global/actions";
import {uploadVentureDocuments} from "../../../../../store/modules/documents/actions";



function UploadVentureDocumentsDialog({
    active = false,
    onClose = () => ({}),
    imageGroup,
    currentVenture,
    dispatchUploadVentureDocuments,
    uploadingProgressPercentage,
    dispatchSnackbar
}) {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const [fileToBeUpdated, setFileToBeUpdated] = useState(-1)

    // Watchers
    useEffect(() => {
        if (active) {
            resetLocalState()
        }
    }, [active])

    // Methods
    const handleClose = () => {
        onClose()
    }

    const handleUploadVentureDocuments = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            console.log(">>>>> UPLOADING >>>>>>")
            await dispatchUploadVentureDocuments({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id
            }, {
                documents: selectedDocuments
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Documento adicionado com sucesso"
            })
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleVentureImagesOnSelect = (documents) => {
        setSelectedDocuments([...selectedDocuments, ...Array.from(documents).filter((document) => {
            const allowedDocumentMimeTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"]

            if (allowedDocumentMimeTypes.includes(document.type)) {
                return true
            }

            return false
        }).map((document) => {
            let documentMimeType = document.type.replace("image/", "")
            if (document.type === "application/pdf" || document.type === "application/PDF") {
                documentMimeType = document.type.replace("application/", "")
            }
            if (documentMimeType === "jpeg") documentMimeType = "jpg"

            const customName = document.name.replace(`.${documentMimeType}`, "")

            return {
                file: document,
                customName
            }
        })])

        setUserHasChanges(true)
    }

    const handleDeleteVentureImage = (ventureImageIndex) => {
        setSelectedDocuments(selectedDocuments.filter((_, index) => index !== ventureImageIndex))
    }

    const handleUpdateFileName = (index) => {
        setFileToBeUpdated(index)
    }

    const handleFileNameOnChange = (e, index) => {
        const documents = [...selectedDocuments].map((selectedDocument, i) => {
            if (index === i) {
                return {
                    ...selectedDocument,
                    customName: e.target.value
                }
            }

            return selectedDocument
        })

        setSelectedDocuments(documents)
    }

    const handleFileNameUpdateCloseOnEnter = (e) => {
        if (e.key === "Enter") {
            setFileToBeUpdated(-1)
        }
    }

    const resetLocalState = () => {
        setSelectedDocuments([])
        setLoading(false)
        setUserHasChanges(false)
    }

    return (
        <StyledDialog
            title="Adicionar documento"
            resetBodyTopPadding
            subtitle={imageGroup?.name}
            active={active}
            onClose={handleClose} maxWidth="md">
            <form onSubmit={handleUploadVentureDocuments}>
                <Container>
                    <Label small>Upload apenas de documentos (PDF, JPG ou PNG)</Label>
                </Container>
                <Container mt="20px">
                    <SelectFile placeholder="Selecione documentos" multiple onSelect={handleVentureImagesOnSelect}/>
                </Container>
                {selectedDocuments.length > 0 && (
                    <Container>
                        <Container mt="40px">
                            <Label small>{selectedDocuments?.length} imagens selecionadas</Label>
                        </Container>
                        <Container mt="20px" grid columns={3} maxHeight="40vh" overflowAuto>
                            {selectedDocuments.map((selectedDocument, index) => (
                                <Container key={index}>
                                    <Container>Document</Container>
                                    <Container mt="10px" flex alignCenter justifyBetween>
                                        {fileToBeUpdated === index ? (
                                            <Container fluid flex alignCenter>
                                                <StyledInput
                                                value={selectedDocument.customName}
                                                small
                                                onKeyDown={handleFileNameUpdateCloseOnEnter}
                                                onChange={(e) => handleFileNameOnChange(e, index)} />
                                                <Container ml="20px">
                                                    <StyledButton
                                                        iconSize="16px"
                                                        iconButton
                                                        icon={{ url: closeIcon }}
                                                        onClick={() => setFileToBeUpdated(-1)} />
                                                </Container>
                                            </Container>
                                        ) : (
                                            <>
                                                <Container>
                                                    <Label>{selectedDocument.customName}</Label>
                                                </Container>
                                                <Container flex alignCenter>
                                                    <Container>
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton icon={{url: editIcon}}
                                                            onClick={() => handleUpdateFileName(index)}/>
                                                    </Container>
                                                    <Container ml="10px">
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton icon={{url: deleteIcon}}
                                                            onClick={() => handleDeleteVentureImage(index)}/>
                                                    </Container>
                                                </Container>
                                            </>
                                        )}
                                    </Container>
                                </Container>
                            ))}
                        </Container>
                    </Container>
                )}
                {loading && (
                    <Container mt="20px">
                        <ProgressBar width={uploadingProgressPercentage} />
                        <Container mt="10px">
                            <Label>
                                {`${uploadingProgressPercentage.toFixed(2)}%`}
                            </Label>
                        </Container>
                    </Container>
                )}
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container disabled={!userHasChanges} ml="10px">
                        <StyledButton
                            disabled={!userHasChanges}
                            kind="primary" type="submit"
                            loading={loading}>
                            Upload
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    uploadingProgressPercentage: state.images.uploadingProgressPercentage,
    resizingImages: state.images.resizingImages
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchCreateImageGroup({ corporationId, ventureId }, { name }) {
        await dispatch(createImageGroup({ corporationId, ventureId }, { name }))
    },

    async dispatchUploadVentureDocuments({ corporationId, ventureId }, { documents }) {
        await dispatch(uploadVentureDocuments({ corporationId, ventureId }, { documents }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(UploadVentureDocumentsDialog)
