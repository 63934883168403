// Libraries
import styled from "styled-components"


export const SnackbarContainer = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 20px;
  width: 100%;
`

export const SnackbarPositionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SnackbarCard = styled.div`
  width: 350px;
  padding: 0 20px;
  text-align: center;
  box-sizing: border-box;  height: 50px;
  box-shadow: 0px 4px 6px #00000061;
  background-color: ${(props) => {
    if (props.type === "success") {
        return "#4CAF50"
    } else if (props.type === "error") {
        return "#FF7961"
    }
    
    return "#333"
  }};
  color: white;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.active ? "0" : "-50vh"};
  transition: margin-bottom .6s ease-in-out;
`

