import store from "../../index";
export const CORPORATION_MORTGAGE = "CORPORATION_MORTGAGE"
export const BANK_MORTGAGE = "BANK_MORTGAGE"

class Corporation {
    constructor({
        id,
        name,
        description,
        logoUrl,
        mortgageOptions = [],
        mortgageAnnualFee
    }) {
        this.id = id
        this.name = name
        this.description = description
        this.logoUrl = logoUrl
        this.mortgageAnnualFee = mortgageAnnualFee
        this.mortgageOptions = mortgageOptions
    }

    static getCorporationById(corporationId) {
        const corporation = store.getState().corporation.corporations.find(c => c.id === parseInt(corporationId))
        return corporation
    }

    getMortgageOptions() {
        return [
            { name: "Direto com o incorporador", value: CORPORATION_MORTGAGE },
            { name: "Com o seu banco de preferência", value: BANK_MORTGAGE },
        ]
    }
}



export default Corporation
