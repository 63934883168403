import {
    SET_CURRENT_VENTURE_DOCUMENTS,
    SET_UPLOADING_PROGRESS_PERCENTAGE
} from "./mutations";

const initialState = {
    currentVentureDocuments: [],
    uploadingProgressPercentage: 0
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CURRENT_VENTURE_DOCUMENTS:
            return { ...state, currentVentureDocuments: action.payload }
        case SET_UPLOADING_PROGRESS_PERCENTAGE:
            return { ...state, uploadingProgressPercentage: action.payload }
        default:
            return state;
    }
}

export default reducer;
