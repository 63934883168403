// Libraries
import React, {useState, useEffect, useContext} from "react"
import { connect } from "react-redux"

// Styles
import {CommonQuestionsContainer, CommonQuestionContainer} from "./styled"

// Structure components
import { Container, Label, Span } from "../../../../structureComponents"

// Components
import StyledButton from "../../../../components/StyledButton/StyledButton"
import InlineLoading from "../../../../components/Loading/Loading"
import CreateCommonQuestionDialog from "./CreateCommonQuestionDialog/CreateCommonQuestionDialog"
import { deleteCommonQuestion, fetchCommonQuestions } from "../../../../store/modules/commonQuestions/actions"

// Store
import { setSnackbarState } from "../../../../store/modules/global/actions"

// Assets
import editIcon from "../../../../assets/icons/edit.svg"
import trashIcon from "../../../../assets/icons/trash.svg"
import EmptyState from "../../../../components/EmptyState/EmptyState"
import ConfirmDeleteDialog from "../../../../businessComponents/ConfirmDeleteDialog/ConfirmDeleteDialog"

import faqIcon from "../../../../assets/icons/faq.svg"
import UpdateCommonQuestionDialog from "./UpdateCommonQuestionDialog/UpdateCommonQuestionDialog";
import {ADMIN_USER_ROLE} from "../../../../utils/constants";
import {AuthContext} from "../../../../Auth";


function CommonQuestions({
    ventureCommonQuestions = [],
    dispatchFetchCommonQuestions,
    currentVenture,
    dispatchDeleteCommonQuestion,
    dispatchSnackbar
}) {
    const { currentUser } = useContext(AuthContext)

    // States
    const [showCreateCommonQuestionDialog, setShowCreateCommonQuestionDialog] = useState(false)
    const [selectedCommonQuestion, setSelectedCommonQuestion] = useState(null)
    const [showUpdateCommonQuestionDialog, setShowUpdateCommonQuestionDialog] = useState(false)
    const [showDeleteCommonQuestionDialog, setShowDeleteCommonQuestionDialog] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingRequest, setLoadingRequest] = useState(false)


    // Mounted
    useEffect(() => {
        if (currentVenture) {
            handleFetchCommonQuestions()
        } else {
            setLoading(false)
        }
    }, [currentVenture])


    // Methods{
    const handleFetchCommonQuestions = async () => {
        setLoading(true)
        await dispatchFetchCommonQuestions({ ventureId: currentVenture?.id })
        setLoading(false)
    }

    const handleShowUpdateCommonQuestionDialog = (commonQuestion) => {
        setSelectedCommonQuestion(commonQuestion)
        setShowUpdateCommonQuestionDialog(true)
    }

    const handleShowDeleteCommonQuestionDialog = (commonQuestion) => {
        setSelectedCommonQuestion(commonQuestion)
        setShowDeleteCommonQuestionDialog(true)
    }

    const handleDeleteCommonQuestion = async () => {
        try {
            setLoadingRequest(true)

            await dispatchDeleteCommonQuestion({
                ventureId: currentVenture?.id,
                commonQuestionId: selectedCommonQuestion?.id
            })

            setShowDeleteCommonQuestionDialog(false)

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Pergunta frequente deletada com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoadingRequest(false)
        }
    }


    if (loading) {
        return (
            <Container flex alignCenter justifyCenter height="calc(100vh - 60px - 80px)">
                <InlineLoading />
            </Container>
        )
    }

    return (
        <>
        {ventureCommonQuestions.length === 0 ? (
            <Container flex alignCenter justifyCenter height="calc(100vh - 60px - 80px)">
                <EmptyState
                noAction={currentUser?.role !== ADMIN_USER_ROLE}
                icon={faqIcon}
                buttonText="Criar pergunta frequente"
                text="Nenhuma pergunta frequente cadastrada"
                onClick={() => setShowCreateCommonQuestionDialog(true)}/>
            </Container>

        ) : (
            <CommonQuestionsContainer>
                {currentVenture?.role === ADMIN_USER_ROLE && (
                    <Container flex alignCenter justifyEnd>
                        <StyledButton
                        kind="primary"
                        onClick={() => setShowCreateCommonQuestionDialog(true)}>
                            Criar pergunta frequente
                        </StyledButton>
                    </Container>
                )}

                <Container mt="40px">
                    <Container mb="20px">
                        <Label><Span bold>{ventureCommonQuestions.length}</Span> perguntas frequentes</Label>
                    </Container>
                    {ventureCommonQuestions.map((ventureCommonQuestion) => (
                        <CommonQuestionContainer>
                            <Container>
                                <Label>{ventureCommonQuestion?.question}</Label>
                            </Container>
                            {currentUser?.role === ADMIN_USER_ROLE && (
                                <Container flex alignCenter justifyEnd>
                                    <Container>
                                        <StyledButton
                                        iconButton
                                        icon={{ url: editIcon }}
                                        iconSize="20px"
                                        onClick={() => handleShowUpdateCommonQuestionDialog(ventureCommonQuestion)}/>
                                    </Container>
                                    <Container ml="10px">
                                        <StyledButton
                                        iconButton
                                        iconSize="20px"
                                        icon={{ url: trashIcon }}
                                        onClick={() => handleShowDeleteCommonQuestionDialog(ventureCommonQuestion)}/>
                                    </Container>
                                </Container>
                            )}
                        </CommonQuestionContainer>
                    ))}
                </Container>
            </CommonQuestionsContainer>
        )}

            <CreateCommonQuestionDialog
            active={showCreateCommonQuestionDialog}
            onClose={() => setShowCreateCommonQuestionDialog(false)} />
            <UpdateCommonQuestionDialog
            active={showUpdateCommonQuestionDialog}
            selectedCommonQuestion={selectedCommonQuestion}
            onClose={() => setShowUpdateCommonQuestionDialog(false)} />
            <ConfirmDeleteDialog
            active={showDeleteCommonQuestionDialog}
            title="Deletar pergunta frequente"
            loading={loadingRequest}
            text="Tem certeza que deseja deletar esta pergunta frequente?"
            onClose={() => setShowDeleteCommonQuestionDialog(false)}
            onDelete={handleDeleteCommonQuestion} />
        </>
    )
}


const mapStateToProps = (state) => ({
    ventureCommonQuestions: state.commonQuestions.ventureCommonQuestions,
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchCommonQuestions({ ventureId, corporationId }) {
        await dispatch(fetchCommonQuestions({ ventureId, corporationId }))
    },

    async dispatchDeleteCommonQuestion({ ventureId, commonQuestionId }) {
        await dispatch(deleteCommonQuestion({ ventureId, commonQuestionId }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CommonQuestions)
