// Libraries
import React, {useEffect, useState} from "react"

// Styles
import {VentureAboutProjectContainer} from "./styled"

// Components
import StyledTabs from "../../../../components/StyledTabs/StyledTabs"

// Utils
import {
    PROJECT_DESCRIPTION_TAB,
    FACILITIES_TAB,
    HIGHLIGHTS_TAB,
    BUILDINGS_INFO_TAB
} from "../../../../utils/constants"

// Partials
import VentureProjectDescription from "./VentureProjectDescription/VentureProjectDescription"
import VentureFacilities from "./VentureFacilities/VentureFacilities"
import VentureHighlights from "./VentureHighlights/VentureHighlights"
import VentureBuildingsInfo from "./VentureBuildingsInfo/VentureBuildingsInfo"
import DiscardChangesDialog from "../../../../components/DiscardChangesDialog/DiscardChangesDialog";


function VentureAboutProject() {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)

    // Methods
    const handleOnUserChanges = (hasChanges) => {
        setUserHasChanges(hasChanges)
    }

    const aboutProjectTabs = [
        {
            name: PROJECT_DESCRIPTION_TAB,
            label: "Descrição do projeto",
            children: <VentureProjectDescription onUserChanges={handleOnUserChanges} />
        },
        {
            name: BUILDINGS_INFO_TAB,
            label: "Sobre a construção",
            children: <VentureBuildingsInfo onUserChanges={handleOnUserChanges} />
        },
        {
            name: FACILITIES_TAB,
            label: "Áreas de convívio e lazer",
            children: <VentureFacilities onUserChanges={handleOnUserChanges}/>
        },
        {
            name: HIGHLIGHTS_TAB,
            label: "Diferenciais",
            children: <VentureHighlights onUserChanges={handleOnUserChanges}/>
        }
    ]

    return (
        <VentureAboutProjectContainer>
            <StyledTabs tabs={aboutProjectTabs} userHasChanges={userHasChanges} />
        </VentureAboutProjectContainer>
    )
}

export default VentureAboutProject
