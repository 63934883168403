// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import StyledSwitch from "../../../../components/StyledSwitch/StyledSwitch";
import CorporationHandler from "../../../../handlers/CorporationHandler";
import { setSnackbarState } from "../../../../store/modules/global/actions";

// Structure components
import { Container, Label, Text } from "../../../../structureComponents"

// Styles
import {CorporationSettingsContainer} from "./styled";
import {Divider} from "@material-ui/core";



const RESTRICT_RECOMMENDATIONS = "RESTRICT_RECOMMENDATIONS"
const PRIORITIZE_VENTURES_ON_SEARCH = "PRIORITIZE_VENTURES_ON_SEARCH"
const HOME_SPOTLIGHT_VENTURES = "HOME_SPOTLIGHT_VENTURES"

function CorporationSettings({
    dispatchSnackbar,
    currentCorporation
}) {
    // State
    const [restrictRecommendation, setRestrictRecommendation] = useState(false)
    const [homeSpotlightVentures, setHomeSpotlightVentures] = useState(false)
    const [prioritizeVenturesOnSearch, setPrioritizeVenturesOnSearch] = useState(false)

    // Mounted
    useEffect(() => {
        handleFetchCorporationSettings()
    }, [])

    // Methods
    const handleFetchCorporationSettings = async () => {
        try {
            const corporationSettings = await CorporationHandler.fetchCorporationSettings({
                corporationId: currentCorporation.id
            })

            const restrictRecommentationSetting = corporationSettings.find(setting => setting.settingKey === "RESTRICT_RECOMMENDATIONS")
            const homeSpotlightVenturesSetting = corporationSettings.find(setting => setting.settingKey === "HOME_SPOTLIGHT_VENTURES")
            const prioritizeVenturesOnSearch = corporationSettings.find(setting => setting.settingKey === PRIORITIZE_VENTURES_ON_SEARCH)

            setRestrictRecommendation(!!restrictRecommentationSetting?.settingValue)
            setHomeSpotlightVentures(!!homeSpotlightVenturesSetting?.settingValue)
            setPrioritizeVenturesOnSearch(!!prioritizeVenturesOnSearch?.settingValue)
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        }
    }

    const handleRestrictRecommendationOnChange = async (settingValue) => {
        try {
            await CorporationHandler.updateCorporationSettings({ corporationId: currentCorporation.id } ,{
                settingKey: RESTRICT_RECOMMENDATIONS,
                settingValue: +settingValue
            })


            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Configuracoes atualizadas com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        }
    }

    const handleSpotlight = async (settingValue) => {
        try {
            await CorporationHandler.updateCorporationSettings({ corporationId: currentCorporation.id } ,{
                settingKey: HOME_SPOTLIGHT_VENTURES,
                settingValue: +settingValue
            })


            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Configuracoes atualizadas com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        }
    }

    const handlePrioritizeVenturesOnSearch = async (settingValue) => {
        try {
            await CorporationHandler.updateCorporationSettings({ corporationId: currentCorporation.id } ,{
                settingKey: PRIORITIZE_VENTURES_ON_SEARCH,
                settingValue: +settingValue
            })


            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Configuracoes atualizadas com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        }
    }

    return (
        <CorporationSettingsContainer>
            <Container>
                <Label bold large>Configuracoes</Label>
            </Container>
            <Container mt="40px">
                <Label bold medium>Recomendacoes</Label>
                <Container fluid maxWidth="40em" mt="20px">
                    <Container>
                        <Container flex alignCenter justifyBetween>
                            <Label bold>Restringir recomendacoes somente da incorporadora</Label>
                            <StyledSwitch
                            value={restrictRecommendation}
                            onChange={() => handleRestrictRecommendationOnChange(!restrictRecommendation)}/>
                        </Container>
                        <Container fluid maxWidth="32em" mt="5px">
                            <Text small>
                                As recomendacoes que aparecerao nas paginas do empreendimento da
                                incorporadora serao restritas aos empreendimentos da incorporadora
                            </Text>
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Container p="40px 0">
                <Divider />
            </Container>
            <Container>
                <Label bold medium>Destaque na home</Label>
                <Container fluid maxWidth="40em" mt="20px">
                    <Container>
                        <Container flex alignCenter justifyBetween>
                            <Label bold>Incluir os empreendimentos desta incorporadora nos destaques da home</Label>
                            <StyledSwitch
                                value={homeSpotlightVentures}
                                onChange={() => handleSpotlight(!homeSpotlightVentures)}/>
                        </Container>
                        <Container fluid maxWidth="32em" mt="5px">
                            <Text small>
                                Os empreendimentos dessa incorporadora aparecerao de forma aleatoria nos destaques
                                da home.
                            </Text>
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Container p="40px 0">
                <Divider />
            </Container>
            <Container>
                <Label bold medium>Busca</Label>
                <Container fluid maxWidth="40em" mt="20px">
                    <Container>
                        <Container flex alignCenter justifyBetween>
                            <Label bold>Priorizar empreendimentos na listagem da busca</Label>
                            <StyledSwitch
                                value={prioritizeVenturesOnSearch}
                                onChange={() => handlePrioritizeVenturesOnSearch(!prioritizeVenturesOnSearch)}/>
                        </Container>
                        <Container fluid maxWidth="32em" mt="5px">
                            <Text small>
                                Ao habilitar essa função, os empreendimentos desse incorporador serão priorizados no
                                topo das listagens da página de busca
                            </Text>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </CorporationSettingsContainer>
    )
}

const mapStateToProps = (state) => ({
    currentCorporation: state.corporations.currentCorporation
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CorporationSettings)
