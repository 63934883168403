// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../structureComponents";
import StyledSlider from "../../../components/StyledSlider/StyledSlider";
import StyledInput from "../../../components/StyledInput/StyledInput";
import Formatter from "../../../utils/Formatter";
import StyledButton from "../../../components/StyledButton/StyledButton";
import StyledChip from "../../../components/StyledChip/StyledChip";
import {MoreFiltersActionContainer} from "./styled";
import {fetchActiveVentures, resetFilters} from "../../../store/modules/ventures/actions";


function MoreFilters ({
    maxRangeFilters,
    currentFilters,
    dispatchFetchActiveVentures,
    dispatchResetFilters,
    onLoading = () => ({})
}) {
    // State
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        active: false,
        minSizeDefault: 50,
        maxSizeDefault: 2000,
        minSize: 50,
        maxSize: 2000,
        isFilterApplied: false,
        garageSpots: null,
        garageSpotsOptions: [
            { label: "1+", value: 1 },
            { label: "2+", value: 2 },
            { label: "3+", value: 3 },
            { label: "4+", value: 4 }
        ],
        moreFiltersAppliedLabel: "",
        moreFilters: [],
        userHasChanges: false
    })

    // Watchers
    useEffect(() => {
        if (currentFilters) {
            setState({
                ...state,
                garageSpots: currentFilters.garageSpots,
                maxSize: currentFilters.maxSize || state.maxSize,
                minSize: currentFilters.minSize || state.minSize
            })
        }
    }, [currentFilters])


    useEffect(() => {
        if (maxRangeFilters) {
            setState({
                ...state,
                maxSizeDefault: maxRangeFilters.maxSize,
                maxSize: maxRangeFilters.maxSize
            })
        }
    }, [maxRangeFilters])

    // Methods
    const handleGarageSpotsOnChange = (garageSpots) => {
        setState({
            ...state,
            garageSpots: state.garageSpots === garageSpots ? null : garageSpots,
            userHasChanges: true
        })
    }

    const handleSizeRangeOnChange = (range) => {
        setState({
            ...state,
            minSize: range[0],
            maxSize: range[1],
            userHasChanges: true
        })
    }

    const handleClear = async ({ onlyInternal }) => {
        onLoading(true)

        if (onlyInternal) {
            setState({
                ...state,
                minSize: maxRangeFilters.minSize,
                maxSize: maxRangeFilters.maxSize,
                garageSpots: null,
                isFilterApplied: false,
                moreFiltersAppliedLabel: "",
                active: false
            })

            dispatchResetFilters(["minSize", "maxSize", "garageSpots"])

            await dispatchFetchActiveVentures({
                minSize: null,
                maxSize: null,
                garageSpots: null,
                searchValue: currentFilters.searchValue
            })

            onLoading(false)

            return
        }

        setState({
            ...state,
            maxSize: maxRangeFilters.maxSize,
            bedrooms: null,
            garageSpots: null,
            isFilterApplied: false,
            moreFiltersAppliedLabel: "",
            active: false
        })

        dispatchResetFilters([])

        await dispatchFetchActiveVentures({
            minSize: null,
            maxSize: null,
            garageSpots: null,
            searchValue: currentFilters.searchValue
        })

        onLoading(false)
    }

    const handleApplyFilter = async () => {
        setLoading(true)
        onLoading(true)
        setState({ ...state, active: false })
        const filters = {}
        if (state.minSize && state.minSize !== state.minSizeDefault) filters.minSize = state.minSize
        if (state.maxSize && state.maxSize !== state.maxSizeDefault) filters.maxSize = state.maxSize
        if (state.garageSpots) filters.garageSpots = state.garageSpots

        setState({
            ...state,
            active: 0,
            isFilterApplied: true,
            moreFiltersAppliedLabel: Object.keys(filters).length
        })

        await dispatchFetchActiveVentures({ ...filters, searchValue: currentFilters.searchValue })

        onLoading(false)
        setLoading(false)
    }


    return (
        <>
            {!state.isFilterApplied ? (
                <StyledButton
                    kind={state.moreFiltersAppliedLabel ? "dark-active" : "dark-outlined"}
                    shape="rounded"
                    height="36px"
                    light
                    onClick={() => setState({ ...state, active: true })}>
                    Mais filtros
                </StyledButton>
            ) : (
                <StyledChip
                    label={`Mais filtros ${state.moreFiltersAppliedLabel}`}
                    kind="delete"
                    onClick={() => setState({ ...state, active: true })}
                    onDelete={() => handleClear({ onlyInternal: true })}/>
            )}
            <StyledDialog
                active={state.active}
                className="more-filters--dialog"
                title="Mais filtros"
                maxWidth="sm"
                onClose={() => setState({ ...state, active: false })}>
                <Container>
                    <Container className="more-filters-body"  overflowAuto maxHeight="calc(880px - 90px - 120px - 40px - 40px)">
                        <Container fluid maxWidth="376px">
                            <Container>
                                <Label semiBold xLarge>Vagas</Label>
                                <Container flex alignCenter mt="20px">
                                    {state.garageSpotsOptions.map((garageSpotOption, index) => (
                                        <Container key={index} ml={index > 0 ? "10px" : null}>
                                            <StyledButton
                                                kind="dark-outlined"
                                                shape="circle"
                                                active={state.garageSpots === garageSpotOption.value}
                                                onClick={() => handleGarageSpotsOnChange(garageSpotOption.value)}>
                                                {garageSpotOption.label}
                                            </StyledButton>
                                        </Container>
                                    ))}
                                </Container>
                            </Container>
                            <Container mt="48px">
                                <Label semiBold xLarge>Área (metragem)</Label>
                                <Container mt="20px">
                                    <StyledSlider
                                        minValue={state.minSizeDefault}
                                        maxValue={state.maxSizeDefault}
                                        value={[state.minSize, state.maxSize]}
                                        range
                                        step={50}
                                        onChange={handleSizeRangeOnChange} />
                                </Container>
                                <Container flex mt="20px">
                                    <Container>
                                        <Label>Área minima</Label>
                                        <Container mt="10px">
                                            <StyledInput value={Formatter.toBrazilianCurrency(state.minSize)} />
                                        </Container>
                                    </Container>
                                    <Container ml="16px">
                                        <Label>Área máxima</Label>
                                        <Container mt="10px">
                                            <StyledInput value={Formatter.toBrazilianCurrency(state.maxSize)} />
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                    <MoreFiltersActionContainer>
                        <StyledButton kind="primary-transparent" onClick={handleClear}>Limpar</StyledButton>
                        <StyledButton
                            kind="primary"
                            loading={loading}
                            disabled={!state.userHasChanges}
                            onClick={handleApplyFilter}>
                            Aplicar filtros
                        </StyledButton>
                    </MoreFiltersActionContainer>
                </Container>
            </StyledDialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentFilters: state.ventures.currentFilters
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchActiveVentures({ minSize, maxSize, garageSpots, searchValue }) {
        await dispatch(fetchActiveVentures({
            minSize,
            maxSize,
            garageSpots,
            searchValue
        }))
    },

    dispatchResetFilters(filters) {
        dispatch(resetFilters(filters))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(MoreFilters)
