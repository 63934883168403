// Libraries
import React, {useState, useEffect, useContext} from "react"
import { connect } from "react-redux"
import currencyFormatter from "currency-formatter"

// Styles
import {VentureBuildingsInfoContainer} from "./styled"

// Components
import StyledButton from "../../../../../components/StyledButton/StyledButton"

// Structure components
import {Container, Label} from "../../../../../structureComponents"
import StyledInput from "../../../../../components/StyledInput/StyledInput";

// Store
import {updateVentureBuildingsInfo} from "../../../../../store/modules/ventures/actions"
import MaskHelper from "../../../../../utils/MaskHelper";
import {setSnackbarState, setUserHasChanges} from "../../../../../store/modules/global/actions";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";


function VentureBuildingsInfo({
    currentVenture,
    dispatchUpdateVentureBuildingsInfo,
    onUserChanges = () => ({}),
    dispatchSnackbar,
    dispatchSetUserHasChanges
}) {
    const { currentUser } = useContext(AuthContext)

    // State
    const [loading, setLoading] = useState(false)
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [buildingsInfo, setBuildingsInfo] = useState({
        landSize: null,
        amountOfTowers: null,
        amountOfApartments: null,
        amountOfFloors: null
    })

    // Mounted
    useEffect(() => {
        onUserChanges(false)
    }, [])

    // Watchers
    useEffect(() => {
        if (currentVenture) {
            setBuildingsInfo({
                landSize: currencyFormatter.format(currentVenture.landSize, { code: "BRL", symbol: "" }),
                amountOfTowers: currentVenture.amountOfTowers,
                amountOfApartments: currentVenture.amountOfApartments,
                amountOfFloors: currentVenture.amountOfFloors
            })
        }
    }, [currentVenture])

    useEffect(() => {
        if (userHasChanges) {
            onUserChanges(userHasChanges)
        } else {
            onUserChanges(false)
        }
    }, [userHasChanges])

    // Methods
    const handleBuildingsInfoOnChange = (e, key) => {
        let value = e.target.value
        if (key === "landSize") {
            const maskValue = MaskHelper.toBrazilianNumberFormat(e.target.value)
            if (maskValue === "NaN") {
                value = ""
            } else {
                value = maskValue
            }
        }
        setBuildingsInfo({ ...buildingsInfo, [key]: value })

        if (buildingsInfo[key] !== e.target.value) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
        }
    }

    const handleUpdateVentureBuildingsInfo = async  () => {
        try {
            setLoading(true)
            const corporationId = currentVenture.corporationId
            const ventureId = currentVenture.id

            await dispatchUpdateVentureBuildingsInfo({ corporationId, ventureId }, buildingsInfo )
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Empreendimento atualizado com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <>
        <VentureBuildingsInfoContainer>
            <Container fluid maxWidth="80em" mt="40px">
                <Container>
                    <Container fluid maxWidth="10em">
                        <Label bold>Terreno m²</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                            <StyledInput
                                value={buildingsInfo.landSize}
                                onChange={(e) => handleBuildingsInfoOnChange(e, "landSize")}/>
                        </Container>
                    </Container>
                    <Container flex alignCenter mt="20px">
                        <Container fluid maxWidth="5em">
                            <Label bold>Torres</Label>
                            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                                <StyledInput
                                    placeholder="Nº"
                                    type="number"
                                    value={buildingsInfo.amountOfTowers}
                                    onChange={(e) => handleBuildingsInfoOnChange(e, "amountOfTowers")}/>
                            </Container>
                        </Container>
                        <Container fluid maxWidth="5em" ml="20px">
                            <Label bold>Andares</Label>
                            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                                <StyledInput
                                    placeholder="Nº"
                                    type="number"
                                    value={buildingsInfo.amountOfFloors}
                                    onChange={(e) => handleBuildingsInfoOnChange(e, "amountOfFloors")}/>
                            </Container>
                        </Container>
                        <Container fluid maxWidth="5em" ml="20px">
                            <Label bold>Unidades</Label>
                            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                                <StyledInput
                                    placeholder="Nº"
                                    type="number"
                                    value={buildingsInfo.amountOfApartments}
                                    onChange={(e) => handleBuildingsInfoOnChange(e, "amountOfApartments")}/>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                {currentUser?.role === ADMIN_USER_ROLE && (
                    <Container flex alignCenter fluid justifyEnd mt="40px">
                        <Container disabled={!userHasChanges} flex alignCenter justifyEnd ml="10px">
                            <StyledButton
                                disabled={!userHasChanges}
                                loading={loading}
                                kind="primary"
                                onClick={handleUpdateVentureBuildingsInfo}>
                                Salvar
                            </StyledButton>
                        </Container>
                    </Container>
                )}
            </Container>
        </VentureBuildingsInfoContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVentureBuildingsInfo({ corporationId, ventureId }, buildingsInfo) {
        await dispatch(updateVentureBuildingsInfo({ corporationId, ventureId }, buildingsInfo))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureBuildingsInfo)
