// Libraries
import axios from "axios"

// Helpers
import {decodeJWT} from "./helpers";
import {SET_CURRENT_USER, SET_USER_IS_LOGGED_IN} from "./mutations";
import {ORULO_END_USER_AUTH_TOKEN} from "../../../config";
import AuthHandler from "../../../handlers/AuthHandler";


export const login = ({ email, password }) => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/public/authentication`, {
            email,
            password
        })

        const { authToken } = response.data

        localStorage.setItem("LI_AUTH_TOKEN", authToken)

        const user = decodeJWT(authToken).data

        dispatch({ type: SET_CURRENT_USER, payload: user })
        dispatch({ type: SET_USER_IS_LOGGED_IN, payload: true })

        return user
    } catch (error) {
        throw new Error(error)
    }
}

export const logout = () => (dispatch) => {
    try {
        localStorage.removeItem("LI_AUTH_TOKEN")
        localStorage.removeItem(ORULO_END_USER_AUTH_TOKEN)

        dispatch({ type: SET_CURRENT_USER, payload: null })
        dispatch({ type: SET_USER_IS_LOGGED_IN, payload: false })

    } catch (error) {
        throw new Error(error)
    }
}

export const validateAuthToken = (authToken) => async (dispatch) => {
    try {
        await axios.get('/api/public/authentication/validateToken', {
            headers: { "x-auth-token": authToken }
        })

        const user = decodeJWT(authToken).data

        dispatch({ type: SET_CURRENT_USER, payload: user })
        dispatch({ type: SET_USER_IS_LOGGED_IN, payload: true })

        return true
    } catch (error) {
        throw new Error(error)
    }
}

export const setCurrentUser = (currentUser) => (dispatch) => {
    dispatch({ type: SET_CURRENT_USER, payload: currentUser })
}

export const dispatchPasswordRecoveryRequest = ({ email }) => async () => {
    await AuthHandler.recoverPassword({ email })
}
