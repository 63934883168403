// Libraries
import React from "react"
import { connect } from "react-redux"

// Structure components
import {Container} from "../../structureComponents";

// Styles
import PriceFilter from "./PriceFilter/PriceFilter";
import BedroomsFilter from "./BedroomsFilter/BedroomsFilter";
import MoreFilters from "./MoreFilters/MoreFilters";
import { VenturesFilterContainer } from "./styled"


const maxRangeFilters = {
    minPrice: 50000,
    maxPrice: 50000000,
    minSize: 50,
    maxSize: 2000
}

function VenturesFilters({ onLoading = () => ({}) }) {

    return (
        <VenturesFilterContainer>
            <Container flex alignCenter style={{ overflowY: "unset" }} className="ventures-filter-body-container">
                <Container>
                    <PriceFilter
                    maxPriceAllowed={maxRangeFilters?.maxPrice}
                    onLoading={(loading) => onLoading(loading)}/>
                </Container>
                <Container ml="10px">
                    <BedroomsFilter onLoading={(loading) => onLoading(loading)} />
                </Container>
                <Container ml="10px">
                   <MoreFilters
                   onLoading={(loading) => onLoading(loading)}
                   maxRangeFilters={maxRangeFilters} />
                </Container>
            </Container>
        </VenturesFilterContainer>
    )
}

export default connect(null, null)(VenturesFilters)
