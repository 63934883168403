// Libraries
import React, {useEffect, useState} from "react"

// Styles
import {SelectFileContainer, ChangeFileContainer} from "./styled"
import { Container } from "../../structureComponents"

// Components
import StyledButton from "../StyledButton/StyledButton"
import SvgIcon from "../SvgIcon/SvgIcon";

// Assets
import { primary } from "../../assets/colorPallet";


function SelectFile({
    placeholder = "Upload image",
    onSelect = () => ({}),
    multiple = false,
    fileUrl,
    loading = false
}) {
    // States
    const [selectedFileLocalUrl, setSelectedFileLocalUrl] = useState(null)

    // Watchers
    useEffect(() => {
        if (fileUrl) {
            setSelectedFileLocalUrl(fileUrl)
        }
    }, [fileUrl])

    // Methods
    const handleFileOnChange = (e) => {
        const { files } = e.target

        if (!multiple) {
            setSelectedFileLocalUrl(URL.createObjectURL(files[0]))
        }

        onSelect(files)
    }

    return (
        <>
        <SelectFileContainer>
            {selectedFileLocalUrl ? (
                <Container>
                    <img src={selectedFileLocalUrl} />
                </Container>
            ) : (
                <>
                    <input type="file" onChange={handleFileOnChange} multiple={multiple} />
                    <Container absolute>
                        <StyledButton slimHeight kind="primary-outlined">
                            {placeholder}
                        </StyledButton>
                    </Container>
                </>
            )}
        </SelectFileContainer>
            {selectedFileLocalUrl && (
                <ChangeFileContainer>
                    <Container absolute>
                        <StyledButton loading={loading} loadingColor={primary} slimHeight kind="primary-outlined">
                            Selecionar imagem
                        </StyledButton>
                    </Container>
                    <input type="file" onChange={handleFileOnChange} />
                </ChangeFileContainer>
            )}
        </>
    )
}


export default SelectFile
