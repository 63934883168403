// Libraries
import React, {useState} from "react"
import { connect } from "react-redux"

// Components
import StyledButton from "../../../components/StyledButton/StyledButton"
import StyledDialog from "../../../components/StyledDialog/StyledDialog"

// Structure components
import { Container, Label } from "../../../structureComponents"
import StyledInput from "../../../components/StyledInput/StyledInput";
import StyledSelect from "../../../components/StyledSelect/StyledSelect";
import TeamMember from "../../../models/TeamMember";
import {dispatchCreateTeamMember} from "../../../store/modules/teamMembers/actions";
import MaskHelper from "../../../utils/MaskHelper";
import StyledRadioGroup from "../../../components/StyledRadioGroup/StyledRadioGroup";


const roles = [
    { label: "Corretor", value: TeamMember.getAllowedRoles().BROKER_ROLE },
    { label: "Atendente", value: TeamMember.getAllowedRoles().ATTENDANT_ROLE }
]


const opportunityRestrictionOptions = [
    { label: "Sim", value: TeamMember.getAllowOpportunityRestrictionValues().APPLY_OPPORTUNITY_RESTRICTION },
    { label: "Não", value: TeamMember.getAllowOpportunityRestrictionValues().ALLOW_ALL_CORPORATIONS }
]


function CreateTeamMemberDialog({
    active,
    createTeamMember,
    onClose = () => ({})},
) {
    // States
    const [loading, setLoading] = useState(false)
    const [teamMember, setTeamMember] = useState(new TeamMember({}))

    // Methods
    const handleTeamMemberOnChange = (key, value) => {
        let _value = value
        if (key === "whatsappNumber") {
            _value = MaskHelper.toPhoneNumber(value)
        }
        setTeamMember(new TeamMember({
            ...teamMember,
            [key]: _value
        }))
    }

    const handleSave = async () => {
        setLoading(true)
        console.log({ teamMember })
        await createTeamMember({
            name: teamMember.name,
            lastName: teamMember.lastName,
            email: teamMember.email,
            role: teamMember.role,
            whatsappNumber: teamMember.whatsappNumber,
            opportunityRestriction: teamMember.opportunityRestriction
        })
        setLoading(false)
        onClose()
    }

    return (
        <StyledDialog
            title="Cadastrar membro da equipe"
            active={active}
            onClose={() => onClose()}
            maxWidth="sm">
            <Container>
                <Container>
                    <Container flex>
                        <Container fluid maxWidth="22em">
                            <Label medium bold>Nome</Label>
                            <Container mt="10px">
                                <StyledInput
                                placeholder="Digite o nome"
                                value={teamMember?.name}
                                onChange={(e) => handleTeamMemberOnChange("name", e.target.value)}/>
                            </Container>
                        </Container>
                        <Container fluid ml="20px">
                            <Label medium bold>Sobrenome</Label>
                            <Container mt="10px">
                                <StyledInput
                                placeholder="Digite o sobrenome"
                                value={teamMember?.lastName}
                                onChange={(e) => handleTeamMemberOnChange("lastName", e.target.value)}/>
                            </Container>
                        </Container>
                    </Container>
                    <Container fluid mt="20px">
                        <Label medium bold>Email</Label>
                        <Container mt="10px">
                            <StyledInput
                            placeholder="Digite o email"
                            value={teamMember?.email}
                            error={!teamMember?.emailIsValid && teamMember?.email?.length > 0}
                            helperText="Somente será aceito um email válido da Imobpro"
                            onChange={(e) => handleTeamMemberOnChange("email", e.target.value)}/>
                        </Container>
                    </Container>
                    <Container fluid mt="20px">
                        <Label medium bold>WhatsApp</Label>
                        <Container mt="10px">
                            <StyledInput
                                placeholder="Digite seu whatsApp"
                                value={teamMember?.whatsappNumber}
                                maxLength={15}
                                error={!teamMember?.whatsappNumberIsValid && teamMember?.whatsappNumber?.length > 0}
                                helperText="Somente será aceito um email válido da Imobpro"
                                onChange={(e) => handleTeamMemberOnChange("whatsappNumber", e.target.value)}/>
                        </Container>
                    </Container>
                    <Container fluid mt="20px">
                        <Label medium bold>Cargo</Label>
                        <Container mt="10px">
                            <StyledSelect
                            items={roles}
                            value={roles.find(role => role.value === teamMember.role)}
                            onChange={(selectedRole) => handleTeamMemberOnChange("role", selectedRole.value)}/>
                        </Container>
                    </Container>
                    <Container fluid mt="20px">
                        <Label medium bold>Restringir vendas apenas para Opportunity?</Label>
                        <Container mt="10px">
                            <StyledRadioGroup
                                value={teamMember?.opportunityRestriction}
                                onChange={(selectionOption) => handleTeamMemberOnChange("opportunityRestriction", selectionOption)}
                                items={opportunityRestrictionOptions} />
                        </Container>
                    </Container>
                </Container>
                <Container mt="40px" flex alignCenter justifyEnd fluid>
                    <Container>
                        <StyledButton kind="primary-outlined" slimHeight onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container disabled={!teamMember?.hasValidFields}  ml="10px">
                        <StyledButton
                        disabled={!teamMember?.hasValidFields}
                        loading={loading}
                        kind="primary"
                        slimHeight
                        onClick={handleSave}>
                            Salvar
                        </StyledButton>
                    </Container>
                </Container>
            </Container>
        </StyledDialog>
    )
}


const mapDispatchToProps = (dispatch) => ({
    async createTeamMember({ name, lastName, email, whatsappNumber, role, opportunityRestriction }) {
        await dispatch(dispatchCreateTeamMember({
            name,
            lastName,
            whatsappNumber,
            email,
            role,
            opportunityRestriction
        }))
    }
})

export default connect(null, mapDispatchToProps)(CreateTeamMemberDialog)