// Libraries
import styled from "styled-components"


export const VentureApartmentPlansContainer = styled.div`

`

export const VentureImage = styled.img`
  object-fit: contain;  
  height: 100%;
  width: 100%;
`
