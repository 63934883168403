// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Componets
import StyledDialog from "../../../components/StyledDialog/StyledDialog";
import StyledInput from "../../../components/StyledInput/StyledInput";
import StyledButton from "../../../components/StyledButton/StyledButton";

// Structure Components
import {Container, Label} from "../../../structureComponents";
import {createCorporation} from "../../../store/modules/corporations/actions";
import SelectFile from "../../../components/SelectFile/SelectFile";
import {uploadCorporationImage, uploadFiles} from "../../../store/modules/files/actions";


function CreateCorporationDialog({
    active = false,
    onClose = () => ({}),
    dispatchCreateCorporation,
    dispatchUploadFiles
}) {
    // States
    const [loading, setLoading] = useState(false)
    const [selectedLogoFile, setSelectedLogoFile] = useState(null)
    const [corporation, setCorporation] = useState({
        name: "",
        description: "",
        logoUrl: ""
    })

    // Mounted
    useEffect(() => {
      resetCorporation()
    }, [])

    useEffect(() => {
        resetCorporation()
    }, [active])

    // Methods
    const resetCorporation = () => {
        setCorporation({
            name: "",
            description: "",
            logoUrl: ""
        })
    }

    const handleFileOnSelect = (selectedFiles) => {
        const file = selectedFiles[0]
        setSelectedLogoFile(file)
    }

    const handleCorporationName = (e) => {
        setCorporation({ ...corporation, name: e.target.value })
    }

    const handleCorporationDescription = (e) => {
        setCorporation({ ...corporation, description: e.target.value })
    }

    const handleSaveCorporation = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)
            const files = [selectedLogoFile]

            const createdCorporation = await dispatchCreateCorporation({
                name: corporation.name,
                description: corporation.description,
                logoUrl: ""
            })

            await dispatchUploadFiles({ corporationId: createdCorporation.id }, files)

            setLoading(false)
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
        title="Criar Incorporadora"
        active={active}
        onClose={() => onClose()} maxWidth="sm">
            <form onSubmit={handleSaveCorporation}>
                <Container>
                    <SelectFile onSelect={handleFileOnSelect} />
                </Container>
                <Container mt="20px">
                    <Label bold>Nome</Label>
                    <Container mt="10px">
                        <StyledInput
                        value={corporation.name}
                        onChange={handleCorporationName} />
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label bold>Descricao</Label>
                    <Container mt="10px">
                        <StyledInput
                        value={corporation.description}
                        multiline
                        rows={8}
                        onChange={handleCorporationDescription}/>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton kind="primary-outlined" onClick={() => onClose()}>Cancelar</StyledButton>
                    </Container>
                    <Container ml="20px">
                        <StyledButton kind="primary" type="submit" loading={loading}>Salvar</StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapDispatchToProps = dispatch => ({
    async dispatchCreateCorporation({ name, description, logoUrl }) {
        const createdCorporation = await dispatch(createCorporation({ name, description, logoUrl }))
        return createdCorporation
    },

    async dispatchUploadFiles({ corporationId }, files) {
        const response = await dispatch(uploadCorporationImage({ corporationId }, files))
        return response
    }
})


export default connect(null, mapDispatchToProps)(CreateCorporationDialog)
