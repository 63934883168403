import styled from 'styled-components'
import {mediumLightGrey} from "../../assets/colorPallet";

const TxText = styled.p`
    color: ${props => {
        if(props.color) return props.color
        else if (props.lightGrey) return mediumLightGrey
        else return "#333"
    }};
    font-size: ${props => {
        if (props.small) return "12px"
        else if (props.medium) return "16px"
        else if (props.large) return "18px"
        else if (props.xLarge) return "20px"
        else return "14px"
    }};
    font-weight: ${props => props.bold ? "600" : null};
    margin: 0;
    padding: 0;
    letter-spacing: .025em;
    line-height: ${props => props.lineHeight ? props.lineHeight : "24px"};
    text-align: ${props => props.center ? "center" : null};
`

export default TxText
