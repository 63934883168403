// Libraries
import styled from "styled-components"


export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
`

export const LoginForm = styled.form`
  width: 100%;
  max-width: 26em;
`
