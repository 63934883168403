import { SET_CURRENT_USER, SET_USER_IS_LOGGED_IN } from "./mutations";

const initialState = {
    currentUser: null,
    userIsLoggedIn: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CURRENT_USER:
            return { ...state, currentUser: action.payload }
        case SET_USER_IS_LOGGED_IN:
            return { ...state, userIsLoggedIn: action.payload }
        default:
            return state;
    }
}

export default reducer;
