// Libraries
import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { ClickAwayListener } from '@material-ui/core'

// Styles
import {
  StyledColorPickerContainer,
  ChromePickerContainer,
StyledColorContainer,
} from './styled'

// Assets
import colorIcon from '../../assets/icons/color-picker.svg'

// Structure components
import { Container } from '../../structureComponents'
import StyledButton from "../StyledButton/StyledButton";

function StyledColorPicker({ value, onChange = () => ({}) }) {
  // state
  const [active, setActive] = useState(false)
  const [selectedColor, setSelectedColor] = useState('#000')

  // Watchers
  useEffect(() => {
    if (value) {
      setSelectedColor(value)
    }
  }, [value])

  // Methods
  const handleColorOnChange = (color) => {
    setSelectedColor(color?.hex)
    onChange(color?.hex)
  }

  return (
    <StyledColorPickerContainer>
      <Container p="10px" border="1px solid #ccc" radius="8px">
        <StyledColorContainer
          color={selectedColor}
          onClick={() => setActive(!active)}
        />
      </Container>
      <Container ml="20px">
        <StyledButton
          iconButton
          iconSize="36px"
          icon={colorIcon}
          onClick={() => setActive(!active)}
        />
      </Container>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => setActive(false)}
      >
        <ChromePickerContainer active={active}>
          <ChromePicker color={selectedColor} onChange={handleColorOnChange} />
        </ChromePickerContainer>
      </ClickAwayListener>
    </StyledColorPickerContainer>
  )
}

export default StyledColorPicker
