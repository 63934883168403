// Libraries
import React, {useState, useEffect, useContext} from "react"
import { connect } from "react-redux"
import {VentureHighlightsContainer} from "./styled";
import {updateVentureHighlights, fetchVentureHighlightsOptions} from "../../../../../store/modules/ventures/actions";
import StyledCheckboxList from "../../../../../components/StyledCheckboxList/StyledCheckboxList";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import {Container} from "../../../../../structureComponents";
import {setSnackbarState, setUserHasChanges} from "../../../../../store/modules/global/actions";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";


function VentureFacilities({
   dispatchUpdateVentureHighlights,
   currentVenture,
   ventureHighlightsOptions,
   dispatchFetchVentureHighlightsOptions,
    onUserChanges = () => ({}),
    dispatchSnackbar,
    dispatchSetUserHasChanges
}) {
    const { currentUser } = useContext(AuthContext)
    // State
    const [loading, setLoading] = useState(false)
    const [highlightsIds, setHighlightsIds] = useState([])
    const [userHasChanges, setUserHasChanges] = useState(false)


    // Mounted
    useEffect(() => {
        dispatchFetchVentureHighlightsOptions()
        onUserChanges(false)
    }, [])

    // Watchers
    useEffect(() => {
        if (currentVenture && currentVenture.highlightsIds) {
            setHighlightsIds(currentVenture.highlightsIds.map(id => parseInt(id)))
        }
    }, [currentVenture])

    useEffect(() => {
        if (userHasChanges) {
            onUserChanges(userHasChanges)
        } else {
            onUserChanges(false)
        }
    }, [userHasChanges])

    // Methods
    const handleVentureHighlightsOnChange = (ventureHighlightsIds) => {
        setHighlightsIds(ventureHighlightsIds.map(id => parseInt(id)))

        if (ventureHighlightsIds.length !== highlightsIds.length) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)

        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
        }
    }

    const handleUpdateVentureHighlights = async () => {
        try {
            setLoading(true)

            const corporationId = currentVenture.corporationId
            const ventureId = currentVenture.id
            const active = currentVenture.active

            await dispatchUpdateVentureHighlights({ corporationId, ventureId, active }, { highlightsIds })
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Empreendimento atualizado com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <VentureHighlightsContainer>
            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} fluid maxWidth="80em">
                <StyledCheckboxList
                    items={ventureHighlightsOptions}
                    checkedItems={currentVenture?.highlightsIds}
                    onChange={handleVentureHighlightsOnChange} />
                {currentUser?.role === ADMIN_USER_ROLE && (
                    <Container flex alignCenter fluid justifyEnd mt="40px">
                        <Container disabled={!userHasChanges} flex alignCenter justifyEnd ml="10px">
                            <StyledButton
                                disabled={!userHasChanges}
                                loading={loading}
                                kind="primary"
                                onClick={handleUpdateVentureHighlights}>
                                Salvar
                            </StyledButton>
                        </Container>
                    </Container>
                )}
            </Container>
        </VentureHighlightsContainer>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    ventureHighlightsOptions: state.ventures.ventureHighlightsOptions
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVentureHighlights({ corporationId, ventureId, active }, { highlightsIds }) {
        await dispatch(updateVentureHighlights({ corporationId, ventureId, active }, { highlightsIds }))
    },

    async dispatchFetchVentureHighlightsOptions() {
        await dispatch(fetchVentureHighlightsOptions())
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureFacilities)
