export const SET_VENTURES = "SET_VENTURES"
export const SET_CURRENT_VENTURE = "SET_CURRENT_VENTURE"
export const SET_VENTURE_FACILITIES_OPTIONS = "SET_VENTURE_FACILITIES_OPTIONS"
export const SET_VENTURE_HIGHLIGHTS_OPTIONS = "SET_VENTURE_HIGHLIGHTS_OPTIONS"
export const SET_PROPERTY_TYPES = "SET_PROPERTY_TYPES"
export const SET_VENTURE_TYPES = "SET_VENTURE_TYPES"
export const SET_ARCHIVE_VENTURES = "SET_ARCHIVE_VENTURES"
export const SET_INBOX_VENTURES = "SET_INBOX_VENTURES"
export const SET_INBOX_VENTURES_META = "SET_INBOX_VENTURES_META"
export const SET_ARCHIVE_VENTURES_META = "SET_ARCHIVE_VENTURES_META"
export const SET_ACTIVE_VENTURES = "SET_ACTIVE_VENTURES"
export const SET_ACTIVE_VENTURES_META = "SET_ACTIVE_VENTURES_META"
export const SET_INACTIVE_VENTURES = "SET_INACTIVE_VENTURES"
export const SET_INACTIVE_VENTURES_META = "SET_INACTIVE_VENTURES_META"
export const SET_CURRENT_FILTERS = "SET_CURRENT_FILTERS"