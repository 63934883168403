// Libraries
import React, {useContext, useState} from "react"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ProfileMenuContainer} from "./styled";
import {Container, Label} from "../../../structureComponents";

// Assets
import avatarIcon from "../../../assets/icons/avatar.svg"
import {logout} from "../../../store/modules/authentication/actions";
import {connect} from "react-redux";
import {AuthContext} from "../../../Auth";
import SvgIcon from "../../../components/SvgIcon/SvgIcon";
import ProfileMenuSideDrawer from "../ProfileMenuSideDrawer/ProfileMenuSideDrawer";
import {Avatar} from "@material-ui/core";


function ProfileMenu(props) {
    // context
    const { currentUser } = useContext(AuthContext)

    // States
    const [showProfileMenuSideDrawer, setShowProfileMenuSideDrawer] = useState(false)


    return (
        <>
            <ProfileMenuContainer onClick={() => setShowProfileMenuSideDrawer(true)}>
                <Container>
                    <Container flex alignCenter>
                        <Avatar src={currentUser?.teamMember?.avatarUrl} size="30px" color="#333" />
                        <Container flex alignCenter ml="10px">
                            <Label color="#333" medium bold pointer>{currentUser.name}</Label>
                            {currentUser?.teamMember && (
                                <Container flex alignCenter ml="10px" bgColor="#eee" p="5px 12px" radius="20em">
                                    <Label>id: {currentUser?.teamMember?.id}</Label>
                                </Container>
                            )}
                        </Container>
                    </Container>
                </Container>
            </ProfileMenuContainer>
            <ProfileMenuSideDrawer
            {...props}
            active={showProfileMenuSideDrawer}
            onLogout={() => props.logout()}
            onClose={() => setShowProfileMenuSideDrawer(false)} />
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    logout() {
        dispatch(logout())
    }
})

export default connect(null, mapDispatchToProps)(ProfileMenu)
