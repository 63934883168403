// Libraries
import React, {useEffect, useState} from "react"
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from "react-redux"

// Componets
import StyledDialog from "../../../components/StyledDialog/StyledDialog";
import StyledInput from "../../../components/StyledInput/StyledInput";
import StyledButton from "../../../components/StyledButton/StyledButton";

// Structure Components
import {Container, Label} from "../../../structureComponents";
import {createCorporation} from "../../../store/modules/corporations/actions";
import {createVenture} from "../../../store/modules/ventures/actions";
import StyledSelect from "../../../components/StyledSelect/StyledSelect";
import ViaCEPService from "../../../services/ViaCEPService";
import Venture from "../../../store/modules/ventures/model";
import ZipcodeSearch from "../../../businessComponents/ZipcodeSearch/ZipcodeSearch";


function CreateVentureDialog({
    active = false,
    onClose = () => ({}),
    dispatchCreateVenture,
    corporations
}) {
    // States
    const [missingFields, setMissingFields] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingCEP, setLoadingCEP] = useState(false)
    const [venture, setVenture] = useState(new Venture({
        name: null,
        zipcode: null,
        streetName: null,
        streetNumber: null,
        neighborhood: null,
        city: null,
        countryState: null,
        corporationId: null
    }))

    // Mounted
    useEffect(() => {
        setVenture(new Venture({
            name: null,
            zipcode: null,
            streetName: null,
            streetNumber: null,
            neighborhood: null,
            city: null,
            countryState: null,
            corporationId: null
        }))
    }, [])


    // Methods
    const handleCorporationOnSelect = (selectedCorporation) => {
        setMissingFields(missingFields.filter(m => m !== "corporationId"))
        setVenture(new Venture({
            ...venture,
            corporationId: selectedCorporation.id
        }))
    }
    const handleVentureName = (e) => {
        setMissingFields(missingFields.filter(m => m !== "name"))
        setVenture(new Venture({
            ...venture,
            name: e.target.value
        }))
    }

    const handleZipcodeOnChange = () => {
        setMissingFields(missingFields.filter(m => m !== "zipcode"))
    }

    const autoCompleteAddressByZipcode = async (ventureAddress) => {
        setVenture(new Venture({
            ...venture,
            streetName: ventureAddress.streetName,
            neighborhood: ventureAddress.neighborhood,
            city: ventureAddress.city,
            countryState: ventureAddress.countryState,
            zipcode: ventureAddress.zipcode
        }))

        setMissingFields(missingFields.filter((m) => {
            if (
                m !== "streetName"
                && m !== "neighborhood"
                && m !== "city"
                && m !== "countryState"
            ) {
                return m
            }
        }))
    }

    const handleStreetNameOnChange = (e) => {
        setMissingFields(missingFields.filter(m => m !== "streetName"))
        setVenture(new Venture({
            ...venture,
            streetName: e.target.value
        }))
    }

    const handleStreetNumberOnChange = (e) => {
        setMissingFields(missingFields.filter(m => m !== "streetNumber"))
        setVenture(new Venture({
            ...venture,
            streetNumber: e.target.value
        }))
    }

    const handleCountryStateOnChange = (e) => {
        setMissingFields(missingFields.filter(m => m !== "countryState"))

        setVenture(new Venture({
            ...venture,
            countryState: e.target.value
        }))
    }

    const handleNeighborhoodOnChange = (e) => {
        setMissingFields(missingFields.filter(m => m !== "neighborhood"))

        setVenture(new Venture({
            ...venture,
            neighborhood: e.target.value
        }))
    }

    const handleCityOnChange = (e) => {
        setMissingFields(missingFields.filter(m => m !== "city"))

        setVenture(new Venture({
            ...venture,
            city: e.target.value
        }))
    }

    const handleMissingFields = (ventureInstance) => {
        const missingFieldsOnProcess = []
        if (!ventureInstance.name) {
            missingFieldsOnProcess.push("name")
        }

        if (!ventureInstance.zipcode) {
            missingFieldsOnProcess.push("zipcode")
        }

        if (!ventureInstance.streetName) {
            missingFieldsOnProcess.push("streetName")
        }

        if (!ventureInstance.streetNumber) {
            missingFieldsOnProcess.push("streetNumber")
        }

        if (!ventureInstance.neighborhood) {
            missingFieldsOnProcess.push("neighborhood")
        }

        if (!ventureInstance.city) {
            missingFieldsOnProcess.push("city")
        }

        if (!ventureInstance.countryState) {
            missingFieldsOnProcess.push("countryState")
        }

        if (!ventureInstance.corporationId) {
            missingFieldsOnProcess.push("corporationId")
        }

        setMissingFields(missingFieldsOnProcess)
        return missingFieldsOnProcess
    }


    const handleSaveVenture = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const ventureInstance = new Venture({
                ...venture
            })

            const missingFieldsList = handleMissingFields(ventureInstance)

            if (!ventureInstance.creationPayloadIsValid && missingFieldsList.length > 0) {
                setLoading(false)
                return
            }


            await dispatchCreateVenture({corporationId: venture.corporationId}, {
                name: venture.name,
                zipcode: venture.zipcode,
                streetName: venture.streetName,
                streetNumber: venture.streetNumber,
                neighborhood: venture.neighborhood,
                city: venture.city,
                countryState: venture.countryState,
            })

            onClose()
            resetForm()
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const resetForm = () => {
        setVenture(new Venture({}))
    }

    const handleCancel = () => {
        onClose()
        resetForm()
    }

    return (
        <StyledDialog
        title="Criar Empreendimento"
        active={active}
        onClose={handleCancel} maxWidth="sm">
            <form onSubmit={handleSaveVenture}>
                <Container>
                    <StyledSelect
                    items={corporations}
                    itemValue="id"
                    error={missingFields.includes("corporationId")}
                    itemLabel="name"
                    placeholder="Selecione a incorporadora"
                    onChange={handleCorporationOnSelect}/>
                </Container>
                <Container flex alignCenter mt="20px">
                    <Container fluid>
                        <Label bold>Nome</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.name}
                                error={missingFields.includes("name")}
                                helperText="Nome do empreendimento invalido"
                                onChange={handleVentureName} />
                        </Container>
                    </Container>
                    <Container ml="20px">
                       <ZipcodeSearch
                       error={missingFields.includes("zipcode")}
                       onChange={handleZipcodeOnChange}
                       onSearch={autoCompleteAddressByZipcode} />
                    </Container>
                </Container>
                <Container mt="20px" flex alignCenter>
                    <Container fluid>
                        <Label bold>Logradouro</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.streetName}
                                error={missingFields.includes("streetName")}
                                helperText="Logradouro invalido"
                                onChange={handleStreetNameOnChange} />
                        </Container>
                    </Container>
                    <Container ml="20px">
                        <Label bold>Numero</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.streetNumber}
                                error={missingFields.includes("streetNumber")}
                                helperText="Numero invalido"
                                onChange={handleStreetNumberOnChange} />
                        </Container>
                    </Container>
                </Container>
                <Container mt="20px" flex alignCenter>
                    <Container fluid>
                        <Label bold>Estado</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.countryState}
                                error={missingFields.includes("countryState")}
                                helperText="Estado invalido"
                                onChange={handleCountryStateOnChange} />
                        </Container>
                    </Container>
                    <Container fluid ml="20px">
                        <Label bold>Cidade</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.city}
                                error={missingFields.includes("city")}
                                helperText="Cidade invalida"
                                onChange={handleCityOnChange} />
                        </Container>
                    </Container>
                    <Container fluid ml="20px">
                        <Label bold>Bairro</Label>
                        <Container mt="10px">
                            <StyledInput
                                value={venture.neighborhood}
                                error={missingFields.includes("neighborhood")}
                                helperText="Bairro invalido"
                                onChange={handleNeighborhoodOnChange} />
                        </Container>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton kind="primary-outlined" onClick={handleCancel}>Cancelar</StyledButton>
                    </Container>
                    <Container ml="20px">
                        <StyledButton kind="primary" type="submit" loading={loading}>Salvar</StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    corporations: state.corporations.corporations
})

const mapDispatchToProps = dispatch => ({
    async dispatchCreateVenture({corporationId}, {
        name,
        zipcode,
        streetName,
        streetNumber,
        neighborhood,
        city,
        countryState,
    }) {
        dispatch(createVenture({corporationId}, {
            name,
            zipcode,
            streetName,
            streetNumber,
            neighborhood,
            city,
            countryState
        }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateVentureDialog)
