import styled from "styled-components"
import {neutral500} from "../../assets/colorPallet";


export const StyledAvatarContainer = styled.div`
  width: ${props => props.width ? props.width : "150px"};
  height: ${props => props.height ? props.height : "150px"};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .styled-avatar--input {
    position: absolute;
    width: ${props => props.width ? props.width : "150px"};
    opacity: 0;
    height: ${props => props.height ? props.height : "150px"};;
    border-radius: 50%;
  }
`