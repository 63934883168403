// Libraries
import React, {useEffect, useState} from "react"

// Components
import StyledButton from "../../components/StyledButton/StyledButton"
import StyledDialog from "../../components/StyledDialog/StyledDialog"

// Structure components
import { Container, Label } from "../../structureComponents"
import StyledInput from "../../components/StyledInput/StyledInput";
import Validator from "../../utils/Validator";
import AuthHandler from "../../handlers/AuthHandler";

function PasswordRecoveryDialog({
    active,
    onClose = () => ({})
}) {
    const [email, setEmail] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [requestSent, setRequestSent] = useState(false)

    useEffect(() => {
        if (active) {
            setLoading(false)
            setRequestSent(false)
            setEmail(null)
            setEmailError(null)
        }
    }, [active])

    // Methods
    const handleEmailOnChange = (e) => {
        setEmailError(null)
        setEmail(e.target.value)
    }

    const handlePasswordRecoveryRequest = async () => {
        try {
            if (!Validator.isValidEmail(email)) {
                setEmailError("Email inválido")
                return
            } else {
                setEmailError(null)
            }

            setLoading(true)
            await AuthHandler.recoverPassword({ email })
            setRequestSent(true)
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Recuperar senha"
            active={active}
            onClose={() => onClose()}
            maxWidth="xs">
            <Container>
                {!requestSent ? (
                    <>
                        <Label>
                            Informe seu email de acesso e caso seja encontrado em nossa base de usuários,
                            você receberá um email para definir uma nova senha.
                        </Label>
                        <Container mt="20px">
                            <StyledInput
                                placeholder="Digite seu email de acesso"
                                value={email}
                                onChange={handleEmailOnChange} />
                        </Container>
                        {emailError && (
                            <Container mt="10px">
                                <Label error>{emailError}</Label>
                            </Container>
                        )}
                        <Container mt="40px" flex alignCenter justifyEnd fluid>
                            <Container>
                                <StyledButton kind="primary-outlined" slimHeight onClick={() => onClose()}>
                                    Cancelar
                                </StyledButton>
                            </Container>
                            <Container ml="10px">
                                <StyledButton
                                    loading={loading}
                                    kind="primary"
                                    slimHeight
                                    onClick={handlePasswordRecoveryRequest}>
                                    Recuperar senha
                                </StyledButton>
                            </Container>
                        </Container>
                    </>
                ) : (
                    <>
                        <Label>
                            Solicitação enviada com sucesso. Caso seu email seja encontrado em nossa
                            base, você receberá um email para cadastrar uma nova senha.
                        </Label>
                        <Container mt="40px" flex alignCenter justifyEnd fluid>
                            <Container>
                                <StyledButton
                                    loading={loading}
                                    kind="primary"
                                    slimHeight
                                    onClick={() => onClose()}>
                                    Fechar
                                </StyledButton>
                            </Container>
                        </Container>
                    </>
                )}
            </Container>
        </StyledDialog>
    )
}


export default PasswordRecoveryDialog
