// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import { VentureProjectDescriptionContainer } from "./styled"
import StyledInput from "../../../../../components/StyledInput/StyledInput";

// Structure components
import { Container } from "../../../../../structureComponents"

// Components
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Store
import { updateVentureProjectDescription } from "../../../../../store/modules/ventures/actions";
import {setSnackbarState, setUserHasChanges} from "../../../../../store/modules/global/actions";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";



function VentureProjectDescription({
   dispatchUpdateVentureProjectDescription,
   currentVenture,
   onUserChanges,
    dispatchSnackbar,
    dispatchSetUserHasChanges
}) {
    const { currentUser } = useContext(AuthContext)

    // States
    const [projectDescription, setProjectDescription] = useState(null)
    const [loading, setLoading] = useState(false)
    const [userHasChanges, setUserHasChanges] = useState(false)

    // Mounted
    useEffect(() => {
        onUserChanges(false)
    }, [])

    // Watchers
    useEffect(() => {
        if (currentVenture) {
            setProjectDescription(currentVenture.projectDescription)
        }
    }, [currentVenture])

    useEffect(() => {
        if (userHasChanges) {
            onUserChanges(userHasChanges)
        } else {
            onUserChanges(false)
        }
    }, [userHasChanges])

    // Methods
    const handleProjectDescriptionOnChange = (e) => {
        const projectDescriptionValue = e.target.value
        setProjectDescription(projectDescriptionValue)

        if (projectDescription !== projectDescriptionValue) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
        }
    }

    const handleUpdateProjectDescription = async () => {
        try {
            setLoading(true)
            const corporationId = currentVenture.corporationId
            const ventureId = currentVenture.id
            await dispatchUpdateVentureProjectDescription({ corporationId, ventureId }, { projectDescription })
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Empreendimento atualizado com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <VentureProjectDescriptionContainer>
            <Container fluid maxWidth="80em">
                <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}>
                    <StyledInput
                        multiline={true}
                        rows={20}
                        value={projectDescription}
                        placeholder="Descrição do projeto"
                        onChange={handleProjectDescriptionOnChange}/>
                </Container>
                {currentUser?.role === ADMIN_USER_ROLE && (
                    <Container flex alignCenter fluid justifyEnd mt="40px">
                        <Container disabled={!userHasChanges} flex alignCenter justifyEnd ml="10px">
                            <StyledButton
                                disabled={!userHasChanges}
                                loading={loading}
                                kind="primary"
                                onClick={handleUpdateProjectDescription}>
                                Salvar
                            </StyledButton>
                        </Container>
                    </Container>
                )}
            </Container>
        </VentureProjectDescriptionContainer>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const mapDispatchToProps = dispatch => ({
    async dispatchUpdateVentureProjectDescription({ corporationId, ventureId }, { projectDescription }) {
        await dispatch(updateVentureProjectDescription({ corporationId, ventureId }, { projectDescription }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureProjectDescription)
