import CorporationManager from "../models/CorporationManager";

export default class CorporationManagerHandler extends CorporationManager {
    static async createCorporationManager({ corporationId }, { email, name, phoneNumber }) {
        const corporationManager = await this.create({ corporationId }, {
            email,
            name,
            phoneNumber
        })

        return new CorporationManager({ ...corporationManager })
    }

    static async updateCorporationManager({ corporationId, corporationManagerId }, { email, name, phoneNumber }) {
        const updatedCorporationManager = await this.update({
            corporationId,
            corporationManagerId
        }, {
            email,
            name,
            phoneNumber
        })

        return new CorporationManager({ ...updatedCorporationManager })
    }

    static async getCorporationManagers(corporationId) {
        const corporationManagers = await this.findAllByCorporationId(corporationId)
            .map((corporationManager) => new CorporationManager({
                ...corporationManager
            }))

        return corporationManagers
    }
}
