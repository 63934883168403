// Libraries
import styled from "styled-components"


export const GeneralInfoLocationContainer = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 100%;
     box-sizing: border-box;
     margin-top: 40px;
`

export const GeneralInfoLocationForm = styled.form`
  width: 100%;
`
