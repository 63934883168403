// Libraries
import axios from "axios"

// Config
import store from "../../index";
import {SET_UPLOADING_PROGRESS_PERCENTAGE, SET_RESIZING_IMAGES, SET_PROPERTY_PLANS} from "./mutations"
import ImageHelper from "./helper";
import PropertyPlanHandler from "../../../handlers/PropertyPlanHandler"
import PropertyPlan from "../../../models/PropertyPlan";

export const dispatchUploadVenturePropertyPlans = ({ corporationId, ventureId }, { propertyPlans }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const uploadedImages = []

        dispatch({ type: SET_RESIZING_IMAGES, payload: true })

        const imagesToBeResized = []

        for (let i = 0; i < propertyPlans.length; i += 1) {
            const img = propertyPlans[i]

            imagesToBeResized.push(img.file)
        }

        const resizedImages = await ImageHelper.resizeAllImages(imagesToBeResized)
        dispatch({ type: SET_RESIZING_IMAGES, payload: false })

        let count = 1

        for (let imagePosition = 0; imagePosition < resizedImages.length; imagePosition += 1) {
            const image = resizedImages[imagePosition]

            let formData = new FormData()
            const imageName = propertyPlans.find((img, i) => imagePosition === i).customName
            formData.append('image', image, imageName)

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/propertyPlans`,
                formData,
                headers
            )

            uploadedImages.push(new PropertyPlan({
                ...response.data
            }))
            const uploadingProgressPercentage = (count / resizedImages.length) * 100
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: uploadingProgressPercentage })
            count += 1
        }

        const updatedPropertyPlans = [
            ...store.getState().propertyPlans.currentPropertyPlans,
            ...uploadedImages
        ]

        dispatch({ type: SET_PROPERTY_PLANS, payload: updatedPropertyPlans })

        setTimeout(() => {
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: 0 })
        }, 3000)
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const dispatchFetchVenturePropertyPlans = ({ corporationId, ventureId }) => async (dispatch) => {
    const propertyPlans = await PropertyPlanHandler.fetchPropertyPlans({
        corporationId,
        ventureId
    })

    dispatch({ type: SET_PROPERTY_PLANS, payload: propertyPlans })
}

export const dispatchUpdatePropertyPlan = ({ corporationId, ventureId, propertyPlanId }, {
    name, size, bedrooms, suites, garageSpots
}) => async (dispatch) => {
    const propertyPlan = await PropertyPlanHandler.updatePropertyPlan({
        corporationId,
        ventureId,
        propertyPlanId
    }, {
        name,
        size,
        bedrooms,
        suites,
        garageSpots
    })

    const propertyPlans = [...store.getState().propertyPlans.currentPropertyPlans].map((storedPropertyPlan) => {
        if (storedPropertyPlan.id === propertyPlan.id) {
            return new PropertyPlan({
                ...propertyPlan
            })
        }

        return storedPropertyPlan
    })

    dispatch({ type: SET_PROPERTY_PLANS, payload: propertyPlans })
}

export const dispatchDeletePropertyPlan = ({ corporationId, ventureId, propertyPlanId }) => async (dispatch) => {
    await PropertyPlanHandler.deletePropertyPlan({ corporationId, ventureId, propertyPlanId })

    const propertyPlans = [...store.getState().propertyPlans.currentPropertyPlans].filter((storedPropertyPlan) => {
        if (storedPropertyPlan.id === propertyPlanId) {
            return false
        }

        return true
    })

    dispatch({ type: SET_PROPERTY_PLANS, payload: propertyPlans })
}
