export default class Video {
    constructor({
        id,
        name,
        url,
        ventureId,
        corporationId
    }) {
        this.id = id
        this.name = name
        this.url = url
        this.ventureId = ventureId
        this.corporationId = corporationId
    }

    get hasValidData() {
        return this.name && this.url
    }
}
