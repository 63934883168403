// Libraries
import styled from 'styled-components'

export const StyledColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 12em;
`

export const ChromePickerContainer = styled.div`
  position: absolute;
  z-index: 50;
  margin-left: 13em;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity 0.4s ease, margin-top 0.4s ease;
  margin-top: ${(props) => (props.active ? '0' : '-100vh')};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};

  .chrome-picker {
    pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  }
`

export const StyledColorContainer = styled.div`
  width: 8em;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 0.8;
  }
`
