export default class PropertyPlan{
    constructor({
        id,
        name,
        corporationId,
        ventureId,
        imageUrl,
        imageId,
        size,
        bedrooms,
        garageSpots,
        suites
    }) {
        this.id = id
        this.name = name
        this.corporationId = corporationId
        this.ventureId = ventureId
        this.imageUrl = imageUrl
        this.imageId = imageId
        this.size = size
        this.bedrooms = bedrooms
        this.garageSpots = garageSpots
        this.suites = suites
    }

    get sizeLabel() {
        if (this.size) {
            return `${this.size} m²`
        }

        return "- m²"
    }

    get bedroomsLabel() {
        let label = "quartos"
        if (this.bedrooms === 1) label = "quarto"

        if (this.bedrooms) {
            return `${this.bedrooms} ${label}`
        }

        if (this.bedrooms === 0) {
            return "Sem quarto"
        }

        return "- quartos"
    }

    get suitesLabel() {
        let label = "suites"
        if (this.suites === 1) label = "suites"

        if (this.suites) {
            return `${this.suites} ${label}`
        }

        if (this.suites === 0) {
            return "Sem suites"
        }

        return "- suites"
    }

    get garageSpotsLabel() {
        let label = "vagas"
        if (this.garageSpots === 1) label = "vaga"

        if (this.garageSpots > 0) {
            return `${this.garageSpots} ${label}`
        }

        if (this.garageSpots === 0) {
            return "Sem vaga"
        }

        return `- vagas`
    }

    static getBedroomAmountOptions() {
        return [
            { label: 0, value: 0 },
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 },
            { label: 9, value: 9 },
            { label: 10, value: 10 },
            { label: 11, value: 11 }

        ]
    }
}
