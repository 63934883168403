// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../../structureComponents";
import StyledButton from "../../../../../../components/StyledButton/StyledButton";
import StyledInput from "../../../../../../components/StyledInput/StyledInput";
import ImageGroup from "../../../../../../store/modules/imageGroups/model";
import {createImageGroup} from "../../../../../../store/modules/imageGroups/actions";
import {setSnackbarState} from "../../../../../../store/modules/global/actions";


function CreateImageGroupDialog({
    active = false,
    onClose = () => ({}),
    currentVenture,
    dispatchCreateImageGroup = () => ({}),
    dispatchSnackbar
}) {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageGroup, setImageGroup] = useState(new ImageGroup({}))

    // Mounted
    useEffect(() => {
        resetLocalState()
    }, [])

    // Methods
    const handleClose = () => {
        onClose()
        resetLocalState()
    }

    const handleCreateImageGroup = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchCreateImageGroup({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id
            }, {
                name: imageGroup.name
            })

            setLoading(true)
            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Grupo de imagem criado com sucesso"
            })
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleImageGroupNameOnChange = (e) => {
        const imageGroupName = e.target.value
        setImageGroup({ ...imageGroup, name: imageGroupName })
        setUserHasChanges(true)
    }

    const resetLocalState = () => {
        setTimeout(() => {
            setUserHasChanges(false)
            setImageGroup(new ImageGroup({}))
        }, 1000)
    }


    return (
        <StyledDialog
            title="Criar grupo de imagem"
            active={active}
            onClose={handleClose} maxWidth="sm">
            <form onSubmit={handleCreateImageGroup}>
                <Container>
                    <Label bold>Nome</Label>
                    <Container mt="10px">
                        <StyledInput
                            placeholder="Nome do grupo de imagem"
                            value={imageGroup.name}
                            onChange={handleImageGroupNameOnChange} />
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container disabled={!userHasChanges} ml="10px">
                        <StyledButton
                            disabled={!userHasChanges}
                            kind="primary" type="submit"
                            loading={loading}>
                            Salvar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchCreateImageGroup({ corporationId, ventureId }, { name }) {
        await dispatch(createImageGroup({ corporationId, ventureId }, { name }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateImageGroupDialog)
