export default class GeneralHelper {
    static groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if(obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
            }, {})
    }

    static validateEmail(email) {
        const re = /\S+@\S+\.\S+/
        return re.test(email);
    }
}
