import styled from 'styled-components'
import { primary } from '../../assets/colorPallet'

const Logo = styled.h2`
    font-size: 36px;
    font-weight: 600;
    color: ${primary};
    cursor: pointer;
    
    a{
      text-decoration: none;
    }
`

export default Logo
