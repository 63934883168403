// Libraries
import styled from "styled-components"
import {primary} from "../../../../assets/colorPallet";


export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
`

export const ImageContainer = styled.div`
  width: 200px;
  height: 150px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-icon-container {
    opacity: 0;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 40px;
    background-color: ${primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s ease-in-out;
  }
  
  .image {
    opacity: ${props => props.active ? .7 : null};
  }
  
  .image-icon-container {
    opacity: ${props => props.active ? 1 : null};
  }
  
  &:hover {
    .image-icon-container {
      opacity: 1;
    }

    .image {
      opacity: .7;
    }
  }
`
