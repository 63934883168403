// Libraries
import axios from "axios"
import currencyFormatter from "currency-formatter"

// Models
import Corporation from "./model"

// Mutations
import {SET_CORPORATIONS, SET_CURRENT_CORPORATION} from "./mutations"
import store from "../../index";


export const createCorporation = ({ name, description, logoUrl }) => async (dispatch) => {
    try {
        const payload = { name, description, logoUrl }
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/corporations`, payload, headers )

        const corporation = new Corporation({
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            logoUrl: response.data.logoUrl
        })

        const corporationsClone = [ ...store.getState().corporations.corporations ]
        corporationsClone.push(corporation)
        dispatch({ type: SET_CORPORATIONS, payload: corporationsClone })

        return corporation
    } catch (error) {
        throw new Error(error)
    }
}

export const updateCorporation = ({ corporationId }, { 
    name, 
    description, 
    logoUrl,
    mortgageOptions,
    mortgageAnnualFee
}) => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const payload = {}
        if (name) payload.name = name
        if (description) payload.description = description
        if (logoUrl) payload.logoUrl = logoUrl
        if (mortgageAnnualFee) payload.mortgageAnnualFee = currencyFormatter.unformat(mortgageAnnualFee, { code: "BRL" }).toFixed(2)
        if (mortgageOptions) payload.mortgageOptions = mortgageOptions

        const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}`, payload, headers )

        const updatedCorporation = new Corporation({
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            logoUrl: response.data.logoUrl,
            mortgageAnnualFee: response.data.mortgageAnnualFee,
            mortgageOptions: response.data.mortgageOptions
        })

        const corporations = [ ...store.getState().corporations.corporations].map((c) => {
            if (c.id === corporationId) {
                return updatedCorporation
            }

            return c
        })

        dispatch({ type: SET_CURRENT_CORPORATION, payload: updatedCorporation })
        dispatch({ type: SET_CORPORATIONS, payload: corporations })
    } catch (error) {
        throw error
    }
}


export const fetchCorporations = () => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/corporations`, headers)

        const corporations = response.data.map((corporation) => new Corporation({
            id: corporation.id,
            name: corporation.name,
            description: corporation.description,
            logoUrl: corporation.logoUrl,
            mortgageAnnualFee: corporation.mortgageAnnualFee,
            mortgageOptions: corporation.mortgageOptions
        }))

        dispatch({ type: SET_CORPORATIONS, payload: corporations })

        return corporations
    } catch (error) {
        throw new Error(error)
    }
}

export const setCurrentCorporation = (currentCorporation) => dispatch => {
    dispatch({ type: SET_CURRENT_CORPORATION, payload: currentCorporation })
}
