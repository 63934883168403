import styled from "styled-components"


export const ProfileMenuSideDrawerOption = styled.div`
    cursor: pointer;
    padding: 15px 40px;
    display: flex;
    align-items: center;
  
    &:hover {
      background-color: #eee;
    }
`