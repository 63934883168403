// Libraries
import React, {useState, useEffect, useContext} from "react"
import { connect } from "react-redux"

// Styles
import {GeneralInfoDescriptionContainer} from "./styled"

// Structure components
import { Container, Label, Span } from "../../../../../structureComponents"
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import Venture, { READY_VENTURE_STATUS} from "../../../../../store/modules/ventures/model";
import {updateVenture} from "../../../../../store/modules/ventures/actions";
import SelectFile from "../../../../../components/SelectFile/SelectFile";
import {uploadFiles} from "../../../../../store/modules/files/actions";
import StyledSelect from "../../../../../components/StyledSelect/StyledSelect";
import {fetchCorporations} from "../../../../../store/modules/corporations/actions";
import {setSnackbarState, setUserHasChanges} from "../../../../../store/modules/global/actions";
import { fetchVentureTypes } from "../../../../../store/modules/ventures/actions"

//Helpers
import MaskHelper from "../../../../../utils/MaskHelper"
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";


function GeneralInfoDescription({
    currentVenture,
    dispatchUpdateVenture,
    dispatchUploadFiles,
    dispatchFetchCorporations,
    corporations,
    dispatchSnackbar,
    ventureTypes = [],
    dispatchVentureTypes,
    onUserChanges = () => ({}),
    dispatchSetUserHasChanges
}) {

    const { currentUser } = useContext(AuthContext)

    const fullAmountOptions = [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: "10", value: 10 },
    ]

    // States
    const [venture, setVenture] = useState(new Venture({}))
    const [loading, setLoading] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [fileTypeError, setFileTypeError] = useState(false)
    const [logoFile, setLogoFile] = useState(null)
    const [ventureCorporation, setVentureCorporation] = useState(null)
    const [selectedCorporation, setSelectedCorporation] = useState(null)
    const [minBedroomsAmountOptions, setMinBedroomsAmountOptions] = useState(fullAmountOptions)
    const [maxBedroomsAmountOptions, setMaxBedroomsAmountOptions] = useState(fullAmountOptions)
    const [minSuitesAmountOptions, setMinSuitesAmountOptions] = useState([
        { label: "0", value: 0 },
        ...fullAmountOptions
    ])
    const [maxSuitesAmountOptions, setMaxSuitesAmountOptions] = useState(fullAmountOptions)
    const [minBathroomsAmountOptions, setMinBathroomsAmountOptions] = useState(fullAmountOptions)
    const [maxBathroomsAmountOptions, setMaxBathroomsAmountOptions] = useState(fullAmountOptions)
    const [minGarageSpotsAmountOptions, setMinGarageSpotsAmountOptions] = useState([
        { label: "0", value: 0 },
        ...fullAmountOptions
    ])
    const [maxGarageSpotsAmountOptions, setMaxGarageSpotsAmountOptions] = useState(fullAmountOptions)
    const [ventureStatusOptions, setVentureStatusOptions]= useState([])

    // Mounted
    useEffect(() => {
        dispatchFetchCorporations()
        dispatchVentureTypes()
        onUserChanges(false)
    }, [])

    // Watchers
    useEffect(() => {
        if (currentVenture) {
            setVenture({
                ...currentVenture
            })

            if (currentVenture.minBedroomsAmount) {
                setMaxBedroomsAmountOptions(maxBedroomsAmountOptions.filter(option => option.value >= currentVenture.minBedroomsAmount))
            }

            if (currentVenture.minSuitesAmount) {
                setMaxSuitesAmountOptions(maxSuitesAmountOptions.filter(option => option.value >= currentVenture.minSuitesAmount))
            }

            if (currentVenture.minGarageSpotsAmount) {
                setMaxGarageSpotsAmountOptions(maxGarageSpotsAmountOptions.filter(option => option.value >= currentVenture.minGarageSpotsAmount))
            }

            if (currentVenture.minBathroomsAmount) {
                setMaxBathroomsAmountOptions(maxBathroomsAmountOptions.filter(option => option.value >= currentVenture.maxBathroomsAmount))
            }

            setVentureStatusOptions(Venture.getStatusOptions())
        }
    }, [currentVenture])

    useEffect(() => {
        if (corporations && currentVenture) {
            const ventCorp = corporations.find(c => c.id === currentVenture.corporationId)
            setVentureCorporation(ventCorp)
        }
    }, [corporations, currentVenture])

    // Methods
    const handleVentureLogoOnSelect = async (files) => {
        setFileTypeError(false)
        const file = files[0]
        const fileType = file.type

        const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"]

        if (!allowedFileTypes.includes(fileType)){
            setFileTypeError(true)
            return
        }

        setLogoFile(files[0])
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
        setVenture({ ...venture, logoUrl: URL.createObjectURL(files[0]) })
    }

    const handleVentureTypeOnChange = (selectedVentureType) => {
        setVenture({ ...venture, ventureType: selectedVentureType.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleVentureNameOnChange = (e) => {
        if (e.target.value !== venture.name) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
            onUserChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            onUserChanges(false)
        }

        setVenture({ ...venture, name: e.target.value })
    }

    const handleCorporationOnSelect = (corporation) => {
        setSelectedCorporation(corporation)

        if (corporation.id !== currentVenture.corporationId) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
            onUserChanges(true)
        }
    }

    // BEDROOMS
    const handleMinBedroomsAmountOnChange = (selectedMinBedroomsAmount) => {
        setMaxBedroomsAmountOptions(fullAmountOptions.filter(option => option.value >= selectedMinBedroomsAmount.value))
        setVenture({ ...venture, minBedroomsAmount: selectedMinBedroomsAmount.value, maxBedroomsAmount: null })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleMaxBedroomsAmountOnChange = (selectedMaxBedroomsAmount) => {
        setVenture({ ...venture, maxBedroomsAmount: selectedMaxBedroomsAmount.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    // SUITES
    const handleMinSuitesAmountOnChange = (selectedMinSuitesAmount) => {
        setMaxSuitesAmountOptions(fullAmountOptions.filter(option => option.value >= selectedMinSuitesAmount.value))
        setVenture({ ...venture, minSuitesAmount: selectedMinSuitesAmount.value, maxSuitesAmount: null })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleMaxSuitesAmountOnChange = (selectedMaxSuitesAmount) => {
        setVenture({ ...venture, maxSuitesAmount: selectedMaxSuitesAmount.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    // BATHROOMS
    const handleMinBathroomsAmountOnChange = (selectedMinBathroomsAmount) => {
        setMaxBathroomsAmountOptions(fullAmountOptions.filter(option => option.value >= selectedMinBathroomsAmount.value))
        setVenture({ ...venture, minBathroomsAmount: selectedMinBathroomsAmount.value, maxBathroomsAmount: null })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleMaxBathroomsAmountOnChange = (selectedMaxBathroomsAmount) => {
        setVenture({ ...venture, maxBathroomsAmount: selectedMaxBathroomsAmount.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    // GARAGE
    const handleMinGarageSpotsAmount = (selectedMinGarageSpotsAmount) => {
        setMaxGarageSpotsAmountOptions(fullAmountOptions.filter(option => option.value >= selectedMinGarageSpotsAmount.value))
        setVenture({ ...venture, minGarageSpotsAmount: selectedMinGarageSpotsAmount.value, maxGarageSpotsAmount: null })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleMaxGarageSpotsAmount = (selectedGarageSpotsAmount) => {
        setVenture({ ...venture, maxGarageSpotsAmount: selectedGarageSpotsAmount.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    // PRICES
    const handlePriceFromOnChange = (e) => {
        let value = e.target.value

        const maskValue = MaskHelper.toBrazilianNumberFormat(e.target.value)
        if (maskValue === "NaN") {
            value = ""
        } else {
            value = maskValue
        }

        setVenture({ ...venture, priceFrom: value })

        if (venture.priceFrom !== e.target.value) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
            onUserChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            onUserChanges(false)
        }
    }

    // MIN and MAX SIZE
    const handleMinAndMaxSize = (e, key) => {
        let value = e.target.value

        const maskValue = MaskHelper.toBrazilianNumberFormat(e.target.value)
        if (maskValue === "NaN") {
            value = ""
        } else {
            value = maskValue
        }

        setVenture({ ...venture, [key]: value })

        if (venture[key] !== e.target.value) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
            onUserChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            onUserChanges(false)
        }
    }


    // DESCRIPTION
    const handleHeroDescriptionOnChange = (e) => {
        setVenture({
            ...venture,
            heroDescription: e.target.value
        })

        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleVentureStatusOnChange = (selectedVentureStatus) => {
        setVenture(new Venture({
            ...venture,
            status: selectedVentureStatus.value
        }))

        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleVentureDeliveryDateOnChange = (e) => {
        setVenture(new Venture({
            ...venture,
            deliveryDate: MaskHelper.toDate(e.target.value, {
                format: "MM/YYYY"
            })
        }))

        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
        onUserChanges(true)
    }

    const handleSaveGeneralInfoDescription = async () => {
        try {
            setLoading(true)

            let logoUrl = null

            if (logoFile) {
                setLoadingFile(true)
                const logoUrls = await dispatchUploadFiles({
                    corporationId: currentVenture.corporationId,
                    ventureId: currentVenture.id
                }, [logoFile])

                setLoadingFile(false)
                logoUrl = logoUrls.url
            }

            const payload = {}
            if (venture.heroDescription !== currentVenture.heroDescription) payload.heroDescription = venture.heroDescription
            if (logoUrl !== currentVenture.logoUrl) payload.logoUrl = logoUrl
            if (venture.name !== currentVenture.name) payload.name = venture.name
            if (selectedCorporation) payload.newCorporationId = selectedCorporation.id
            if (venture.ventureType) payload.ventureType = venture.ventureType
            if (venture.minBedroomsAmount !== currentVenture.minBedroomsAmount) payload.minBedroomsAmount = venture.minBedroomsAmount
            if (venture.maxBedroomsAmount !== currentVenture.maxBedroomsAmount) payload.maxBedroomsAmount = venture.maxBedroomsAmount
            if (venture.minSuitesAmount !== currentVenture.minSuitesAmount) payload.minSuitesAmount = venture.minSuitesAmount
            if (venture.maxSuitesAmount !== currentVenture.maxSuitesAmount) payload.maxSuitesAmount = venture.maxSuitesAmount
            if (venture.minBathroomsAmount !== currentVenture.minBathroomsAmount) payload.minBathroomsAmount = venture.minBathroomsAmount
            if (venture.maxBathroomsAmount !== currentVenture.maxBathroomsAmount) payload.maxBathroomsAmount = venture.maxBathroomsAmount
            if (venture.minGarageSpotsAmount !== currentVenture.minGarageSpotsAmount) payload.minGarageSpotsAmount = venture.minGarageSpotsAmount
            if (venture.maxGarageSpotsAmount !== currentVenture.maxGarageSpotsAmount) payload.maxGarageSpotsAmount = venture.maxGarageSpotsAmount
            if (venture.priceFrom !== currentVenture.priceFrom) payload.priceFrom = venture.priceFrom
            if (venture.minSize !== currentVenture.minSize) payload.minSize = venture.minSize
            if (venture.maxSize !== currentVenture.maxSize) payload.maxSize = venture.maxSize
            if (venture.status !== currentVenture.status) payload.status = venture.status
            if (venture.deliveryDate !== currentVenture.deliveryDate) payload.deliveryDate = venture.deliveryDate

            await dispatchUpdateVenture({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id
            }, payload)

            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            onUserChanges(false)
            setSelectedCorporation(null)
            setLogoFile(null)
            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Empreendimento atualizado com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <GeneralInfoDescriptionContainer>
            <Container fluid>
                <Container fluid maxWidth="30em">
                    <Label bold>Logo do empreendimento <Span>(jpeg, jpg ou png)</Span></Label>
                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px">
                        <SelectFile  loading={loadingFile} onSelect={handleVentureLogoOnSelect} fileUrl={currentVenture?.logoUrl} />
                    </Container>
                    {fileTypeError && (
                        <Container mt="10px">
                            <Label small error>Extensão de arquivo não suportada. Faça o upload de um arquivo jpeg ou png</Label>
                        </Container>
                    )}
                </Container>
                <Container flex alignCenter mt="20px">
                    <Container fluid maxWidth="15em">
                        <Label bold>Status do empreendimento</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px">
                            <StyledSelect
                                items={ventureStatusOptions}
                                itemLabel="name"
                                value={ventureStatusOptions.find(v => v.value === venture?.status)}
                                onChange={handleVentureStatusOnChange} />
                        </Container>
                    </Container>
                    {venture.status && venture.status !== READY_VENTURE_STATUS && (
                        <Container ml="20px" fluid maxWidth="15em">
                            <Label bold>Data de entrega</Label>
                            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px">
                                <StyledInput
                                placeholder="Digite a data de entrega"
                                value={venture?.deliveryDate}
                                maxLength={7}
                                onChange={handleVentureDeliveryDateOnChange}/>
                            </Container>
                        </Container>
                    )}
                </Container>
                <Container mt="20px" fluid maxWidth="40em">
                    <Label bold>Tipo de empreendimento</Label>
                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px">
                        <StyledSelect
                            items={ventureTypes}
                            itemLabel="name"
                            value={ventureTypes.find(v => v.value === venture?.ventureType)}
                            onChange={handleVentureTypeOnChange} />
                    </Container>
                </Container>
                <Container mt="20px" >
                    <Container fluid maxWidth="40em">
                        <Label bold>Nome do empreendimento</Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid flex alignCenter  mt="10px">
                            <StyledInput value={venture?.name} onChange={handleVentureNameOnChange} />
                        </Container>
                    </Container>
                </Container>
                <Container mt="20px">
                    <Container>
                        <Label bold>Incorporadora</Label>
                    </Container>
                    <Container mt="10px">
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                            <StyledSelect
                                items={corporations}
                                value={ventureCorporation}
                                itemLabel="name"
                                placeholder="Selecione a incorporadora"
                                onChange={handleCorporationOnSelect}/>
                        </Container>
                    </Container>
                </Container>
                <Container mt="40px">
                    <Label bold color="#888" large>Caracteristicas do empreendimento</Label>
                    <Container mt="20px">
                        <Container flex alignCenter>
                            <Container>
                                <Container>
                                    <Label bold>Tamanho mínimo (m²)</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledInput
                                            placeholder="Add tamanho minimo"
                                            value={venture.minSize}
                                            onChange={(e) => handleMinAndMaxSize(e, "minSize")}/>
                                    </Container>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Container>
                                    <Label bold>Tamanho máximo (m²)</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledInput
                                            placeholder="Add tamanho máximo"
                                            value={venture.maxSize}
                                            onChange={(e) => handleMinAndMaxSize(e, "maxSize")}/>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="20px">
                            <Container>
                                <Container>
                                    <Label bold>Mínimo de quartos</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={minBedroomsAmountOptions}
                                            value={minBedroomsAmountOptions.find(i => i.value === venture.minBedroomsAmount)}
                                            itemLabel="label"
                                            onChange={handleMinBedroomsAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Container>
                                    <Label bold>Máximo de quartos</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={maxBedroomsAmountOptions}
                                            value={maxBedroomsAmountOptions.find(i => i.value === venture.maxBedroomsAmount)}
                                            itemLabel="label"
                                            onChange={handleMaxBedroomsAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="40px">
                            <Container>
                                <Container>
                                    <Label bold>Mínimo de suítes</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={minSuitesAmountOptions}
                                            value={minSuitesAmountOptions.find(i => i.value === venture.minSuitesAmount)}
                                            itemLabel="label"
                                            onChange={handleMinSuitesAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                            <Container disabled={venture.minSuitesAmount === 0} ml="20px">
                                <Container>
                                    <Label bold>Máximo de suítes</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={maxSuitesAmountOptions}
                                            value={maxSuitesAmountOptions.find(i => i.value === venture.maxSuitesAmount)}
                                            itemLabel="label"
                                            onChange={handleMaxSuitesAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="40px">
                            <Container>
                                <Container>
                                    <Label bold>Mínimo de banheiros</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={minBathroomsAmountOptions}
                                            value={minBathroomsAmountOptions.find(i => i.value === venture.minBathroomsAmount)}
                                            itemLabel="label"
                                            onChange={handleMinBathroomsAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Container>
                                    <Label bold>Máximo de banheiros</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={maxBathroomsAmountOptions}
                                            value={maxBathroomsAmountOptions.find(i => i.value === venture.maxBathroomsAmount)}
                                            itemLabel="label"
                                            onChange={handleMaxBathroomsAmountOnChange}/>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="40px">
                            <Container>
                                <Container>
                                    <Label bold>Mínimo de garagem</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={minGarageSpotsAmountOptions}
                                            value={minGarageSpotsAmountOptions.find(i => i.value === venture.minGarageSpotsAmount)}
                                            itemLabel="label"
                                            onChange={handleMinGarageSpotsAmount}/>
                                    </Container>
                                </Container>
                            </Container>
                            <Container ml="20px">
                                <Container>
                                    <Label bold>Máximo de garagem</Label>
                                </Container>
                                <Container mt="10px">
                                    <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  fluid maxWidth="40em">
                                        <StyledSelect
                                            items={maxGarageSpotsAmountOptions}
                                            value={maxGarageSpotsAmountOptions.find(i => i.value === venture.maxGarageSpotsAmount)}
                                            itemLabel="label"
                                            onChange={handleMaxGarageSpotsAmount}/>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container mt="40px">
                    <Container fluid maxWidth="40em">
                        <Label bold color="#888" large>Valores</Label>
                        <Container mt="20px">
                            <Label bold>A partir de:</Label>
                            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px" fluid maxWidth="40em">
                                <StyledInput
                                    placeholder="Add valor minimo"
                                    value={venture.priceFrom}
                                    onChange={handlePriceFromOnChange}/>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container mt="40px">
                    <Label bold color="#888" large>Descrição</Label>
                    <Container mt="20px">
                        <Label bold>Descrição do empreendimento <Span>(máx. 800 caracteres)</Span></Label>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}  mt="10px" fluid maxWidth="80em">
                            <StyledInput
                                multiline
                                placeholder="Add descricao do header"
                                rows={10}
                                maxLength={800}
                                value={venture.heroDescription}
                                onChange={handleHeroDescriptionOnChange}/>
                        </Container>
                    </Container>
                </Container>
                {currentUser?.role === ADMIN_USER_ROLE && (
                    <Container  disabled={!userHasChanges} flex fluid justifyEnd alignCenter mt="40px">
                        <StyledButton
                            loading={loading}
                            kind="primary"
                            onClick={handleSaveGeneralInfoDescription}>
                            Salvar
                        </StyledButton>
                    </Container>
                )}
            </Container>
        </GeneralInfoDescriptionContainer>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    corporations: state.corporations.corporations,
    ventureTypes: state.ventures.ventureTypes
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUploadFiles({ corporationId, ventureId }, files) {
        const response = await dispatch(uploadFiles({ corporationId, ventureId }, files))
        return response
    },

    async dispatchUpdateVenture({ corporationId, ventureId }, {
        heroDescription,
        logoUrl,
        name,
        newCorporationId,
        ventureType,
        minBedroomsAmount,
        maxBedroomsAmount,
        minSuitesAmount,
        maxSuitesAmount,
        minBathroomsAmount,
        maxBathroomsAmount,
        minGarageSpotsAmount,
        maxGarageSpotsAmount,
        priceFrom,
        minSize,
        maxSize,
        status,
        deliveryDate
    }) {
        await dispatch(updateVenture({ corporationId, ventureId }, {
            heroDescription,
            logoUrl,
            name,
            corporationId: newCorporationId,
            ventureType,
            minBedroomsAmount,
            maxBedroomsAmount,
            minSuitesAmount,
            maxSuitesAmount,
            minGarageSpotsAmount,
            maxGarageSpotsAmount,
            minBathroomsAmount,
            maxBathroomsAmount,
            priceFrom,
            minSize,
            maxSize,
            status,
            deliveryDate
        }))
    },

    async dispatchFetchCorporations() {
        await dispatch(fetchCorporations())
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchVentureTypes() {
        dispatch(fetchVentureTypes())
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoDescription)
