// Libraries
import styled from "styled-components"


export const StyledDialogMainContainer = styled.div`

`

export const StyledDialogContainer = styled.div`
    border-radius: 16px !important;
`
export const ModalBody = styled.div`
    width: 100%;
    border-radius: 12px;
    background-color: white;
    outline: none;
    padding: ${props => props.resetBodyTopPadding ? "0 40px 40px" : "40px"};
    box-sizing: border-box;
`


export const ModalHeader = styled.div`
    display: flex;
    padding: 20px 20px 0 40px;
    box-sizing: border-box;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: white;
    z-index: 10;
    justify-content: ${props => {
        if (props.hasTitle) return "space-between"
        else return "flex-end"
    }};
    
    @media only screen and (max-width: 600px) {
      padding: 0;
    }
`

export const ModalTitle = styled.h2`
    margin: 0;
    font-size: ${props => props.largeTitle ? "32px" : "18px"};
    color: #333;
`

export const ModalSubtitle = styled.label`
    font-size: 18px;
    color: #888;
    font-weight: normal;
`
