// Libraries
import styled from "styled-components"
import {primary} from "../../assets/colorPallet";


export const StyledTabsContainer = styled.div`
  .styled-tabs--app-bar {
    box-shadow: none;
    border-bottom: 1px solid #ccc;
    
    &__tabs--tab {
      span {
        text-transform: none !important;
        font-size: 16px;
      }
    }
    
    .MuiTabs-indicator{
      background-color: ${primary} !important;
    }
  }
  
  .MuiBox-root {
    padding: 0!important;
    margin-top: 20px;
  }
  
  button#simple-tab-0 {
    max-width: 22em !important;
  }
`

