// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../../structureComponents";
import StyledButton from "../../../../../../components/StyledButton/StyledButton";
import { deleteVideoById } from "../../../../../../store/modules/videos/actions";
import {setSnackbarState} from "../../../../../../store/modules/global/actions";


function DeleteVideoDialog({
    active = false,
    onClose = () => ({}),
    currentVenture,
    dispatchDeleteVideo = () => ({}),
    videoToBeDeleted,
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)

    // Methods
    const handleClose = () => {
        onClose()
    }

    const handleDeleteVideo = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchDeleteVideo({
                ventureId: currentVenture.id,
                corporationId: currentVenture.corporationId,
                videoId: videoToBeDeleted.id
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Video deletado com sucesso"
            })
            setLoading(true)
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Deletar video"
            active={active}
            onClose={handleClose} maxWidth="sm">
            <form onSubmit={handleDeleteVideo}>
                <Container>
                    <Label>Tem certeza que voce quer deletar este video?</Label>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                            kind="primary" type="submit"
                            loading={loading}>
                            Deletar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteVideo({ corporationId, ventureId, videoId }) {
        await dispatch(deleteVideoById({ corporationId, ventureId, videoId }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(DeleteVideoDialog)
