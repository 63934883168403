// Libraries
import styled from "styled-components"


export const VentureBuildingsInfoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
