import styled from "styled-components";

export const StyledDrawerContainer = styled.div``;

export const StyledDrawerHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyBetween ? "space-between" : "flex-end"};
  padding: 32px;
`;

export const StyledDrawerBodyContainer = styled.div`
  width: ${(props) => (props.width ? props.width : null)};
`;
