import Resizer from 'react-image-file-resizer';


export default class ImageHelper {
    static resizeImage(file){
        return new Promise(resolve => {
            const fileType = file.name.split('/')[1]
            Resizer.imageFileResizer(
                file,
                1920,
                1080,
                fileType,
                100,
                0,
                uri => {
                    const image = this.b64toBlob(uri, file.type)
                    resolve(image)
                },
                'base64'
            );
        })
    }

    static b64toBlob(b64Data, contentType, sliceSize){
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    static async resizeAllImages(images){
        const imgList = []

        for (let i = 0; i < images.length; i += 1) {
            const image = images[i]

            const resizedImage = await this.resizeImage(image).then(resized => resized)
            resizedImage.name = image.name
            resizedImage.lastModifiedDate = new Date()
            const file = new File([resizedImage], image.name, { type: image.type })

            imgList.push(file)
        }

        return imgList
    }

}
