// Libraries
import React from "react"
import StyledCard from "../../../../../components/StyledCard/StyledCard";
import {Container, Label} from "../../../../../structureComponents";
import SvgIcon from "../../../../../components/SvgIcon/SvgIcon";

// Assets
import corporationManagerIcon from "../../../../../assets/icons/corporationManager.svg"
import StyledButton from "../../../../../components/StyledButton/StyledButton";


function CorporationManagerCard({
    name,
    email,
    onUpdate = () => ({})
}) {
    return (
        <StyledCard maxWidth="16em">
            <Container p="40px">
                <Container flex alignCenter justifyCenter>
                    <SvgIcon src={corporationManagerIcon} size="70px" color="#888" />
                </Container>
                <Container mt="20px" flex alignCenter justifyCenter column>
                    <Label bold medium>{name}</Label>
                    <Container mt="5px">
                        <Label>{email}</Label>
                    </Container>
                </Container>
                <Container fluid flex alignCenter justifyCenter mt="40px">
                    <StyledButton kind="primary-outlined" onClick={() => onUpdate()}>Editar</StyledButton>
                </Container>
            </Container>
        </StyledCard>
    )
}

export default CorporationManagerCard
