import styled from 'styled-components'

const Span = styled.span`
    font-size: ${props => props.fs ? props.fs : null};
    font-weight: ${props => {
        if (props.bold) return "600"
        if (props.semiBold) return "500"
        else return "400"
    }};
    cursor: ${props => {
        if (props.clickable) return "pointer"
        else return null
    }};
    color: ${props => props.color ? props.color : null};
    font-family: 'Poppins', sans-serif !important;
`

export default Span
