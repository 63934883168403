// Libraries
import React from "react"
import TextField from '@material-ui/core/TextField';

// Styles
import {StyledInputContainer} from "./styled";


function StyledInput ({
    label,
    onChange = () => ({}),
    onKeyDown = () => ({}),
    value,
    required,
    readOnly,
    maxLength,
    rows,
    multiline,
    placeholder,
    error,
    helperText,
    disabled,
    small,
    type
}) {

    // Methods
    const handleChange = (e) => {
        onChange(e)
    }

    return (
        <StyledInputContainer small={small}>
            <TextField
                className="styled-input"
                multiline={multiline}
                rows={rows}
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                placeholder={label || placeholder}
                inputProps={{ maxLength: maxLength }}
                variant="outlined"
                error={error}
                type={type}
                helperText={error ? <label>{helperText}</label> : ""}
                value={value}
                onKeyDown={(e) => onKeyDown(e)}
                onChange={handleChange} />
        </StyledInputContainer>
    )
}

export default StyledInput
