import styled from 'styled-components'
import {mediumLightGrey} from "../../assets/colorPallet";

const Module = styled.div`
  padding: 40px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 1px 2px #00000017;
  height: ${props => props.height};
  display: ${props => props.flex ? "flex" : null};
  align-items: ${props => props.alignCenter ? "center" : null};
`

export default Module
