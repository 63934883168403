// Libraries
import styled from "styled-components"


export const PriceFilterContainer = styled.div`
    position: absolute;
    width: 424px;
    height: 320px;
    padding: 24px;
    z-index: 2000;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity .2s ease, margin-top .2s ease, top .2s ease;
    pointer-events: ${props => props.active ? "all" : "none"};
    margin-top: ${props => props.active ? "20px" : "-10px"};

    > * {
      pointer-events: ${props => props.active ? "all" : "none"};
    }
  
    .price-filter--action-container__apply-btn {
      pointer-events: ${props => props.active ? "all" : "none"};
    }
    
    
    .price-filter-header--mobile {
      display: none;
    }
  
    @media only screen and (max-width: 720px) {
      height: 520px;
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      margin: 0;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      top: ${props => props.active ? "0" : "-200vh"};

      .price-filter-header--mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      
      .price-filter--action-container {
        flex-direction: column-reverse;
        
        div {
          width: 100%;
        }
        
        &__clean-btn {
          order: 2;
          width: 100%;
          margin-top: 10px;
          height: 48px !important;
        }
        
        &__apply-btn {
          order: 1;
          width: 100%;
          height: 48px !important;
        }
      }
    }
`

export const PriceFilterOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: transparent;
    pointer-events: ${props => props.active ? "all" : "none"};
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity .4s ease-in-out;
  
    @media only screen and (max-width: 720px) {
      background-color: rgba(25, 31, 35, 0.32) !important;
      position: fixed;
    }
  
`
