import React from 'react';
import { InlineLoadingContainer, Point } from './styled';
import {primary} from "../../assets/colorPallet";

export default class InlineLoading extends React.Component{
    render(){

        const points = [1,2,3];

        return(
            <InlineLoadingContainer>
                {points.map((_, index) => {
                    return <Point key={index} color={this.props.color ? this.props.color : primary} />
                })}
            </InlineLoadingContainer>
        )
    }
}
