import styled from "styled-components"


export const CorporationCardContainer = styled.div`
  .corporation--logo {
    width: 100%;
    height: 100px;
    object-fit: contain;
    padding: 20px;
    box-sizing: border-box;
  }
`

