// Libraries
import React from "react"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Styles
import { StyledSearchContainer } from "./styled"


function StyledSearch({  
    placeholder,
    onChange = () => ({}),
    onClear = () => ({})
}) {
    // Methods
    const handleChange = (e) => {
        onChange(e.target.value)
    }


    return (
        <StyledSearchContainer>
            <Autocomplete
                disableClearable
                color="primary"
                placeholder={placeholder}
                freeSolo
                options={[]}
                renderInput={(params) => (
                    <TextField {...params} 
                    placeholder={placeholder} 
                    margin="normal" 
                    variant="outlined" 
                    onChange={handleChange} />
                )}
            />
        </StyledSearchContainer>
    )
}

export default StyledSearch