import {
    SET_IMAGE_GROUPS
} from "./mutations";

const initialState = {
    imageGroups: []
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_IMAGE_GROUPS:
            return { ...state, imageGroups: action.payload }

        default:
            return state;
    }
}

export default reducer;
