// Libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Components
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import Message from "../../../../../store/modules/messages/model";
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Structure components
import { Container, Label } from "../../../../../structureComponents";

// Store actions
import { setSnackbarState } from "../../../../../store/modules/global/actions";
import { createMessage } from "../../../../../store/modules/messages/actions";

function CreateCommonQuestionDialog({
  currentVenture,
  active,
  onClose = () => ({}),
  dispatchSnackbar,
  dispatchCreateMessage,
}) {
  // State
  const [message, setMessage] = useState(new Message({}));
  const [loading, setLoading] = useState(false);

  // Watchers
  useEffect(() => {
    if (active) {
      setMessage(new Message({}));
    }
  }, [active]);

  // Methods
  const handleNameOnChange = (e) => {
    setMessage({ ...message, name: e.target.value });
  };

  const handelTextOnChange = (e) => {
    setMessage({ ...message, text: e.target.value });
  };

  const handleCreateMessage = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const ventureId = currentVenture.id;

      await dispatchCreateMessage(
        { ventureId },
        {
          name: message.name,
          text: message.text,
        }
      );

      onClose();

      dispatchSnackbar({
        type: "success",
        active: true,
        message: "Mensagem criada com sucesso",
      });
    } catch (error) {
      dispatchSnackbar({
        type: "error",
        active: true,
        message: "Infelizmente ocorreu um erro",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog
      title="Criar mensagem"
      active={active}
      onClose={() => onClose()}
      maxWidth="sm"
    >
      <form onSubmit={handleCreateMessage}>
        <Container>
          <Label bold>Nome</Label>
          <Container mt="10px">
            <StyledInput
              placeholder="Digite o nome"
              value={message.question}
              onChange={handleNameOnChange}
            />
          </Container>
        </Container>
        <Container mt="20px">
          <Label bold>Texto</Label>
          <Container mt="10px">
            <StyledInput
              placeholder="Digite o Texto"
              value={message.answer}
              multiline
              rows={8}
              onChange={handelTextOnChange}
            />
          </Container>
        </Container>
        <Container flex alignCenter justifyEnd mt="40px">
          <Container>
            <StyledButton kind="primary-outlined" onClick={() => onClose()}>
              Cancelar
            </StyledButton>
          </Container>
          <Container ml="20px">
            <StyledButton kind="primary" type="submit" loading={loading}>
              Salvar
            </StyledButton>
          </Container>
        </Container>
      </form>
    </StyledDialog>
  );
}

const mapStateToProps = (state) => ({
  currentVenture: state.ventures.currentVenture,
});

const mapDispatchToProps = (dispatch) => ({
  async dispatchCreateMessage({ ventureId }, { name, text }) {
    await dispatch(createMessage({ ventureId }, { name, text }));
  },

  dispatchSnackbar({ message, type, active }) {
    dispatch(setSnackbarState({ message, type, active }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCommonQuestionDialog);
