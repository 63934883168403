// Mutations
import { SET_SNACKBAR_STATE, SET_USER_HAS_CHANGES, SET_SHOW_DISCARD_CHANGES_DIALOG } from "./mutations"
import store from "../../index";

export const setSnackbarState = ({ message, type, active }) => async (dispatch) => {
    try {
        if (!message || !type || !active) {
            throw new Error("Missing params")
        }

        dispatch({ type: SET_SNACKBAR_STATE, payload: { message, type, active } })

        const snackbarState = store.getState().global.snackbarState

        setTimeout(() => {
            dispatch({ type: SET_SNACKBAR_STATE, payload: { ...snackbarState, active: false } })
        }, 2000)
    } catch (error) {
        throw error
    }
}

export const setUserHasChanges = (userHasChanges) => (dispatch) => {
    dispatch({ type: SET_USER_HAS_CHANGES, payload: userHasChanges })
}

export const setShowDiscardChangesDialog = (showDiscardChangesDialog) => (dispatch) => {
    dispatch({ type: SET_SHOW_DISCARD_CHANGES_DIALOG, payload: showDiscardChangesDialog })
}
