import axios from "axios";

export default class AuthHandler {
    static async recoverPassword({ email }) {
        const payload = { email }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/public/authentication/recoverPassword`,
            payload
        )

        return response.data
    }
}