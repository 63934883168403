import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer'
import thunk from 'redux-thunk';

let composeEnhancers =  compose
if (process.env.BROWSER) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || (null || compose)
}
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
));

export default store;
