import {
    SET_TEAM_MEMBERS,
    SET_TEAM_MEMBERS_META,
    SET_TEAM_MEMBERS_CURRENT_PAGE
} from "./mutations";

const initialState = {
    teamMembers: [],
    teamMembersMeta: null,
    teamMembersCurrentPage: 1
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_TEAM_MEMBERS:
            return { ...state, teamMembers: action.payload }

        case SET_TEAM_MEMBERS_META:
            return { ...state, teamMembersMeta: action.payload }

        case SET_TEAM_MEMBERS_CURRENT_PAGE:
            return { ...state, teamMembersCurrentPage: action.payload }

        default:
            return state;
    }
}

export default reducer;
