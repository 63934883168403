// Libraries
import React, {useEffect, useState} from "react"
import Pagination from '@material-ui/lab/Pagination'
import { connect } from "react-redux"
import PageWrapper from "../../structureComponents/modules/PageWrapper"
import { Container } from "../../structureComponents"
import VentureModerationCard from "./VentureModerationCard/VentureModerationCard"
import VentureHandler from "../../handlers/VentureHandler"

// Components
import StyledTabs from "../../components/StyledTabs/StyledTabs"
import StyledSearch from "../../components/StyledSearch/StyledSearch"

import { ThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from "../../MaterialTheme"
import { Skeleton } from "@material-ui/lab"
import VentureCardSkeleton from "../Ventures/VentureCard/VentureCardSkeleton"

// Constants]
const INBOX_VENTURES = "INBOX_VENTURES"
const ARCHIVE_VENTURES = "ARCHIVE_VENTURES"

function VenturesModeration({ 
    inboxVentures, 
    inboxVenturesMeta,
    archiveVentures,
    archiveVenturesMeta
}) {
    // States
    const [loading, setLoading] = useState(true)
    const [selectedTab, setSelectedTab] = useState(INBOX_VENTURES)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState("")


    // Mounted
    useEffect(() => {
            handleInboxVentures({ page: currentPage, searchValue: "" })
            handleArchiveVentures({ page: currentPage, searchValue: "" })
    }, [selectedTab])

    // Methods
    const handleInboxVentures = async ({ page, search }) => {
        try {
            setLoading(true)
            await VentureHandler.fetchInboxVentures({ page, searchValue: search })
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleArchiveVentures = async ({ page, search }) => {
        try {
            setLoading(true)
            await VentureHandler.fetchArchiveVentures({ page, searchValue: search })
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handlePaginationOnChange = (e, page) => {
        setCurrentPage(page)
        window.scrollTo(0, 0)

        if (selectedTab === INBOX_VENTURES) {
            handleInboxVentures({ page, searchValue })
        }

        if (selectedTab === ARCHIVE_VENTURES) {
            handleArchiveVentures({ page, searchValue })
        }
    }

    const handleTabsOnChange = (tab) => {
       setSelectedTab(tab)
       setCurrentPage(1)
    }

    const handleSearchArhiveVentures = async (searchValue) => {
        try {
            setSearchValue(searchValue)
            if (searchValue.length >= 3 || searchValue.length === 0) {
                await VentureHandler.fetchArchiveVentures({ page: 1, searchValue })
                setCurrentPage(1)
            }
        } catch (error) {

        } 
    }

    const handleSearchInboxVentures = async (searchValue) => {
        try {
            setSearchValue(searchValue)
            if (searchValue.length >= 3 || searchValue.length === 0) {
                await VentureHandler.fetchInboxVentures({ page: 1, searchValue })
                setCurrentPage(1)
            }
        } catch (error) {

        }
    }

    const moderationVentureTabs = [
        {
            name: INBOX_VENTURES,
            label: `Entrada (${inboxVenturesMeta ? inboxVenturesMeta.total : "..."})`,
            children: (
                loading ? (
                    <Container flex column alignCenter>
                        <Container
                        fluid 
                        flex 
                        column 
                        justifyCenter 
                        alignCenter 
                        maxWidth="60em">
                            <Container fluid flex justifyStart alignCenter mt="40px">
                                <Skeleton width="30em" height="80px" />
                            </Container>
                            <Container fluid mt="40px">
                                <Container>
                                    <VentureCardSkeleton />
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                ) : (
                    <Container
                    fluid 
                    flex 
                    column 
                    justifyCenter 
                    alignCenter>
                        <Container fluid maxWidth="60em">
                            <Container fluid flex justifyStart alignCenter mt="20px">
                                <Container fluid maxWidth="30em">
                                    <StyledSearch 
                                    placeholder="Busque por nome do empreendimento ou bairro"
                                    onChange={handleSearchInboxVentures} 
                                    onClear={handleInboxVentures}/>
                                </Container>
                            </Container>
                            <Container fluid mt="20px">
                                {inboxVentures.map((inboxVenture, index) => (
                                    <VentureModerationCard
                                    key={index}
                                    currentPage={currentPage}
                                    venture={inboxVenture}/>
                                ))}
                                <Container flex alignCenter justifyCenter mt="80px">
                                    <Pagination 
                                    count={inboxVenturesMeta?.totalPages} 
                                    color="primary" 
                                    page={currentPage}
                                    currentSearchValue={searchValue}
                                    onChange={handlePaginationOnChange} />
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                )
            )
        },
        {
            name: ARCHIVE_VENTURES,
            label: `Arquivo (${archiveVenturesMeta ? archiveVenturesMeta.total : "..."})`,
            children: (
                loading ? (
                    <Container flex column alignCenter>
                        <Container
                        fluid 
                        flex 
                        column 
                        justifyCenter 
                        alignCenter 
                        maxWidth="60em">
                            <Container fluid flex justifyStart alignCenter mt="40px">
                                <Skeleton width="30em" height="80px" />
                            </Container>
                            <Container fluid mt="40px">
                                <Container>
                                    <VentureCardSkeleton />
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                ) : (
                    <Container
                    fluid 
                    flex 
                    column 
                    justifyCenter 
                    alignCenter>
                        <Container fluid maxWidth="60em">
                            <Container fluid flex justifyStart alignCenter mt="20px">
                                <Container fluid maxWidth="30em">
                                    <StyledSearch 
                                    placeholder="Busque por nome do empreendimento ou bairro"
                                    onChange={handleSearchArhiveVentures}
                                    onClear={handleArchiveVentures} />
                                </Container>
                            </Container>
                            <Container fluid mt="20px">
                                {archiveVentures.map((archiveVenture, index) => (
                                    <VentureModerationCard
                                    approveOnly
                                    key={index}
                                    currentPage={currentPage}
                                    currentSearchValue={searchValue}
                                    venture={archiveVenture}/>
                                ))}
                                    <Container flex alignCenter justifyCenter mt="80px">
                                        <ThemeProvider theme={MaterialTheme}>
                                            <Pagination 
                                            count={archiveVenturesMeta?.totalPages} 
                                            color="primary" 
                                            page={currentPage}
                                            onChange={handlePaginationOnChange} />
                                        </ThemeProvider>
                                    </Container>
                            </Container>
                        </Container>
                    </Container>
                )
            )
        }
    ]

    return (
        <PageWrapper style={{ paddingTop: 0 }}>
            <Container p="0 80px">
                <StyledTabs tabs={moderationVentureTabs} onChange={handleTabsOnChange} />
            </Container>
        </PageWrapper>
    )
}

export const mapStateToProps = (state) => ({
    inboxVentures: state.ventures.inboxVentures,
    inboxVenturesMeta: state.ventures.inboxVenturesMeta,
    archiveVentures: state.ventures.archiveVentures,
    archiveVenturesMeta: state.ventures.archiveVenturesMeta
})

export default connect(mapStateToProps)(VenturesModeration)
