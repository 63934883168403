// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../../../components/StyledDialog/StyledDialog";
import {Container, Label, Span} from "../../../../../../../structureComponents";
import StyledButton from "../../../../../../../components/StyledButton/StyledButton";
import {deleteImageGroup} from "../../../../../../../store/modules/imageGroups/actions";
import {setSnackbarState} from "../../../../../../../store/modules/global/actions";


function DeleteImageGroupDialog({
    active = false,
    onClose = () => ({}),
    currentVenture,
    dispatchDeleteImageGroup = () => ({}),
    imageGroup,
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)

    // Methods
    const handleClose = () => {
        onClose()
    }

    const handleDeleteImageGroup = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchDeleteImageGroup({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id,
                imageGroupId: imageGroup.id
            })

            setLoading(true)
            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Grupo de imagem deletado com sucesso"
            })
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Deletar grupo de imagem"
            active={active}
            onClose={handleClose} maxWidth="sm">
            <form onSubmit={handleDeleteImageGroup}>
                <Container>
                    <Label>
                        Tem certeza que deseja deletar o grupo de imagem <Span bold>{imageGroup?.name}</Span>?
                        Todas as imagens vinculadas a este grupo serao deletadas.
                    </Label>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                            kind="primary"
                            type="submit"
                            loading={loading}>
                            Deletar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteImageGroup({ corporationId, ventureId, imageGroupId }) {
        await dispatch(deleteImageGroup({ corporationId, ventureId, imageGroupId }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(DeleteImageGroupDialog)
