class ImageGroup {
    constructor({
        id,
        name,
        ventureId,
        corporationId,
        images = []
    }) {
        this.id = id
        this.name = name
        this.ventureId = ventureId
        this.corporationId = corporationId
        this.images = images
    }

    get creationPayloadIsValid() {
        if (
            !this.name
        ) {
            return false
        }

        return true
    }
}


export default ImageGroup
