// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import {VenturesContainer, VenturesListContainer} from "./styled"
import {Container} from "../../structureComponents"

// Components
import EmptyState from "../../components/EmptyState/EmptyState";
import StyledTabs from "../../components/StyledTabs/StyledTabs"

// Icons
import ventureIcon from "../../assets/icons/venture.svg"
import StyledButton from "../../components/StyledButton/StyledButton";
import VentureCard from "./VentureCard/VentureCard";
import CreateVentureDialog from "./CreateVentureDialog/CreateVentureDialog";
import {RouteContext} from "../../context/RouteContext";
import {ADMIN_USER_ROLE, VENTURES_ROUTE} from "../../utils/constants";
import {fetchCorporations} from "../../store/modules/corporations/actions";
import Pagination from "@material-ui/lab/Pagination";
import StyledSearch from "../../components/StyledSearch/StyledSearch";
import { ThemeProvider } from '@material-ui/core/styles';
import MaterialTheme from "../../MaterialTheme"
import VentureCardSkeleton from "./VentureCard/VentureCardSkeleton";
import { Skeleton } from "@material-ui/lab";
import VenturesFilters from "../../businessComponents/VenturesFilters/VenturesFilters";
import {fetchActiveVentures, fetchInactiveVentures} from "../../store/modules/ventures/actions";
import VenturesOrderBy from "../../businessComponents/VenturesOrderBy/VenturesOrderBy";
import {AuthContext} from "../../Auth";
import {neutral500} from "../../assets/colorPallet";
import Label from "../../structureComponents/modules/Label";

const ACTIVE_VENTURES = "ACTIVE_VENTURES"
const INACTIVE_VENTURES = "INACTIVE_VENTURES"

function Ventures({ 
    dispatchFetchCorporations,
    activeVentures,
    activeVenturesMeta,
    inactiveVentures,
    inactiveVenturesMeta, 
    match,
    dispatchFetchInactiveVentures,
    dispatchFetchActiveVentures,
    history
}) {
    // Context
    const { currentUser } = useContext(AuthContext)
    const { setCurrentRoute } = useContext(RouteContext)

    // States
    const [loadingVentures, setLoadingVentures] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showCreateVentureDialog, setShowCreateVentureDialog] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [processingSearch, setProcessingSearch] = useState(false)
    const [selectedTab, setSelectedTab] = useState(null)

    // Mounted
    useEffect(() => {
        setSelectedTab(INACTIVE_VENTURES)
        handleFetchCorporations()
        setCurrentRoute({
            name: VENTURES_ROUTE,
            path: match.path
        })
    }, [])

    // Watchers
    useEffect(() => {
        if (currentUser?.role === ADMIN_USER_ROLE) {
            handleFetchVentures({
                page: currentPage,
                active: 0
            })
        }

        handleFetchVentures({
            page: currentPage,
            active: 1
        })
    }, [selectedTab])

    // Methods
    const handleFetchCorporations = async () => {
        try {
            setLoading(true)
            await dispatchFetchCorporations()
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleFetchVentures = async ({ page, searchValue, active }) => {
        try {
            setLoading(true)
            if (!active) {
                dispatchFetchInactiveVentures({ page, searchValue })
            } else {
                dispatchFetchActiveVentures({ page, searchValue, orderBy: "maisRelevantes" })
            }

            setLoading(false)

        } finally {
            setLoading(false)
        }
    }

    const handlePaginationOnChange = (e, page) => {
        setCurrentPage(page)
        window.scrollTo(0, 0)

        const active = selectedTab === ACTIVE_VENTURES ? 1 : 0
        handleFetchVentures({ page, active })
    }

    const handleSearchVentures = async ({ searchValue, active }) => {
        try {
            setProcessingSearch(true)
            if (searchValue.length >= 3 || searchValue.length === 0) {
                if (active) {
                    await dispatchFetchActiveVentures({ page: 1, searchValue, orderBy: "maisRelevantes" })
                } else {
                    await dispatchFetchInactiveVentures({ page: 1, searchValue })
                }
            }
        } catch (error) {

        } 
    }

    const handleTabsOnChange = (tab) => {
        setSelectedTab(tab)
        setCurrentPage(1)
    }

    
    // Views
    const ventureTabs = [
        {
            name: INACTIVE_VENTURES,
            label: `Não publicados (${inactiveVenturesMeta ? inactiveVenturesMeta.total : "..."})`,
            children: (
                loading ? (
                    <Container flex column alignCenter>
                        <Container
                        fluid 
                        flex 
                        column 
                        justifyCenter 
                        alignCenter 
                        maxWidth="60em">
                            <Container flex fluid alignCenter justifyEnd>
                                <Skeleton width="200px" height="80px" />
                            </Container>
                            <Container fluid flex justifyStart alignCenter mt="20px">
                                <Skeleton width="30em" height="80px" />
                            </Container>
                            <Container fluid mt="40px">
                                <Container>
                                    <VentureCardSkeleton />
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                ) : (
                    <Container mt="40px">
                        <VenturesContainer>
                            {inactiveVentures.length === 0 && !processingSearch ? (
                                <Container 
                                height="calc(100vh - 60px - 80px)" 
                                flex 
                                alignCenter 
                                justifyCenter>
                                    <EmptyState
                                        icon={ventureIcon}
                                        buttonText="Criar empreendimento"
                                        text="Nenhum empreendimento cadastrado"
                                        onClick={() => setShowCreateVentureDialog(true)}/>
                                </Container>
                            ) : (
                                <Container fluid flex column justifyCenter alignCenter maxWidth="70em">
                                    <Container flex fluid alignCenter justifyEnd>
                                        <StyledButton
                                            kind="primary-outlined"
                                            onClick={() => setShowCreateVentureDialog(true)}>
                                            Criar empreendimento
                                        </StyledButton>
                                    </Container>
                                    <Container fluid flex justifyStart alignCenter mt="20px">
                                        <Container fluid maxWidth="30em">
                                            <StyledSearch
                                            placeholder="Busque por nome do empreendimento ou bairro"
                                            onChange={(searchValue) => handleSearchVentures({
                                                searchValue,
                                                active: 0
                                            })} />
                                        </Container>
                                    </Container>
                                    <VenturesListContainer>
                                        {inactiveVentures.map((inactiveVenture, index) => (
                                            <Container key={index} fluid mt={index > 0 ? "20px" : null}>
                                                <VentureCard 
                                                match={match} 
                                                key={index} 
                                                ventureType={INACTIVE_VENTURES}
                                                venture={inactiveVenture} />
                                            </Container>
                                        ))}
                                    </VenturesListContainer>
                                    <Container flex alignCenter justifyCenter mt="80px">
                                        <ThemeProvider theme={MaterialTheme}>
                                            <Pagination 
                                            count={inactiveVenturesMeta?.totalPages} 
                                            page={currentPage}
                                            color="primary" 
                                            onChange={handlePaginationOnChange} />
                                        </ThemeProvider>
                                    </Container>
                                </Container>
                            )}
                    </VenturesContainer>
                    <CreateVentureDialog
                    active={showCreateVentureDialog}
                    onClose={() => setShowCreateVentureDialog(false)} />
                </Container>
                )
            )
        },
        {
            name: ACTIVE_VENTURES,
            label: `Publicados (${activeVenturesMeta ? activeVenturesMeta.total : "..."})`,
            children: (
                loading ? (
                    <Container flex column alignCenter>
                        <Container
                        fluid 
                        flex 
                        column 
                        justifyCenter 
                        alignCenter 
                        maxWidth="70em">
                            <Container fluid flex justifyStart alignCenter mt="40px">
                                <Skeleton width="30em" height="80px" />
                            </Container>
                            <Container fluid mt="40px">
                                <Container>
                                    <VentureCardSkeleton />
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                ) : (
                    <Container mt="40px">
                        <VenturesContainer>
                            {activeVentures.length === 0 && !processingSearch ? (
                                <Container 
                                height="calc(100vh - 60px - 80px)" 
                                flex 
                                alignCenter 
                                justifyCenter>
                                    <EmptyState
                                    icon={ventureIcon}
                                    text="Nenhum empreendimento publicado"/>
                                </Container>
                            ) : (
                                <>
                                    {currentUser?.teamMember && (
                                        <Container
                                            fluid
                                            flex
                                            column
                                            mb="40px"
                                            justifyCenter
                                            alignCenter
                                            maxWidth="70em">
                                            <Container
                                                flex
                                                alignCenter
                                                justifyBetween
                                                p="20px"
                                                radius="8px"
                                                fluid
                                                bgColor={neutral500}>
                                                <Label>
                                                    Acesse a sua página de vendas no LI: {currentUser?.brokerPortalUrl}
                                                </Label>
                                                <a href={currentUser?.brokerPortalUrl} target="_blank">
                                                    <StyledButton slimHeight kind="primary">Ver meu site</StyledButton>
                                                </a>
                                            </Container>
                                        </Container>
                                    )}
                                    <Container
                                        fluid
                                        flex
                                        column
                                        justifyCenter
                                        alignCenter
                                        maxWidth="70em">
                                        <Container fluid flex justifyStart alignCenter>
                                            <Container fluid maxWidth="30em">
                                                <StyledSearch
                                                    placeholder="Busque por nome do empreendimento ou bairro"
                                                    onChange={(searchValue) => handleSearchVentures({
                                                        searchValue,
                                                        active: 1
                                                    })} />
                                            </Container>
                                            <Container ml="20px">
                                                <VenturesFilters onLoading={(value) => setLoadingVentures(value)} />
                                            </Container>
                                        </Container>
                                        <Container fluid mt="20px">
                                            <VenturesOrderBy onLoading={(value) => setLoadingVentures(value)} />
                                        </Container>
                                        {loadingVentures ? (
                                            <Container fluid mt="20px">
                                                {[1,2,3].map(() => (
                                                    <Container fluid mb="20px">
                                                        <VentureCardSkeleton />
                                                    </Container>
                                                ))}
                                            </Container>
                                        ) : (
                                            <>
                                                <VenturesListContainer>
                                                    {activeVentures.map((activeVenture, index) => (
                                                        <Container key={index} fluid mt={index > 0 ? "20px" : null}>
                                                            <VentureCard
                                                                match={match}
                                                                history={history}
                                                                key={index}
                                                                ventureType={ACTIVE_VENTURES}
                                                                venture={activeVenture} />
                                                        </Container>
                                                    ))}
                                                </VenturesListContainer>
                                                <Container flex alignCenter justifyCenter mt="80px">
                                                    <ThemeProvider theme={MaterialTheme}>
                                                        <Pagination
                                                            count={activeVenturesMeta?.totalPages}
                                                            page={currentPage}
                                                            color="primary"
                                                            onChange={handlePaginationOnChange} />
                                                    </ThemeProvider>
                                                </Container>
                                            </>
                                        )}
                                    </Container>
                                </>
                            )}
                    </VenturesContainer>
                    <CreateVentureDialog
                    active={showCreateVentureDialog}
                    onClose={() => setShowCreateVentureDialog(false)} />
                </Container>
                )
            )
        }
    ]


    return (
        <Container p="0 80px">
            <StyledTabs
            tabs={currentUser?.role === ADMIN_USER_ROLE ? ventureTabs : ventureTabs.filter(tab => tab.name === ACTIVE_VENTURES)}
            onChange={handleTabsOnChange} />
        </Container>
    )
}

const mapStateToProps = (state) => ({
    activeVentures: state.ventures.activeVentures,
    activeVenturesMeta: state.ventures.activeVenturesMeta,
    inactiveVentures: state.ventures.inactiveVentures,
    inactiveVenturesMeta: state.ventures.inactiveVenturesMeta
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchCorporations() {
        await dispatch(fetchCorporations())
    },

    async dispatchFetchInactiveVentures({ page, searchValue }) {
        await dispatch(fetchInactiveVentures({ page, searchValue }))
    },

    async dispatchFetchActiveVentures({ page, searchValue, active, orderBy }) {
        await dispatch(fetchActiveVentures({
            page,
            searchValue,
            active,
            orderBy
        }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Ventures)
