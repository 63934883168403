// Libraries
import React, { useEffect, useState } from "react";

// Styles
import { LoginContainer } from "./styled";
import { Container } from "../../structureComponents";

import StyledButton from "../../components/StyledButton/StyledButton";

// Components
import SvgIcon from "../../components/SvgIcon/SvgIcon";

// Icons
import imobproLogo from "../../assets/logos/imobpro-logo-secondary.svg";
import InlineLoading from "../../components/Loading/Loading";

function AuthenticationOrulo() {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const url = `imobPro://app/path?code=${code}`;
    setUrl(url);
    console.log(url);
  }, []);
  // const openApp = () => {
  //   window.location.href = url;
  // };

  // const handleClick = () => {
  //   const anchor = document.createElement("a");
  //   anchor.href = url;

  //   const event = document.createEvent("HTMLEvents");
  //   event.initEvent("click", true, true);
  //   anchor.dispatchEvent(event);
  // };
  return (
    <Container flex column justifyCenter alignCenter height="100vh">
      <Container flex column justifyCenter alignCenter>
        <SvgIcon src={imobproLogo} size={200} />
      </Container>
      <Container mt="20px" flex alignCenter justifyCenter>
        <Container>
          <a href={url}>
            <StyledButton
              fluid
              kind="primary-outlined"
              type="submit"
              slimHeight
            >
              Retornar ao aplicativo
            </StyledButton>
          </a>
        </Container>
      </Container>
    </Container>
  );
}

export default AuthenticationOrulo;
