import axios from "axios"


const VIA_CEP_API_URL = "https://viacep.com.br/ws"


export default class ViaCEP {
    static async findAddress(CEP) {
        const response = await axios.get(`${VIA_CEP_API_URL}/${CEP}/json`)
        return response.data
    }
}
