// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Componets
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Structure Components
import {Container, Label} from "../../../../../structureComponents";
import { createCorporationManager } from "../../../../../store/modules/corporationManagers/actions";
import {setSnackbarState} from "../../../../../store/modules/global/actions";
import MaskHelper from "../../../../../utils/MaskHelper";
import GeneralHelper from "../../../../../utils/GeneralHelper";


function CreateCorporationManagerDialog({
    active = false,
    onClose = () => ({}),
    dispatchCreateCorporationManager,
    currentCorporation,
    dispatchSnackbar
 }) {
    // States
    const [loading, setLoading] = useState(false)
    const [corporationManager, setCorporationManager] = useState({
        name: null,
        email: null,
        phoneNumber: null
    })
    const [error, setError] = useState({
        targets: [],
        message: ""
    })

    // Mounted
    useEffect(() => {
        if (active) {
            setCorporationManager({
                name: null,
                email: null,
                phoneNumber: null
            })
        }
    }, [active])


    // Methods
    const handleCorporationManagerName = (e) => {
        setCorporationManager({ ...corporationManager, name: e.target.value })
    }

    const handleCorporationManagerEmail = (e) => {
        setCorporationManager({ ...corporationManager, email: e.target.value })
        if (e.target.value.length > 0 && GeneralHelper.validateEmail(corporationManager.email)) {
            setError({
                targets: error.targets.filter(e => e !== "email"),
                message: ""
            })
        } else if (!GeneralHelper.validateEmail(corporationManager.email)) {
            setError({
                targets: ["email"],
                message: "Email invalido"
            })
        }
    }

    const handleCorporationManagerPhoneNumber = (e) => {
        setCorporationManager({ ...corporationManager, phoneNumber: MaskHelper.toPhoneNumber(e.target.value) })
    }

    const handleCreateCorporationManager = async (e) => {
        try {
            e.preventDefault()

            if (!corporationManager.name && !corporationManager.email && !corporationManager.phoneNumber) {
                setError({
                    targets: ["name", "email", "phoneNumber"],
                    message: "Preencher este campo"
                })

                return
            } else if (corporationManager.name && !corporationManager.email && !corporationManager.phoneNumber) {
                setError({
                    targets: ["email", "phoneNumber"],
                    message: "Preencher este campo"
                })
                return
            } else if (corporationManager.name && corporationManager.email && !corporationManager.phoneNumber) {
                setError({
                    targets: ["phoneNumber"],
                    message: "Preencher este campo"
                })
                return
            } else if (!corporationManager.name) {
                setError({
                    targets: ["name"],
                    message: "Preencher este campo"
                })
                return
            } else if (!corporationManager.email) {
                setError({
                    targets: ["email"],
                    message: "Preencher este campo"
                })
                return
            } else if (!corporationManager.phoneNumber) {
                setError({
                    targets: ["phoneNumber"],
                    message: "Preencher este campo"
                })
                return
            } else if (corporationManager.phoneNumber.length < 15) {
                setError({
                    targets: ["phoneNumber"],
                    message: "Celular invalido"
                })
                return
            } else if (!GeneralHelper.validateEmail(corporationManager.email)) {
                setError({
                    targets: ["email"],
                    message: "Email invalido"
                })
                return
            }

            setLoading(true)
            await dispatchCreateCorporationManager({ corporationId: currentCorporation.id }, {
                name: corporationManager.name,
                email: corporationManager.email,
                phoneNumber: corporationManager.phoneNumber
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Gerente de incorporadora cadastrado com sucesso"
            })
            onClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Criar gerente de incorporadora"
            active={active}
            onClose={() => onClose()} maxWidth="sm">
            <form onSubmit={handleCreateCorporationManager}>
                <Container>
                    <Label bold>Nome</Label>
                    <Container mt="10px">
                        <StyledInput
                            placeholder="Digite o nome do gerente"
                            error={error.targets.includes("name")}
                            helperText={error.message}
                            value={corporationManager.name}
                            onChange={handleCorporationManagerName}/>
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label bold>Email</Label>
                    <Container mt="10px">
                        <StyledInput
                            placeholder="Digite o email do gerente"
                            error={error.targets.includes("email")}
                            helperText={error.message}
                            value={corporationManager.email}
                            onChange={handleCorporationManagerEmail}/>
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label bold>Celular (Whatsapp)</Label>
                    <Container mt="10px">
                        <StyledInput
                            maxLength={15}
                            error={error.targets.includes("phoneNumber")}
                            helperText={error.message}
                            placeholder="Digite o whatsapp do gerente"
                            value={corporationManager.phoneNumber}
                            onChange={handleCorporationManagerPhoneNumber}/>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton kind="primary-outlined" onClick={() => onClose()}>Cancelar</StyledButton>
                    </Container>
                    <Container ml="20px">
                        <StyledButton kind="primary" type="submit" loading={loading}>Salvar</StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentCorporation: state.corporations.currentCorporation,
})

const mapDispatchToProps = dispatch => ({
    async dispatchCreateCorporationManager({ corporationId }, { name, email, phoneNumber }) {
        await dispatch(createCorporationManager({ corporationId }, {
            name,
            email,
            phoneNumber
        }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateCorporationManagerDialog)
