import axios from "axios"
import currencyFormatter from "currency-formatter"
import ApiConfig from "../apiConfig";
import PropertyPlan from "../models/PropertyPlan";


export default class PropertyPlanHandler {
    static async fetchPropertyPlans({ corporationId, ventureId }) {
        const headers = ApiConfig.generateHeaders()

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/propertyPlans`,
            headers
        )

        const propertyPlans = response.data.map((propertyPlan) => new PropertyPlan({
            ...propertyPlan,
            size: currencyFormatter.format(propertyPlan.size, { code: "BRL", symbol: "" })
        }))

        return propertyPlans
    }

    static async updatePropertyPlan({ corporationId, ventureId, propertyPlanId }, {
        name, size, bedrooms, suites, garageSpots
    }) {
        const headers = ApiConfig.generateHeaders()
        const payload = {}
        if (name) payload.name = name
        if (size) payload.size = currencyFormatter.unformat(size, { code: "BRL" })
        if (bedrooms || bedrooms === 0) payload.bedrooms = bedrooms
        if (suites || suites === 0) payload.suites = suites
        if (garageSpots || garageSpots === 0) payload.garageSpots = garageSpots

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/propertyPlans/${propertyPlanId}`,
            payload,
            headers
        )

        const propertyPlan = new PropertyPlan({
            ...response.data
        })

        return propertyPlan
    }

    static async deletePropertyPlan({ corporationId, ventureId, propertyPlanId }) {
        const headers = ApiConfig.generateHeaders()

        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/propertyPlans/${propertyPlanId}`,
            headers
        )

        const propertyPlan = new PropertyPlan({
            ...response.data
        })

        return propertyPlan
    }
}
