// Libraries
import React, { useState } from "react"
import {DiscardChangesDialogContainer} from "./styled";
import StyledDialog from "../StyledDialog/StyledDialog";
import {Container, Label} from "../../structureComponents";
import StyledButton from "../StyledButton/StyledButton";


export default function DiscardChangesDialog({
    active = false,
    onCancel = () => ({}),
    onDiscardChanges = () => ({})
}) {

    // Methods
    const handleClose = () => {
        onCancel()
    }

    const handleDiscardChanges = (e) => {
        e.preventDefault()
        onDiscardChanges()
    }


    return (
        <DiscardChangesDialogContainer>
            <StyledDialog
                title="Atenção!"
                active={active}
                onClose={handleClose} maxWidth="sm">
                <form onSubmit={handleDiscardChanges}>
                    <Container>
                        <Label medium>Deseja sair sem salvar as alterações? </Label>
                    </Container>
                    <Container flex alignCenter justifyEnd mt="40px">
                        <Container>
                            <StyledButton kind="primary-outlined" slimHeight onClick={handleClose}>
                                Não
                            </StyledButton>
                        </Container>
                        <Container ml="10px">
                            <StyledButton kind="primary" slimHeight type="submit">
                                Sim
                            </StyledButton>
                        </Container>
                    </Container>
                </form>
            </StyledDialog>
        </DiscardChangesDialogContainer>
    )
}
