// Libraries
import styled from "styled-components"


export const SideBarContainer = styled.div`
  width: 100%;
  max-width: 16em;
  background-color: #FAFBFC;
  height: calc(100vh - 60px);
  position: fixed;
  margin-top: 60px;
  top: 0;
  left: 0;
  transition: max-width .2s ease;
  box-shadow: 1px 4px 8px #00000017;
  z-index: 100;
  padding: ${props => props.padding ? props.padding : null};
  box-sizing: border-box;
`

export const SideBarOptionsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  transition: background-color .4s ease, color .4s ease;
  cursor: pointer;
  
  span {
    color: #888;
  }
  
  &:hover {
    background-color: #f1f1f1;
    
    span {
      color: #333;
    }
  }
`

