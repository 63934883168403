// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Assets
import { VentureVideosContainer } from "./styled"
import {ImageGroupListContainer} from "../VentureImageGroups/styled";
import {Container, Label} from "../../../../../structureComponents";
import EmptyState from "../../../../../components/EmptyState/EmptyState";
import videoIcon from "../../../../../assets/icons/videos.svg";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import CreateVideoDialog from "./CreateVideoDialog/CreateVideoDialog";
import editIcon from "../../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../../assets/icons/trash.svg";
import UpdateVideoDialog from "./UpdateVideoDialog/UpdateVideoDialog"
import DeleteVideoDialog from "./DeleteVideoDialog/DeleteVideoDialog";
import {fetchVideosByVentureId} from "../../../../../store/modules/videos/actions";
import InlineLoading from "../../../../../components/Loading/Loading";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";


function VentureVideos({
   videos,
   currentVenture,
   dispatchFetchVideos
}) {
    // Contexts
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [showCreateVideoDialog, setShowCreateVideoDialog] = useState(false)
    const [showUpdateVideoDialog, setShowUpdateVideoDialog] = useState(false)
    const [showDeleteVideoDialog, setShowDeleteVideoDialog] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState(null)

    // Mounted
    useEffect(() => {
        handleFetchVideos()
    }, [])

    // Methods
    const handleFetchVideos = async () => {
        try {
            setLoading(true)
            await dispatchFetchVideos({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id
            })
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleUpdateVideo = (video) => {
        setSelectedVideo(video)
        setShowUpdateVideoDialog(true)
    }

    const handleDeleteVideo = (video) => {
        setSelectedVideo(video)
        setShowDeleteVideoDialog(true)
    }

    if (loading) {
        return (
            <Container fluid flex alignCenter justifyCenter height="calc(100vh - 49px - 40px - 60px - 40px - 40px)">
                <InlineLoading />
            </Container>
        )
    }

    return (
        <>
            <VentureVideosContainer>
                {!loading && videos?.length === 0 ? (
                    <Container
                        flex
                        alignCenter
                        justifyCenter
                        height="calc(100vh - 49px - 40px - 60px - 40px - 40px)">
                        <EmptyState
                            noAction={currentUser?.role !== ADMIN_USER_ROLE}
                            icon={videoIcon}
                            text="Este empreendimento nao possui nenhum video."
                            buttonText="Adicionar video"
                            onClick={() => setShowCreateVideoDialog(true)}
                        />
                    </Container>
                ) : (
                    <Container>
                        <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} flex alignCenter justifyEnd>
                            <StyledButton
                                kind="primary-outlined"
                                onClick={() => setShowCreateVideoDialog(true)}>
                                Criar video
                            </StyledButton>
                        </Container>
                        <ImageGroupListContainer>
                            {videos.map((video) => (
                                <Container fluid>
                                    <iframe
                                        type="text/html"
                                        frameBorder="0"
                                        id="player"
                                        width="100%"
                                        style={{height: "300px"}}
                                        src={video.url} />
                                        <Container flex fluid alignCenter justifyBetween mt="10px">
                                            <Container>
                                                <Label>{video.name}</Label>
                                            </Container>
                                            <Container flex alignCenter justifyEnd>
                                                <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE}>
                                                    <StyledButton
                                                        iconSize="16px"
                                                        iconButton
                                                        icon={{url: editIcon}}
                                                        onClick={() => handleUpdateVideo(video)}/>
                                                </Container>
                                                <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} ml="10px">
                                                    <StyledButton
                                                        iconSize="16px"
                                                        iconButton
                                                        icon={{url: deleteIcon}}
                                                        onClick={() => handleDeleteVideo(video)}/>
                                                </Container>
                                            </Container>
                                        </Container>
                                </Container>
                            ))}
                        </ImageGroupListContainer>
                    </Container>
                )}
            </VentureVideosContainer>
            <CreateVideoDialog
                active={showCreateVideoDialog}
                onClose={() => setShowCreateVideoDialog(false)} />
            <UpdateVideoDialog
                active={showUpdateVideoDialog}
                onClose={() => setShowUpdateVideoDialog(false)}
                videoToBeUpdated={selectedVideo}/>
            <DeleteVideoDialog
                active={showDeleteVideoDialog}
                onClose={() => setShowDeleteVideoDialog(false)}
                videoToBeDeleted={selectedVideo}/>
        </>
    )
}

const mapStateToProps = state => ({
    videos: state.videos.videos,
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchVideos({ corporationId, ventureId }) {
        await dispatch(fetchVideosByVentureId({ corporationId, ventureId }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureVideos)
