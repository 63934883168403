// Libraries
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

// Styles
import { CommonQuestionsContainer, CommonQuestionContainer } from "./styled";

// Structure components
import { Container, Label, Span } from "../../../../structureComponents";

// Components
import StyledButton from "../../../../components/StyledButton/StyledButton";
import InlineLoading from "../../../../components/Loading/Loading";
import VizualizeMessage from "./VizualizeMessage/VizualizeMessage";
import UpdateMessage from "./UpdateMessage/UpdateMessage";
import {
  deleteCommonQuestion,
  fetchCommonQuestions,
} from "../../../../store/modules/commonQuestions/actions";

// Store
import { setSnackbarState } from "../../../../store/modules/global/actions";

// Assets
import editIcon from "../../../../assets/icons/edit.svg";
import trashIcon from "../../../../assets/icons/trash.svg";
import vizualizeIcon from "../../../../assets/icons/vizualize.svg";

import EmptyState from "../../../../components/EmptyState/EmptyState";
import ConfirmDeleteDialog from "../../../../businessComponents/ConfirmDeleteDialog/ConfirmDeleteDialog";

import mesIcon from "../../../../assets/icons/envelope-regular.svg";

import { ADMIN_USER_ROLE } from "../../../../utils/constants";
import { AuthContext } from "../../../../Auth";
import CreateMessage from "./CreateMessage/CreateMessage";
import {
  deleteMessages,
  fetchMessage,
} from "../../../../store/modules/messages/actions";

function VentureMessages({
  ventureMessage = [],
  dispatchFetchMessage,
  currentVenture,
  dispatchDeleteMessage,
  dispatchSnackbar,
}) {
  const { currentUser } = useContext(AuthContext);

  // States
  const [showMessage, setShowMessage] = useState(false);
  const [showCreateMessage, setShowCreateMessage] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);

  // Mounted
  useEffect(() => {
    if (currentVenture) {
      handleFetchCommonQuestions();
    } else {
      setLoading(false);
    }
  }, [currentVenture]);

  // Methods{
  const handleFetchCommonQuestions = async () => {
    setLoading(true);
    await dispatchFetchMessage({ ventureId: currentVenture?.id });
    setLoading(false);
  };

  const handleShowMessage = (commonQuestion) => {
    setSelectedMessage(commonQuestion);
    setShowMessage(true);
  };

  const handleShowUpdateMessage = (message) => {
    setSelectedMessage(message);
    setShowUpdateMessage(true);
  };

  const handleShowDeleteMessage = (message) => {
    setSelectedMessage(message);
    setShowDeleteMessage(true);
  };

  const sliceText = (text) => {
    if (text?.length <= 80) {
      return text;
    }
    return text?.slice(0, 80) + "...";
  };

  const handleDeleteMessage = async () => {
    try {
      setLoadingRequest(true);

      await dispatchDeleteMessage({
        ventureId: currentVenture?.id,
        messageId: selectedMessage?.id,
      });

      setShowDeleteMessage(false);

      dispatchSnackbar({
        type: "success",
        active: true,
        message: "Pergunta frequente deletada com sucesso",
      });
    } catch (error) {
      dispatchSnackbar({
        type: "error",
        active: true,
        message: "Infelizmente ocorreu um erro",
      });
    } finally {
      setLoadingRequest(false);
    }
  };

  if (loading) {
    return (
      <Container
        flex
        alignCenter
        justifyCenter
        height="calc(100vh - 60px - 80px)"
      >
        <InlineLoading />
      </Container>
    );
  }

  return (
    <>
      {ventureMessage.length === 0 ? (
        <Container
          flex
          alignCenter
          justifyCenter
          height="calc(100vh - 60px - 80px)"
        >
          <EmptyState
            noAction={currentUser?.role !== ADMIN_USER_ROLE}
            icon={mesIcon}
            buttonText="Criar mensagem do empreendimento"
            text="Nenhuma mensagem cadastrada"
            onClick={() => setShowCreateMessage(true)}
          />
        </Container>
      ) : (
        <CommonQuestionsContainer>
          <Container alignCenter justifyBetween flex>
            <Container grid>
              <Label xXLarge bold>
                Mensagens
              </Label>
              <Label>
                <Span bold>{ventureMessage.length}</Span> mensagens
              </Label>
            </Container>
            {/* {currentVenture?.role === ADMIN_USER_ROLE && ( */}
            <StyledButton
              kind="primary-outlined"
              onClick={() => setShowCreateMessage(true)}
            >
              Criar mensagem
            </StyledButton>
            {/* )} */}
          </Container>

          <Container mt="40px">
            <Container mb="20px" ml="10px">
              <Container flex>
                <Container>
                  <Label bold>Nome</Label>
                </Container>
                <Container ml="130px">
                  <Label bold>Texto</Label>
                </Container>
              </Container>
            </Container>
            {ventureMessage.map((messages) => (
              <Container
                mt="15px"
                height="66px"
                fluid
                flex
                alignCenter
                justifyBetween
                // border="1px solid"
                radius="8px"
                pl="12px"
                shadow="0px 1px 4px rgba(0, 0, 0, 0.20)"
              >
                <Container flex alignCenter>
                  <Container width="90px">
                    <Label>{messages?.name}</Label>
                  </Container>
                  <Container ml="76px" alignCenter>
                    <Label>{sliceText(messages?.text)}</Label>
                  </Container>
                </Container>

                {currentUser?.role === ADMIN_USER_ROLE && (
                  <Container flex alignCenter>
                    <Container>
                      <StyledButton
                        iconButton
                        icon={{ url: vizualizeIcon }}
                        iconSize="20px"
                        onClick={() => handleShowMessage(messages)}
                      />
                    </Container>
                    <Container ml="10px">
                      <StyledButton
                        iconButton
                        icon={{ url: editIcon }}
                        iconSize="20px"
                        onClick={() => handleShowUpdateMessage(messages)}
                      />
                    </Container>
                    <Container ml="10px">
                      <StyledButton
                        iconButton
                        iconSize="20px"
                        icon={{ url: trashIcon }}
                        onClick={() => handleShowDeleteMessage(messages)}
                      />
                    </Container>
                  </Container>
                )}
              </Container>
            ))}
          </Container>
        </CommonQuestionsContainer>
      )}
      <VizualizeMessage
        active={showMessage}
        selectedMessage={selectedMessage}
        onClose={() => setShowMessage(false)}
      />
      <CreateMessage
        active={showCreateMessage}
        onClose={() => setShowCreateMessage(false)}
      />
      <UpdateMessage
        active={showUpdateMessage}
        selectedMessage={selectedMessage}
        onClose={() => setShowUpdateMessage(false)}
      />
      <ConfirmDeleteDialog
        active={showDeleteMessage}
        title="Deletar mensagem"
        loading={loadingRequest}
        text="Tem certeza que deseja deletar esta mensagem?"
        onClose={() => setShowDeleteMessage(false)}
        onDelete={handleDeleteMessage}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  ventureMessage: state.messages.ventureMessages,
  currentVenture: state.ventures.currentVenture,
});

const mapDispatchToProps = (dispatch) => ({
  async dispatchFetchMessage({ ventureId }) {
    await dispatch(fetchMessage({ ventureId }));
  },

  async dispatchDeleteMessage({ ventureId, messageId }) {
    await dispatch(deleteMessages({ ventureId, messageId }));
  },

  dispatchSnackbar({ message, type, active }) {
    dispatch(setSnackbarState({ message, type, active }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VentureMessages);
