// Libraries
import React, {useState} from "react"
import { connect } from "react-redux"
import StyledSelectV2 from "../../components/StyledSelectV2/StyledSelectV2";
import {fetchActiveVentures} from "../../store/modules/ventures/actions";

function VenturesOrderBy({ dispatchFetchActiveVentures, currentFilters, onLoading = () => ({}) }) {
    const [state, setState] = useState({
        orderByOptions: [
            { label: "Mais relevantes", value: "maisRelevantes" },
            { label: "Menor preço", value: "menorPreco" },
            { label: "Maior preço", value: "maiorPreco" },
            { label: "Menor metragem", value: "menorMetragem" },
            { label: "Maior metragem", value: "maiorMetragem" }
        ],
        selectedOrderByOption: null,
        orderByLabel: `Ordernar por: Mais relevantes`
    })

    // Methods
    const handleSelectedOrderByOptionOnChange = async (selectedOrderByOption) => {
        onLoading(true)
        const orderByLabel = `Ordenar por: ${selectedOrderByOption.label}`

        setState({
            ...state,
            selectedOrderByOption,
            orderByLabel
        })


        await dispatchFetchActiveVentures({ orderBy: selectedOrderByOption.value, searchValue: currentFilters.searchValue })
        onLoading(false)
    }

    return (
        <>
            <StyledSelectV2
                options={state.orderByOptions}
                placeholder={state.orderByLabel}
                value={state.selectedOrderByOption}
                onChange={handleSelectedOrderByOptionOnChange}/>
        </>
    )
}


const mapStateToProps = (state) => ({
    currentFilters: state.ventures.currentFilters
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchActiveVentures({ orderBy, searchValue }) {
        await dispatch(fetchActiveVentures({ orderBy, searchValue }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VenturesOrderBy)
