import React, {useEffect, useState} from "react"
import {Avatar, IconButton} from "@material-ui/core";
import { StyledAvatarContainer } from "./styled"


function StyledAvatar({
    src,
    width = "150px",
    height = "150px",
    onChange = () => ({})
}) {
    // States
    const [selectedFileUrl, setSelectedFileUrl] = useState(null)

    // Watchers
    useEffect(() => {
        if (src) {
            setSelectedFileUrl(src)
        }
    }, [src])


    // Methods
    const handleOnClick = () => {
        const element = document.getElementById("container-button-file")
        element.click()
    }

    const handleFileOnChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            setSelectedFileUrl(URL.createObjectURL(file))
            onChange(file)
        }
    }


    return (
        <StyledAvatarContainer width={width} height={height}>
            <input
                accept="image/*"
                id="container-button-file"
                className="styled-avatar--input"
                multiple
                type="file"
                onChange={handleFileOnChange}
            />
            <IconButton onClick={handleOnClick}>
                <Avatar
                    src={selectedFileUrl}
                    style={{
                        margin: "10px",
                        width,
                        height,
                    }}
                />
            </IconButton>
        </StyledAvatarContainer>
    )
}


export default StyledAvatar