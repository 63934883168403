import {
    SET_CURRENT_VENTURE,
    SET_VENTURE_FACILITIES_OPTIONS,
    SET_VENTURE_HIGHLIGHTS_OPTIONS,
    SET_VENTURE_TYPES,
    SET_ARCHIVE_VENTURES,
    SET_INBOX_VENTURES,
    SET_INBOX_VENTURES_META,
    SET_ARCHIVE_VENTURES_META,
    SET_INACTIVE_VENTURES_META,
    SET_ACTIVE_VENTURES_META,
    SET_ACTIVE_VENTURES,
    SET_INACTIVE_VENTURES,
    SET_CURRENT_FILTERS
} from "./mutations";

const initialState = {
    archiveVentures: [],
    archiveVenturesMeta: null,
    inboxVentures: [],
    inboxVenturesMeta: null,
    activeVentures: [],
    activeVenturesMeta: null,
    inactiveVentures: [],
    inactiveVenturesMeta: null,
    currentVenture: null,
    ventureFacilitiesOptions: [],
    ventureHighlightsOptions: [],
    ventureTypes: [],
    currentFilters: {
        minPrice: null,
        maxPrice: null,
        searchValue: null,
        minBedrooms: null,
        minSize: null,
        maxSize: null,
        garageSpots: null
    }
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CURRENT_VENTURE:
            return { ...state, currentVenture: action.payload }

        case SET_VENTURE_FACILITIES_OPTIONS:
            return { ...state, ventureFacilitiesOptions: action.payload }

        case SET_VENTURE_HIGHLIGHTS_OPTIONS:
            return { ...state, ventureHighlightsOptions: action.payload }

        case SET_VENTURE_TYPES:
            return { ...state, ventureTypes: action.payload }

        case SET_ARCHIVE_VENTURES:
            return { ...state, archiveVentures: action.payload }

        case SET_INBOX_VENTURES:
            return {  ...state, inboxVentures: action.payload }

        case SET_INBOX_VENTURES_META:
            return { ...state, inboxVenturesMeta: action.payload }    

        case SET_ARCHIVE_VENTURES_META:
            return { ...state, archiveVenturesMeta: action.payload }   
        
        case SET_ACTIVE_VENTURES:
            return { ...state, activeVentures: action.payload }

        case SET_ACTIVE_VENTURES_META:
            return { ...state, activeVenturesMeta: action.payload }

        case SET_INACTIVE_VENTURES:
            return { ...state, inactiveVentures: action.payload }

        case SET_INACTIVE_VENTURES_META:
            return { ...state, inactiveVenturesMeta: action.payload }

        case SET_CURRENT_FILTERS:
            return { ...state, currentFilters: action.payload }

        default:
            return state;
    }
}

export default reducer;
