// Libraries
import styled from "styled-components"
import {primaryDark} from "../../../assets/colorPallet";


export const ProfileMenuContainer = styled.div`
  width: 100%;
  max-width: 20em;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  cursor: pointer;
  
  &:hover {
    background-color: #f4f4f4;
  }
`
