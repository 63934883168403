import { connect } from "react-redux"
import PageWrapper from "../../structureComponents/modules/PageWrapper";
import TitleH2 from "../../structureComponents/modules/TitleH2";
import {Container, Label} from "../../structureComponents";
import Text from "../../structureComponents/modules/Text";
import {dispatchFetchTeamMembers, dispatchSetTeamMembersCurrentPage} from "../../store/modules/teamMembers/actions";
import {useEffect, useState} from "react";
import InlineLoading from "../../components/Loading/Loading";
import {navBarHeight} from "../../assets/sharedStyleValues";
import teamMemberIcon from "../../assets/icons/team-members.svg"
import EmptyState from "../../components/EmptyState/EmptyState";
import CreateTeamMemberDialog from "../../businessComponents/TeamMembers/CreateTeamMemberDialog/CreateTeamMemberDialog";
import List from "../../structureComponents/modules/List";
import ListItem from "../../structureComponents/modules/ListItem";

import StyledCard from "../../components/StyledCard/StyledCard";
import StyledButton from "../../components/StyledButton/StyledButton";
import editIcon from "../../assets/icons/edit.svg"
import deleteIcon from "../../assets/icons/trash.svg"
import UpdateTeamMemberDialog from "../../businessComponents/TeamMembers/UpdateTeamMemberDialog/UpdateTeamMemberDialog";
import DeleteTeamMemberDialog from "../../businessComponents/TeamMembers/DeleteTeamMemberDialog/DeleteTeamMemberDialog";
import StyledAvatar from "../../components/StyledAvatar/StyledAvatar";
import {Avatar} from "@material-ui/core";


function TeamMembers({
    teamMembers,
    teamMembersMeta,
    teamMembersCurrentPage,
    dispatchSetTeamMembersCurrentPage,
    dispatchFetchTeamMembers
}) {
    // State
    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)
    const [showCreateTeamMemberDialog, setShowCreateTeamMemberDialog] = useState(false)
    const [selectedTeamMember, setSelectedTeamMember] = useState(null)
    const [showUpdateTeamMemberDialog, setShowUpdateTeamMemberDialog] = useState(false)
    const [showDeleteTeamMemberDialog, setShowDeleteTeamMemberDialog] = useState(false)


    // Mounted
    useEffect(() => {
        handleFetchTeamMembers()
    }, [])

    // Methods
    const handleFetchTeamMembers = async () => {
        setLoading(true)
        await dispatchFetchTeamMembers({}, {
            page: teamMembersCurrentPage
        })
        setLoading(false)
    }

    const handleShowUpdateTeamMemberDialog = (teamMember) => {
        setSelectedTeamMember(teamMember)
        setShowUpdateTeamMemberDialog(true)
    }

    const handleShowDeleteTeamMemberDialog = (teamMember) => {
        setSelectedTeamMember(teamMember)
        setShowDeleteTeamMemberDialog(true)
    }

    const handleLoadMore = async () => {
        if(teamMembers.length < teamMembersMeta?.total) {
            const page = teamMembersCurrentPage + 1

            dispatchSetTeamMembersCurrentPage(page)

            setLoadingMore(true)

            await dispatchFetchTeamMembers({}, { page })

            setLoadingMore(false)
        }
    }
    if (loading) {
        return (
            <PageWrapper>
                <Container flex alignCenter justifyCenter height={`calc(100vh - ${navBarHeight})`}>
                    <InlineLoading />
                </Container>
            </PageWrapper>
        )
    }


    return (
        <PageWrapper>
            {!loading && teamMembers.length === 0 ? (
                <Container flex alignCenter justifyCenter height={`calc(100vh - ${navBarHeight} - 80px)`}>
                    <EmptyState
                        icon={teamMemberIcon}
                        text="Nenhum membro de equipe cadastrado"
                        buttonText="Cadastrar membro"
                        onClick={() => setShowCreateTeamMemberDialog(true)}/>
                </Container>
            ) : (
                <Container>
                    <TitleH2 xLarge>Equipe</TitleH2>
                    <Container mt="10px" flex alignCenter justifyBetween>
                        <Text medium>Nesta página você pode cadastrar os corretores e atendentes que fazem parte da equipe</Text>
                        <StyledButton kind="primary" onClick={() => setShowCreateTeamMemberDialog(true)}>
                            Cadastrar membro de equipe
                        </StyledButton>
                    </Container>
                    <Container fluid mt="60px">
                        <List column>
                            <Container fluid flex alignCenter p="0 20px">
                                <Container fluid maxWidth="6em">
                                    <Label bold>ID</Label>
                                </Container>
                                <Container fluid maxWidth="14em">
                                    <Label bold>Nome completo</Label>
                                </Container>
                                <Container fluid maxWidth="14em" ml="20px">
                                    <Label bold>WhatsApp</Label>
                                </Container>
                                <Container fluid maxWidth="14em" ml="20px">
                                    <Label bold>Email</Label>
                                </Container>
                                <Container fluid maxWidth="14em" ml="20px">
                                    <Label bold>Cargo</Label>
                                </Container>
                                <Container fluid maxWidth="14em" ml="20px">
                                    <Label bold>Restrição Opportunity</Label>
                                </Container>
                            </Container>
                            <Container mt="20px">
                                {teamMembers.map((teamMember, index) => (
                                    <ListItem mt={index > 0 ? "10px" : null} noMargin fluid>
                                        <StyledCard fluid>
                                            <Container flex alignCenter justifyBetween fluid p="20px 20px">
                                                <Container fluid flex alignCenter>
                                                    <Container fluid maxWidth="6em">
                                                        <Label>{teamMember?.id}</Label>
                                                    </Container>
                                                    <Container flex alignCenter fluid maxWidth="14em">
                                                        <Avatar
                                                            style={{
                                                                margin: "10px",
                                                                width: "30px",
                                                                height: "30px",
                                                            }}
                                                            src={teamMember?.avatarUrl} />
                                                        <Container ml="10px">
                                                            <Label>{teamMember?.fullName}</Label>
                                                        </Container>
                                                    </Container>
                                                    <Container fluid maxWidth="14em" ml="20px">
                                                        <Label>{teamMember?.whatsappNumber}</Label>
                                                    </Container>
                                                    <Container fluid maxWidth="14em" ml="20px">
                                                        <Label>{teamMember?.email}</Label>
                                                    </Container>
                                                    <Container fluid maxWidth="14em" ml="20px">
                                                        <Label>{teamMember?.roleLabel}</Label>
                                                    </Container>
                                                    <Container fluid maxWidth="14em" ml="20px">
                                                        <Label>{teamMember?.opportunityRestrictionLabel}</Label>
                                                    </Container>
                                                </Container>
                                                <Container flex alignCenter>
                                                    <StyledButton
                                                        iconButton
                                                        icon={{ url: editIcon }}
                                                        onClick={() => handleShowUpdateTeamMemberDialog(teamMember)}/>
                                                    <Container ml="10px">
                                                        <StyledButton
                                                            iconButton
                                                            icon={{ url: deleteIcon }}
                                                            onClick={() => handleShowDeleteTeamMemberDialog(teamMember)}/>
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </StyledCard>
                                    </ListItem>
                                ))}
                                {teamMembersMeta?.total > teamMembers?.length && (
                                    <Container mt="40px" flex justifyCenter>
                                        <StyledButton
                                            slimHeight
                                            loading={loadingMore}
                                            kind="primary"
                                            onClick={handleLoadMore}>
                                            Carregar mais
                                        </StyledButton>
                                    </Container>
                                )}
                            </Container>
                        </List>
                    </Container>
                </Container>
            )}
            <CreateTeamMemberDialog
            active={showCreateTeamMemberDialog}
            onClose={() => setShowCreateTeamMemberDialog(false)}/>
            <UpdateTeamMemberDialog
            active={showUpdateTeamMemberDialog}
            selectedTeamMember={selectedTeamMember}
            onClose={() => setShowUpdateTeamMemberDialog(false)}/>
            <DeleteTeamMemberDialog
            active={showDeleteTeamMemberDialog}
            selectedTeamMember={selectedTeamMember}
            onClose={() => setShowDeleteTeamMemberDialog(false)}/>
        </PageWrapper>
    )
}


const mapStateToProps = (state) => ({
    teamMembers: state.teamMembers.teamMembers,
    teamMembersMeta: state.teamMembers.teamMembersMeta,
    teamMembersCurrentPage: state.teamMembers.teamMembersCurrentPage
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchTeamMembers({}, { page }) {
        await dispatch(dispatchFetchTeamMembers({}, { page }))
    },

    dispatchSetTeamMembersCurrentPage(currentPage) {
        dispatch(dispatchSetTeamMembersCurrentPage(currentPage))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers)