class CommonQuestion {
    constructor({
        id,
        question,
        answer,
        ventureId
    }) {
        this.id = id
        this.question = question
        this.answer = answer
        this.ventureId = ventureId
    }
}


export default CommonQuestion
