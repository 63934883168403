import { SET_VENTURE_MESSAGES } from "./mutations";

const initialState = {
  ventureMessages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENTURE_MESSAGES:
      return { ...state, ventureMessages: action.payload };
    default:
      return state;
  }
};

export default reducer;
