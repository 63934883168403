// Libraries
import React, {useEffect, useState} from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { StyledRadioGroupContainer} from './styled'

export default function StyledRadioGroup({
    items = [],
    onChange = () => ({}),
    value
}) {
  const [internalValue, setInternalValue] = useState(null)

  useEffect(() => {
    if (value) {
      setInternalValue(value)
    }
  }, [value])

  // methods
  const handleChange = (e) => {
    setInternalValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <StyledRadioGroupContainer>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={internalValue}
        onChange={handleChange}
      >
          {items?.map((item, index) => (
              <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.label}
              />
          ))}
      </RadioGroup>
    </StyledRadioGroupContainer>
  )
}
