import React, {useContext} from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import StyledCard from "../../../components/StyledCard/StyledCard";
import { CorporationCardContainer } from "./styled"

import { Container, Label } from "../../../structureComponents"
import StyledButton from "../../../components/StyledButton/StyledButton";
import {CORPORATION_ROUTE} from "../../../utils/constants";
import {RouteContext} from "../../../context/RouteContext";
import {setCurrentCorporation} from "../../../store/modules/corporations/actions";
import SvgIcon from "../../../components/SvgIcon/SvgIcon"
import corporationIcon from "../../../assets/icons/corporationIcon.svg";
import Corporation from "../../../store/modules/corporations/model";


function CorporationCard({ corporation, match, dispatchSetCurrentCorporation }) {
    const { setCurrentRoute }= useContext(RouteContext)

    // Methods
    const handleEdit = () => {
        setCurrentRoute({
            name: CORPORATION_ROUTE,
            path: match.path
        })

        dispatchSetCurrentCorporation(new Corporation({ ...corporation }))
    }


    return (
        <CorporationCardContainer>
            <StyledCard>
                <Container p="20px">
                    <Container border="2px dashed #eee" fluid flex alignCenter justifyCenter p="20px" height="150px">
                        {corporation.logoUrl ? <img src={corporation.logoUrl} className="corporation--logo" />
                        : <SvgIcon src={corporationIcon} size="60px" color="#ccc" /> }
                    </Container>
                    <Container mt="20px">
                        <Container>
                            <Label bold>Incorporadora</Label>
                            <Container mt="5px">
                                <Label medium>{corporation.name}</Label>
                            </Container>
                        </Container>
                        <Container mt="40px" flex justifyEnd alignCenter>
                            <Link to={`${match.path}/${corporation.id}`} onClick={handleEdit}>
                                <Container>
                                    <StyledButton kind="primary-outlined" slimHeight>Editar</StyledButton>
                                </Container>
                            </Link>
                            <Container ml="10px">
                                <StyledButton kind="primary-outlined" slimHeight>Desativar</StyledButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </StyledCard>
        </CorporationCardContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    dispatchSetCurrentCorporation(currentCorporation) {
        dispatch(setCurrentCorporation(currentCorporation))
    }
})  

export default connect(null, mapDispatchToProps)(CorporationCard)
