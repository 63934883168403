// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Components
import EmptyState from "../../../../../components/EmptyState/EmptyState";

// Styles
import { VenturePhotosContainer, ImageGroupListContainer } from "./styled"

// Structure components
import { Container } from "../../../../../structureComponents"

// Assets
import photosIcon from "../../../../../assets/icons/photos.svg"

// Store actions
import {fetchImageGroups} from "../../../../../store/modules/imageGroups/actions";
import CreateImageGroupDialog from "./CreateImageGroupDialog/CreateImageGroupDialog";
import ImageGroupCard from "./ImageGroupCard/ImageGroupCard";
import StyledButton from "../../../../../components/StyledButton/StyledButton";
import InlineLoading from "../../../../../components/Loading/Loading";
import {ADMIN_USER_ROLE} from "../../../../../utils/constants";
import {AuthContext} from "../../../../../Auth";


function VentureImageGroups({
    dispatchFetchVentureImages,
    currentVenture,
    imageGroups,
    match,
    history
}) {
    // Contexts
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [showCreateImageGroupDialog, setShowCreateImageGroupDialog] = useState(false)

    // Watcher
    useEffect(() => {
        if (currentVenture) {
            handleFetchVentureImages({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id
            })
        }
    }, [currentVenture])

    // Methods
    const handleFetchVentureImages = async ({ corporationId, ventureId }) => {
        try {
            setLoading(true)
            await dispatchFetchVentureImages({ corporationId, ventureId })
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }


    if (loading) {
        return (
            <Container fluid flex alignCenter justifyCenter height="calc(100vh - 49px - 40px - 60px - 40px - 40px)">
                <InlineLoading />
            </Container>
        )
    }

    return (
        <>
            <VenturePhotosContainer>
                {!loading && imageGroups?.length === 0 ? (
                    <Container
                        flex
                        alignCenter
                        justifyCenter
                        height="calc(100vh - 49px - 40px - 60px - 40px - 40px)">
                        <EmptyState
                            noAction={currentUser?.role !== ADMIN_USER_ROLE}
                            icon={photosIcon}
                            text="Este empreendimento nao possui nenhum grupo de imagens."
                            buttonText="Criar grupo de imagem"
                            onClick={() => setShowCreateImageGroupDialog(true)}
                        />
                    </Container>
                ) : (
                    <Container>
                        <Container flex alignCenter justifyEnd>
                            {currentUser?.role === ADMIN_USER_ROLE && (
                                <StyledButton
                                kind="primary-outlined"
                                onClick={() => setShowCreateImageGroupDialog(true)}>
                                Criar grupo de imagem
                                </StyledButton>
                            )}
                        </Container>
                        <ImageGroupListContainer>
                            {imageGroups.map((imageGroup, index) => (
                                <Container key={index} fluid>
                                    <ImageGroupCard history={history} match={match} imageGroup={imageGroup} />
                                </Container>
                            ))}
                        </ImageGroupListContainer>
                    </Container>
                )}
            </VenturePhotosContainer>
            <CreateImageGroupDialog
                active={showCreateImageGroupDialog}
                onClose={() => setShowCreateImageGroupDialog(false)} />
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    imageGroups: state.imageGroups.imageGroups
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchVentureImages({ corporationId, ventureId }) {
        await dispatch(fetchImageGroups({ corporationId, ventureId }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureImageGroups)
