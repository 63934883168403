import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { StyledTabsContainer } from "./styled"
import DiscardChangesDialog from "../DiscardChangesDialog/DiscardChangesDialog";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function StyledTabs({
   tabs = [],
   userHasChanges,
   onChange = () => ({})
}) {
    // States
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false)
    const [selectedTab, setSelectedTab] = useState(null)

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    // Methods
    const handleChange = (event, newValue) => {
        if (userHasChanges) {
            setShowDiscardChangesDialog(true)
            setSelectedTab(newValue)
        } else {
            setShowDiscardChangesDialog(false)
            setValue(newValue);
        }

        onChange(tabs[newValue].name)
    };

    const handleDiscardChanges = () => {
        setShowDiscardChangesDialog(false)
        setValue(selectedTab)
    }

    return (
        <>
            <StyledTabsContainer className={classes.root}>
                <AppBar position="static" color="transparent" classes={{ root: "styled-tabs--app-bar" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab?.label} {...a11yProps(0)} classes={{ root: "styled-tabs--app-bar__tabs--tab" }} />
                        ))}
                    </Tabs>
                </AppBar>
                {tabs.map((tab, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {tab.children}
                    </TabPanel>
                ))}

            </StyledTabsContainer>
            <DiscardChangesDialog
            active={showDiscardChangesDialog}
            onCancel={() => setShowDiscardChangesDialog(false)}
            onDiscardChanges={handleDiscardChanges}/>
        </>
    );
}
