import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import PageWrapper from "../../structureComponents/modules/PageWrapper";
import {Avatar, Divider} from "@material-ui/core";
import {AuthContext} from "../../Auth";
import { Container, Label } from "../../structureComponents"
import TitleH2 from "../../structureComponents/modules/TitleH2";
import TitleH3 from "../../structureComponents/modules/TitleH3";
import StyledInput from "../../components/StyledInput/StyledInput";
import StyledButton from "../../components/StyledButton/StyledButton";
import MaskHelper from "../../utils/MaskHelper";
import {dispatchChangePassword} from "../../store/modules/users/actions";
import {setSnackbarState} from "../../store/modules/global/actions";
import {dispatchUpdateWhatsappNumber} from "../../store/modules/teamMembers/actions";


function MyProfile({ dispatchChangePassword, dispatchSnackbar, dispatchUpdateWhatsappNumber }) {
    const { currentUser, setCurrentUser } = useContext(AuthContext)

    // States
    const [passwordError, setPasswordError] = useState(null)
    const [password, setPassword] = useState("********")
    const [whatsappNumber, setWhatsappNumber] = useState(null)
    const [loadingPasswordChanging, setLoadingPasswordChanging] = useState(false)
    const [loadingWhatsappChanging, setLoadingWhatsappChanging] = useState(false)
    const [whatsappNumberError, setWhatsappNumberError] = useState(null)

    // Watchers
    useEffect(() => {
        if (currentUser) {
            setWhatsappNumber(currentUser?.teamMember?.whatsappNumber)
        }
    }, [])


    // Methods
    const handlePasswordOnChange = (e) => {
        setPassword(e.target.value)
    }

    const handleChangePassword = async () => {
        try {
            if (password.length < 6 || password?.length > 12) {
                setPasswordError("A senha deve ter no mínimo 6 e no máximo 12 caracteres.")
                return
            } else {
                setPasswordError(null)
            }

            setLoadingPasswordChanging(true)
            await dispatchChangePassword({ password })

            dispatchSnackbar({
                type: "success",
                message: "Senha atualizada com sucesso.",
                active: true
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro.",
                active: true
            })
        } finally {
            setLoadingPasswordChanging(false)
        }
    }

    const handleWhatsappNumberOnChange = (e) => {
        setWhatsappNumber(MaskHelper.toPhoneNumber(e.target.value))
    }

    const handleSaveWhatsappNumber = async () => {
        try {
            if (whatsappNumber.length < 14 || whatsappNumber?.length > 15) {
                setWhatsappNumberError("Número de whatsApp inválido")
                return
            } else {
                setWhatsappNumberError(null)
            }

            setLoadingWhatsappChanging(true)
            await dispatchUpdateWhatsappNumber({ whatsappNumber })

            dispatchSnackbar({
                type: "success",
                message: "WhatsApp atualizado com sucesso.",
                active: true
            })

            setCurrentUser({
                ...currentUser,
                teamMember: {
                    ...currentUser?.teamMember,
                    whatsappNumber
                }
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro.",
                active: true
            })
        } finally {
            setLoadingWhatsappChanging(false)
        }
    }


    return (
        <PageWrapper>
            <TitleH2 xLarge>Editar perfil</TitleH2>
            <Container mt="40px">
                <Container flex alignCenter>
                    <Avatar src={currentUser?.teamMember?.avatarUrl} style={{ width: "100px", height: "100px" }} />
                    <Container ml="20px">
                        <Label xLarge bold>{currentUser?.name}</Label>
                        <Container mt="5px">
                            <Label>{currentUser?.email}</Label>
                        </Container>
                    </Container>
                </Container>
                <Container p="40px 0">
                    <Divider />
                </Container>
                <Container>
                    <TitleH3 medium>Senha</TitleH3>
                    <Container mt="20px">
                        <Container>
                            <Label medium>Senha atual</Label>
                            <Container mt="10px" maxWidth="318px">
                                <StyledInput
                                type="password"
                                value={password}
                                onChange={handlePasswordOnChange} />
                            </Container>
                            {passwordError && (
                                <Container mt="10px">
                                    <Label error>{passwordError}</Label>
                                </Container>
                            )}
                        </Container>
                        <Container mt="20px">
                            <StyledButton
                            slimHeight
                            kind="primary"
                            loading={loadingPasswordChanging}
                            onClick={handleChangePassword}>
                                Trocar senha
                            </StyledButton>
                        </Container>
                    </Container>
                </Container>
                {currentUser?.teamMember && (
                  <>
                      <Container p="40px 0">
                          <Divider />
                      </Container>
                      <Container>
                          <TitleH3 medium>Contato (WhatsApp)</TitleH3>
                          <Container mt="20px">
                              <Container maxWidth="318px">
                                  <StyledInput maxLength={15} value={whatsappNumber} onChange={handleWhatsappNumberOnChange}/>
                              </Container>
                              {whatsappNumberError && (
                                  <Container mt="10px">
                                      <Label error>{whatsappNumberError}</Label>
                                  </Container>
                              )}
                              <Container mt="20px">
                                  <StyledButton
                                      slimHeight
                                      kind="primary"
                                      loading={loadingWhatsappChanging}
                                      onClick={handleSaveWhatsappNumber}>
                                      Alterar contato
                                  </StyledButton>
                              </Container>
                          </Container>
                      </Container>
                  </>
                )}
            </Container>
        </PageWrapper>
    )
}


const mapDispatchToProps = (dispatch) => ({
    async dispatchChangePassword({ password }) {
        await dispatch(dispatchChangePassword({ password }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    async dispatchUpdateWhatsappNumber({ whatsappNumber }) {
        await dispatch(dispatchUpdateWhatsappNumber({ whatsappNumber }))
    }
})


export default connect(null, mapDispatchToProps) (MyProfile)