// Libraries
import axios from "axios"

// Config
import store from "../../index";
import {SET_IMAGE_GROUPS} from "../imageGroups/mutations";
import { SET_UPLOADING_PROGRESS_PERCENTAGE, SET_RESIZING_IMAGES } from "./mutations"
import ImageHelper from "./helper";
import {setImageGroups} from "../imageGroups/actions";

// Mutations


export const fetchImagesByImageGroupId= ({ corporationId, ventureId, imageGroupId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}/images`,
            headers
        )

        return response.data
    } catch (error) {
        throw error
    }
}


export const uploadVentureImages = ({ corporationId, ventureId, imageGroupId }, { images }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const uploadedImages = []

        dispatch({ type: SET_RESIZING_IMAGES, payload: true })

        const imagesToBeResized = []

        for (let i = 0; i < images.length; i += 1) {
            const img = images[i]

            imagesToBeResized.push(img.file)
        }

        const resizedImages = await ImageHelper.resizeAllImages(imagesToBeResized)
        dispatch({ type: SET_RESIZING_IMAGES, payload: false })

        let count = 1

        for (let imagePosition = 0; imagePosition < resizedImages.length; imagePosition += 1) {
            const image = resizedImages[imagePosition]

            let formData = new FormData()
            const imageName = images.find((img, i) => imagePosition === i).customName
            formData.append('image', image, imageName)

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}/images`,
                formData,
                headers
            )

            uploadedImages.push(response.data)
            const uploadingProgressPercentage = (count / resizedImages.length) * 100
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: uploadingProgressPercentage })
            count += 1
        }

        const imageGroups = [...store.getState().imageGroups.imageGroups].map((imageGroup) => {
            if (imageGroup.id === imageGroupId) {
                return {
                    ...imageGroup,
                    images: [
                        ...imageGroup.images,
                        ...uploadedImages
                    ]
                }
            }

            return imageGroup
        })

        dispatch({ type: SET_IMAGE_GROUPS, payload: imageGroups })

        setTimeout(() => {
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: 0 })
        }, 3000)
    } catch (error) {
        throw error
    }
}

export const deleteVentureImage = ({ corporationId, ventureId, imageGroupId, imageId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId || !imageId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        await axios.delete(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}/images/${imageId}`,
            headers
        )

        const imageGroups = [...store.getState().imageGroups.imageGroups].map((imageGroup) => {
            if (imageGroup.id === imageGroupId) {
                const images = [...imageGroup.images].filter((image) => image.id !== imageId)
                imageGroup.images = images
                return imageGroup
            }

            return imageGroup
        })

        dispatch(setImageGroups(imageGroups))
    } catch (error){
        throw error
    }
}

export const updateVentureImage = ({ corporationId, ventureId, imageGroupId, imageId }, { name }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId || !imageId) {
            throw new Error("Missing params")
        }

        if (!name) {
            return new Error("Payload is incorrect")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}
        const payload = { name }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}/images/${imageId}`,
            payload,
            headers
        )

        const updatedImage = response.data

        const imageGroups = [...store.getState().imageGroups.imageGroups].map((imageGroup) => {
            if (imageGroup.id === imageGroupId){
                const images = [...imageGroup.images].map((img) => {
                    if (img.id === imageId) {
                        img.name = updatedImage.name
                        return img
                    }

                    return img
                })

                imageGroup.images = images
                return imageGroup
            }

            return imageGroup
        })

        dispatch(setImageGroups(imageGroups))
    } catch (error) {
        throw error
    }
}
