// Libraries
import styled from "styled-components"
import {primary} from "../../assets/colorPallet";


export const NavbarContainer = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  z-index: 150;
  background-color: white;
  position: fixed;
  box-shadow: 1px 1px 8px #00000017;
`

