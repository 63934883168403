// Libraries
import React, {useState} from "react"

// Styles
import {VentureImagesAndVideosContainer} from "./styled"
import StyledTabs from "../../../../components/StyledTabs/StyledTabs";
import {PHOTOS_TAB, VIDEOS_TAB} from "../../../../utils/constants";
import VentureImageGroups from "./VentureImageGroups/VentureImageGroups";
import VentureVideos from "./VentureVideos/VentureVideos";

function VentureImagesAndVideos(props) {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)

    // Methods
    const handleOnUserChanges = (hasChanges) => {
        setUserHasChanges(hasChanges)
    }

    const photosAndVideosTabs = [
        {
            name: PHOTOS_TAB,
            label: "Imagens",
            children: <VentureImageGroups {...props}  onUserChanges={handleOnUserChanges} />
        },
        {
            name: VIDEOS_TAB,
            label: "Videos",
            children: <VentureVideos onUserChanges={handleOnUserChanges} />
        }
    ]
    return (
        <VentureImagesAndVideosContainer>
            <StyledTabs tabs={photosAndVideosTabs} userHasChanges={userHasChanges} />
        </VentureImagesAndVideosContainer>
    )
}

export default VentureImagesAndVideos
