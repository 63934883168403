// Libraries
import React from "react"
import {EmptyStateContainer} from "./styled";
import StyledButton from "../StyledButton/StyledButton";
import {Container, Text} from "../../structureComponents";
import SvgIcon from "../SvgIcon/SvgIcon";


function EmptyState({
    buttonText = "Criar novo",
    noAction,
    icon,
    text,
    onClick = () => ({})
}) {
    return (
        <EmptyStateContainer>
            <Container flex alignCenter justifyCenter column>
                <Container flex alignCenter justifyCenter column>
                    <SvgIcon src={icon} size="160px" color="#eee" fillPath />
                    <Container mt="20px" fluid maxWidth="22em">
                        <Text large lightGrey center>{text}</Text>
                    </Container>
                </Container>
                {!noAction && (
                    <Container mt="40px">
                        <StyledButton kind="primary-outlined" onClick={() => onClick()}>{buttonText}</StyledButton>
                    </Container>
                )}
            </Container>
        </EmptyStateContainer>
    )
}

export default EmptyState
