import styled from "styled-components"


export const StyledCardContainer = styled.div`
  cursor: ${props => props.pointer ? "pointer" : null};
  width: ${props => props.fluid ? "100%" : null};
  
  .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    width: ${props => props.fluid ? "100%" : null};
    max-width: ${props => props.maxWidth ? props.maxWidth : null};
  }
`

