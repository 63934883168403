// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles
import { VentureImageGroupContainer, VentureImage } from "./styled"

// Structure components
import { Container, TitleH2, Label } from "../../../../../../structureComponents"

// Assets
import {primary} from "../../../../../../assets/colorPallet"

// Store actions
import ImageGroup from "../../../../../../store/modules/imageGroups/model";
import StyledButton from "../../../../../../components/StyledButton/StyledButton";
import UploadVentureImagesDialog from "./UploadVentureImagesDialog/UploadVentureImagesDialog";
import EmptyState from "../../../../../../components/EmptyState/EmptyState";
import photosIcon from "../../../../../../assets/icons/photos.svg";
import editIcon from "../../../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../../../assets/icons/trash.svg";
import DeleteVentureImageDialog from "./DeleteVentureImageDialog/DeleteVentureImageDialog";
import UpdateVentureImageDialog from "./UpdateVentureImageDialog/UpdateVentureImageDialog";
import VentureImageDialog from "./VentureImageDialog/VentureImageDialog";
import {AuthContext} from "../../../../../../Auth";
import {ADMIN_USER_ROLE} from "../../../../../../utils/constants";


function VentureImageGroup({ history, imageGroups }) {
    const { currentUser } = useContext(AuthContext)
    // States
    const [currentImageGroup, setCurrentImageGroup] = useState(new ImageGroup({}))
    const [showUploadVentureImageDialog, setShowUploadVentureImageDialog] = useState(false)
    const [showDeleteVentureImageDialog, setShowDeleteVentureImageDialog] = useState(false)
    const [selectedVentureImage, setSelectedVentureImage] = useState(null)
    const [showUpdateVentureImageDialog, setShowUpdateVentureImageDialog] = useState(false)
    const [showVentureImageDialog, setShowVentureImageDialog] = useState(false)

    // Mounted
    useEffect(() => {
        if (history && history.location && history.location.state) {
            const historyState = history.location.state
            const imageGroup = imageGroups.find(imageGroup => imageGroup.id === historyState.imageGroup.id)
            setCurrentImageGroup(imageGroup)
        }
    }, [])

    // Watchers
    useEffect(() => {
        if (history && history.location && history.location.state) {
            const historyState = history.location.state
            const imageGroup = imageGroups.find(imageGroup => imageGroup.id === historyState.imageGroup.id)
            setCurrentImageGroup(imageGroup)
        }
    }, [history])

    useEffect(() => {
        if (imageGroups.length > 0) {
            const historyState = history.location.state

            const imageGroup = imageGroups.find(imageGroup => imageGroup.id === historyState.imageGroup.id)
            setCurrentImageGroup(imageGroup)
        }
    }, [imageGroups])

    // Methods
    const handleShowDeleteImageDialog = (ventureImage) => {
        setShowDeleteVentureImageDialog(true)
        setSelectedVentureImage(ventureImage)
    }

    const handleShowUpdateVentureImageDialog = (ventureImage) => {
        setShowUpdateVentureImageDialog(true)
        setSelectedVentureImage(ventureImage)
    }

    const handleOpenVentureImage = (ventureImage) => {
        setShowVentureImageDialog(true)
        setSelectedVentureImage(ventureImage)
    }


    return (
        <>
            <VentureImageGroupContainer>
                <Container>
                    <a
                    style={{ textDecoration: "underline", color: primary, cursor: "pointer" }}
                    onClick={() => history.goBack()}>
                    Grupos de imagens
                    </a>
                </Container>
                <Container mt="40px" fluid>
                    {currentImageGroup?.images?.length > 0 ? (
                        <Container>
                            <Container flex alignCenter justifyBetween fluid>
                                <TitleH2 bold>{currentImageGroup.name}</TitleH2>
                                {currentUser?.role === ADMIN_USER_ROLE && (
                                    <Container>
                                        <StyledButton
                                            slimHeight
                                            kind="primary-outlined"
                                            onClick={() => setShowUploadVentureImageDialog(true)}>
                                            Upload
                                        </StyledButton>
                                    </Container>
                                )}
                            </Container>
                            <Container mt="40px" grid columns={3}>
                                {currentImageGroup?.images.map((ventureImage) => (
                                    <Container>
                                        <VentureImage
                                            src={ventureImage.mediumUrl}
                                            onClick={() => handleOpenVentureImage(ventureImage)} />
                                        <Container flex fluid alignCenter justifyBetween mt="10px">
                                            <Container>
                                                <Label>{ventureImage.description || ventureImage.name}</Label>
                                            </Container>
                                            {currentUser?.role === ADMIN_USER_ROLE && (
                                                <Container flex alignCenter justifyEnd>
                                                    <Container>
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton
                                                            icon={{ url: editIcon}}
                                                            onClick={() => handleShowUpdateVentureImageDialog(ventureImage)}/>
                                                    </Container>
                                                    <Container ml="10px">
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton
                                                            icon={{ url: deleteIcon}}
                                                            onClick={() => handleShowDeleteImageDialog(ventureImage)}/>
                                                    </Container>
                                                </Container>
                                            )}
                                        </Container>
                                    </Container>
                                ))}
                            </Container>
                        </Container>
                    ) : (
                        <Container mt="20px" fluid>
                            <Container
                                flex
                                alignCenter
                                justifyCenter
                                height="calc(100vh - 49px - 40px - 60px - 40px - 40px - 20px)">
                                <EmptyState
                                    icon={photosIcon}
                                    text="Este grupo nao possui nenhuma imagem."
                                    buttonText="Adicionar imagens"
                                    onClick={() => setShowUploadVentureImageDialog(true)}
                                />
                            </Container>
                        </Container>
                    )}
                </Container>
            </VentureImageGroupContainer>
            <UploadVentureImagesDialog
            active={showUploadVentureImageDialog}
            imageGroup={currentImageGroup}
            onClose={() => setShowUploadVentureImageDialog(false)}/>
            <DeleteVentureImageDialog
            active={showDeleteVentureImageDialog}
            ventureImage={selectedVentureImage}
            onClose={() => setShowDeleteVentureImageDialog(false)}/>
            <UpdateVentureImageDialog
            active={showUpdateVentureImageDialog}
            ventureImage={selectedVentureImage}
            onClose={() => setShowUpdateVentureImageDialog(false)}/>
            <VentureImageDialog
            active={showVentureImageDialog}
            ventureImage={selectedVentureImage}
            onClose={() => setShowVentureImageDialog(false)}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    imageGroups: state.imageGroups.imageGroups
})

export default connect(mapStateToProps)(VentureImageGroup)
