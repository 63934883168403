// Libraries
import styled from "styled-components"


export const VentureModerationCardContainer = styled.div`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 300px;
    display: flex;
  
  &:not(:first-child) {
    margin-top: 20px;
  }
`

export const VentureImage = styled.img`
    width: 400px;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`

export const CorporationName = styled.div`
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      background-color: #eee;
      border-radius: 20em;
`
