import React, {useContext} from "react"
import StyledDrawer from "../../../components/StyledDrawer/StyledDrawer";
import {Container, Label} from "../../../structureComponents";
import {AuthContext} from "../../../Auth";
import {ProfileMenuSideDrawerOption} from "./styled";
import {Avatar} from "@material-ui/core";
import routes from "../../../routes";


function ProfileMenuSideDrawer({ active, history, onClose = () => ({}), onLogout = () => ({}) }) {
    // context
    const { currentUser, setCurrentUser } = useContext(AuthContext)

    const handleLogout = () => {
        onLogout()
        setCurrentUser(null)
        onClose()
    }

    const goToProfile = () => {
        history.push(routes.profile.url)
        onClose()
    }


    return (
        <StyledDrawer active={active} onClose={() => onClose()} anchor="right">
            <Container width="20em">
                <Container flex alignCenter ml="40px">
                    <Avatar src={currentUser?.teamMember?.avatarUrl} size="30px" color="#333" />
                    <Container flex alignCenter ml="10px">
                        <Label color="#333" bold pointer medium>{currentUser.name}</Label>
                        {currentUser?.teamMember && (
                            <Container flex alignCenter ml="10px" bgColor="#eee" p="5px 12px" radius="20em">
                                <Label>id: {currentUser?.teamMember?.id}</Label>
                            </Container>
                        )}
                    </Container>
                </Container>
                <Container mt="40px">
                    <ProfileMenuSideDrawerOption onClick={goToProfile}>
                        <Label pointer>Meu perfil</Label>
                    </ProfileMenuSideDrawerOption>
                    <ProfileMenuSideDrawerOption onClick={handleLogout}>
                        <Label pointer>Sair da conta</Label>
                    </ProfileMenuSideDrawerOption>
                </Container>
            </Container>
        </StyledDrawer>
    )
}

export default ProfileMenuSideDrawer