// Libraries
import React, {useContext, useEffect, useState} from "react"

import Corporation from "../../../store/modules/corporations/model";
import {CORPORATION_ROUTE} from "../../../utils/constants";
import {RouteContext} from "../../../context/RouteContext";


function CorporationDetails({ match }) {
    // Context
    const { setCurrentRoute } = useContext(RouteContext)


    // Mounted
    useEffect(() => {
        setCurrentRoute({
            name: CORPORATION_ROUTE,
            path: match.path,
            params: match.params
        })
    }, [])


    return (
        <div>
        </div>
    )
}

export default CorporationDetails
