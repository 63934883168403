import React, {useState} from "react"
import {StyledImageContainer, StyledImageSource} from "./styled";
import {Container} from "../../structureComponents";
import {Skeleton} from "@material-ui/lab";


export default function StyledImage({ src, rounded, cover, height, width, onClick = () => ({}) }) {
    // State
    const [loading, setLoading] = useState(true)
    return (
        <StyledImageContainer onClick={() => onClick()}>
            {loading && (
                <Container flex>
                    <Skeleton width="100%" style={{ height }} />
                </Container>
            )}
            <StyledImageSource
            rounded={rounded}
            cover={cover}
            loading={loading}
            src={src}
            width={width}
            height={height}
            onLoad={() => setLoading(false)} />
        </StyledImageContainer>
    )
}
