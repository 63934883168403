import React, { useState, createContext } from "react"


export const RouteContext = createContext({
    currentVenture: null,
    setCurrentVenture: () => {}
})


export default function RouteProvider({ children }) {
    // States
    const [currentRoute, setCurrentRoute] = useState(null)

    return (
        <RouteContext.Provider value={{ currentRoute, setCurrentRoute }}>
            {children}
        </RouteContext.Provider>
    )
}
