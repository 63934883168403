import React from "react"
import Card from '@material-ui/core/Card';

import { StyledCardContainer } from "./styled"


function StyledCard({ fluid, maxWidth, children, pointer = false, onClick = () => ({}) }) {
    return (
        <StyledCardContainer
            pointer={pointer}
            onClick={() => onClick()}
            maxWidth={maxWidth}
            fluid={fluid}>
            <Card>
                {children}
            </Card>
        </StyledCardContainer>
    )
}


export default StyledCard
