// Libraries
import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"

// Structure components
import { Container } from "../../../structureComponents"


export default function VentureCardSkeleton() {
    return (
        <Container fluid flex height="300px" border="1px solid #ccc" radius="8px">
            <Container height="300px">
                <Skeleton width={400} style={{ height: "500px", marginTop: "-120px", borderRadius: "8px" }} />
            </Container>
            <Container fluid flex column justifyBetween p="20px">
                <Container>
                    <Container>
                        <Skeleton width={200} height={40} />
                        <Container ml="10px">
                            <Skeleton width={50} height={40} />
                        </Container>
                    </Container>
                    <Container mt="10px">
                        <Skeleton width={150} height={40} />
                    </Container>
                </Container>
                <Container flex fluid alignCenter justifyEnd>
                    <Container>
                        <Skeleton width={120} height={80} />
                    </Container>
                    <Container ml="10px">
                        <Skeleton width={120} height={80} />
                    </Container>
                </Container>
            </Container>
        </Container>
    )
}
