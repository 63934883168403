// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {NavbarContainer} from "./styled";
import {Container} from "../../structureComponents";
import {logout} from "../../store/modules/authentication/actions";
import {AuthContext} from "../../Auth";
import SvgIcon from "../../components/SvgIcon/SvgIcon";
import imobproLogo from "../../assets/logos/imobpro-logo-secondary.svg";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import UserHandler from "../../handlers/UserHandler";
import ProfileMenuSideDrawer from "./ProfileMenuSideDrawer/ProfileMenuSideDrawer";


function Navbar(props) {
    const { currentUser, setCurrentUser } = useContext(AuthContext)

    // Watchers
    useEffect(() => {
        if (currentUser) {
            handleFetchUser()
        }
    }, [])

    // Methods
    const handleFetchUser = async () => {
        const user = await UserHandler.fetchUser()
        setCurrentUser(user)
    }


    return (
            <NavbarContainer>
                <Container fluid flex alignCenter justifyBetween>
                    <Container fluid maxWidth="16em" flex alignCenter>
                        <Link style={{ width: "100%" }} to="/">
                            <Container height="40px" flex alignCenter justifyCenter br="1px solid #ccc">
                                <SvgIcon src={imobproLogo} size="120px"/>
                            </Container>
                        </Link>
                    </Container>
                    <Container flex alignCenter>
                        <ProfileMenu { ...props } />
                    </Container>
                </Container>
            </NavbarContainer>
    )
}

const mapDispatchToProps = dispatch => ({
    logout() {
        dispatch(logout())
    }
})

export default connect(null, mapDispatchToProps)(Navbar)
