// Libraries
import React from "react";

// Components
import { Button, IconButton } from "@material-ui/core";
import { StyledButtonContainer } from "./styled";
import Loading from "../Loading/Loading";
import Container from "../../structureComponents/modules/Container";
import SvgIcon from "../SvgIcon/SvgIcon";

export default function StyledButton({
  children,
  color = "white",
  slimHeight,
  height,
  uppercase,
  kind,
  shape,
  active,
  disabled,
  fluid,
  icon = { url: "" },
  semiBold,
  iconButton,
  iconColor,
  flat,
  large,
  iconSize = "18px",
  loading,
  onClick = () => ({}),
  type,
  className,
  light,
  id,
}) {
  return (
    <StyledButtonContainer
      kind={kind}
      semiBold={semiBold}
      shape={shape}
      disabled={disabled}
      iconColor={iconColor}
      light={light}
      id={id}
      active={active}
      iconButton={iconButton}
      color={color}
      height={height}
      slimHeight={slimHeight}
      flat={flat}
      fluid={fluid}
      iconSize={iconSize}
      large={large}
      uppercase={uppercase}
    >
      {iconButton ? (
        <IconButton
          className={className}
          onClick={(e) => onClick(e)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          {icon && icon.url && (
            <SvgIcon src={icon?.url} size={iconSize} color={iconColor} />
          )}
        </IconButton>
      ) : (
        <Button
          onClick={(e) => onClick(e)}
          id="styled-button"
          className={className}
          type={type}
        >
          {loading ? (
            <Loading color="white" />
          ) : icon ? (
            <Container flex alignCenter>
              <SvgIcon src={icon?.url} color={iconColor} size={iconSize} />
              <Container>{children}</Container>
            </Container>
          ) : (
            children
          )}
        </Button>
      )}
    </StyledButtonContainer>
  );
}
