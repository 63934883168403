// Libraries
import styled from "styled-components"
import {primary, textDefault, primaryLight, secondary, whatsappColor, green500} from "../../assets/colorPallet";

export const StyledButtonContainer = styled.div`
  width: ${props => props.fluid ? "100%" : props.shape === "circle" ? "48px" : null};
  height: ${props => props.shape === "circle" ? "48px" : null};
  cursor: ${props => props.disabled ? "not-allowed" : null};
  pointer-events: ${props => props.disabled ? "none" : "all"};
  opacity: ${props => props.disabled ? .4 : 1};
 

  
  #styled-button {
    background-color: ${props => {
        if (props.kind === "primary") return primary
        else if (props.kind === "primary-light") return primaryLight    
        else if (props.kind === "secondary") return secondary
        else if (
            (props.kind === "primary-outlined"
            || props.kind === "secondary-outlined"
            || props.kind === "primary-light-outlined"
            || props.kind === "primary-outlined__white")
            || props.kind === "dark-outlined"
            && !props.active
        ) {
            return "transparent"
        }
        else if (props.kind === "dark-outlined--solid") {
            return "white"
        }
        else if (props.kind === "primary-outlined--solid" && !props.active) {
            return "white"
        }
        else if ((props.kind === "primary-outlined" || props.kind === "primary-outlined--solid") && props.active) {
            return primary
        } 
        else if (props.kind === "secondary-outlined" && props.active) {
            return secondary
        }
        else if (props.kind === "primary-light-outlined" && props.active) {
            return primaryLight
        }
        else if (props.kind === "whatsapp") {
            return whatsappColor
        }
        else if (props.kind === "dark-outlined" && props.shape === "circle") {
            return green500
        }
        else if (props.kind === "dark-active") {
            return "#ECEFF1"
        }
    }} ;
    border-radius: ${props => {
        if (props.shape === "rounded") return "30px" 
        else if (props.shape === "circle") return "50%"
        else return "8px";
    }};
    color: ${props => {
        if (props.kind === "primary") return "white"
        else if (props.kind === "secondary") return "white"
        else if ((props.kind === "primary-outlined" || props.kind === "primary-outlined--solid") && !props.active) return primary
        else if (props.kind === "primary-light-outlined" && !props.active) return primaryLight     
        else if (props.kind === "secondary-outlined" && !props.active) return secondary
        else if (props.kind === "primary-outlined" && props.active) return "white"
        else if (props.kind === "secondary-outlined" && props.active) return "white"    
        else if (props.kind === "primary-outlined__white") return "white"   
        else if (props.kind === "primary-transparent") return primary
        else if (props.kind === "transparent" || (props.kind === "dark-outlined" && props.shape !== "circle")) return textDefault    
        else if (props.kind === "dark-outlined" && props.shape === "circle" && !props.active) return textDefault        
        else if (props.kind === "dark-outlined" && props.shape === "circle" && props.active) return "white"
        else if (props.kind === "dark-outlined--solid") return textDefault        
        else if (props.kind === "dark-active") return "#000A12"        
        else return "white"
    }};
    font-family: "Roboto", sans-serif !important;
    border: ${props => {
        if (props.kind === "primary-outlined" || props.kind === "primary-outlined--solid") return `2px solid ${primary}`
        else if (props.kind === "primary-light-outlined") return `2px solid ${primaryLight}`    
        else if (props.kind === "secondary-outlined") return `2px solid ${secondary}`
        else if (props.kind === "outlined") return "2px solid white"    
        else if (props.kind === "primary-outlined__white") return "2px solid white" 
        else if (props.kind === "dark-outlined" && props.shape !== "circle") return `1px solid ${textDefault}`
        else if (props.flat || props.kind === "primary-transparent") return "none"
        else if ((props.kind === "dark-outlined" && props.shape === "circle") || props.kind === "dark-outlined--solid") return `1px solid ${textDefault}`
        else if (props.kind === "dark-active") return `2px solid #000A12`
        else return null
    }};
    text-transform: ${props => {
       if (props.uppercase) {
           return "uppercase"
       } 
       
       return "unset"
    }};
    
    height: ${props => {
        if (props.slimHeight && !props.height) {
            return "34px"
        }
        
        if (!props.slimHeight && !props.height && props.shape !== "circle") {
            return "60px"
        }
        
        if (props.shape === "circle") {
            return "48px"
        }
        
        if (props.height) {
            return props.height
        }
    }};
    padding: ${props => props.shape === "circle" ? "0" : props.shape === "rounded" ? "8px 16px" : "0 24px"};
    font-weight: ${props => props.flat || props.semiBold ? "500" : props.light ? "400" : "600"};
    font-size: ${props => {
        if (props.large) return "16px"
        return "14px"
    }};
    width: ${props => props.fluid ? "100%" : props.shape === "circle" ? "48px" : null};
    line-height: 18px;
    font-family: "Poppins";
  }
  
  .MuiButton-root {
    min-width: ${props => props.shape === "circle" ? "48px" : null};
  }
  
  .MuiIconButton-root {
    background-color: ${props => {
        switch(props.kind) {
          case "white":
              return "white"
          default:
              break
        }
    }};
    transition: opacity .2s ease-in-out;
    
    &:hover {
      background-color: ${props => {
        switch(props.kind) {
          case "white":
            return "white"
          default:
            break
        }
      }};
    }
  }
  
  svg {
    fill: ${props => props.iconColor ? props.iconColor : textDefault};
  }
  
`
