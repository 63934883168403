import { DateTime } from 'luxon'

export default class Validator {
    static isCPFValid(CPF) {
        let CPFClone = CPF

        CPFClone = CPFClone.toString()
        let Soma
        let Resto
        Soma = 0

        if (CPFClone === '00000000000') return false

        for (let i = 1; i <= 9; i += 1)
            Soma = Soma + parseInt(CPFClone.substring(i - 1, i)) * (11 - i)

        Resto = (Soma * 10) % 11

        if (Resto === 10 || Resto === 11) Resto = 0
        if (Resto !== parseInt(CPFClone.substring(9, 10))) return false

        Soma = 0
        for (let i = 1; i <= 10; i += 1)
            Soma = Soma + parseInt(CPFClone.substring(i - 1, i)) * (12 - i)
        Resto = (Soma * 10) % 11

        if (Resto === 10 || Resto === 11) Resto = 0
        if (Resto !== parseInt(CPFClone.substring(10, 11))) return false

        return true
    }

    static isValidEmail(email) {
        // eslint-disable-next-line global-require
        const isEmail = require('validator/es/lib/isEmail').default

        if (!isEmail(email)) {
            return false
        }

        return true
    }

    static toISOFormat(dateTimeString) {
        const [date] = dateTimeString.split(' ')
        const [DD, MM, YYYY] = date.split('/')

        return `${YYYY}-${MM}-${DD}T00:00`
    }

    static isValidBirthDate(birthDate) {
        try {
            const date = DateTime.fromISO(birthDate)

            if (!typeof date === DateTime) {
                return false
            }

            const today = DateTime.local()
            const maximumAllowedDate = today.minus({ year: 18 }).toMillis()
            const minimumAllowedDate = today.minus({ year: 110 }).toMillis()

            const birthDateInMilliseconds = date.toMillis()

            return (
                birthDateInMilliseconds >= minimumAllowedDate &&
                birthDateInMilliseconds <= maximumAllowedDate
            )
        } catch (error) {
            return false
        }
    }

    static isValidPhoneNumber(phoneNumber) {
        if (!phoneNumber) return false

        if (phoneNumber && phoneNumber?.length < 15) {
            return false
        }

        let flatPhoneNumber = phoneNumber.match(/\d/g)
        flatPhoneNumber = flatPhoneNumber.join('')
        const flatPhoneNumberArray = flatPhoneNumber.split('')

        const firstNumber = flatPhoneNumberArray[0]

        if (flatPhoneNumberArray.every((e) => e === firstNumber)) {
            return false
        }

        return true
    }
}
