import styled from 'styled-components'
import { primary } from '../../assets/colorPallet'

export const ProgressBarContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    background-color: #eee;
    height: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`

export const Progress = styled.div`
    width: 100%;
    max-width: ${props => props.width ? props.width : 0}%;
    height: 5px;
    background-color: ${primary};
    transition: max-width .4s ease;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`
