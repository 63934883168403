import {
    SET_SHOW_DISCARD_CHANGES_DIALOG,
    SET_SNACKBAR_STATE, SET_USER_HAS_CHANGES
} from "./mutations";

const initialState = {
    snackbarState: {
        message: "",
        type: null,
        active: false
    },
    userHasChanges: false,
    showDiscardChangesDialog: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_SNACKBAR_STATE:
            return { ...state, snackbarState: action.payload }

        case SET_USER_HAS_CHANGES:
            return { ...state, userHasChanges: action.payload }

        case SET_SHOW_DISCARD_CHANGES_DIALOG:
            return { ...state, showDiscardChangesDialog: action.payload }

        default:
            return state;
    }
}

export default reducer;
