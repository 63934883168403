// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../../../structureComponents";
import StyledButton from "../../../../../../../components/StyledButton/StyledButton";
import StyledInput from "../../../../../../../components/StyledInput/StyledInput";
import {createImageGroup} from "../../../../../../../store/modules/imageGroups/actions";
import SelectFile from "../../../../../../../components/SelectFile/SelectFile";
import { LocalVentureImage } from "./styled"
import editIcon from "../../../../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../../../../assets/icons/trash.svg";
import closeIcon from "../../../../../../../assets/icons/close.svg";

import { uploadVentureImages } from "../../../../../../../store/modules/images/actions"
import {ProgressBar} from "../../../../../../../components/ProgressBar";
import {setSnackbarState} from "../../../../../../../store/modules/global/actions";



function UploadVentureImagesDialog({
    active = false,
    onClose = () => ({}),
    imageGroup,
    currentVenture,
    dispatchUploadVentureImages,
    uploadingProgressPercentage,
    resizingImages,
    dispatchSnackbar
}) {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedImages, setSelectedImages] = useState([])
    const [fileToBeUpdated, setFileToBeUpdated] = useState(-1)

    // Watchers
    useEffect(() => {
        if (active) {
            resetLocalState()
        }
    }, [active])

    // Methods
    const handleClose = () => {
        onClose()
    }

    const handleUploadVentureImages = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchUploadVentureImages({
                corporationId: currentVenture.corporationId,
                ventureId: currentVenture.id,
                imageGroupId: imageGroup.id
            }, {
                images: selectedImages
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Imagem adicionada com sucesso"
            })
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleVentureImagesOnSelect = (images) => {
        setSelectedImages([...selectedImages, ...Array.from(images).filter((img) => {
            const allowedImageTypes = ["jpeg", "jpg", "png"]
            const type = img.type.replace("image/", "")

            if (allowedImageTypes.includes(type)) {
                return true
            }

            return false
        }).map((image) => {
            let imageType = image.type.replace("image/", "")
            if (imageType === "jpeg") imageType = "jpg"
            const customName = image.name.replace(`.${imageType}`, "")

            return {
                localURL: URL.createObjectURL(image),
                file: image,
                customName
            }
        })])

        setUserHasChanges(true)
    }

    const handleDeleteVentureImage = (ventureImageIndex) => {
        setSelectedImages(selectedImages.filter((_, index) => index !== ventureImageIndex))
    }

    const handleUpdateFileName = (index) => {
        setFileToBeUpdated(index)
    }

    const handleFileNameOnChange = (e, index) => {
        const images = [...selectedImages].map((selectedImage, i) => {
            if (index === i) {
                return {
                    ...selectedImage,
                    customName: e.target.value
                }
            }

            return selectedImage
        })

        setSelectedImages(images)
    }

    const handleFileNameUpdateCloseOnEnter = (e) => {
        if (e.key === "Enter") {
            setFileToBeUpdated(-1)
        }
    }

    const resetLocalState = () => {
        setSelectedImages([])
        setLoading(false)
        setUserHasChanges(false)
    }

    return (
        <StyledDialog
            title="Adicionar de imagens"
            resetBodyTopPadding
            subtitle={imageGroup?.name}
            active={active}
            onClose={handleClose} maxWidth="md">
            <form onSubmit={handleUploadVentureImages}>
                <Container>
                    <Label small>Upload apenas de imagens (jpeg, jpg ou png)</Label>
                </Container>
                <Container mt="20px">
                    <SelectFile placeholder="Selecione imagens" multiple onSelect={handleVentureImagesOnSelect}/>
                </Container>
                {selectedImages.length > 0 && (
                    <Container>
                        <Container mt="40px">
                            <Label small>{selectedImages?.length} imagens selecionadas</Label>
                        </Container>
                        <Container mt="20px" grid columns={3} maxHeight="40vh" overflowAuto>
                            {selectedImages.map((selectedImage, index) => (
                                <Container key={index}>
                                    <LocalVentureImage src={selectedImage?.localURL} />
                                    <Container mt="10px" flex alignCenter justifyBetween>
                                        {fileToBeUpdated === index ? (
                                            <Container fluid flex alignCenter>
                                                <StyledInput
                                                value={selectedImage.customName}
                                                small
                                                onKeyDown={handleFileNameUpdateCloseOnEnter}
                                                onChange={(e) => handleFileNameOnChange(e, index)} />
                                                <Container ml="20px">
                                                    <StyledButton
                                                        iconSize="16px"
                                                        iconButton
                                                        icon={{url: closeIcon}}
                                                        onClick={() => setFileToBeUpdated(-1)} />
                                                </Container>
                                            </Container>
                                        ) : (
                                            <>
                                                <Container>
                                                    <Label>{selectedImage.customName}</Label>
                                                </Container>
                                                <Container flex alignCenter>
                                                    <Container>
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton icon={{ url: editIcon}}
                                                            onClick={() => handleUpdateFileName(index)}/>
                                                    </Container>
                                                    <Container ml="10px">
                                                        <StyledButton
                                                            iconSize="16px"
                                                            iconButton icon={{ url: deleteIcon}}
                                                            onClick={() => handleDeleteVentureImage(index)}/>
                                                    </Container>
                                                </Container>
                                            </>
                                        )}
                                    </Container>
                                </Container>
                            ))}
                        </Container>
                    </Container>
                )}
                {loading && (
                    <Container mt="20px">
                        <ProgressBar width={uploadingProgressPercentage} />
                        <Container mt="10px">
                            <Label>
                                {resizingImages ? "Redimensionando imagens..." : `${uploadingProgressPercentage.toFixed(2)}%`}
                            </Label>
                        </Container>
                    </Container>
                )}
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container disabled={!userHasChanges} ml="10px">
                        <StyledButton
                            disabled={!userHasChanges}
                            kind="primary" type="submit"
                            loading={loading}>
                            Upload
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    uploadingProgressPercentage: state.images.uploadingProgressPercentage,
    resizingImages: state.images.resizingImages
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchCreateImageGroup({ corporationId, ventureId }, { name }) {
        await dispatch(createImageGroup({ corporationId, ventureId }, { name }))
    },

    async dispatchUploadVentureImages({ corporationId, ventureId, imageGroupId }, { images }) {
        await dispatch(uploadVentureImages({ corporationId, ventureId, imageGroupId }, { images }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(UploadVentureImagesDialog)
