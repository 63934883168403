import styled from "styled-components";

const RxContainer = styled.div`
  width: ${(props) => {
    if (props.fluid) {
      return "100%";
    } else {
      return null;
    }
  }};
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : null)};
  min-width: ${(props) => (props.minWidth ? props.minWidth : null)};
  height: ${(props) => {
    if (props.height) return props.height;
    else return null;
  }};
  background-color: ${(props) => (props.bgColor ? props.bgColor : null)};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : null)};
  min-height: ${(props) => (props.minHeight ? props.minHeight : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin: ${(props) => {
    if (props.m) return props.m;
    if (props.flexGap) return "-12px 0 0 -12px";
    return null;
  }};
  padding: ${(props) => (props.p ? props.p : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  border: ${(props) => (props.border ? props.border : null)};
  border-bottom: ${(props) => (props.bb ? props.bb : null)};
  border-right: ${(props) => (props.br ? props.br : null)};
  border-radius: ${(props) => (props.radius ? props.radius : null)};
  box-shadow: ${(props) => (props.shadow ? props.shadow : null)};

  display: ${(props) => {
    if (props.flex) return "flex";
    else if (props.grid) return "grid";
    else if (props.hideOnDesktop) return "none";
    else if (props.flexGap) return "inline-flex";
    else return null;
  }};
  grid-template-columns: ${(props) => {
    if (props.columns === 3) return "1fr 1fr 1fr";
    else if (props.columns === 4) return "1fr 1fr 1fr 1fr";
    else return null;
  }};
  grid-column-gap: ${(props) => {
    if (props.columnGap) return `${props.columnGap} !important`;
    else if (props.grid) return "20px";
    else return null;
  }};
  grid-row-gap: ${(props) => {
    if (props.rowGap) return `${props.rowGap} !important`;
    else if (props.grid) return "20px";
    else return null;
  }};
  justify-content: ${(props) => {
    if (props.justifyBetween) return "space-between";
    else if (props.justifyCenter) return "center";
    else if (props.justifyStart) return "flex-start";
    else if (props.justifyEnd) return "flex-end";
  }};
  align-items: ${(props) => {
    if (props.alignCenter) return "center";
    else if (props.alignBottom) return "flex-end";
    else if (props.alignEnd) return "flex-end";
    else if (props.alignTop) return "flex-start";
    else return null;
  }};
  position: ${(props) => {
    if (props.absolute) return "absolute";
    else if (props.fixed) return "fixed";
    else return null;
  }};
  flex-direction: ${(props) => {
    if (props.column) return "column";
    else return null;
  }};
  box-sizing: border-box;
  overflow: ${(props) => {
    if (props.overflowAuto) return "auto";
    if (props.overflowHidden) return "hidden";
    else return null;
  }};
  flex-wrap: ${(props) => (props.wrap || props.flexGap ? "wrap" : null)};
  flex: ${(props) => (props.flexOne ? 1 : null)};
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
  right: ${(props) => (props.right ? props.right : null)};
  z-index: ${(props) => (props.zIndex ? props.zIndex : null)};
  top: ${(props) => (props.top ? props.top : null)};
  right: ${(props) => (props.right ? props.right : null)};
  bottom: ${(props) => (props.bottom ? props.bottom : null)};
  left: ${(props) => (props.left ? props.left : null)};

  a {
    text-decoration: none !important;
  }

  > * {
    margin: ${(props) => (props.flexGap ? "25px 0 0 25px" : null)};
    pointer-events: ${(props) =>
      props.disabled || props.viewOnly ? "none" : "all"};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  }

  //CUSTOM SCROLL BAR

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    background-color: #ccc;
    -webkit-box-shadow: inset 1px 1px 0px #eee;
  }
`;

export default RxContainer;
