// Libraries
import axios from "axios"

// Models
import ImageGroup from "./model"

// Mutations
import {
    SET_IMAGE_GROUPS,
} from "./mutations"

import store from "../../index";


export const createImageGroup = ({corporationId, ventureId}, { name }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing corporationId or ventureId")
        }

        if (!name) {
            throw new Error("Payload is incorrect")
        }

        const payload = {name}
        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups`,
            payload,
            headers
        )

        const imageGroup = new ImageGroup({
            id: response.data.id,
            name: response.data.name,
            ventureId: response.data.ventureId,
            corporationId: response.data.corporationId
        })

        const imageGroups = [ ...store.getState().imageGroups.imageGroups ]
        imageGroups.push(imageGroup)

        dispatch({type: SET_IMAGE_GROUPS, payload: imageGroups})
    } catch (error) {
        throw error
    }
}

export const fetchImageGroups = ({ corporationId, ventureId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing corporationId or ventureId")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups`,
            headers
        )

        const imageGroups = response.data.map((imageGroup) => new ImageGroup({
            id: imageGroup.id,
            name: imageGroup.name,
            ventureId: imageGroup.ventureId,
            corporationId: imageGroup.corporationId,
            images: imageGroup.images
        }))

        dispatch({ type: SET_IMAGE_GROUPS, payload: imageGroups })
    } catch (error) {
        throw error
    }
}

export const deleteImageGroup = ({ corporationId, ventureId, imageGroupId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        await axios.delete(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}`,
            headers
        )

        const imageGroups = [ ...store.getState().imageGroups.imageGroups ]
            .filter((imageGroup) => imageGroup.id !== imageGroupId)

        dispatch({ type: SET_IMAGE_GROUPS, payload: imageGroups })
    } catch (error) {
        throw error
    }
}

export const updateImageGroup = ({ corporationId, ventureId, imageGroupId }, { name }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !imageGroupId) {
            throw new Error("Missing params")
        }

        if (!name) {
            throw new Error("Payload is incorrect")
        }

        const payload = {name}
        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/imageGroups/${imageGroupId}`,
            payload,
            headers
        )

        const imageGroup = [ ...store.getState().imageGroups.imageGroups ].find(imageGroup => imageGroup.id === imageGroupId)

        const updatedImageGroup = new ImageGroup({
            id: response.data.id,
            name: response.data.name,
            ventureId: response.data.ventureId,
            corporationId: response.data.corporationId,
            images: imageGroup.images
        })

        const imageGroups = [ ...store.getState().imageGroups.imageGroups ].map((imageGroup) => {
            if (imageGroup.id === imageGroupId){
                return updatedImageGroup
            }

            return imageGroup
        })

        dispatch({type: SET_IMAGE_GROUPS, payload: imageGroups})

    } catch (error) {
        throw error
    }
}


// SETTERS
export const setImageGroups = (imageGroups) => (dispatch) => {
    dispatch({ type: SET_IMAGE_GROUPS, payload: imageGroups })
}
