// Libraries
import axios from "axios"

// Store
import store from "../../index"

// Mutations
import {SET_VIDEOS} from "./mutations"

// Models
import Video from "./video.model"


export const fetchVideosByVentureId= ({ corporationId, ventureId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/videos`,
            headers
        )

        const videos = response.data

        dispatch({ type: SET_VIDEOS, payload: videos })
    } catch (error) {
        throw error
    }
}


export const createVideo = ({ corporationId, ventureId }, { name, url }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}
        const payload = { name, url }

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/videos`,
               payload,
               headers
        )

        const createdVideo = response.data
        const videos = [...store.getState().videos.videos]

        videos.push(new Video({
            id: createdVideo.id,
            name: createdVideo.name,
            url: createdVideo.url,
            ventureId: createdVideo.ventureId,
            corporationId: createdVideo.corporationId
        }))

        dispatch({ type: SET_VIDEOS, payload: videos })
    } catch (error) {
        throw error
    }
}


export const deleteVideoById = ({ corporationId, ventureId, videoId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !videoId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        await axios.delete(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/videos/${videoId}`,
            headers
        )

        const videos = [...store.getState().videos.videos].filter((video) => video.id !== videoId)

        dispatch({ type: SET_VIDEOS, payload: videos })
    } catch (error){
        throw error
    }
}

export const updateVideoById = ({ corporationId, ventureId, videoId }, { name, url }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId || !videoId) {
            throw new Error("Missing params")
        }

        if (!name && !url) {
            return new Error("Payload is incorrect")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const payload = {}
        if (name) payload.name = name
        if (url) payload.url = url

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/videos/${videoId}`,
            payload,
            headers
        )

        const updatedVideo = response.data

        const videos = [...store.getState().videos.videos].map((video) => {
            if (video.id === videoId) {
                return updatedVideo
            }

            return video
        })

        dispatch({ type: SET_VIDEOS, payload: videos })
    } catch (error) {
        throw error
    }
}
