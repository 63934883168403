import styled from "styled-components"
import {primary} from "../../assets/colorPallet";


export const StyledSwitchContainer = styled.div`
  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${primary};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${primary};
  }
`
