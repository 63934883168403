// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Componets
import StyledInput from "../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../components/StyledButton/StyledButton";

// Structure Components
import {Container, Label} from "../../../../structureComponents";
import {updateCorporation} from "../../../../store/modules/corporations/actions";
import SelectFile from "../../../../components/SelectFile/SelectFile";
import {uploadCorporationImage} from "../../../../store/modules/files/actions";
import Corporation from "../../../../store/modules/corporations/model";
import {CorporationGeneralInfoContainer} from "./styled";
import {setSnackbarState, setUserHasChanges} from "../../../../store/modules/global/actions";
import {maxFileSize} from "../../../../config";

import { CORPORATION_MORTGAGE } from "../../../../store/modules/corporations/model"
import MaskHelper from "../../../../utils/MaskHelper";
import StyledSelect from "../../../../components/StyledSelect/StyledSelect";


function CorporationGeneralInfo({
    dispatchUploadFiles,
    currentCorporation,
    dispatchUpdateCorporation,
    dispatchSnackbar,
    dispatchSetUserHasChanges
 }) {
    // States
    const [error, setError] = useState({ status: false, message: "" })
    const [userHasChanges, setUserHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedLogoFile, setSelectedLogoFile] = useState(null)
    const [corporation, setCorporation] = useState({
        name: "",
        description: "",
        logoUrl: "",
        mortgageOptions: [],
        mortgageAnnualFee: null
    })
    const [corporationMortgageOptions, setCorporationMortgageOptions]= useState([])


    // Mounted
    useEffect(() => {
        resetCorporation()
        setUserHasChanges(false)
    }, [])

    useEffect(() => {
        if (currentCorporation) {
            setCorporation(new Corporation({
                ...currentCorporation,
                mortgageOptions: currentCorporation.mortgageOptions ? currentCorporation.mortgageOptions : []
            }))

            setCorporationMortgageOptions(currentCorporation?.getMortgageOptions())
        }
    }, [currentCorporation])

    // Methods
    const resetCorporation = () => {
        setCorporation({
            name: "",
            description: "",
            logoUrl: "",
            mortgageOptions: [],
            mortgageAnnualFee: null
        })
    }

    const handleFileOnSelect = (selectedFiles) => {
        const file = selectedFiles[0]

        if (file.size > maxFileSize) {
            setError({ status: true, message: "O arquivo deve ter no máximo 5MB." })
        } else {
            setError({ status: false, message: "" })
        }

        setSelectedLogoFile(file)
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleCorporationName = (e) => {
        setCorporation({ ...corporation, name: e.target.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleCorporationDescription = (e) => {
        setCorporation({ ...corporation, description: e.target.value })
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleCorporationMortgageOptionsOnChange = (selectedCorporationMortgageOption) => {
        setCorporation(new Corporation({
            ...corporation,
            mortgageOptions: [selectedCorporationMortgageOption.value]
        }))

        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleMortgageFeeOnChange = (e) => {
        let value = e.target.value

        const maskValue = MaskHelper.toBrazilianNumberFormat(e.target.value)
        if (maskValue === "NaN") {
            value = ""
        } else {
            value = maskValue
        }

        setCorporation(new Corporation({
            ...corporation, mortgageAnnualFee: value
        }))

        if (corporation.mortgageAnnualFee !== e.target.value) {
            setUserHasChanges(true)
            dispatchSetUserHasChanges(true)
        } else {
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
        }

    }

    const handleUpdateCorporation = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            let logoUrls = null
            if (selectedLogoFile) {
                const files = [selectedLogoFile]
                logoUrls = await dispatchUploadFiles({ corporationId: currentCorporation.id }, files)
            }

            const corporationId = currentCorporation.id
            const payload = {
                name: corporation.name,
                description: corporation.description,
                logoUrl: logoUrls?.originalUrl,
                mortgageAnnualFee: corporation.mortgageAnnualFee,
                mortgageOptions: corporation.mortgageOptions
            }

            await dispatchUpdateCorporation({ corporationId }, payload)

            setLoading(false)
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
            dispatchSnackbar({
                type: "success",
                message: "Incorporadora atualizada com sucesso",
                active: true
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro",
                active: true
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <CorporationGeneralInfoContainer>
            <form onSubmit={handleUpdateCorporation}>
                <Container fluid maxWidth="40em">
                    <SelectFile onSelect={handleFileOnSelect} fileUrl={corporation.logoUrl} />
                    {error.status && (
                        <Container mt="5px">
                            <Label error small>{ error.message }</Label>
                        </Container>
                    )}
                </Container>
                <Container mt="20px" fluid maxWidth="40em">
                    <Label bold>Nome</Label>
                    <Container mt="10px">
                        <StyledInput
                            value={corporation.name}
                            onChange={handleCorporationName} />
                    </Container>
                </Container>
                <Container flex alignCenter mt="20px">
                    <Container fluid maxWidth="20em">
                        <Label bold>Opcao de financiamento:</Label>
                        <Container mt="10px" fluid maxWidth="20em">
                            <StyledSelect
                                items={corporationMortgageOptions}
                                value={corporationMortgageOptions.find(i => i.value === corporation?.mortgageOptions[0])}
                                itemLabel="name"
                                onChange={handleCorporationMortgageOptionsOnChange}/>
                        </Container>
                    </Container>
                    {corporation?.mortgageOptions?.includes(CORPORATION_MORTGAGE) && (
                        <Container ml="10px">
                            <Label bold>Taxa a.a.</Label>
                            <Container mt="10px" fluid flex alignCenter maxWidth="8em">
                                <StyledInput
                                    placeholder="Add taxa"
                                    value={corporation.mortgageAnnualFee}
                                    onChange={handleMortgageFeeOnChange}/>
                                <Container ml="10px">
                                    <Label>%</Label>
                                </Container>
                            </Container>
                        </Container>
                    )}
                </Container>
                <Container mt="20px">
                    <Label bold>Descricao</Label>
                    <Container mt="10px">
                        <StyledInput
                            value={corporation.description}
                            multiline
                            rows={8}
                            onChange={handleCorporationDescription}/>
                    </Container>
                </Container>
                <Container disabled={!userHasChanges} flex alignCenter justifyEnd mt="40px">
                    <Container ml="20px">
                        <StyledButton kind="primary" type="submit" loading={loading}>Salvar</StyledButton>
                    </Container>
                </Container>
            </form>
        </CorporationGeneralInfoContainer>
    )
}

const mapStateToProps = (state) => ({
    currentCorporation: state.corporations.currentCorporation
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateCorporation({ corporationId }, {
        name,
        description,
        logoUrl,
        mortgageAnnualFee,
        mortgageOptions
    }){
        await dispatch(updateCorporation({ corporationId }, {
            name,
            description,
            logoUrl,
            mortgageAnnualFee,
            mortgageOptions
        }))
    },

    async dispatchUploadFiles({ corporationId }, files) {
        const response = await dispatch(uploadCorporationImage({ corporationId }, files))
        return response
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CorporationGeneralInfo)
