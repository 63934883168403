// Libraries
import styled from "styled-components"

import image from "../../assets/images/select-images-background.png"


export const SelectFileContainer = styled.div`
  box-sizing: border-box;
  border: 2px dashed rgb(187, 187, 187);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-image: ${`url(${image})`};
  height: 120px;
  background-position: center;
  background-size: cover;
  
  input {
    width: 100%;
    height: 120px;
    opacity: 0;
    z-index: 120;
    cursor: pointer;
  }
  
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
`

export const ChangeFileContainer = styled.div`
  height: 40px;
  margin-top: 20px;
  
  input {
    width: 100%;
    height: 40px;
    opacity: 0;
    z-index: 120;
    cursor: pointer;
  }
`


