import Validator from "../utils/Validator";

export default class TeamMember {
    constructor({
        id,
        name = "",
        lastName = "",
        email = "",
        whatsappNumber,
        role = "",
        avatarUrl = "",
        corporationIds = [],
        opportunityRestriction = "APPLY_OPPORTUNITY_RESTRICTION"
    }) {
        this.id = id
        this.name = name
        this.lastName = lastName
        this.email = email
        this.whatsappNumber = whatsappNumber
        this.role = role
        this.avatarUrl = avatarUrl
        this.corporationIds = corporationIds
        this.opportunityRestriction = opportunityRestriction
    }

    static getAllowOpportunityRestrictionValues() {
        return {
            APPLY_OPPORTUNITY_RESTRICTION: "APPLY_OPPORTUNITY_RESTRICTION",
            ALLOW_ALL_CORPORATIONS: "ALLOW_ALL_CORPORATIONS"
        }
    }

    static getAllowedRoles() {
        return {
            BROKER_ROLE: "BROKER_ROLE",
            ATTENDANT_ROLE: "ATTENDANT_ROLE"
        }
    }

    get fullName(){
        return `${this.name} ${this.lastName}`
    }

    get roleLabel() {
        if (this.role === TeamMember.getAllowedRoles().ATTENDANT_ROLE) {
            return "Atendente"
        }

        if (this.role === TeamMember.getAllowedRoles().BROKER_ROLE) {
            return "Corretor"
        }

        return ""
    }

    get opportunityRestrictionLabel() {
        const { APPLY_OPPORTUNITY_RESTRICTION } = TeamMember.getAllowOpportunityRestrictionValues()
        if (this.opportunityRestriction === APPLY_OPPORTUNITY_RESTRICTION) {
            return "Sim"
        }

        return "Não"
    }

    get emailIsValid() {
        const containsImobProDomain = this.email?.includes("@imob.pro.br")
        return Validator.isValidEmail(this.email) && containsImobProDomain
    }

    get whatsappNumberIsValid() {
        return Validator.isValidPhoneNumber(this.whatsappNumber)
    }

    get hasValidFields() {
        return (
            this.emailIsValid
            && this.whatsappNumberIsValid
            && this.name?.length > 0
            && this.lastName?.length > 0
            && Object.keys(TeamMember.getAllowedRoles()).includes(this.role)
        )
    }
}