class Message {
  constructor({ id, name, text, ventureId }) {
    this.id = id;
    this.name = name;
    this.text = text;
    this.ventureId = ventureId;
  }
}

export default Message;
