import styled from "styled-components"


export const VenturesFilterContainer = styled.div`
    overflow-y: auto;
    @media only screen and (max-width: 720px) {
      width: 100%;
      overflow: auto;
      
      .ventures-filter-body-container {
        .MuiButton-label {
          white-space: nowrap !important;
        }
        
        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }


        &::-webkit-scrollbar-track {
          width: 0;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          width: 0;
          background-color: transparent;
          -webkit-box-shadow: inset 1px 1px 0px transparent;
        }
      }
    }
`
