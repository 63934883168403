// Libraries
import React, { useState } from "react"

// Styles
import { VentureModerationCardContainer, VentureImage, CorporationName } from "./styled"
import {Container, Label} from "../../../structureComponents";

import StyledButton from "../../../components/StyledButton/StyledButton";
import ConfirmVentureModerationDialog from "../ConfirmVentureModerationDialog/ConfirmVentureModerationDialog";
import { VentureCoverImageIconContainer} from "../../Ventures/VentureCard/styled";
import SvgIcon from "../../../components/SvgIcon/SvgIcon";
import photoBackgroundIcon from "../../../assets/icons/photo-background.svg";


export const IGNORE_ACTION = "IGNORE_ACTION"
export const APPROVE_ACTION = "APPROVE_ACTION"

function VentureModerationCard({ 
    venture, 
    approveOnly = false, 
    currentPage, 
    currentSearchValue,
    onMetaChange = () => ({})
}) {
    // States
    const [showConfirmVentureModerationDialog, setShowConfirmVentureModerationDialog] = useState(false)
    const [selectedModerationAction, setSelectedModerationAction] = useState(null)

    // Methods
    const handleShowConfirmVentureModerationDialog = (moderationAction) => {
        setShowConfirmVentureModerationDialog(true)
        setSelectedModerationAction(moderationAction)
    }

    return (
        <>
            <VentureModerationCardContainer>
                {venture.coverImage ? (
                    <VentureImage src={venture?.coverImage} />
                ) : (
                    <Container>
                        <VentureCoverImageIconContainer className="venture-photo-background--container">
                            <SvgIcon src={photoBackgroundIcon} size="100px" color="#ccc"/>
                        </VentureCoverImageIconContainer>
                    </Container>
                )}
                <Container fluid maxWidth="calc(100% - 400px)" flex column justifyBetween p="20px">
                    <Container>
                        <Container flex fluid alignCenter justifyBetween>
                            <Label bold large>{venture?.name}</Label>
                            <CorporationName>
                                <Label bold>{venture?.corporation?.name}</Label>
                            </CorporationName>
                        </Container>
                        <Container>
                            <Label>{venture?.neighborhood}, {venture?.city} - {venture?.countryState}</Label>
                        </Container>
                    </Container>
                    <Container flex aligCenter justifyEnd>
                        {!approveOnly && (
                            <Container>
                                <StyledButton
                                kind="primary-outlined"
                                onClick={() => handleShowConfirmVentureModerationDialog(IGNORE_ACTION)}>
                                    Arquivar
                                </StyledButton>
                            </Container>
                        )}
                        <Container ml="10px">
                            <StyledButton
                            kind="primary"
                            onClick={() => handleShowConfirmVentureModerationDialog(APPROVE_ACTION)}>
                                Aprovar
                            </StyledButton>
                        </Container>
                    </Container>
                </Container>
            </VentureModerationCardContainer>
            <ConfirmVentureModerationDialog
            venture={venture}
            active={showConfirmVentureModerationDialog}
            currentPage={currentPage}
            currentSearchValue={currentSearchValue}
            moderationAction={selectedModerationAction}
            onClose={() => setShowConfirmVentureModerationDialog(false)} />
        </>
    )
}

export default VentureModerationCard
