import {
    SET_CURRENT_IMAGE_GROUP_IMAGES, SET_RESIZING_IMAGES, SET_UPLOADING_PROGRESS_PERCENTAGE
} from "./mutations";

const initialState = {
    currentImageGroupImages: [],
    uploadingProgressPercentage: 0,
    resizingImages: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CURRENT_IMAGE_GROUP_IMAGES:
            return { ...state, currentImageGroupImages: action.payload }
        case SET_UPLOADING_PROGRESS_PERCENTAGE:
            return { ...state, uploadingProgressPercentage: action.payload }
        case SET_RESIZING_IMAGES:
            return { ...state, resizingImages: action.payload }
        default:
            return state;
    }
}

export default reducer;
