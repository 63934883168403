// Libraries
import axios from "axios"

// Config
import {ORULO_END_USER_AUTH_TOKEN} from "../../../config"
import {SET_CURRENT_VENTURE_DOCUMENTS, SET_UPLOADING_PROGRESS_PERCENTAGE} from "./mutations"
import store from "../../index";
import Document from "./model";


// Mutations

export const uploadVentureDocuments = ({ corporationId, ventureId }, { documents }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing params")
        }

        const headers = {headers: {"x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")}}

        const uploadedDocuments = []

        let count = 1

        for (let index = 0; index < documents.length; index += 1) {

            const document = documents[index]

            let formData = new FormData()

            const documentName = documents.find((img, i) => index === i).customName

            formData.append('document', document.file, documentName)

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/documents`,
                formData,
                headers
            )

            uploadedDocuments.push(new Document({
                id: response.data.id,
                name: response.data.name,
                ventureId: response.data.ventureId,
                corporationId: response.data.fileUrl
            }))

            const uploadingProgressPercentage = (count / documents.length) * 100
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: uploadingProgressPercentage })
            count += 1
        }

        const currentVentureDocuments = [...store.getState().documents.currentVentureDocuments]

        dispatch({ type: SET_CURRENT_VENTURE_DOCUMENTS, payload: [...currentVentureDocuments, ...uploadedDocuments] })

        setTimeout(() => {
            dispatch({ type: SET_UPLOADING_PROGRESS_PERCENTAGE, payload: 0 })
        }, 3000)
    } catch (error) {
        throw error
    }
}

export const fetchDocuments= ({ corporationId, ventureId }) => async (dispatch) => {
    try {
        if (!corporationId || !ventureId) {
            throw new Error("Missing params")
        }

        const headers = {
            headers: {
                "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN"),
                "x-orulo-end-user_token": localStorage.getItem(ORULO_END_USER_AUTH_TOKEN)
            }
        }

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/documents`,
            headers
        )


        const documents = response?.data?.map(document => new Document({
            ...document
        }))

        dispatch({ type: SET_CURRENT_VENTURE_DOCUMENTS, payload: documents })

        return documents
    } catch (error) {
        throw error
    }
}
