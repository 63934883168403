// Libraries
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

// Components
import StyledDialog from "../../../../components/StyledDialog/StyledDialog"
import StyledButton from "../../../../components/StyledButton/StyledButton"

// Structure components
import { Container, Label } from "../../../../structureComponents"

import { Image, ImageContainer } from "./styled"

import imageIcon from "../../../../assets/icons/photos.svg"
import SvgIcon from "../../../../components/SvgIcon/SvgIcon";
import {updateVenture} from "../actions";
import {setSnackbarState} from "../../global/actions";


function SelectVentureCoverImageDialog({
    active = false,
    venture,
    ventureType,
    onClose = () => ({}),
    dispatchUpdateVenture,
    dispatchSnackbar
}) {
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() => {
        if (venture) {
            const coverImage = venture?.images?.find(image => image.url === venture.coverImage)
            setSelectedImage(coverImage)
        }
    }, [venture])

    // Methods
    const handleSaveCoverImage = async () => {
        try {
            setLoading(true)
            await dispatchUpdateVenture({
                ventureId: venture.id,
                corporationId: venture.corporationId
            }, {
                coverImage: selectedImage.url
            }, ventureType)

            dispatchSnackbar({
                type: "success",
                message: "Imagem de capa adicionada com sucesso.",
                active: true
            })

            onClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro.",
                active: true
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <StyledDialog
            title="Imagem de capa"
            active={active}
            onClose={() => onClose()}
            maxWidth={venture?.images?.length === 0 ? "xs" : "md"}>
            <Container>
                <Container fluid maxWidth="40em">
                    {venture?.images?.length === 0 ? (
                        <Label>Adicione imagens para este empreendimento antes de escolher a imagem de capa.</Label>
                    ) : (
                        <Label>Selecione uma das imagens abaixo para ser a imagem de capa do empreendimento</Label>
                    )}
                </Container>
                <Container mt="20px">
                    {venture?.images?.length > 0 && (
                        <Container flexGap>
                            {venture?.images?.map((image, index) => (
                                <ImageContainer
                                key={index}
                                active={selectedImage?.id === image?.id}
                                onClick={() => setSelectedImage(image)}>
                                    <div className="image-icon-container">
                                        <SvgIcon src={imageIcon} />
                                    </div>
                                    <Image src={image.mediumUrl} className="image" />
                                </ImageContainer>
                            ))}
                        </Container>
                    )}
                </Container>
                <Container fluid flex justifyEnd alignCenter mt="40px">
                    <Container>
                        <StyledButton kind="primary-outlined" onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    {venture?.images?.length === 0 ? (
                        <Container ml="10px">
                            <Link to={`/admin/empreendimentos/${venture?.id}/imagens-e-videos`}>
                                <StyledButton kind="primary">
                                    Adicionar imagens
                                </StyledButton>
                            </Link>
                        </Container>
                    ) : (
                        <Container ml="10px" disabled={!selectedImage}>
                            <StyledButton
                                loading={loading}
                                kind="primary"
                                onClick={handleSaveCoverImage}>
                                Salvar
                            </StyledButton>
                        </Container>
                    )}
                </Container>
            </Container>
        </StyledDialog>
    )
}

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVenture({ ventureId, corporationId }, { coverImage }, ventureType) {
        await dispatch(updateVenture({ ventureId, corporationId }, { coverImage }, ventureType))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(null, mapDispatchToProps)(SelectVentureCoverImageDialog)
