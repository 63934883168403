// Libraries
import styled from "styled-components"

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.column ? "column" : null};
  margin: 0;
  padding: 0;
  
  &:not(:first-child) {
    margin-left: ${props => !props.column ? "20px" : null};
  }
  
  @media only screen and (max-width: 600px) {
    flex-direction: ${props => props.columnOnMobile ? "column" : null};
  }
`

export default List
