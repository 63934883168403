import { createMuiTheme } from '@material-ui/core/styles';
import {primary, secondary} from "./assets/colorPallet";

const MaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
    },
});

export default MaterialTheme
