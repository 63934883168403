// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"

// Styles

// Structure components
import { Container, Label } from "../../../../structureComponents"

// Store actions
import StyledButton from "../../../../components/StyledButton/StyledButton";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import photosIcon from "../../../../assets/icons/photos.svg";
import editIcon from "../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../assets/icons/trash.svg";
import VenturePropertyPlanDialog from "./VenturePropertyPlanDialog/VenturePropertyPlanDialog";
import UploadVenturePropertyPlansDialog
    from "./UploadVenturePropertyPlansDialog/UploadVenturePropertyPlansDialog";
import DeleteVenturePropertyPlansDialog
    from "./DeleteVenturePropertyPlanDialog/DeleteVenturePropertyPlanDialog";
import UpdateVenturePropertyPlansDialog
    from "./UpdateVenturePropertyPlanDialog/UpdateVenturePropertyPlanDialog";
import {dispatchFetchVenturePropertyPlans} from "../../../../store/modules/propertyPlans/actions";
import TitleH1 from "../../../../structureComponents/modules/TitleH1";
import StyledImage from "../../../../components/StyledImage/StyledImage";
import InlineLoading from "../../../../components/Loading/Loading";
import StyledCard from "../../../../components/StyledCard/StyledCard";
import {ADMIN_USER_ROLE} from "../../../../utils/constants";
import {AuthContext} from "../../../../Auth";


function VenturePropertyPlans({ currentVenture, currentPropertyPlans, dispatchFetchPropertyPlans }) {
    // Contexts
    const { currentUser } = useContext(AuthContext)

    // States
    const [showUploadPropertyPlanDialog, setShowUploadPropertyPlanDialog] = useState(false)
    const [showDeletePropertyPlanDialog, setShowDeletePropertyPlanDialog] = useState(false)
    const [selectedPropertyPlan, setSelectedPropertyPlan] = useState(null)
    const [showUpdatePropertyPlanDialog, setShowUpdatePropertyPlanDialog] = useState(false)
    const [showVentureImageDialog, setShowVentureImageDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    // Mounted
    useEffect(() => {
        if (currentVenture) {
            handleFetchPropertyPlans()
        }
    }, [currentVenture])

    // Methods
    const handleFetchPropertyPlans = async () => {
        setLoading(true)
        await dispatchFetchPropertyPlans({
            corporationId: currentVenture?.corporation?.id,
            ventureId: currentVenture?.id
        })

        setLoading(false)
    }

    const handleShowDeletePropertyPlanDialog = (propertyPlan) => {
        setShowDeletePropertyPlanDialog(true)
        setSelectedPropertyPlan(propertyPlan)
    }

    const handleShowUpdatePropertyPlanDialog = (propertyPlan) => {
        setShowUpdatePropertyPlanDialog(true)
        setSelectedPropertyPlan(propertyPlan)
    }

    const handleShowPropertyPlanImage = (propertyPlan) => {
        setShowVentureImageDialog(true)
        setSelectedPropertyPlan(propertyPlan)
    }

    if (loading) {
        return (
            <Container height="calc(100vh - 100px)" flex alignCenter justifyCenter>
                <InlineLoading />
            </Container>
        )
    }

    return (
        <>
            <Container>
                <Container fluid>
                    {currentPropertyPlans.length === 0  ? (
                        <Container mt="20px" fluid>
                            <Container
                                flex
                                alignCenter
                                justifyCenter
                                height="calc(100vh - 49px - 40px - 60px - 40px - 40px - 20px)">
                                <EmptyState
                                    noAction={currentUser?.role !== ADMIN_USER_ROLE}
                                    icon={photosIcon}
                                    text="Nenhuma planta foi adicionada para esse empreendimento."
                                    buttonText="Adicionar plantas"
                                    onClick={() => setShowUploadPropertyPlanDialog(true)}
                                />
                            </Container>
                        </Container>
                    ) : (
                        <Container>
                            <TitleH1 xLarge>Plantas</TitleH1>
                            <Container mt="40px">
                                <Container flex alignCenter justifyBetween fluid>
                                    <Label>{currentPropertyPlans.length} plantas</Label>
                                    {currentUser?.role === ADMIN_USER_ROLE && (
                                        <StyledButton kind="primary" onClick={() => setShowUploadPropertyPlanDialog(true)}>
                                         Adicionar plantas
                                        </StyledButton>
                                    )}
                                </Container>
                                <Container mt="40px">
                                    <Container flexGap>
                                        {currentPropertyPlans?.map((propertyPlan) => (
                                            <Container fluid maxWidth="23em">
                                                <StyledCard>
                                                    <Container p="20px">
                                                        <Container cursor="pointer">
                                                            <StyledImage
                                                                height="234px"
                                                                cover
                                                                src={propertyPlan.imageUrl}
                                                                onClick={() => handleShowPropertyPlanImage(propertyPlan)} />
                                                        </Container>
                                                        <Container  mt="10px">
                                                            <Container flex alignCenter justifyBetween>
                                                                <Label bold>{propertyPlan.name}</Label>
                                                                {currentUser?.role === ADMIN_USER_ROLE && (
                                                                    <Container flex alignCenter mt="10px">
                                                                        <Container>
                                                                            <StyledButton
                                                                                iconSize="16px"
                                                                                iconButton
                                                                                icon={{ url: editIcon }}
                                                                                onClick={() => handleShowUpdatePropertyPlanDialog(propertyPlan)}/>
                                                                        </Container>
                                                                        <Container ml="10px">
                                                                            <StyledButton
                                                                                iconSize="16px"
                                                                                iconButton
                                                                                icon={{ url: deleteIcon }}
                                                                                onClick={() => handleShowDeletePropertyPlanDialog(propertyPlan)}/>
                                                                        </Container>
                                                                    </Container>
                                                                )}
                                                            </Container>
                                                            <Container flex alignCenter mt="10px">
                                                                <Container>
                                                                    <Label>{propertyPlan?.sizeLabel}</Label>
                                                                </Container>
                                                                <Container ml="30px">
                                                                    <Label>{propertyPlan?.bedroomsLabel}</Label>
                                                                </Container>
                                                                <Container ml="30px">
                                                                    <Label>{propertyPlan?.suitesLabel}</Label>
                                                                </Container>
                                                                <Container ml="30px">
                                                                    <Label>{propertyPlan?.garageSpotsLabel}</Label>
                                                                </Container>
                                                            </Container>
                                                        </Container>
                                                    </Container>
                                                </StyledCard>
                                            </Container>
                                        ))}
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    )}
                </Container>
            </Container>
            <UploadVenturePropertyPlansDialog
                active={showUploadPropertyPlanDialog}
                onClose={() => setShowUploadPropertyPlanDialog(false)}/>
            <DeleteVenturePropertyPlansDialog
                active={showDeletePropertyPlanDialog}
                propertyPlan={selectedPropertyPlan}
                onClose={() => setShowDeletePropertyPlanDialog(false)}/>
            <UpdateVenturePropertyPlansDialog
                active={showUpdatePropertyPlanDialog}
                propertyPlan={selectedPropertyPlan}
                onClose={() => setShowUpdatePropertyPlanDialog(false)}/>
            <VenturePropertyPlanDialog
                active={showVentureImageDialog}
                propertyPlanImageUrl={selectedPropertyPlan?.imageUrl}
                onClose={() => setShowVentureImageDialog(false)}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
    imageGroups: state.imageGroups.imageGroups,
    currentPropertyPlans: state.propertyPlans.currentPropertyPlans
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchPropertyPlans({ corporationId, ventureId }) {
        await dispatch(dispatchFetchVenturePropertyPlans({ corporationId, ventureId }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VenturePropertyPlans)
