// Libraries
import styled from "styled-components"
import { primary } from "../../assets/colorPallet"


export const StyledSearchContainer = styled.div`
    width: 100%;
    .MuiFormLabel-root.Mui-focused {
        color: ${primary} !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
        outline-color: ${primary} !important;
    }

    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
        outline-color: ${primary} !important;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: ${primary} !important;
    }   

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
        border-color: ${primary} !important;
    }
    
    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border-color: ${primary} !important;
    }
    
    .MuiInput-underline:after{
      border-color: ${primary};
    }
`