// Libraries
import axios from "axios"


export const uploadFiles = ({ corporationId, ventureId }, files) => async () => {
    try {
        const file = files[0]

        let formData = new FormData()
        formData.append("image", file)

        const headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")
            }
        }

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/storage/files/upload`,
            formData,
            headers
        )

        return response.data
    } catch (error) {
        throw new Error(error)
    }
}

export const uploadCorporationImage = ({ corporationId }, files) => async () => {
    try {
        const file = files[0]

        let formData = new FormData()
        formData.append("image", file)

        const headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN")
            }
        }

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/images`,
            formData,
            headers
        )

        return response.data
    } catch (error) {
        throw new Error(error)
    }
}
