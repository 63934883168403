import {ORULO_END_USER_AUTH_TOKEN} from "../config";
import ApiConfig from "../apiConfig";
import User from "../models/User";

const axios = require("axios")

export default class UserHandler {
    static async getOruloEndUserAuthToken(code) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/public/orulo/endUserToken?code=${code}`)

        const token = response?.data?.oruloEndUserAuthToken

        localStorage.setItem(ORULO_END_USER_AUTH_TOKEN, token)

        return token
    }

    static async fetchUser() {
        const headers = ApiConfig.generateHeaders()
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/user`,
            headers
        )

        const user = new User({
            ...response.data
        })

        return user
    }

    static async changePassword({ password }) {
        const headers = ApiConfig.generateHeaders()
        const payload = { password }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/private/changePassword`,
            payload,
            headers
        )

        return response.data
    }
}
