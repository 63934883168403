// Libraries
import React, {useEffect, useState} from "react"
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';

// Styles
import { StyledSliderContainer } from "./styled"

const AirbnbSlider = withStyles({
    root: {
        color: '#3a8589',
        height: 3,
        padding: '13px 0',
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
})(Slider);

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
      <span className="bar}" />
      <span className="bar" />
      <span className="bar" />
    </span>
    );
}

export default function StyledSlider({
    value,
    onChange = () => ({}),
    range = false,
    flat = false,
    minValue,
    maxValue,
    step = 5,
    defaultValue = 5
}) {
    // State
    const [internalValue, setInternalValue] = useState(range ? [10, 20] : 10)

    // Watchers
    useEffect(() => {
        if (value) {
            setInternalValue(value)
        }
    }, [value])

    // Methods
    const handleOnChange = (e, value) => {
        onChange(value)
        setInternalValue(value)
    }


    return (
        <StyledSliderContainer>
            <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                aria-labelledby={range ? "range-slider" : "discrete-slider"}
                getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                step={step}
                value={internalValue}
                min={minValue}
                max={maxValue}
                defaultValue={[minValue, maxValue]}
                onChange={handleOnChange}
            />
        </StyledSliderContainer>
    )
}
