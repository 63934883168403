// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// Styles
import {SideBarContainer, SideBarOptionsContainer, BackButtonContainer} from "./styled"

// Structure Components
import { Container, Span, Label} from "../../structureComponents"

// Icons
import backIcon from "../../assets/icons/arrow-left.svg"
import SideBarOption from "./SideBarOption/SideBarOption";
import {RouteContext} from "../../context/RouteContext";
import SideBarHelper from "./SideBarHelper";
import {CORPORATION_ROUTE, CORPORATIONS_ROUTE, VENTURE_ROUTE, VENTURES_ROUTE} from "../../utils/constants";
import SvgIcon from "../../components/SvgIcon/SvgIcon";
import routes from "../../routes";
import ventureIcon from "../../assets/icons/venture.svg"
import corporationIcon from "../../assets/icons/corporationIcon.svg"
import {primary} from "../../assets/colorPallet";
import {setShowDiscardChangesDialog, setUserHasChanges} from "../../store/modules/global/actions";
import DiscardChangesDialog from "../../components/DiscardChangesDialog/DiscardChangesDialog";
import {AuthContext} from "../../Auth";


function SideBar({
    match,
    history,
    currentVenture,
    currentCorporation,
    userHasChanges,
    dispatchSetShowDiscardChangesDialog,
    showDiscardChangesDialog,
    dispatchSetUserHasChanges
}) {
    // Context
    const { currentUser } = useContext(AuthContext)
    const { currentRoute } = useContext(RouteContext)
    // States
    const [selectedOption, setSelectedOption] = useState(null)
    const [sideBarOptions, setSideBarOptions] = useState([])
    const [currentSelectedOption, setCurrentSelectedOption] = useState(null)

    const handleSelectedOption = (option) => {
        setCurrentSelectedOption(option)

        if (userHasChanges) {
            dispatchSetShowDiscardChangesDialog(true)
        } else {
            setSelectedOption(option)
            history.push(option.routeUrl)
        }
    }

    const handleDiscardChanges = () => {
        setSelectedOption(currentSelectedOption)
        history.push(currentSelectedOption.routeUrl)
        dispatchSetShowDiscardChangesDialog(false)
        dispatchSetUserHasChanges(false)
    }


    useEffect(() => {
        if (currentRoute?.name === CORPORATIONS_ROUTE) {
            setSelectedOption(sideBarOptions[1])
        }

        if (currentRoute?.name === VENTURES_ROUTE) {
            setSelectedOption(sideBarOptions[0])
            history.push(routes.ventures.url)
        }

        if (currentRoute?.name === VENTURE_ROUTE) {
            if (currentRoute?.nextPath && currentRoute?.sideBarOption) {
                setSelectedOption(currentRoute?.sideBarOption)
                history.push(currentRoute?.nextPath)
            } else {
                setSelectedOption(sideBarOptions[0])
                history.push(`${routes.ventures.url}/${currentRoute?.params?.id}/informacoes-gerais`)
            }
        }

        if (currentRoute?.name === CORPORATION_ROUTE) {
            setSelectedOption(sideBarOptions[0])
            history.push(`${routes.corporations.url}/${currentRoute?.params?.id}/informacoes-gerais`)
        }
    }, [sideBarOptions])

    useEffect(() => {
        if (currentRoute) {
            const options = SideBarHelper.getOptions(currentRoute, currentUser)
            setSideBarOptions(options)
        }
    }, [currentRoute, currentRoute?.params, currentRoute?.name, currentRoute?.path])



    return (
        <>
        <SideBarContainer padding={currentRoute?.name === VENTURE_ROUTE ? "0" : "20px 0"}>
            {currentRoute?.name === VENTURE_ROUTE && (
                <>
                    <Container
                        flex
                        alignCenter
                        justifyCenter
                        bgColor="white"
                        p="20px"
                        bb="1px solid #eee">
                        <SvgIcon src={ventureIcon} size="24px" color={primary} />
                        <Container ml="10px">
                            <Label color={primary} bold>{currentVenture?.name}</Label>
                        </Container>
                    </Container>
                    <Container mt="10px">
                        <Link to={routes.ventures.url}>
                            <BackButtonContainer>
                                <SvgIcon src={backIcon} size="14px" color={primary} fillPath />
                                <Container ml="10px">
                                    <Span fs="14px" pointer>Empreendimentos</Span>
                                </Container>
                            </BackButtonContainer>
                        </Link>
                    </Container>
                </>
            )}
            {currentRoute?.name === CORPORATION_ROUTE && (
                <>
                    <Container
                        flex
                        alignCenter
                        justifyCenter
                        bgColor="white"
                        p="20px"
                        bb="1px solid #eee">
                        <SvgIcon src={corporationIcon} size="24px" color={primary} />
                        <Container ml="10px">
                            <Label color={primary} bold>{currentCorporation?.name}</Label>
                        </Container>
                    </Container>
                    <Container mt="10px">
                        <Link to={routes.corporations.url}>
                            <BackButtonContainer>
                                <SvgIcon src={backIcon} size="14px" color={primary} fillPath />
                                <Container ml="10px">
                                    <Span fs="14px" pointer>Incorporadoras</Span>
                                </Container>
                            </BackButtonContainer>
                        </Link>
                    </Container>
                </>
            )}
            <SideBarOptionsContainer>
                {sideBarOptions?.map((item, index) => (
                    <SideBarOption
                        active={selectedOption?.name === item.name}
                        onClick={() => handleSelectedOption(item)}
                        label={item.label}
                        icon={item.icon}
                        key={index}/>
                ))}
            </SideBarOptionsContainer>
        </SideBarContainer>
            <DiscardChangesDialog
            active={showDiscardChangesDialog}
            onCancel={() => dispatchSetShowDiscardChangesDialog(false)}
            onDiscardChanges={handleDiscardChanges}/>
        </>
    )
}

const mapStateToProps = state => ({
    currentVenture: state.ventures.currentVenture,
    currentCorporation: state.corporations.currentCorporation,
    userHasChanges: state.global.userHasChanges,
    showDiscardChangesDialog: state.global.showDiscardChangesDialog
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSetShowDiscardChangesDialog(showDiscardChangesDialog) {
        dispatch(setShowDiscardChangesDialog(showDiscardChangesDialog))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
