import styled from 'styled-components'
import { primary } from '../../assets/colorPallet'


const Title = styled.h2`
    font-size: ${props => {
        if (props.small) return "16px"
        else if (props.medium) return "18px"
        else if (props.large) return "20px"
        else if (props.xLarge) return "22px"
        else if (props.xXLarge) return "24px"    
        else return "18px"
    }};
    font-weight: ${props => props.bold ? "600" : "500"};
    color: ${props => {
        if (props.primary) return primary
        else if (props.color) return props.color
        else return "#333"
    }};
    cursor: ${props => props.pointer ? "pointer" : null};
    margin: 0;
    padding: 0;
`

export default Title
