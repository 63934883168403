import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthProvider } from "./Auth";
import Login from "./pages/Login/Login";
import Admin from "./pages";
import store from "./store";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";
import RouteProvider from "./context/RouteContext";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import AuthenticationOrulo from "./pages/AuthenticationOrulo/AuthenticationOrulo";

export const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <AuthProvider history={history}>
        <RouteProvider history={history}>
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={routes.ventures.url} />}
              />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/authentication/orulo"
                component={AuthenticationOrulo}
              />
              <Route
                exact
                path="/processamento/alterar-senha"
                component={ChangePassword}
              />
              <PrivateRoute path="/admin" component={Admin} />
            </Switch>
          </Router>
        </RouteProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
