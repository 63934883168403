// Mutations
import {
    SET_TEAM_MEMBERS_CURRENT_PAGE,
    SET_TEAM_MEMBERS,
    SET_TEAM_MEMBERS_META
} from "./mutations"
import store from "../../index";
import TeamMemberHandler from "../../../handlers/TeamMemberHandler";
import TeamMember from "../../../models/TeamMember";


export const dispatchCreateTeamMember = ({
    name,
    lastName,
    email,
    whatsappNumber,
    corporationIds,
    role,
    avatarUrl,
    opportunityRestriction
}) => async (dispatch) => {
    try {
        const teamMember = await TeamMemberHandler.createTeamMember({
            name,
            lastName,
            email,
            whatsappNumber,
            corporationIds,
            role,
            avatarUrl,
            opportunityRestriction
        })

        const teamMembersClone = [ ...store.getState().teamMembers.teamMembers ]
        teamMembersClone.push(teamMember)
        dispatch({ type: SET_TEAM_MEMBERS, payload: teamMembersClone })

        return teamMembersClone
    } catch (error) {
        throw new Error(error)
    }
}


export const dispatchFetchTeamMembers = ({}, { page }) => async (dispatch) => {
    try {
        const { teamMembers, meta } = await TeamMemberHandler.fetchTeamMembers({}, { page })

        const storedTeamMembers = [...store.getState().teamMembers.teamMembers]

        teamMembers.forEach((teamMember) => {
            const teamMemberIsStored = storedTeamMembers.find(storedTeamMember => storedTeamMember.id === teamMember.id)

            if (!teamMemberIsStored) {
                storedTeamMembers.push(teamMember)
            }
        })

        dispatch({ type: SET_TEAM_MEMBERS, payload: storedTeamMembers })
        dispatch({ type: SET_TEAM_MEMBERS_META, payload: meta })

        return teamMembers
    } catch (error) {
        throw new Error(error)
    }
}

export const dispatchSetTeamMembersCurrentPage = (currentPage) => dispatch => {
    dispatch({ type: SET_TEAM_MEMBERS_CURRENT_PAGE, payload: currentPage })
}

export const dispatchDeleteTeamMember = (teamMemberId) => async (dispatch) => {
    await TeamMemberHandler.deleteTeamMember(teamMemberId)

    const teamMembers = [...store.getState().teamMembers.teamMembers].filter((teamMember) => {
        return teamMember.id !== teamMemberId
    })

    dispatch({ type: SET_TEAM_MEMBERS, payload: teamMembers })
}

export const dispatchUpdateTeamMember = ({ teamMemberId }, {
    name,
    lastName,
    email,
    whatsappNumber,
    corporationIds,
    role,
    avatarUrl,
    opportunityRestriction,
    avatarFile
}) => async (dispatch) => {
    try {
        const updatedTeamMember = await TeamMemberHandler.updateTeamMember({ teamMemberId }, {
            name,
            lastName,
            email,
            whatsappNumber,
            corporationIds,
            role,
            avatarUrl,
            opportunityRestriction,
            avatarFile
        })

        const teamMembersClone = [ ...store.getState().teamMembers.teamMembers ].map((teamMember) => {
            if (teamMember.id === teamMemberId) {
                return updatedTeamMember
            }

            return teamMember
        })

        dispatch({ type: SET_TEAM_MEMBERS, payload: teamMembersClone })

        return teamMembersClone
    } catch (error) {
        throw new Error(error)
    }
}

export const dispatchUpdateWhatsappNumber = ({ whatsappNumber }) => async () => {
    await TeamMemberHandler.updateWhatsappNumber({ whatsappNumber })
}
