// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../../structureComponents";
import StyledButton from "../../../../../../components/StyledButton/StyledButton";
import StyledInput from "../../../../../../components/StyledInput/StyledInput";
import Video from "../../../../../../store/modules/videos/video.model";
import {createVideo} from "../../../../../../store/modules/videos/actions";
import SvgIcon from "../../../../../../components/SvgIcon/SvgIcon";
import videoIcon from "../../../../../../assets/icons/videos.svg"
import {setSnackbarState} from "../../../../../../store/modules/global/actions";


function CreateVideoDialog({
    active = false,
    onClose = () => ({}),
    currentVenture,
    dispatchCreateVideo = () => ({}),
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)
    const [video, setVideo] = useState(new Video({}))

    // Mounted
    useEffect(() => {
        resetLocalState()
    }, [])

    // Watchers
    useEffect(() => {
        if (active) {
            resetLocalState()
        }
    }, [active])

    // Methods
    const handleClose = () => {
        onClose()
        resetLocalState()
    }

    const handleCreateImageGroup = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchCreateVideo({
                ventureId: currentVenture.id,
                corporationId: currentVenture.corporationId
            }, {
                name: video.name,
                url: video.url
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Video adicionado com sucesso"
            })
            setLoading(true)
            handleClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleVideoNameOnChange = (e) => {
        const videoName = e.target.value
        setVideo({ ...video, name: videoName })
    }

    const getVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    const handleVideoUrlOnChange = (e) => {
        const videoId = getVideoId(e.target.value)
        const url = `https://www.youtube.com/embed/${videoId}`
        setVideo({ ...video, url })
    }

    const resetLocalState = () => {
        setTimeout(() => {
            setVideo(new Video({}))
        }, 1000)
    }


    return (
        <StyledDialog
            title="Criar video"
            active={active}
            onClose={handleClose} maxWidth="sm">
            <form onSubmit={handleCreateImageGroup}>
                <Container>
                    <Label bold>Nome</Label>
                    <Container mt="10px">
                        <StyledInput
                            placeholder="Nome do video"
                            value={video.name}
                            onChange={handleVideoNameOnChange} />
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label bold>URL</Label>
                    <Container mt="10px">
                        <StyledInput
                            placeholder="URL do video"
                            value={video.url}
                            onChange={handleVideoUrlOnChange} />
                    </Container>
                </Container>
                {video.url ? (
                    <Container mt="20px">
                        <iframe
                            type="text/html"
                            frameBorder="0"
                            id="player"
                            width="100%"
                            style={{height: "300px"}}
                            src={video.url} />
                    </Container>
                ) : (
                    <Container height="300px" flex alignCenter justifyCenter mt="20px" border="1px solid #ccc">
                        <SvgIcon src={videoIcon} size="80px" color="#888" />
                    </Container>
                )}
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={handleClose}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container disabled={!(video.name && video.url)} ml="10px">
                        <StyledButton
                            disabled={!(video.name && video.url)}
                            kind="primary" type="submit"
                            loading={loading}>
                            Salvar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture,
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchCreateVideo({ corporationId, ventureId }, { name, url }) {
        await dispatch(createVideo({ corporationId, ventureId }, { name, url }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateVideoDialog)
