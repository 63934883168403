import {
  CORPORATIONS_ROUTE,
  VENTURE_ROUTE,
  VENTURES_ROUTE,
  CORPORATION_ROUTE,
  VENTURES_MODERATION_ROUTE,
  ADMIN_USER_ROLE,
} from "../../utils/constants";
import routes from "../../routes";

// Assets
import ventureIcon from "../../assets/icons/venture.svg";
import corporationIcon from "../../assets/icons/corporationIcon.svg";

export default class SideBarHelper {
  static getOptions(currentRoute, currentUser) {
    if (
      currentRoute.name === VENTURES_ROUTE ||
      currentRoute.name === CORPORATIONS_ROUTE ||
      currentRoute.name === VENTURES_MODERATION_ROUTE
    ) {
      const options = [
        {
          name: "venture",
          label: "Empreendimentos",
          icon: ventureIcon,
          routeUrl: routes.ventures.url,
        },
      ];

      if (currentUser?.role === ADMIN_USER_ROLE) {
        options.push({
          name: "corporation",
          label: "Incorporadoras",
          icon: corporationIcon,
          routeUrl: routes.corporations.url,
        });

        options.push({
          name: "venturesModeration",
          label: "Moderacao",
          icon: corporationIcon,
          routeUrl: routes.venturesModeration.url,
        });

        options.push({
          name: "teamMembers",
          label: "Equipe",
          icon: corporationIcon,
          routeUrl: routes.teamMembers.url,
        });
      }

      return options;
    }

    if (currentRoute.name === VENTURE_ROUTE) {
      return [
        {
          name: "generalInfo",
          label: "Info. Gerais",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/informacoes-gerais`,
        },
        {
          name: "imagensAndVideos",
          label: "Imagens e videos",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/imagens-e-videos`,
        },
        {
          name: "propertyPlans",
          label: "Plantas",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/plantas`,
        },
        {
          name: "aboutProject",
          label: "Sobre o projeto",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/sobre-o-projeto`,
        },
        {
          name: "aboutNeighborhood",
          label: "Sobre o bairro",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/sobre-o-bairro`,
        },
        {
          name: "faq",
          label: "Perguntas frequentes",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/perguntas-frequentes`,
        },
        {
          name: "documents",
          label: "Documentos",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/documentos`,
        },
        {
          name: "landingPage",
          label: "Landing page",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/landing-page`,
        },
        {
          name: "ventureMessages",
          label: "Mensagens",
          routeUrl: `${routes.ventures.url}/${currentRoute?.params?.id}/mensagens-empreendimentos`,
        },
      ];
    }

    if (currentRoute.name === CORPORATION_ROUTE) {
      return [
        {
          name: "generalInfo",
          label: "Informacoes Gerais",
          routeUrl: `${routes.corporations.url}/${currentRoute?.params?.id}/informacoes-gerais`,
        },
        {
          name: "faq",
          label: "Perguntas frequentes",
          routeUrl: `${routes.corporations.url}/${currentRoute?.params?.id}/perguntas-frequentes`,
        },
        {
          name: "corporationManagers",
          label: "Gerente da incorporadora",
          routeUrl: `${routes.corporations.url}/${currentRoute?.params?.id}/gerente-da-incorporadora`,
        },
        {
          name: "corporationSettings",
          label: "Configuracoes",
          routeUrl: `${routes.corporations.url}/${currentRoute?.params?.id}/configuracoes`,
        },
      ];
    }
  }
}
