// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import queryString from 'query-string'

// Styles
import { Container, Label } from "../../structureComponents";
import StyledInput from "../../components/StyledInput/StyledInput";

// Store actions
import {login} from "../../store/modules/authentication/actions";

// Components
import SvgIcon from "../../components/SvgIcon/SvgIcon";

// Icons
import imobproLogo from "../../assets/logos/imobpro-logo-secondary.svg"
import {AuthContext} from "../../Auth";
import {LI_AUTH_TOKEN} from "../../config";
import UserHandler from "../../handlers/UserHandler";
import StyledButton from "../../components/StyledButton/StyledButton";



function ChangePassword(props) {
    // States
    const [password, setPassword] = useState(null)
    const [passwordRepeat, setPasswordRepeat] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // Mounted
    useEffect(() => {
        getAccessToken()
        setError(null)
    }, [])


    // Methods
    const getAccessToken = async () => {
        try {
            const { token } = queryString.parse(window.location.search);
            localStorage.setItem(LI_AUTH_TOKEN, token)
        } catch (error) {
            window.location.href = "/"
        }
    }

    const handlePasswordOnChange = (e) => {
        setPassword(e.target.value)
    }

    const handlePasswordRepeatOnChange = (e) => {
        setPasswordRepeat(e.target.value)
    }

    const handleSavePassword = async () => {
        if (password !== passwordRepeat) {
            setError("As senhas estão diferentes")
            return
        }

        if (password?.length < 6 || password?.length > 12) {
            setError("A senha deve ter no mínimo 6 e no máximo 12 digitos")
        }
        setLoading(true)
        await UserHandler.changePassword({ password })
        props.history.push("/")
        setLoading(false)
    }


    return (
        <Container fluid flex justifyCenter alignCenter>
            <Container fluid maxWidth="30em" mt="60px">
                <Container flex column justifyCenter alignCenter>
                    <Container onClick={() => props.history.push("/")}>
                        <SvgIcon src={imobproLogo} size={200}  />
                    </Container>
                    <Container mt="20px" fluid p="10px" flex alignCenter justifyCenter bgColor="#f4f4f4" radius="8px">
                        <Label large>Cadastrar nova senha</Label>
                    </Container>
                </Container>
                <Container fluid mt="40px">
                    <Container>
                        <Label bold>Senha</Label>
                        <Container mt="10px">
                            <StyledInput type="password" placeholder="Digite a senha" value={password} onChange={handlePasswordOnChange} />
                        </Container>
                    </Container>
                    <Container mt="30px">
                        <Label bold>Repetir senha</Label>
                        <Container mt="10px">
                            <StyledInput type="password" placeholder="Digite a senha novamente" value={passwordRepeat} onChange={handlePasswordRepeatOnChange} />
                        </Container>
                    </Container>
                </Container>
                {error && (
                    <Container mt="20px">
                        <Label small error>{error}</Label>
                    </Container>
                )}
                <Container mt="40px">
                    <StyledButton loading={loading} kind="primary" onClick={handleSavePassword}>Alterar senha</StyledButton>
                </Container>
            </Container>
        </Container>
    )
}

const mapStateToProps = state => ({
    userIsLoggedIn: state.authentication.userIsLoggedIn
})

const mapDispatchToProps = dispatch => ({
    async login({ email, password }) {
        const user = await dispatch(login({ email, password }))
        return user
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
