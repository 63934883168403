import {SET_CORPORATIONS, SET_CURRENT_CORPORATION} from "./mutations";

const initialState = {
    corporations: [],
    currentCorporation: null
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CORPORATIONS:
            return { ...state, corporations: action.payload }

        case SET_CURRENT_CORPORATION:
            return { ...state, currentCorporation: action.payload }

        default:
            return state;
    }
}

export default reducer;
