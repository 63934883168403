export const ADMIN_USER_ROLE = 10
export const GENERAL_USER_ROLE = 20
export const ATTENDANT_USER_ROLE = 30

// TABS
export const VENTURES_TAB = "VENTURES_TAB"
export const CORPORATIONS_TAB = "CORPORATIONS_TAB"
export const NAME_TAB = "NAME_TAB"
export const LOCATION_TAB = "LOCATION_TAB"
export const CORPORATION_TAB = "CORPORATION_TAB"
export const PRICE_TAB = "PRICE_TAB"
export const PROJECT_DESCRIPTION_TAB = "PROJECT_DESCRIPTION_TAB"
export const FACILITIES_TAB = "FACILITIES_TAB"
export const HIGHLIGHTS_TAB = "HIGHLIGHTS_TAB"
export const BUILDINGS_INFO_TAB = "BUILDINGS_INFO_TAB"
export const PHOTOS_TAB = "PHOTOS_TAB"
export const VIDEOS_TAB = "VIDEOS_TAB"
export const HEADER_TAB = "HEADER_TAB"
export const BUILDING_DOCUMENTS_TAB = "BUILDING_DOCUMENTS_TAB"

// ROUTE NAMES
export const VENTURE_ROUTE = "VENTURE_ROUTE"
export const VENTURES_ROUTE = "VENTURES_ROUTE"
export const CORPORATIONS_ROUTE = "CORPORATIONS_ROUTE"
export const CORPORATION_ROUTE = "CORPORATION_ROUTE"
export const VENTURES_MODERATION_ROUTE = "VENTURES_MODERATION_ROUTE"

