// Libraries
import React from "react"

// Styles
import { SnackbarContainer, SnackbarPositionContainer, SnackbarCard } from "./styled"


/**
 * Snackbar component
 *
 * @param { string } message
 * @param { string } type
 * @param { boolean } active
 *
 */
function Snackbar({ message, type, active = false }) {
    return (
        <SnackbarContainer>
            <SnackbarPositionContainer>
                <SnackbarCard type={type} active={active}>
                    {message}
                </SnackbarCard>
            </SnackbarPositionContainer>
        </SnackbarContainer>
    )
}


export default Snackbar
