// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../structureComponents";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Store actions
import {deleteVentureImage, updateVentureImage} from "../../../../../store/modules/images/actions"
import StyledInput from "../../../../../components/StyledInput/StyledInput";
import {setSnackbarState} from "../../../../../store/modules/global/actions";
import PropertyPlan from "../../../../../models/PropertyPlan";
import {VentureImage} from "../styled";
import {Skeleton} from "@material-ui/lab";
import StyledImage from "../../../../../components/StyledImage/StyledImage";
import {dispatchUpdatePropertyPlan} from "../../../../../store/modules/propertyPlans/actions";
import MaskHelper from "../../../../../utils/MaskHelper";
import StyledSelect from "../../../../../components/StyledSelect/StyledSelect";

const amountOptions = PropertyPlan.getBedroomAmountOptions()
function UpdateVenturePropertyPlanDialog({
    active = false,
    onClose = () => ({}),
    dispatchUpdatePropertyPlan,
    propertyPlan,
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)
    const [internalPropertyPlan, setInternalPropertyPlan] = useState(new PropertyPlan({

    }))

    // Watchers
    useEffect(() => {
        if (propertyPlan) {
            setInternalPropertyPlan(new PropertyPlan({
                ...propertyPlan
            }))
        }
    }, [propertyPlan])

    // Methods
    const handlePropertyPlanOnChange = (e, key) => {
        let { value } = e.target

        if (key === "size") value = MaskHelper.toBrazilianNumberFormat(value)

        if (value.length === 0) value = ""

        setInternalPropertyPlan(new PropertyPlan({
            ...internalPropertyPlan,
            [key]: value
        }))
    }

    const handleSelectOnChange = (selectedItem, key) => {
        setInternalPropertyPlan(new PropertyPlan({
            ...internalPropertyPlan,
            [key]: selectedItem.value
        }))
    }

    const handleUpdatePropertyPlan = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)

            await dispatchUpdatePropertyPlan({
                corporationId: propertyPlan.corporationId,
                ventureId: propertyPlan.ventureId,
                propertyPlanId: propertyPlan.id
            }, {
                name: internalPropertyPlan.name,
                size: internalPropertyPlan.size,
                bedrooms: internalPropertyPlan.bedrooms,
                suites: internalPropertyPlan.suites,
                garageSpots: internalPropertyPlan.garageSpots
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Planta atualizada com sucesso."
            })

        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro."
            })
        } finally {
            setLoading(false)
            onClose()
        }
    }

    return (
        <StyledDialog
            title="Atualizar imagem"
            active={active}
            onClose={() => onClose()} maxWidth="md">
            <form onSubmit={handleUpdatePropertyPlan}>
                <Container flex>
                    <Container fluid maxWidth="500px">
                        <StyledImage rounded src={propertyPlan?.imageUrl} />
                    </Container>
                    <Container ml="30px">
                        <Container>
                            <Label medium>Legenda</Label>
                            <Container mt="10px">
                                <StyledInput
                                    value={internalPropertyPlan?.name}
                                    onChange={(e) => handlePropertyPlanOnChange(e, "name")} />
                            </Container>
                        </Container>
                        <Container flex alignCenter mt="20px">
                            <Container>
                                <Label>Metragem</Label>
                                <Container mt="10px" fluid maxWidth="120px">
                                    <StyledInput
                                        value={internalPropertyPlan?.size}
                                        onChange={(e) => handlePropertyPlanOnChange(e, "size")} />
                                </Container>
                            </Container>
                            <Container ml="10px">
                                <Label>Quartos</Label>
                                <Container mt="10px">
                                    <StyledSelect
                                        items={amountOptions}
                                        placeholder=""
                                        value={amountOptions.find(i => i.value === internalPropertyPlan?.bedrooms)}
                                        itemLabel="label"
                                        onChange={(selectedItem) => handleSelectOnChange(selectedItem, "bedrooms")}/>
                                </Container>
                            </Container>
                            <Container ml="10px">
                                <Label>Suites</Label>
                                <Container mt="10px">
                                    <StyledSelect
                                        items={amountOptions}
                                        placeholder=""
                                        value={amountOptions.find(i => i.value === internalPropertyPlan?.suites)}
                                        itemLabel="label"
                                        onChange={(selectedItem) => handleSelectOnChange(selectedItem,"suites")}/>
                                </Container>
                            </Container>
                            <Container ml="10px">
                                <Label>Vagas</Label>
                                <Container mt="10px">
                                    <StyledSelect
                                        items={amountOptions}
                                        placeholder=""
                                        value={amountOptions.find(i => i.value === internalPropertyPlan?.garageSpots)}
                                        itemLabel="label"
                                        onChange={(selectedItem) => handleSelectOnChange(selectedItem, "garageSpots")}/>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                            kind="primary"
                            type="submit"
                            loading={loading}>
                            Atualizar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdatePropertyPlan({ corporationId, ventureId, propertyPlanId }, {
        name, bedrooms, size, suites, garageSpots
    }) {
        await dispatch(dispatchUpdatePropertyPlan({
            corporationId,
            ventureId,
            propertyPlanId
        }, {
            name,
            bedrooms,
            size,
            suites,
            garageSpots
        }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(UpdateVenturePropertyPlanDialog)
