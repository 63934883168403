// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Structure components
import { Container, Label } from "../../../structureComponents";

// Styles
import { FilterContainer, Overlay } from "./styled"
import StyledButton from "../../../components/StyledButton/StyledButton";
import StyledChip from "../../../components/StyledChip/StyledChip";

// Assets
import closeIcon from "../../../assets/icons/close.svg"
import {fetchActiveVentures, resetFilters} from "../../../store/modules/ventures/actions";


function BedroomsFilter({
    dispatchFetchActiveVentures,
    dispatchResetFilters,
    currentFilters,
    onLoading = () => ({})
}){
    // States
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        active: false,
        bedrooms: null,
        isFilterApplied: false,
        bedroomsOptions: [
            { label: "1+", value: 1 },
            { label: "2+", value: 2 },
            { label: "3+", value: 3 },
            { label: "4+", value: 4 }
        ],
        bedroomsLabel: ""
    })

    // Watchers
    useEffect(() => {
        if (currentFilters && !currentFilters.minBedrooms) {
            setState({
                ...state, bedrooms: null,
                isFilterApplied: false,
                bedroomsLabel: ""
            })
        }

        if (currentFilters && currentFilters.minBedrooms) {
            setState({
                ...state,
                bedrooms: currentFilters.minBedrooms,
            })
        }
    }, [currentFilters])

    useEffect(() => {
        const bodyElement = document.querySelector("body")
        bodyElement.style.overflow = state.active ? "hidden" : null
    }, [state.active])


    // Methods
    const handleBedroomsOnChange = (bedrooms) => {
        setState({
            ...state,
            bedrooms: bedrooms === state.bedrooms ? null : bedrooms
        })
    }

    const handleClear = async () => {
        onLoading(true)
        setState({
            ...state,
            bedrooms: null,
            isFilterApplied: false,
            bedroomsLabel: ""
        })

        dispatchResetFilters(["minBedrooms"])
        await dispatchFetchActiveVentures({ minBedrooms: null, searchValue: currentFilters.searchValue })
        onLoading(false)
    }

    const handleApplyFilter = async () => {
        setLoading(true)
        onLoading(true)
        let bedroomsLabel = `${state.bedrooms} a 4 quartos`
        if (state.bedrooms === 4) {
            bedroomsLabel = `4 quartos`
        }

        setState({
            ...state,
            active: false,
            isFilterApplied: true,
            bedroomsLabel
        })


        await dispatchFetchActiveVentures({ minBedrooms: state.bedrooms, searchValue: currentFilters.searchValue })


        setLoading(false)
        onLoading(false)
    }


    return (
        <>
            <Overlay active={state.active} onClick={() => setState({ ...state, active: false })}/>
            {!state.isFilterApplied ? (
                <StyledButton
                kind="dark-outlined"
                shape="rounded"
                height="36px"
                light
                onClick={() => setState({ ...state, active: true })}>
                Quartos
                </StyledButton>
            ) : (
                <StyledChip
                label={state.bedroomsLabel}
                kind="delete"
                onClick={() => setState({ ...state, active: true })}
                onDelete={handleClear}/>
            )}
            <FilterContainer active={state.active}>
                <Container className="bedrooms-filter-header--mobile" mb="40px">
                    <Container fluid flex justifyBetween alignCenter>
                        <Container>
                            <Label semiBold style={{ fontSize: "24px" }}>Quartos</Label>
                        </Container>
                        <StyledButton
                            iconButton
                            icon={{ url: closeIcon }}
                            onClick={() => setState({ ...state, active: false })} />
                    </Container>
                </Container>
                <Container className="filter-options" flex alignCenter>
                    {state.bedroomsOptions.map((bedroomOption, index) => (
                        <Container key={index} ml={index > 0 ? "10px" : null}>
                            <StyledButton
                                kind="dark-outlined"FilterContainer
                                shape="circle"
                                active={state.bedrooms === bedroomOption.value}
                                onClick={() => handleBedroomsOnChange(bedroomOption.value)}>
                                {bedroomOption.label}
                            </StyledButton>
                        </Container>
                    ))}
                </Container>
                <Container className="bedrooms-filter--action-container" flex alignCenter justifyBetween mt="40px">
                    <StyledButton
                        className="bedrooms-filter--action-container__clean-btn"
                        kind="primary-transparent"
                        slimHeight
                        onClick={handleClear}>
                        Limpar
                    </StyledButton>
                    <StyledButton
                        className="bedrooms-filter--action-container__apply-btn"
                        kind="primary"
                        slimHeight
                        loading={loading}
                        onClick={handleApplyFilter}>
                        Aplicar
                    </StyledButton>
                </Container>
            </FilterContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentFilters: state.ventures.currentFilters
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchActiveVentures({ minBedrooms, searchValue }) {
        await dispatch(fetchActiveVentures({ minBedrooms, searchValue }))
    },

    dispatchResetFilters(filters) {
        dispatch(resetFilters(filters))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(BedroomsFilter)
