import React, {useEffect, useState} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Container, Label } from "../../structureComponents"
import GeneralHelper from "../../utils/GeneralHelper";
import {StyledCheckboxContainer} from "../StyledCheckbox/styled";

export default function StyledCheckboxList({
    items = [],
    itemValue = "id",
    itemLabel = "name",
    onChange = () => ([]),
    checkedItems = [],
    groupedBy
}) {

    const [internalCheckedItems, setInternalCheckedItems] = useState({})
    const [groupedItems, setGroupedItems] = useState([])

    // Mounted
    useEffect(() => {
        if (items.length > 0 && checkedItems) {
            const res = items.reduce((a, b) => ({ ...a, [b.id]: checkedItems.includes(b.id) }), {});
            setInternalCheckedItems(res)
        }
    }, [items])

    // Watchers
    useEffect(() => {
        if (internalCheckedItems) {
            onChange(Object.keys(internalCheckedItems).filter(item => internalCheckedItems[item] === true))
        }

    }, [internalCheckedItems])

    useEffect(() => {
        if (groupedBy && items.length > 0) {
            const grouped = GeneralHelper.groupByKey(items, "category")
            setGroupedItems(grouped)
        }
    }, [groupedBy, items])



    useEffect(() => {
        const internalClone = {...internalCheckedItems}

        if (checkedItems) {
            checkedItems.forEach((checkedItem, index) => {
                internalClone[checkedItem] = true
                if (index === checkedItems.length - 1) {
                    setInternalCheckedItems(internalClone)
                }
            })
        }
    }, [checkedItems])

    // Methods
    const handleChange = (event) => {
        setInternalCheckedItems({ ...internalCheckedItems, [event.target.value]: event.target.checked });
    };

    if (groupedBy) {
        return (
            <StyledCheckboxContainer>
                <FormGroup row>
                    {Object.keys(groupedItems).map((itemGroup, index) => (
                        <Container key={index} mt={index > 0 ? "20px" : null}>
                            <Label bold>{itemGroup}</Label>
                            <Container mt="10px" flex alignCenter wrap>
                                {groupedItems[itemGroup].map((item, i) => (
                                    <Container key={i} flex fluid maxWidth="24em">
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={internalCheckedItems[item.id] ? true : false}
                                                onChange={handleChange}
                                                value={item.id}
                                                name={item.id.toString()} />}
                                            label={item[itemLabel]}
                                        />
                                    </Container>
                                ))}
                            </Container>
                        </Container>
                    ))}
                </FormGroup>
            </StyledCheckboxContainer>
        )
    }

    return (
        <StyledCheckboxContainer>
            <FormGroup row>
                {items.map((item, index) => (
                    <Container key={index} flex fluid maxWidth="24em">
                        <FormControlLabel
                            control={<Checkbox
                                checked={internalCheckedItems[item.id] ? true : false}
                                onChange={handleChange}
                                value={item.id}
                                name={item.id.toString()} />}
                            label={item[itemLabel]}
                        />
                    </Container>
                ))}
            </FormGroup>
        </StyledCheckboxContainer>
    );
}
