// Libraries
import React from "react";

// Components
import StyledButton from "../../components/StyledButton/StyledButton";
import StyledDialog from "../../components/StyledDialog/StyledDialog";

// Structure componnets
import { Container, Label } from "../../structureComponents";

function ConfirmDeleteDialog({
  loading,
  active = false,
  title,
  text,
  onClose = () => ({}),
  onDelete = () => ({}),
}) {
  return (
    <StyledDialog
      title={title}
      active={active}
      onClose={() => onClose()}
      maxWidth="sm"
    >
      <Container>
        <Label>{text}</Label>
        <Container mt="40px" flex alignCenter justifyEnd fluid>
          <Container>
            <StyledButton
              kind="primary-outlined"
              slimHeight
              onClick={() => onClose()}
            >
              Cancelar
            </StyledButton>
          </Container>
          <Container ml="10px">
            <StyledButton
              loading={loading}
              kind="primary"
              slimHeight
              onClick={() => onDelete()}
            >
              Deletar
            </StyledButton>
          </Container>
        </Container>
      </Container>
    </StyledDialog>
  );
}

export default ConfirmDeleteDialog;
