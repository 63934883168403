export default class MaskHelper {
    static toBrazilianNumberFormat = (value) => {
        let valor = value;

        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g,''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ",$1");

        if (valor.length > 6) {
            valor = valor.replace(/./g, function(c, i, a) {
                return i && c !== "." && ((a.length - i) % 3 === 0) ? '.' + c : (c === '.' ? ',' : c);
            })
            valor = valor.replace(/.(?=[^.]*$)/, '')
        }

        return valor.toString();
    }

    static toPhoneNumber(value) {
        let inputValue = value;
        inputValue = inputValue.replace(/\D/g,'');
        inputValue = inputValue.replace(/(^\d{2})(\d)/,'($1) $2');
        inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/,'$1-$2');

        return inputValue;
    }

    static toDate(value, { format }){
        if (!format) {
            value=value.replace(/\D/g,"")
            value=value.replace(/(\d{2})(\d{4})/,"$1/$2")
            value=value.replace(/(\d{2})(\d)/,"$1/$2")
        }

        if (format === "MM/YYYY") {
            value=value.replace(/\D/g,"")
            value=value.replace(/(\d{2})(\d{4})/,"$1/$2")
        }
        return value
    }
}
