import {
    SET_PROPERTY_PLANS, SET_RESIZING_IMAGES, SET_UPLOADING_PROGRESS_PERCENTAGE
} from "./mutations";

const initialState = {
    currentPropertyPlans: [],
    uploadingProgressPercentage: 0,
    resizingImages: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_PROPERTY_PLANS:
            return { ...state, currentPropertyPlans: action.payload }
        case SET_UPLOADING_PROGRESS_PERCENTAGE:
            return { ...state, uploadingProgressPercentage: action.payload }
        case SET_RESIZING_IMAGES:
            return { ...state, resizingImages: action.payload }
        default:
            return state;
    }
}

export default reducer;
