// Libraries
import React, {useEffect, useState} from "react"
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'

import Select from '@material-ui/core/Select'

import {StyledSelectContainer} from "./styled"
import {Container, Label} from "../../structureComponents"
import Corporation from "../../store/modules/corporations/model";


function StyledSelect ({
    items = [],
    onChange = () => ({}),
    error = false,
    helperText,
    itemLabel = "label",
    value,
    placeholder = "Escolha uma opção"
}) {
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        setSelectedItem("")
    }, [])

    useEffect(() => {
        if (value) {
            const item = items.find(i => i === value)
            setSelectedItem(item)
        } else {
            setSelectedItem("placeholder")
        }
    }, [value])

    // Methods
    const handleChange = (e) => {
        const { value } = e.target

        setSelectedItem(value)

        if (value !== "") {
            onChange(value)
        }
    }

    return (
        <StyledSelectContainer value={selectedItem} error={error}>
            <FormControl variant="outlined">
                <Select
                    value={selectedItem}
                    onChange={handleChange}>
                    <MenuItem value="placeholder" disabled>
                        {placeholder}
                    </MenuItem>
                    {items.map((item, index) => (
                        <MenuItem key={index} value={item}>{item[itemLabel]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {helperText && (
                <Container mt="3px" pl="14px" lineHeight="1">
                    <Label small error>{helperText}</Label>
                </Container>
            )}
        </StyledSelectContainer>
    )
}

export default StyledSelect
