// Libraries
import styled from "styled-components"
import {primary} from "../../assets/colorPallet";


export const StyledSelectContainer = styled.div`    
    .MuiFormControl-root{
        width: 100%
    }
    
    .MuiOutlinedInput-root{
        border-radius: 8px !important;
    }
    
    
        
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
        border-color: ${primary} !important;
    }
    
    .MuiSelect-select:focus {
      background-color: transparent !important;
    }
    
    .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.error ? "red" : "#4F5B62"} !important;
    }
    
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      color: ${props => !props.value || props.value === "" ? "#888 !important" : null};
    }
   
`

