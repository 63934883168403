const routes = {
  login: {
    url: "/login",
  },
  root: {
    url: "/",
  },
  ventures: {
    url: "/admin/empreendimentos",
  },
  leads: {
    url: "/admin/leads",
  },
  corporations: {
    url: "/admin/incorporadoras",
  },
  venturesModeration: {
    url: "/admin/moderacao",
  },
  teamMembers: {
    url: "/admin/equipe",
  },
  profile: {
    url: "/admin/perfil",
  },
};

export default routes;
