export const primary = "#007A68"
export const primaryLight = "#52C7B8"
export const primaryDark = "#368c7d"
export const secondary = "#4AE1AB"
export const backgroundDefaultColor = "#f8f8f8"
export const textDefault = "#000A12"
export const lightGrey = "#F6F6F6"
export const mediumLightGrey = "#888"
export const rentingColor = "#00adef"
export const rentingSubColor = "#F5FAFD"
export const sellingColor = "#7557E5"
export const condominiumColor = "#62DDCE"
export const errorColor = "red"
export const whatsappColor = "#25D366"
export const green500 = "#52C7B8"

export const neutral500 = "#ECEFF1"
export const neutral800 = "#4F5B62"
