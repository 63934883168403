// Libraries
import axios from "axios"

// Helpers
import {decodeJWT} from "./helpers";
import {SET_CURRENT_USER, SET_USER_IS_LOGGED_IN} from "./mutations";
import {ORULO_END_USER_AUTH_TOKEN} from "../../../config";
import UserHandler from "../../../handlers/UserHandler";


export const dispatchChangePassword = ({ password }) => async () => {
    try {
        await UserHandler.changePassword({ password })
    } catch (error) {
        throw new Error(error)
    }
}
