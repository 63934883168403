// Libraries
import styled from "styled-components"


export const VentureImageGroupContainer = styled.div`
  width: 100%;
`

export const VentureImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
  transition: opacity .4s ease;
  
  &:hover {
    opacity: .8;
  }
`

