import styled from 'styled-components'
import {errorColor, mediumLightGrey, primary} from "../../assets/colorPallet";

const Label = styled.label`
    font-family: 'Poppins', sans-serif;
    color: ${props => {
            if(props.color) return props.color 
        else if (props.lightGrey) return mediumLightGrey
                else if (props.error) return errorColor
                else if (props.primary) return primary
        else return "#333"
        
    }};
    font-size: ${props => {
        if (props.extraSmall) return "8px"
        else if (props.small) return "12px"    
        else if (props.medium) return "16px"
        else if (props.large) return "18px"
        else if (props.xLarge) return "24px"
        else if (props.xXLarge) return "32px"    
        else return "14px"
    }};
    font-weight: ${props => props.bold ? "600" : props.semiBold ? "500" : null};
    cursor: ${props => props.pointer ? "pointer" : null};
    white-space: ${props => props.nowrap ? "nowrap" : null};
    text-transform: ${props => props.uppercase ? "uppercase" : null};
    text-align: ${props => props.center ? "center" : null};
    
    @media only screen and (max-width: 600px) {
        font-size: ${props => {
            if (props.small) return "12px"
            else if (props.medium) return "14px"
            else if (props.large) return "18px"
            else if (props.xLarge) return "24px"
            else if (props.xXLarge) return "30px"
            else return "14px"
        }};
    }
`

export default Label
