import React from 'react'
import {Link} from "react-router-dom"
import { SideBarOptionContainer } from "./styled";
import { Span, Container } from "../../../structureComponents";


export default function SideBarOption(props) {

    const {
        label,
    } = props

    return (
        <SideBarOptionContainer {...props}>
            <Container ml="10px" flex alignCenter>
                {!props.toggleSideBar && <Span>
                    {label}
                </Span>}
            </Container>
        </SideBarOptionContainer>
    )
}
