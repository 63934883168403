export default class User {
    constructor({
        id,
        name,
        lastName,
        role,
        email,
        teamMember,
    }) {
        this.id = id
        this.name = name
        this.role = role
        this.lastName = lastName
        this.email = email
        this.teamMember = teamMember
    }

    get brokerPortalUrl() {
        if (!this.teamMember) {
            return ""
        }

        return `https://imob.pro.br/especialistas/${this.teamMember?.brokerPortal?.routeName}`
    }
}