// Libraries
import axios from "axios"
import currencyFormatter from "currency-formatter"

// Models
import Venture from "../models/Venture"

// Config
import ApiConfig from "../apiConfig"
import store from "../store";
import {
    setActiveVentures,
    setArchiveVentures,
    setVentures,
    setInboxVentures
} from "../store/modules/ventures/actions";

export default class VentureHandler {
    static async fetchInactiveVentures({ page, searchValue }) {
        const headers = ApiConfig.generateHeaders()

        const params = {
            page,
            needModeration: 0,
            moderationApproved: 1,
            searchValue,
            active: 0
        }

        const response = await axios.get(`
            ${process.env.REACT_APP_API_URL}/admin/ventures`,
            {
                ...headers,
                params
            }
        )

        const ventures = response.data.ventures.map((venture) => new Venture({
            id: venture.id,
            name: venture.name,
            zipcode: venture.zipcode,
            streetName: venture.streetName,
            streetNumber: venture.streetNumber,
            neighborhood: venture.neighborhood,
            countryState: venture.countryState,
            city: venture.city,
            corporationId: venture.corporationId,
            projectDescription: venture.projectDescription,
            facilitiesIds: venture.facilitiesIds ? venture.facilitiesIds : [],
            highlightsIds: venture.highlightsIds ? venture.highlightsIds : [],
            landSize: venture.landSize,
            amountOfFloors: venture.amountOfFloors,
            amountOfApartments: venture.amountOfApartments,
            amountOfTowers: venture.amountOfTowers,
            heroDescription: venture.heroDescription,
            logoUrl: venture.logoUrl,
            ventureType: venture.ventureType,
            minBedroomsAmount: venture.minBedroomsAmount,
            maxBedroomsAmount: venture.maxBedroomsAmount,
            minSuitesAmount: venture.minSuitesAmount,
            maxSuitesAmount: venture.maxSuitesAmount,
            minBathroomsAmount: venture.minBathroomsAmount,
            maxBathroomsAmount: venture.maxBathroomsAmount,
            minGarageSpotsAmount: venture.minGarageSpotsAmount,
            maxGarageSpotsAmount: venture.maxGarageSpotsAmount,
            priceFrom: currencyFormatter.format(venture.priceFrom, { code: "BRL", symbol: "" }),
            minSize: currencyFormatter.format(venture.minSize, { code: "BRL", symbol: "" }),
            maxSize: currencyFormatter.format(venture.maxSize, { code: "BRL", symbol: "" }),
            active: venture.active,
            aboutNeighborhood: venture.aboutNeighborhood,
            routeId: venture.routeId,
            coverImage: venture.coverImage,
            images: venture.images,
            status: venture.status,
            deliveryDate: venture.deliveryDate,
            mortgageOptions: venture.mortgageOptions,
            mortgageAnnualFee: currencyFormatter.format(venture.mortgageAnnualFee, { code: "BRL", symbol: "" }),
            metaTitle: venture.metaTitle,
            metaDescription: venture.metaDescription,
            ventureGTMId: venture.ventureGTMId,
            customDomain: venture.customDomain,
            customDomainVerified: venture.customDomainVerified,
            primaryColor: venture.primaryColor,
            secondaryColor: venture.secondaryColor
        }))


        return {
            inactiveVentures: ventures,
            inactiveVenturesMeta: response.data.meta
        }
    }

    static async fetchActiveVentures({
        page,
        searchValue = "",
        minPrice,
        maxPrice,
        minBedrooms,
        minSize,
        maxSize,
        garageSpots,
        orderBy
    }) {
        const headers = ApiConfig.generateHeaders()

        const params = {
            page,
            needModeration: 0,
            moderationApproved: 1,
            searchValue,
            active: 1,
            minPrice,
            maxPrice,
            minBedrooms,
            minSize,
            maxSize,
            garageSpotsAmount: garageSpots,
            orderBy
        }

        const response = await axios.get(`
            ${process.env.REACT_APP_API_URL}/admin/ventures`,
            {
                ...headers,
                params
            }
        )

        const ventures = response.data.ventures.map((venture) => new Venture({
            id: venture.id,
            name: venture.name,
            zipcode: venture.zipcode,
            streetName: venture.streetName,
            streetNumber: venture.streetNumber,
            neighborhood: venture.neighborhood,
            countryState: venture.countryState,
            city: venture.city,
            corporationId: venture.corporationId,
            projectDescription: venture.projectDescription,
            facilitiesIds: venture.facilitiesIds ? venture.facilitiesIds : [],
            highlightsIds: venture.highlightsIds ? venture.highlightsIds : [],
            landSize: venture.landSize,
            amountOfFloors: venture.amountOfFloors,
            amountOfApartments: venture.amountOfApartments,
            amountOfTowers: venture.amountOfTowers,
            heroDescription: venture.heroDescription,
            logoUrl: venture.logoUrl,
            ventureType: venture.ventureType,
            minBedroomsAmount: venture.minBedroomsAmount,
            maxBedroomsAmount: venture.maxBedroomsAmount,
            minSuitesAmount: venture.minSuitesAmount,
            maxSuitesAmount: venture.maxSuitesAmount,
            minBathroomsAmount: venture.minBathroomsAmount,
            maxBathroomsAmount: venture.maxBathroomsAmount,
            minGarageSpotsAmount: venture.minGarageSpotsAmount,
            maxGarageSpotsAmount: venture.maxGarageSpotsAmount,
            priceFrom: currencyFormatter.format(venture.priceFrom, { code: "BRL", symbol: "" }),
            minSize: currencyFormatter.format(venture.minSize, { code: "BRL", symbol: "" }),
            maxSize: currencyFormatter.format(venture.maxSize, { code: "BRL", symbol: "" }),
            active: venture.active,
            aboutNeighborhood: venture.aboutNeighborhood,
            routeId: venture.routeId,
            coverImage: venture.coverImage,
            images: venture.images,
            status: venture.status,
            deliveryDate: venture.deliveryDate,
            mortgageOptions: venture.mortgageOptions,
            mortgageAnnualFee: currencyFormatter.format(venture.mortgageAnnualFee, { code: "BRL", symbol: "" }),
            metaTitle: venture.metaTitle,
            metaDescription: venture.metaDescription,
            ventureGTMId: venture.ventureGTMId,
            customDomain: venture.customDomain,
            customDomainVerified: venture.customDomainVerified,
            primaryColor: venture.primaryColor,
            secondaryColor: venture.secondaryColor
        }))

        return {
            activeVentures: ventures,
            activeVenturesMeta: response.data.meta
        }
    }

    static async fetchArchiveVentures({ page, searchValue = "" }) {
        const headers = ApiConfig.generateHeaders()

        const params = {
            needModeration: 0,
            moderationApproved: 0,
            page,
            searchValue,
            active: 0
        }

        const response = await axios.get(`
            ${process.env.REACT_APP_API_URL}/admin/ventures`,
            {
                ...headers,
                params
            }
        )

        const ventures = response.data.ventures.map((venture) => new Venture({
            id: venture.id,
            name: venture.name,
            zipcode: venture.zipcode,
            streetName: venture.streetName,
            streetNumber: venture.streetNumber,
            neighborhood: venture.neighborhood,
            countryState: venture.countryState,
            city: venture.city,
            corporationId: venture.corporationId,
            projectDescription: venture.projectDescription,
            facilitiesIds: venture.facilitiesIds ? venture.facilitiesIds : [],
            highlightsIds: venture.highlightsIds ? venture.highlightsIds : [],
            landSize: venture.landSize,
            amountOfFloors: venture.amountOfFloors,
            amountOfApartments: venture.amountOfApartments,
            amountOfTowers: venture.amountOfTowers,
            heroDescription: venture.heroDescription,
            logoUrl: venture.logoUrl,
            ventureType: venture.ventureType,
            minBedroomsAmount: venture.minBedroomsAmount,
            maxBedroomsAmount: venture.maxBedroomsAmount,
            minSuitesAmount: venture.minSuitesAmount,
            maxSuitesAmount: venture.maxSuitesAmount,
            minBathroomsAmount: venture.minBathroomsAmount,
            maxBathroomsAmount: venture.maxBathroomsAmount,
            minGarageSpotsAmount: venture.minGarageSpotsAmount,
            maxGarageSpotsAmount: venture.maxGarageSpotsAmount,
            priceFrom: currencyFormatter.format(venture.priceFrom, { code: "BRL", symbol: "" }),
            minSize: currencyFormatter.format(venture.minSize, { code: "BRL", symbol: "" }),
            maxSize: currencyFormatter.format(venture.maxSize, { code: "BRL", symbol: "" }),
            active: venture.active,
            aboutNeighborhood: venture.aboutNeighborhood,
            routeId: venture.routeId,
            coverImage: venture.coverImage,
            images: venture.images,
            status: venture.status,
            deliveryDate: venture.deliveryDate,
            mortgageOptions: venture.mortgageOptions,
            mortgageAnnualFee: currencyFormatter.format(venture.mortgageAnnualFee, { code: "BRL", symbol: "" }),
            metaTitle: venture.metaTitle,
            metaDescription: venture.metaDescription,
            customDomain: venture.customDomain,
            customDomainVerified: venture.customDomainVerified,
            primaryColor: venture.primaryColor,
            secondaryColor: venture.secondaryColor
        }))

        const archiveVenturesMeta = response.data.meta
        store.dispatch(setArchiveVentures(ventures, archiveVenturesMeta))

        return archiveVenturesMeta
    }

    static async fetchInboxVentures({ page, searchValue = "" }) {
        try {
        const headers = ApiConfig.generateHeaders()
        const params = {
            page,
            needModeration: 1,
            moderationApproved: 0,
            searchValue,
            active: 0
        }

        const response = await axios.get(`
            ${process.env.REACT_APP_API_URL}/admin/ventures`,
            {
                ...headers,
                params
            }
        )

        const ventures = response.data.ventures.map((venture) => new Venture({
            id: venture.id,
            name: venture.name,
            zipcode: venture.zipcode,
            streetName: venture.streetName,
            streetNumber: venture.streetNumber,
            neighborhood: venture.neighborhood,
            countryState: venture.countryState,
            city: venture.city,
            corporationId: venture.corporationId,
            projectDescription: venture.projectDescription,
            facilitiesIds: venture.facilitiesIds ? venture.facilitiesIds : [],
            highlightsIds: venture.highlightsIds ? venture.highlightsIds : [],
            landSize: venture.landSize,
            amountOfFloors: venture.amountOfFloors,
            amountOfApartments: venture.amountOfApartments,
            amountOfTowers: venture.amountOfTowers,
            heroDescription: venture.heroDescription,
            logoUrl: venture.logoUrl,
            ventureType: venture.ventureType,
            minBedroomsAmount: venture.minBedroomsAmount,
            maxBedroomsAmount: venture.maxBedroomsAmount,
            minSuitesAmount: venture.minSuitesAmount,
            maxSuitesAmount: venture.maxSuitesAmount,
            minBathroomsAmount: venture.minBathroomsAmount,
            maxBathroomsAmount: venture.maxBathroomsAmount,
            minGarageSpotsAmount: venture.minGarageSpotsAmount,
            maxGarageSpotsAmount: venture.maxGarageSpotsAmount,
            priceFrom: currencyFormatter.format(venture.priceFrom, { code: "BRL", symbol: "" }),
            minSize: currencyFormatter.format(venture.minSize, { code: "BRL", symbol: "" }),
            maxSize: currencyFormatter.format(venture.maxSize, { code: "BRL", symbol: "" }),
            active: venture.active,
            aboutNeighborhood: venture.aboutNeighborhood,
            routeId: venture.routeId,
            coverImage: venture.coverImage,
            images: venture.images,
            status: venture.status,
            deliveryDate: venture.deliveryDate,
            mortgageOptions: venture.mortgageOptions,
            mortgageAnnualFee: currencyFormatter.format(venture.mortgageAnnualFee, { code: "BRL", symbol: "" }),
            metaTitle: venture.metaTitle,
            metaDescription: venture.metaDescription,
            customDomain: venture.customDomain,
            customDomainVerified: venture.customDomainVerified,
            primaryColor: venture.primaryColor,
            secondaryColor: venture.secondaryColor
        }))

        const inboxVenturesMeta = response.data.meta
        store.dispatch(setInboxVentures(ventures, inboxVenturesMeta))

        return inboxVenturesMeta
        } catch (error) {
            throw error
        }
    }


    static async setArchiveVenture({ ventureId, corporationId }) {
        try {
            const headers = ApiConfig.generateHeaders()
            const payload = {
                needModeration: 0
            }

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}`,
                payload,
                headers
            )

            const updatedVenture = new Venture({
                ...response.data
            })

            const archiveVentures = [...store.getState().ventures.archiveVentures].map((archiveVenture) => {
                if (archiveVenture.id === ventureId) {
                    return updatedVenture
                }

                return archiveVenture
            })

            const inboxVentures = [...store.getState().ventures.inboxVentures].filter((inboxVenture) => {
                if (inboxVenture.id === ventureId) {
                    return false
                }

                return true
            })

            store.dispatch(setInboxVentures(inboxVentures))
            store.dispatch(setArchiveVentures(archiveVentures))
        } catch (error) {
            throw error
        }
    }

    static async approveVentureModeration({
        ventureId,
        corporationId,
        currentPage,
        currentSearchValue
    }) {
        try {
            const headers = ApiConfig.generateHeaders()
            const payload = {
                needModeration: 0,
                moderationApproved: 1
            }

            await axios.put(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}`,
                payload,
                headers
            )


            // Update inboxVentures
            await this.fetchInboxVentures({
                page: currentPage,
                searchValue: currentSearchValue
            })

            // Update archive ventures
            await this.fetchArchiveVentures({
                page: currentPage,
                searchValue: currentSearchValue
            })
        } catch (error) {
            throw error
        }
    }

    static async fetchVentures({ page }) {
        const headers = ApiConfig.generateHeaders()

        const params = {
            page,
            needModeration: 0,
            moderationApproved: 1
        }

        const response = await axios.get(`
            ${process.env.REACT_APP_API_URL}/admin/ventures`,
            {
                ...headers,
                params
            }
        )

        const ventures = response.data.ventures.map((venture) => new Venture({
            id: venture.id,
            name: venture.name,
            zipcode: venture.zipcode,
            streetName: venture.streetName,
            streetNumber: venture.streetNumber,
            neighborhood: venture.neighborhood,
            countryState: venture.countryState,
            city: venture.city,
            corporationId: venture.corporationId,
            projectDescription: venture.projectDescription,
            facilitiesIds: venture.facilitiesIds ? venture.facilitiesIds : [],
            highlightsIds: venture.highlightsIds ? venture.highlightsIds : [],
            landSize: venture.landSize,
            amountOfFloors: venture.amountOfFloors,
            amountOfApartments: venture.amountOfApartments,
            amountOfTowers: venture.amountOfTowers,
            heroDescription: venture.heroDescription,
            logoUrl: venture.logoUrl,
            ventureType: venture.ventureType,
            minBedroomsAmount: venture.minBedroomsAmount,
            maxBedroomsAmount: venture.maxBedroomsAmount,
            minSuitesAmount: venture.minSuitesAmount,
            maxSuitesAmount: venture.maxSuitesAmount,
            minBathroomsAmount: venture.minBathroomsAmount,
            maxBathroomsAmount: venture.maxBathroomsAmount,
            minGarageSpotsAmount: venture.minGarageSpotsAmount,
            maxGarageSpotsAmount: venture.maxGarageSpotsAmount,
            priceFrom: currencyFormatter.format(venture.priceFrom, { code: "BRL", symbol: "" }),
            minSize: currencyFormatter.format(venture.minSize, { code: "BRL", symbol: "" }),
            maxSize: currencyFormatter.format(venture.maxSize, { code: "BRL", symbol: "" }),
            active: venture.active,
            aboutNeighborhood: venture.aboutNeighborhood,
            routeId: venture.routeId,
            coverImage: venture.coverImage,
            images: venture.images,
            status: venture.status,
            deliveryDate: venture.deliveryDate,
            mortgageOptions: venture.mortgageOptions,
            mortgageAnnualFee: currencyFormatter.format(venture.mortgageAnnualFee, { code: "BRL", symbol: "" }),
            metaTitle: venture.metaTitle,
            metaDescription: venture.metaDescription
        }))

        store.dispatch(setVentures(ventures))

        return response.data.meta
    }
}
