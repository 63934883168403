// Libraries
import React from "react"
import { ReactSVG } from "react-svg"

// Styles
import { SvgIconContainer } from "./styled"


function SvgIcon({
     src,
     color = "#333",
     size = "24px",
     fillPath,
}) {
    return (
        <SvgIconContainer color={color} size={size} fillPath={fillPath}>
            <ReactSVG src={src} className="svg-icon" />
        </SvgIconContainer>
    )
}

export default SvgIcon
