// Libraries
import axios from "axios"
import currencyFormatter from "currency-formatter"

// Models
import Venture from "./model"

// Mutations
import {
    SET_CURRENT_VENTURE,
    SET_VENTURES,
    SET_VENTURE_FACILITIES_OPTIONS,
    SET_VENTURE_HIGHLIGHTS_OPTIONS,
    SET_VENTURE_TYPES,
    SET_ARCHIVE_VENTURES,
    SET_INBOX_VENTURES,
    SET_ARCHIVE_VENTURES_META,
    SET_INBOX_VENTURES_META,
    SET_ACTIVE_VENTURES,
    SET_ACTIVE_VENTURES_META,
    SET_INACTIVE_VENTURES,
    SET_INACTIVE_VENTURES_META,
    SET_CURRENT_SEARCH_VALUE, SET_CURRENT_FILTERS
} from "./mutations"
import store from "../../index";
import VentureHandler from "../../../handlers/VentureHandler"


/**
 * Create new venture
 *
 * @param corporationId
 * @param name
 * @param zipcode
 * @param streetName
 * @param streetNumber
 * @param neighborhood
 * @param countryState
 * @param city
 * @returns {function(*): Promise<Venture|undefined>}
 */
export const createVenture = ({corporationId},{
    name,
    zipcode,
    streetName,
    streetNumber,
    neighborhood,
    countryState,
    city
}) => async (dispatch) => {
    try {
        const payload = {
            name,
            zipcode,
            streetName,
            streetNumber,
            neighborhood,
            countryState,
            city
        }

        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures`, payload, headers )

        const venture = new Venture({
            name: response.data.name,
            zipcode: response.data.zipcode,
            streetName: response.data.streetName,
            streetNumber: response.data.streetNumber,
            neighborhood: response.data.neighborhood,
            countryState: response.data.countryState,
            city: response.data.city,
            corporationId
        })

        await VentureHandler.fetchVentures({ page: 1, active: 0 })

        return venture
    } catch (error) {
        throw error
    }
}

export const setArchiveVentures = (archiveVentures, archiveVenturesMeta) => (dispatch) => {
    dispatch({ type: SET_ARCHIVE_VENTURES, payload: archiveVentures })
    dispatch({ type: SET_ARCHIVE_VENTURES_META, payload: archiveVenturesMeta })
}


export const setInboxVentures = (inboxVentures, inboxVenturesMeta) => (dispatch) => {
    dispatch({ type: SET_INBOX_VENTURES, payload: inboxVentures })
    dispatch({ type: SET_INBOX_VENTURES_META, payload: inboxVenturesMeta })
}

export const setActiveVentures = ({ activeVentures, activeVenturesMeta }) => (dispatch) => {
    dispatch({ type: SET_ACTIVE_VENTURES, payload: activeVentures })
    dispatch({ type: SET_ACTIVE_VENTURES_META, payload: activeVenturesMeta })
}

export const setInactiveVentures = ({ inactiveVentures, inactiveVenturesMeta }) => (dispatch) => {
    dispatch({ type: SET_INACTIVE_VENTURES, payload: inactiveVentures })
    dispatch({ type: SET_INACTIVE_VENTURES_META, payload: inactiveVenturesMeta })
}

/**
 * Get ventures
 */
export const fetchInactiveVentures = ({ page, searchValue }) => async (dispatch) => {
    try {
        const venturesResponse = await VentureHandler.fetchInactiveVentures({
            page,
            searchValue
        })

        dispatch({ type: SET_INACTIVE_VENTURES, payload: venturesResponse.inactiveVentures })
        dispatch({ type: SET_INACTIVE_VENTURES_META, payload: venturesResponse.inactiveVenturesMeta })
    } catch (error) {
        throw error
    }
}

export const resetFilters = (filters = []) => dispatch => {
    if (filters.length === 0) {
        dispatch({ type: SET_CURRENT_FILTERS, payload: {
            minPrice: null,
            maxPrice: null,
            searchValue: null,
            minBedrooms: null,
            minSize: null,
            maxSize: null,
            garageSpots: null
        }})
    } else {
        const currentFilter = {...store.getState().ventures.currentFilters}
        filters.forEach(filter => currentFilter[filter] = null)
        dispatch({ type: SET_CURRENT_FILTERS, payload: currentFilter })
    }
}

export const fetchActiveVentures = ({
    page,
    searchValue,
    minPrice,
    maxPrice,
    minBedrooms,
    minSize,
    maxSize,
    garageSpots,
    orderBy
}) => async (dispatch) => {
    const currentFilters = {...store.getState().ventures.currentFilters}

    currentFilters.searchValue = searchValue
    if (minPrice) currentFilters.minPrice = minPrice
    if (maxPrice) currentFilters.maxPrice = maxPrice
    if (minSize) currentFilters.minSize = minSize
    if (maxSize) currentFilters.maxSize = maxSize
    if (garageSpots) currentFilters.garageSpots = garageSpots
    if (minBedrooms) currentFilters.minBedrooms = minBedrooms

    const venturesResponse = await VentureHandler.fetchActiveVentures({
        page,
        searchValue: currentFilters.searchValue,
        minPrice: currentFilters.minPrice,
        maxPrice: currentFilters.maxPrice,
        minBedrooms: currentFilters.minBedrooms,
        minSize: currentFilters.minSize,
        maxSize: currentFilters.maxSize,
        garageSpots: currentFilters.garageSpots,
        orderBy
    })

    dispatch({ type: SET_CURRENT_FILTERS, payload: currentFilters })
    dispatch({ type: SET_ACTIVE_VENTURES, payload: venturesResponse.activeVentures })
    dispatch({ type: SET_ACTIVE_VENTURES_META, payload: venturesResponse.activeVenturesMeta })
}

/**
 * Update venture
 *
 * @param { integer } corporationId
 * @param { integer } ventureId
 * @param { object } payload
 */
export const updateVenture = ({
    corporationId,
    ventureId
}, payload, ventureType) => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }
        const allowedKeys = [
            "heroDescription",
            "logoUrl",
            "name",
            "corporationId",
            "ventureType",
            "minBedroomsAmount",
            "maxBedroomsAmount",
            "minSuitesAmount",
            "maxSuitesAmount",
            "minBathroomsAmount",
            "maxBathroomsAmount",
            "minGarageSpotsAmount",
            "maxGarageSpotsAmount",
            "priceFrom",
            "minSize",
            "maxSize",
            "active",
            "aboutNeighborhood",
            "coverImage",
            "status",
            "deliveryDate",
            "metaTitle",
            "metaDescription",
            "ventureGTMId",
            "customDomain",
            "primaryColor",
            "secondaryColor"
        ]

        if (!Object.keys(payload).every(key => allowedKeys.includes(key))) {
            throw new Error("Payload is incorrect or not allowed")
        }

        if (payload.priceFrom) payload.priceFrom = currencyFormatter.unformat(payload.priceFrom, { code: "BRL" })
        if (payload.priceFrom === "") payload.priceFrom = 0
        if (payload.minSize) payload.minSize = currencyFormatter.unformat(payload.minSize, { code: "BRL" })
        if (payload.maxSize) payload.maxSize = currencyFormatter.unformat(payload.maxSize, { code: "BRL" })

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}`,
                payload,
                headers
        )

        const updatedVenture = response.data

        if (response.status === 202) {
            let venturesKey = "activeVentures"
            if (ventureType === "INACTIVE_VENTURES") {
                venturesKey = "inactiveVentures"
            }

            const venture = new Venture({
                ...updatedVenture,
                priceFrom: currencyFormatter.format(updatedVenture.priceFrom, { code: "BRL", symbol: "" }),
                minSize: currencyFormatter.format(updatedVenture.minSize, { code: "BRL", symbol: "" }),
                maxSize: currencyFormatter.format(updatedVenture.maxSize, { code: "BRL", symbol: "" }),
            })

            // UPDATE ACTIVE AND INACTIVE VENTURES
            if (ventureType === "ACTIVE_VENTURES") {
                if (payload.active == 0 || payload.active == 1) {
                    const inactiveVenturesA = [...store.getState().ventures.inactiveVentures]
                    inactiveVenturesA.push(venture)

                    dispatch({ type: SET_INACTIVE_VENTURES, payload: inactiveVenturesA })

                    const activeVenturesA = [...store.getState().ventures.activeVentures].filter((activeVenture) => {
                        if (activeVenture.id === ventureId) {
                            return false
                        }

                        return true
                    })

                    const activeVenturesMeta = {...store.getState().ventures.activeVenturesMeta}
                    activeVenturesMeta.total = activeVenturesMeta.total - 1

                    dispatch({ type: SET_ACTIVE_VENTURES, payload: activeVenturesA })
                    dispatch({ type: SET_ACTIVE_VENTURES_META, payload: activeVenturesMeta })
                } else {
                    const activeVenturesA = [...store.getState().ventures.activeVentures].map((activeVenture) => {
                        if (activeVenture.id === ventureId) {
                            return updatedVenture
                        }

                        return activeVenture
                    })

                    dispatch({ type: SET_ACTIVE_VENTURES, payload: activeVenturesA })
                }
            }

            // UPDATE INACTIVE AND ACTIVE VENTURES
            if (ventureType === "INACTIVE_VENTURES") {
                if (payload.active == 1 || payload.active == 0) {
                    const activeVenturesB = [...store.getState().ventures.activeVentures]
                    activeVenturesB.push(venture)

                    dispatch({ type: SET_ACTIVE_VENTURES, payload: activeVenturesB })

                    const inactiveVenturesB = [...store.getState().ventures.inactiveVentures].filter((inactiveVenture) => {
                        if (inactiveVenture.id === ventureId) {
                            return false
                        }

                        return true
                    })

                    const inactiveVenturesMeta = {...store.getState().ventures.inactiveVenturesMeta}
                    inactiveVenturesMeta.total = inactiveVenturesMeta.total - 1

                    dispatch({ type: SET_INACTIVE_VENTURES, payload: inactiveVenturesB })
                    dispatch({ type: SET_INACTIVE_VENTURES_META, payload: inactiveVenturesMeta })
                } else {
                    const inactiveVenturesB = [...store.getState().ventures.inactiveVentures].map((inactiveVenture) => {
                        if (inactiveVenture.id === ventureId) {
                            return updatedVenture
                        }

                        return inactiveVenture
                    })
                    dispatch({ type: SET_INACTIVE_VENTURES, payload: inactiveVenturesB })
                }
            }

            console.log({ updatedVenture })
            dispatch({ type: SET_CURRENT_VENTURE, payload: new Venture({
                    ...updatedVenture,
                    priceFrom: currencyFormatter.format(updatedVenture.priceFrom, { code: "BRL", symbol: "" }),
                    minSize: currencyFormatter.format(updatedVenture.minSize, { code: "BRL", symbol: "" }),
                    maxSize: currencyFormatter.format(updatedVenture.maxSize, { code: "BRL", symbol: "" })
                })
            })
        }

    } catch (error) {
        throw error
    }
}

export const updateVentureLocation = ({ corporationId, ventureId }, {
    zipcode,
    streetName,
    streetNumber,
    neighborhood,
    city,
    countryState
}) => async (dispatch) => {
    try {
        const payload = {}
        if (zipcode) payload.zipcode = zipcode
        if (streetName) payload.streetName = streetName
        if (streetNumber) payload.streetNumber = streetNumber
        if (neighborhood) payload.neighborhood = neighborhood
        if (city) payload.city = city
        if (countryState) payload.countryState = countryState

        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/location`,
            payload,
            headers
        )

        const updatedVenture = response.data

        const ventures = [...store.getState().ventures.ventures].map((venture) => {
            if (venture.id === updatedVenture.id) {
                const ventureClone = new Venture({
                    ...updatedVenture
                })

                return ventureClone
            }

            return venture
        })

        dispatch({ type: SET_VENTURES, payload: ventures })
        dispatch({ type: SET_CURRENT_VENTURE, payload: updatedVenture })
    } catch (error) {
        throw error
    }
}


export const updateVentureProjectDescription = ({ corporationId, ventureId }, { projectDescription }) => async (dispatch) => {
    try {
        const payload = { projectDescription }
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/projectDescription`,
            payload,
            headers
        )

        const updatedVenture = response.data

        const ventures = [...store.getState().ventures.inboxVentures].map((venture) => {
            if (venture.id === ventureId) {
                const ventureClone = new Venture({
                    ...updatedVenture
                })

                return ventureClone
            }

            return venture
        })

        dispatch({ type: SET_VENTURES, payload: ventures })
        dispatch({ type: SET_CURRENT_VENTURE, payload: updatedVenture })

    } catch (error) {
        throw error
    }
}

export const updateVentureFacilities = ({ corporationId, ventureId, active }, { facilitiesIds }) => async (dispatch) => {
    try {
        const payload = { facilitiesIds: facilitiesIds.map(facilityId => parseInt(facilityId)) }
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/facilities`,
            payload,
            headers
        )

        const updatedVenture = response.data

        const venturesListName = active ? "activeVentures" : "inactiveVentures"

        const ventures = [...store.getState().ventures[venturesListName]].map((venture) => {
            if (venture.id === ventureId) {
                const ventureClone = new Venture({
                    ...updatedVenture
                })

                return ventureClone
            }

            return venture
        })

        if (active) {
            dispatch({ type: SET_ACTIVE_VENTURES, payload: ventures })
        } else {
            dispatch({ type: SET_INACTIVE_VENTURES, payload: ventures })
        }

        dispatch({ type: SET_CURRENT_VENTURE, payload: updatedVenture })

    } catch (error) {
        throw error
    }
}

export const fetchVentureFacilitiesOptions = () => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/ventureFacilities`, headers)
        const ventureFacilitiesOptions = response.data
        dispatch({ type: SET_VENTURE_FACILITIES_OPTIONS, payload: ventureFacilitiesOptions })
    } catch (error) {
        throw error
    }
}

export const updateVentureHighlights = ({ corporationId, ventureId, active }, { highlightsIds }) => async (dispatch) => {
    try {
        const payload = { highlightsIds: highlightsIds.map(highlightId => parseInt(highlightId)) }
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/highlights`,
            payload,
            headers
        )

        const updatedVenture = response.data
        const ventureListName = active ? "activeVentures" : "inactiveVentures"

        const ventures = [...store.getState().ventures[ventureListName]].map((venture) => {
            if (venture.id === ventureId) {
                const ventureClone = new Venture({
                    ...updatedVenture
                })

                return ventureClone
            }

            return venture
        })

        if (active) {
            dispatch({ type: SET_ACTIVE_VENTURES, payload: ventures })
        } else {
            dispatch({ type: SET_INACTIVE_VENTURES, payload: ventures })
        }

        dispatch({ type: SET_CURRENT_VENTURE, payload: updatedVenture })
    } catch (error) {
        throw error
    }
}

export const fetchVentureHighlightsOptions = () => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/ventureHighlights`, headers)
        const ventureHighlightsOptions = response.data
        dispatch({ type: SET_VENTURE_HIGHLIGHTS_OPTIONS, payload: ventureHighlightsOptions })
    } catch (error) {
        throw error
    }
}

export const updateVentureBuildingsInfo = ({ corporationId, ventureId }, buildingsInfo) => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const payload = {
            ...buildingsInfo,
            landSize: currencyFormatter.unformat(buildingsInfo.landSize, { code: "BRL" })
        }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/ventures/${ventureId}/buildingsInfo`,
            payload,
            headers
        )

        const updatedVenture = response.data

        const ventures = [...store.getState().ventures.inboxVentures].map((venture) => {
            if (venture.id === ventureId) {
                const ventureClone = new Venture({
                    ...updatedVenture,
                    landSize: currencyFormatter.format(updatedVenture.landSize, { code: "BRL", symbol: "" })
                })

                return ventureClone
            }

            return venture
        })

        dispatch({ type: SET_VENTURES, payload: ventures })
        dispatch({ type: SET_CURRENT_VENTURE, payload: updatedVenture })
    } catch (error) {
        throw error
    }
}

export const setCurrentVenture = (currentVenture) => dispatch => {
    dispatch({ type: SET_CURRENT_VENTURE, payload: currentVenture })
}

export const fetchVentureTypes = () => async (dispatch) => {
    try {
        const headers = { headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") } }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/ventures/ventureTypes`, headers)
        const ventureTypes = response.data

        dispatch({ type: SET_VENTURE_TYPES, payload: ventureTypes })
    } catch (error) {
        throw error
    }
}

export const setVentures = (ventures) => (dispatch) => {
    dispatch({ type: SET_VENTURES, payload: ventures })
}

