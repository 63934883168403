// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Components
import StyledButton from "../../../components/StyledButton/StyledButton"
import StyledDialog from "../../../components/StyledDialog/StyledDialog"

// Structure components
import { Container } from "../../../structureComponents"
import TeamMember from "../../../models/TeamMember";
import {dispatchDeleteTeamMember} from "../../../store/modules/teamMembers/actions";
import Text from "../../../structureComponents/modules/Text";
import Span from "../../../structureComponents/modules/Span";


const roles = [
    { label: "Corretor", value: TeamMember.getAllowedRoles().BROKER_ROLE },
    { label: "Atendente", value: TeamMember.getAllowedRoles().ATTENDANT_ROLE }
]


function DeleteTeamMemberDialog({
    active,
    selectedTeamMember,
    dispatchDeleteTeamMember,
    onClose = () => ({})}
) {
    // States
    const [loading, setLoading] = useState(false)

    // Watchers
    // Methods
    const handleDeleteTeamMember = async () => {
        setLoading(true)
        await dispatchDeleteTeamMember(selectedTeamMember?.id)
        setLoading(false)
        onClose()
    }

    return (
        <StyledDialog
            title="Deletar membro da equipe"
            active={active}
            onClose={() => onClose()}
            maxWidth="sm">
            <Container>
                <Container>
                    <Text>Tem certeza que deseja deletar <Span bold>{selectedTeamMember?.fullName}</Span>?</Text>
                </Container>
                <Container mt="40px" flex alignCenter justifyEnd fluid>
                    <Container>
                        <StyledButton kind="primary-outlined" slimHeight onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                        loading={loading}
                        kind="primary"
                        slimHeight
                        onClick={handleDeleteTeamMember}>
                            Deletar
                        </StyledButton>
                    </Container>
                </Container>
            </Container>
        </StyledDialog>
    )
}


const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteTeamMember(teamMemberId) {
        await dispatch(dispatchDeleteTeamMember(teamMemberId))
    }
})

export default connect(null, mapDispatchToProps)(DeleteTeamMemberDialog)