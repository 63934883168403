// Libraries
import React, { useState } from "react"
import { connect } from "react-redux"
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import {Container, Label} from "../../../../../structureComponents";
import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Store actions
import { deleteVentureImage } from "../../../../../store/modules/images/actions"
import {setSnackbarState} from "../../../../../store/modules/global/actions";
import {dispatchDeletePropertyPlan} from "../../../../../store/modules/propertyPlans/actions";


function DeleteVenturePropertyPlanDialog({
    active = false,
    onClose = () => ({}),
    dispatchDeleteVentureImage,
    propertyPlan,
    dispatchSnackbar
}) {
    // States
    const [loading, setLoading] = useState(false)

    // Methods
    const handleDeleteVentureImage = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            await dispatchDeleteVentureImage({
                corporationId: propertyPlan.corporationId,
                ventureId: propertyPlan.ventureId,
                propertyPlanId: propertyPlan.id
            })

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Imagem deletada com sucesso"
            })
            onClose()
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title="Deletar planta"
            active={active}
            onClose={() => onClose()} maxWidth="sm">
            <form onSubmit={handleDeleteVentureImage}>
                <Container>
                    <Label>
                        Tem certeza que deseja deletar esta planta?
                        Esta acao é irreversivel.
                    </Label>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton
                            kind="primary-outlined"
                            onClick={() => onClose()}>
                            Cancelar
                        </StyledButton>
                    </Container>
                    <Container ml="10px">
                        <StyledButton
                            kind="primary"
                            type="submit"
                            loading={loading}>
                            Deletar
                        </StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}

const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchDeleteVentureImage({ corporationId, ventureId, propertyPlanId }) {
        await dispatch(dispatchDeletePropertyPlan({ corporationId, ventureId, propertyPlanId }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(DeleteVenturePropertyPlanDialog)
