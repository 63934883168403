// Libraries
import React, { useState, useEffect } from "react"
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";

import { VentureImage } from "./styled"
import { Container } from "../../../../../structureComponents"
import InlineLoading from "../../../../../components/Loading/Loading";
import StyledImage from "../../../../../components/StyledImage/StyledImage";


function VenturePropertyPlanDialog({
    active = false,
    onClose = () => ({}),
    propertyPlanImageUrl,
}) {

    return (
        <StyledDialog
            active={active}
            onClose={() => onClose()}
            maxWidth="md">
            <Container height="80vh" flex fluid justifyCenter alignCenter>
                <StyledImage src={propertyPlanImageUrl} height="80vh" />
            </Container>
        </StyledDialog>
    )
}

export default VenturePropertyPlanDialog
