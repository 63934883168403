class Document {
    constructor({
        id,
        name,
        ventureId,
        corporationId,
        fileUrl
    }) {
        this.id = id
        this.name = name
        this.ventureId = ventureId
        this.corporationId = corporationId
        this.fileUrl = fileUrl
    }

    get creationPayloadIsValid() {
        if (
            !this.name
        ) {
            return false
        }

        return true
    }
}


export default Document
