// Libraries
import styled from "styled-components";

export const CommonQuestionsContainer = styled.div``;

export const CommonQuestionContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 2px dashed #ccc;
  box-sizing: border-box;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;
