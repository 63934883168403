import React, { useEffect, useState } from 'react'
import {decodeJWT} from "./store/modules/authentication/helpers";
import {ADMIN_USER_ROLE, GENERAL_USER_ROLE} from "./utils/constants";
import User from "./models/User";


export const AuthContext = React.createContext({
    currentUser: null,
    setCurrentUser: () => {}
})


export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        handleUserAuthentication()
    }, [])


    // Methods
    const handleUserAuthentication = async () => {
        try {
            const authToken = localStorage.getItem("LI_AUTH_TOKEN")

            // Redirect to root if user is not logged in
            if (!authToken) setCurrentUser(null)

            const decodedToken = decodeJWT(authToken).data

            if (!currentUser) setCurrentUser(new User({
                ...decodedToken
            }))

            // Redirect to root if user doesn't have enough permissions to access the admin panel
            if (decodedToken && decodedToken.role === GENERAL_USER_ROLE) {
                setCurrentUser(null)
            }

            setCurrentUser(decodedToken)
        } catch (error) {
            setCurrentUser(null)
        }
    }

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
            {children}
        </AuthContext.Provider>

    )
}

