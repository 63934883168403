import styled from "styled-components"


export const MoreFiltersActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  z-index: 2500;
  
  @media only screen and (max-width: 600px) {
    box-shadow: none;
  }
`
