// Libraries
import React, {useContext, useState} from "react"

// Components
import StyledCard from "../../../../../../components/StyledCard/StyledCard"

// Structure Components
import { Container, Label } from "../../../../../../structureComponents"
import StyledButton from "../../../../../../components/StyledButton/StyledButton";

// Assets
import deleteIcon from "../../../../../../assets/icons/trash.svg"
import editIcon from "../../../../../../assets/icons/edit.svg"
import photosIcon from "../../../../../../assets/icons/photos.svg"
import DeleteImageGroupDialog from "./DeleteImageGroupDialog/DeleteImageGroupDialog";
import UpdateImageGroupDialog from "./UpdateImageGroupDialog/UpdateImageGroupDialog";
import SvgIcon from "../../../../../../components/SvgIcon/SvgIcon";
import {ADMIN_USER_ROLE} from "../../../../../../utils/constants";
import {AuthContext} from "../../../../../../Auth";



function ImageGroupCard({ imageGroup, match, history }) {
    const { currentUser } = useContext(AuthContext)
    // States
    const [showDeleteImageGroupDialog, setShowDeleteImageGroupDialog] = useState(false)
    const [showUpdateImageGroupDialog, setShowUpdateImageGroupDialog] = useState(false)

    // Methods
    const handleShowDeleteImageGroupDialog = (e) => {
        e.stopPropagation()
        setShowDeleteImageGroupDialog(true)
    }


    const handleShowUpdateImageGroupDialog = (e) => {
        e.stopPropagation()
        setShowUpdateImageGroupDialog(true)
    }

    const handleImageGroupCardOnClick = () => {
        history.push(`${match.url}/grupo-de-imagens/${imageGroup.id}`, { imageGroup })
    }


    return (
            <>
                <StyledCard pointer onClick={handleImageGroupCardOnClick}>
                    <Container>
                        {currentUser?.role === ADMIN_USER_ROLE && (
                            <Container flex alignCenter justifyEnd p="20px 20px 0">
                                <Container>
                                    <StyledButton
                                        iconSize="16px"
                                        iconButton icon={{ url: editIcon}}
                                        onClick={handleShowUpdateImageGroupDialog}/>
                                </Container>
                                <Container ml="10px">
                                    <StyledButton
                                        iconSize="16px"
                                        iconButton icon={{ url: deleteIcon}}
                                        onClick={handleShowDeleteImageGroupDialog}/>
                                </Container>
                            </Container>
                        )}
                        <Container p="20px 40px 40px">
                            <Container
                                fluid
                                flex
                                alignCenter
                                justifyCenter
                                p="40px 0"
                                border="2px dashed #ccc">
                                <SvgIcon src={photosIcon} size="100px" color="#ccc"/>
                            </Container>
                            <Container mt="20px">
                                <Label bold>{imageGroup?.name}</Label>
                                <Container>
                                    <Label>{imageGroup?.images?.length} fotos</Label>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </StyledCard>
                <DeleteImageGroupDialog
                    active={showDeleteImageGroupDialog}
                    imageGroup={imageGroup}
                    onClose={() => setShowDeleteImageGroupDialog(false)} />
                <UpdateImageGroupDialog
                    active={showUpdateImageGroupDialog}
                    imageGroup={imageGroup}
                    onClose={() => setShowUpdateImageGroupDialog(false)}/>
            </>
    )
}

export default ImageGroupCard
