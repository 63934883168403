// Libraries
import React, { useState, useEffect } from "react"
import StyledDialog from "../../../../../../../components/StyledDialog/StyledDialog";

import { VentureImage } from "./styled"
import { Container } from "../../../../../../../structureComponents"
import InlineLoading from "../../../../../../../components/Loading/Loading";


function VentureImageDialog({
    active = false,
    onClose = () => ({}),
    ventureImage,
}) {
    // State
    const [loading, setLoading] = useState(true)

    // Watchers
    useEffect(() => {
        if (active) {
            setLoading(true)
        }
    }, [active])

    return (
        <StyledDialog
            active={active}
            onClose={() => onClose()}
            maxWidth="md">
            {loading && <Container height="400px" flex alignCenter justifyCenter>
                <InlineLoading />
            </Container>}
            <Container height="80vh" flex fluid justifyCenter alignCenter style={{ display: loading ? "none" : null }}>
                <VentureImage src={ventureImage?.mediumUrl} onLoad={() => setLoading(false)}/>
            </Container>
        </StyledDialog>
    )
}

export default VentureImageDialog
