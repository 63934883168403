// Libraries
import React, {useContext, useEffect, useState} from "react"
import { connect } from "react-redux"
import {CorporationsContainer} from "./styled"
import {PageWrapper, Container, Label} from "../../structureComponents"
import EmptyState from "../../components/EmptyState/EmptyState";
import InlineLoading from "../../components/Loading/Loading"

// Icons
import corporationIcon from "../../assets/icons/corporationIcon.svg"
import CreateCorporationDialog from "./CreateCorporationDialog/CreateCorporationDialog";
import {fetchCorporations} from "../../store/modules/corporations/actions";
import CorporationCard from "./CorporationCard/CorporationCard";
import StyledButton from "../../components/StyledButton/StyledButton";
import {RouteContext} from "../../context/RouteContext";
import {CORPORATIONS_ROUTE} from "../../utils/constants";


function Corporations({ dispatchFetchCorporations, corporations, match }) {
    // Context
    const { setCurrentRoute } = useContext(RouteContext)
    // States
    const [loading, setLoading] = useState(true)
    const [showCreateCorporationDialog, setShowCreateCorporationDialog] = useState(false)

    // Mounted
    useEffect(() => {
        handleFetchCorporations()
        setCurrentRoute({
            name: CORPORATIONS_ROUTE,
            path: match.path
        })
    }, [])

    // Methods
    const handleFetchCorporations = async () => {
        try {
            setLoading(true)
            await dispatchFetchCorporations()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    let corporationsCount = ""

    if (corporations.length === 1) {
        corporationsCount = `${corporations.length} incorporadora`
    } else if (corporations.length > 1) {
        corporationsCount = `${corporations.length} incorporadoras`
    }


    if (loading && corporations.length === 0) {
        return (
            <Container flex alignCenter justifyCenter height="calc(100vh - 60px - 80px)">
                <InlineLoading />
            </Container>
        )
    }


    return (
        <>
            <CorporationsContainer>
                    {corporations.length === 0 ? (
                        <Container height="calc(100vh - 60px - 80px)" flex alignCenter justifyCenter>
                            <EmptyState
                                icon={corporationIcon}
                                buttonText="Criar incorporadora"
                                text="Nenhuma incorporadora cadastrada"
                                onClick={() => setShowCreateCorporationDialog(true)}/>
                        </Container>
                    ) : (
                        <Container>
                            <Container flex fluid alignCenter justifyBetween>
                                <Container>
                                    <Label>{corporationsCount}</Label>
                                </Container>
                                <Container>
                                    <StyledButton
                                        kind="primary-outlined"
                                        onClick={() => setShowCreateCorporationDialog(true)}>
                                        Criar incorporadora
                                    </StyledButton>
                                </Container>
                            </Container>
                            <Container grid columns={4} alignCenter mt="40px">
                                {corporations.map((corporation, index) => (
                                    <Container key={index} fluid>
                                        <CorporationCard match={match} key={index} corporation={corporation} />
                                    </Container>
                                ))}
                            </Container>
                        </Container>
                    )}
            </CorporationsContainer>
            <CreateCorporationDialog
            active={showCreateCorporationDialog}
            onClose={() => setShowCreateCorporationDialog(false)} />
        </>
    )
}

const mapStateToProps = (state) => ({
    corporations: state.corporations.corporations
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchCorporations() {
        await dispatch(fetchCorporations())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Corporations)
