// Libraries
import React, {useState, useEffect, useContext} from "react"
import { connect } from "react-redux"

// Styles
import {VentureAboutNeighborhoodContainer} from "./styled"
import {Container, Span, TitleH2, Label} from "../../../../structureComponents";
import StyledInput from "../../../../components/StyledInput/StyledInput";
import StyledButton from "../../../../components/StyledButton/StyledButton";
import {updateVenture} from "../../../../store/modules/ventures/actions";
import {setSnackbarState, setUserHasChanges} from "../../../../store/modules/global/actions";
import {ADMIN_USER_ROLE} from "../../../../utils/constants";
import {AuthContext} from "../../../../Auth";


function VentureAboutNeighborhood({
    currentVenture,
    dispatchUpdateVenture,
    dispatchSnackbar,
    dispatchSetUserHasChanges
}) {
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [aboutNeighborhood, setAboutNeighborhood] = useState(null)
    const [userHasChanges, setUserHasChanges] = useState(false)

    // Watchers
    useEffect(() => {
        if (currentVenture) {
            setAboutNeighborhood(currentVenture.aboutNeighborhood)
        }
    }, [currentVenture])

    // Methods
    const handleAboutNeighborhoodOnChange = (e) => {
        setAboutNeighborhood(e.target.value)
        setUserHasChanges(true)
        dispatchSetUserHasChanges(true)
    }

    const handleUpdateVenture = async () => {
        try {
            if (aboutNeighborhood === currentVenture.aboutNeighborhood) {
                return
            }

            setLoading(true)
            const corporationId = currentVenture.corporationId
            const ventureId = currentVenture.id

            await dispatchUpdateVenture({ corporationId, ventureId }, { aboutNeighborhood })
            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Text sobre o bairro atualizado com sucesso"
            })
            setUserHasChanges(false)
            dispatchSetUserHasChanges(false)
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <VentureAboutNeighborhoodContainer>
            <Container>
                <TitleH2 large>Sobre o bairro <Span bold>{currentVenture?.neighborhood}</Span></TitleH2>
                <Container mt="10px">
                    <Label>
                        A partir do ponto de vista deste empreendimento, escreva um texto sobre o bairro e seus diferenciais
                    </Label>
                </Container>
                <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="40px">
                    <StyledInput
                    multiline
                    rows={25}
                    value={aboutNeighborhood}
                    onChange={handleAboutNeighborhoodOnChange}/>
                </Container>
                {currentUser?.role === ADMIN_USER_ROLE && (
                    <Container disabled={!userHasChanges} mt="40px" fluid flex alignCenter justifyEnd>
                        <StyledButton
                            kind="primary"
                            loading={loading}
                            onClick={handleUpdateVenture}>
                            Salvar
                        </StyledButton>
                    </Container>
                )}
            </Container>
        </VentureAboutNeighborhoodContainer>
    )
}


const mapStateToProps = (state) => ({
    currentVenture: state.ventures.currentVenture
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchUpdateVenture({ corporationId, ventureId }, { aboutNeighborhood }) {
        await dispatch(updateVenture({ corporationId, ventureId }, { aboutNeighborhood }))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },

    dispatchSetUserHasChanges(userHasChanges) {
        dispatch(setUserHasChanges(userHasChanges))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureAboutNeighborhood)
