// Libraries
import styled from "styled-components"


export const LocalVentureImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

