// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

// Styles
import {CorporationManagersContainer} from "./styled";

// Structure components
import { Container, Label } from "../../../../structureComponents"
import EmptyState from "../../../../components/EmptyState/EmptyState";
import InlineLoading from "../../../../components/Loading/Loading";

// Stores
import { fetchCorporationManagers } from "../../../../store/modules/corporationManagers/actions"

// Assets
import corporationManagerIcon from "../../../../assets/icons/corporationManager.svg"
import CreateCorporationManagerDialog from "./CreateCorporationManagerDialog/CreateCorporationManagerDialog";
import UpdateCorporationManagerDialog from "./UpdateCorporationManagerDialog/UpdateCorporationManagerDialog";
import CorporationManagerCard from "./CorporationManagerCard/CorporationManagerCard";


function CorporationManagers({ corporationManagers, dispatchFetchCorporationManagers, currentCorporation }) {
    // States
    const [loading, setLoading] = useState(false)
    const [showCreateCorporationManagerDialog, setShowCreateCorporationManagerDialog] = useState(false)
    const [showUpdateCorporationManagerDialog, setShowUpdateCorporationManagerDialog] = useState(false)
    const [selectedCorporationManager, setSelectedCorporationManager] = useState(false)

    // Mounted
    useEffect(() => {
        handleFetchCorporationManagers()
    }, [])

    // Methods
    const handleFetchCorporationManagers = async () => {
        try {
            setLoading(true)

            const corporationId = currentCorporation.id
            await dispatchFetchCorporationManagers(corporationId)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleUpdateCorporationManager = (corporationManager) => {
        setSelectedCorporationManager(corporationManager)
        setShowUpdateCorporationManagerDialog(true)
    }


    return (
        <>
            {loading ? (
                <Container flex alignCenter justifyCenter height="calc(100vh - 60px - 80px)">
                    <InlineLoading />
                </Container>
            ) : (
                corporationManagers.length === 0 ? (
                    <Container flex alignCenter justifyCenter height="calc(100vh - 60px - 80px)">
                        <EmptyState
                            icon={corporationManagerIcon}
                            text="Nenhum gerente de incorporadora cadastrado"
                            buttonText="Cadastrar gerente de incorporadora"
                            onClick={() => setShowCreateCorporationManagerDialog(true)}/>
                    </Container>
                ) : (
                    <CorporationManagersContainer>
                        <Container>
                            {corporationManagers.map((corporationManager) => (
                                <CorporationManagerCard
                                    name={corporationManager.name}
                                    email={corporationManager.email}
                                    onUpdate={() => handleUpdateCorporationManager(corporationManager)} />
                            ))}
                        </Container>
                    </CorporationManagersContainer>
                )
            )}
            <CreateCorporationManagerDialog
            active={showCreateCorporationManagerDialog}
            onClose={() => setShowCreateCorporationManagerDialog(false)}/>
            <UpdateCorporationManagerDialog
            active={showUpdateCorporationManagerDialog}
            selectedCorporationManager={selectedCorporationManager}
            onClose={() => setShowUpdateCorporationManagerDialog(false)}/>
        </>
    )
}

const mapStateToProps = (state) => ({
    corporationManagers: state.corporationManagers.corporationManagers,
    currentCorporation: state.corporations.currentCorporation
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchCorporationManagers(corporationId) {
        await dispatch(fetchCorporationManagers(corporationId))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CorporationManagers)
