// Libraries
import React, {useContext, useEffect} from "react"
import {VentureContainer} from "./styled";

import {VENTURE_ROUTE} from "../../../utils/constants";
import {RouteContext} from "../../../context/RouteContext";


function VentureDetails({ match }) {
    // Context
    const { setCurrentRoute } = useContext(RouteContext)

    // Mounted
    useEffect(() => {
        setCurrentRoute({
            name: VENTURE_ROUTE,
            path: match.path,
            params: match.params
        })
    }, [])


    return (
        <VentureContainer>
           
        </VentureContainer>
    )
}

export default VentureDetails
