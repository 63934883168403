// Libraries
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Components
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog";
import CommonQuestion from "../../../../../store/modules/commonQuestions/model";

import StyledButton from "../../../../../components/StyledButton/StyledButton";

// Structure components
import { Container, Label } from "../../../../../structureComponents";

function VizualizeMessage({ selectedMessage, active, onClose = () => ({}) }) {
  return (
    <StyledDialog
      title={selectedMessage?.name}
      active={active}
      onClose={() => onClose()}
      maxWidth="sm"
    >
      <Container mt="20px">
        <Label>{selectedMessage?.text}</Label>
      </Container>
      <Container flex alignCenter justifyEnd mt="40px">
        <Container>
          <StyledButton kind="primary-outlined" onClick={() => onClose()}>
            Fechar
          </StyledButton>
        </Container>
      </Container>
    </StyledDialog>
  );
}

export default VizualizeMessage;
