import React, {useContext, useState} from "react"
import { connect } from "react-redux"

import {Container, Label} from "../../../structureComponents"
import StyledButton from "../../../components/StyledButton/StyledButton";
import SvgIcon from "../../../components/SvgIcon/SvgIcon";
import {Link} from "react-router-dom";
import {RouteContext} from "../../../context/RouteContext";
import {ADMIN_USER_ROLE, VENTURE_ROUTE} from "../../../utils/constants";
import {setCurrentVenture, updateVenture} from "../../../store/modules/ventures/actions";
import {setSnackbarState} from "../../../store/modules/global/actions";
import openIcon from "../../../assets/icons/open.svg"
import {
    ViewVentureContainer,
    VentureCoverImage,
    VentureCardContainer,
    VentureCoverImageContainer,
    VentureCorporationName, VentureCoverImageIconContainer
} from "./styled";
import SelectVentureCoverImageDialog
    from "../../../store/modules/ventures/SelectVentureCoverImageDialog/SelectVentureCoverImageDialog";

import photoBackgroundIcon from "../../../assets/icons/photo-background.svg"
import {AuthContext} from "../../../Auth";
import detailsIcon from "../../../assets/icons/details.svg"
import downloadIcon from "../../../assets/icons/download-v2.svg"
import routes from "../../../routes";


function VentureCard({
    venture,
    ventureType,
    match,
     history,
    dispatchSetCurrentVenture,
    dispatchUpdateVenture,
    dispatchSnackbar
}) {
    const { setCurrentRoute }= useContext(RouteContext)
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [showSelectCoverImageDialog, setShowSelectCoverImageDialog] = useState(false)

    // Methods
    const handleEdit = () => {
        setCurrentRoute({
            name: VENTURE_ROUTE,
            path: match.path
        })

        dispatchSetCurrentVenture(venture)
    }

    const handleToggleVentureActivation = async (venture) => {
        try {
            setLoading(true)

            await dispatchUpdateVenture({
                ventureId: venture.id,
                corporationId: venture.corporationId,
            }, {
                active: +(!venture.active),
            }, ventureType)

            dispatchSnackbar({
                type: "success",
                message: venture.active ? "Empreendimento desativado com sucesso" : "Empreendimento publicado com sucesso",
                active: true
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                message: "Infelizmente ocorreu um erro",
                active: true
            })
        } finally {
            setLoading(false)
        }
    }

    const goToDocuments = () => {
        dispatchSetCurrentVenture(venture)

        setCurrentRoute({
            name: VENTURE_ROUTE,
            nextPath: `/admin/empreendimentos/${venture.id}/documentos`,
            sideBarOption: {
                name: "documents",
                label: "Documentos",
                routeUrl: `${routes.ventures.url}/${venture.id}/documentos`
            },
            params: {
                id: venture.id
            }
        })

        history.push(`/admin/empreendimentos/${venture.id}/documentos`)
    }


    const ventureStatus = ventureType === "ACTIVE_VENTURE" ? 1 : 0

    return (
        <>
        <VentureCardContainer>
            <Container fluid flex>
                    <Container
                        flex
                        alignCenter
                        justifyCenter
                        height="300px"
                        width="400px">
                        {venture.coverImage ? (
                            <VentureCoverImageContainer >
                                <VentureCoverImage src={venture.coverImage} />
                                {currentUser?.role === ADMIN_USER_ROLE && (
                                    <Container absolute className="venture-card--update-cover-image__container">
                                        <StyledButton slimHeight kind="primary-outlined__white" onClick={() => setShowSelectCoverImageDialog(true)}>
                                            Atualizar imagem
                                        </StyledButton>
                                    </Container>
                                )}
                            </VentureCoverImageContainer>
                        ) : (
                            <VentureCoverImageContainer>
                                <VentureCoverImageIconContainer className="venture-photo-background--container">
                                    <SvgIcon src={photoBackgroundIcon} size="100px" color="#ccc"/>
                                </VentureCoverImageIconContainer>
                                <Container absolute className="venture-card--update-cover-image__container">
                                    <StyledButton slimHeight kind="primary-outlined__white" onClick={() => setShowSelectCoverImageDialog(true)}>
                                        Adicionar imagem
                                    </StyledButton>
                                </Container>
                            </VentureCoverImageContainer>
                        )}
                    </Container>
                    <Container p="20px" fluid flex column justifyBetween>
                        <Container>
                            <Container flex justifyBetween alignCenter>
                                <Container flex alignCenter mt="5px">
                                    <Label bold medium>{venture.name}</Label>
                                    <Container ml="10px" >
                                        <VentureCorporationName>
                                            <Label bold>{venture.corporation?.name}</Label>
                                        </VentureCorporationName>
                                    </Container>
                                </Container>
                                <ViewVentureContainer active={venture.active}>
                                    {venture?.customDomain ? (
                                        <a href={currentUser?.teamMember ? (
                                            `https://${venture.customDomain}?id=${currentUser?.teamMember?.id}`
                                        ) : (
                                            `https://${venture.customDomain}`
                                        )} target="_blank">
                                            <StyledButton kind="transparent" icon={{ url: openIcon }}>
                                                Ver Hotsite
                                            </StyledButton>
                                        </a>
                                    ) : (
                                        <a href={`https://imob.pro.br/imovel/${venture.routeId}`} target="_blank">
                                            <StyledButton kind="transparent" icon={{ url: openIcon }}>
                                                Ver na Imobpro
                                            </StyledButton>
                                        </a>
                                    )}

                                </ViewVentureContainer>
                            </Container>
                            <Container mt="10px">
                                <Label>{venture?.neighborhood}, {venture?.city} - {venture?.countryState}</Label>
                            </Container>
                        </Container>
                        {currentUser?.role !== ADMIN_USER_ROLE && (
                            <Container flex alignCenter>
                                <Container>
                                    <Link to={`${match.path}/${venture.id}?active=${ventureStatus}`} onClick={handleEdit}>
                                        <StyledButton
                                            icon={{ url: detailsIcon }}
                                            slimHeight
                                            kind="transparent">Detalhes</StyledButton>
                                    </Link>
                                </Container>
                                <Container ml="20px">
                                    <StyledButton
                                        onClick={goToDocuments}
                                        icon={{ url: downloadIcon }}
                                        slimHeight
                                        kind="transparent">Arquivos</StyledButton>
                                </Container>
                            </Container>
                        )}
                        {currentUser?.role === ADMIN_USER_ROLE && (
                            <Container mt="40px" flex justifyEnd alignCenter>
                                <Link to={`${match.path}/${venture.id}?active=${ventureStatus}`} onClick={handleEdit}>
                                    <Container>
                                        <StyledButton kind="primary-outlined" slimHeight>Editar</StyledButton>
                                    </Container>
                                </Link>
                                <Container ml="10px">
                                    <StyledButton
                                    kind={venture.active ? "primary-outlined" : "primary"}
                                    loading={loading}
                                    slimHeight
                                    onClick={() => handleToggleVentureActivation(venture)}>
                                        {venture.active ? "Despublicar" : "Publicar"}
                                    </StyledButton>
                                </Container>
                            </Container>
                        )}
                    </Container>
            </Container>
        </VentureCardContainer>
            <SelectVentureCoverImageDialog
            venture={venture}
            ventureType={ventureType}
            active={showSelectCoverImageDialog}
            onClose={() => setShowSelectCoverImageDialog(false)} />
        </>
    )
}

const mapStateToProps = (state) => ({
    currentUser: state.authentication.currentUser
})

const mapDispatchToProps = dispatch => ({
    dispatchSetCurrentVenture(currentVenture) {
        dispatch(setCurrentVenture(currentVenture))
    },

    async dispatchUpdateVenture({ ventureId, corporationId }, { active }, ventureType) {
        await dispatch(updateVenture({ ventureId, corporationId }, { active }, ventureType))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(VentureCard)

