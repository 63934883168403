// Libraries
import styled from "styled-components"


export const VenturePhotosContainer = styled.div`
    
`

export const ImageGroupListContainer = styled.div`
  display: grid;
  align-items: center;
  margin-top: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  
  @media only screen and (max-width: 1558px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  @media only screen and (max-width: 1330px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media only screen and (max-width: 930px) {
    grid-template-columns: 1fr;
  }
`
