// Libraries
import axios from "axios"

import ApiConfig from "../apiConfig"

export default class CorporationHandler {
    static async updateCorporationSettings({ corporationId }, {
        settingKey,
        settingValue
    }) {
        try {
            const headers = ApiConfig.generateHeaders()
            const payload = { settingKey, settingValue }
            
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/settings`, payload, headers)

            return response.data
        } catch (error) {
            throw error
        }
    }

    static async fetchCorporationSettings({ corporationId }) {
        try {
            const headers = ApiConfig.generateHeaders()
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/settings`, headers)
            
            return response.data
        } catch (error) {
            throw error
        }
    }
}
