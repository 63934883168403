import { combineReducers } from "redux";
import authentication from "./modules/authentication";
import corporations from "./modules/corporations";
import ventures from "./modules/ventures";
import imageGroups from "./modules/imageGroups";
import images from "./modules/images";
import videos from "./modules/videos";
import global from "./modules/global";
import commonQuestions from "./modules/commonQuestions";
import corporationManagers from "./modules/corporationManagers";
import propertyPlans from "./modules/propertyPlans";
import documents from "./modules/documents";
import teamMembers from "./modules/teamMembers";
import messages from "./modules/messages";

export default combineReducers({
  authentication,
  corporations,
  ventures,
  imageGroups,
  images,
  videos,
  global,
  commonQuestions,
  corporationManagers,
  propertyPlans,
  documents,
  teamMembers,
  messages,
});
