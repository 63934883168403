import styled from "styled-components"


export const StyledChipContainer = styled.div`
    span {
      font-size: 15px;
      cursor: pointer;
      font-weight: 400;
    }

  .MuiChip-root {
    height: 36px !important;
    background-color: #ECEFF1 !important;
    border: 2px solid #000A12 !important;
    color: #000A12 !important;
  }

  .MuiChip-deleteIconColorPrimary {
    color: #009688 !important;
  }
`
