// Libraries
import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Dialog'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

// Styles
import {StyledDialogContainer,
    ModalBody,
    ModalHeader,
    ModalTitle,
    StyledDialogMainContainer,
    ModalSubtitle} from "./styled"
import Container from "../../structureComponents/modules/Container";
import StyledButton from "../StyledButton/StyledButton";

import CloseIcon from "../../assets/icons/close.svg"


function StyledDialog (props) {
    //LOCAL STATES
    const [active, setActive] = useState(false);

    //WATCH
    useEffect(() => {
        setActive(props.active)
    }, [props.active])


    // COMPUTED PROPERTIES
    const hasTitle = () => {
        if (props.title) return true
        return false
    }


    // METHODS
    const handleClose = () => {
        setActive(false);
        props.onClose()
    }


    return (
        <StyledDialogMainContainer resetBodyTopPadding={props.resetBodyTopPadding}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={active}
                onClose={handleClose}
                fullScreen={props.fullScreen}
                fullWidth={true}
                maxWidth={props.maxWidth ? props.maxWidth : "lg"}
                closeAfterTransition
                BackdropComponent={Backdrop}
                className="styled-dialog"
                BackdropProps={{ timeout: 500 }}>
                <Fade in={active}>
                    <StyledDialogContainer maxWidth={props.maxWidth}>
                        {!props.hideHeader && (
                            <ModalHeader hasTitle={hasTitle}>
                                <Container flex alignCenter>
                                    {props.title && <ModalTitle largeTitle={props.largeTitle}>{props.title}</ModalTitle>}
                                    {props.subtitle && (
                                        <Container flex alignCenter>
                                            <Container p="0 10px">-</Container>
                                            <ModalSubtitle>{props.subtitle}</ModalSubtitle>
                                        </Container>
                                    )}
                                </Container>
                                <StyledButton iconButton icon={{ url: CloseIcon}} onClick={handleClose} />
                            </ModalHeader>
                        )}
                        <ModalBody>
                            {props.children}
                        </ModalBody>
                    </StyledDialogContainer>
                </Fade>
            </Modal>
        </StyledDialogMainContainer>
    )
}


export default StyledDialog
