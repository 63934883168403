// Libraries
import React, { useEffect, useState } from "react"
import Switch from '@material-ui/core/Switch'

// Styles
import { StyledSwitchContainer } from "./styled"


export default function StyledSwitch({
    label,
    value,
    onChange = () => ({})
 }) {
    // State
    const [active, setActive] = useState(false)

    // Watchers
    useEffect(() => {
        setActive(value)
    }, [value])

    // Methods
    const handleChange = (e) => {
        setActive(e.target.checked)
        onChange(e.target.checked)
    }

    return (
        <StyledSwitchContainer>
            <Switch
                checked={active}
                onChange={handleChange}
                name={label}
                color="primary"
            />
        </StyledSwitchContainer>
    )
}