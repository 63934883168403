// Libraries
import React, {useContext, useEffect, useState} from "react"
import {Container, Label} from "../../structureComponents";
import CircularProgress from "@material-ui/core/CircularProgress";
import StyledInput from "../../components/StyledInput/StyledInput";
import ViaCEPService from "../../services/ViaCEPService";
import {ADMIN_USER_ROLE} from "../../utils/constants";
import {AuthContext} from "../../Auth";


function ZipcodeSearch({
    initialZipcode,
    error = false,
    onChange = () => ({}),
    onSearch = () => ({})
}) {
    // Contexts
    const { currentUser } = useContext(AuthContext)

    // States
    const [loading, setLoading] = useState(false)
    const [zipcode, setZipcode] = useState(null)

    // Watchers
    useEffect(() => {
        if (zipcode?.length === 8) {
            searchAddressByZipcode(zipcode)
        }
    }, [zipcode])

    useEffect(() => {
        if (initialZipcode) {
            setZipcode(initialZipcode)
        }
    }, [initialZipcode])


    // Methods
    const handleZipcodeOnChange = (e) => {
        onChange()
        setZipcode(e.target.value)
    }

    const searchAddressByZipcode = async (CEP) => {
        if (initialZipcode) return

        setLoading(true)

        const ventureAddress = await ViaCEPService.findAddress(CEP)

        onSearch({
            streetName: ventureAddress.logradouro,
            neighborhood: ventureAddress.bairro,
            city: ventureAddress.localidade,
            countryState: ventureAddress.uf,
            zipcode
        })

        setLoading(false)
    }

    return (
        <Container>
            <Container flex alignCenter>
                <Label bold>CEP</Label>
                {loading && (
                    <Container ml="2.4em" absolute>
                        <CircularProgress size="16px" color="primary" />
                    </Container>
                )}
            </Container>
            <Container viewOnly={currentUser?.role !== ADMIN_USER_ROLE} mt="10px">
                <StyledInput
                    value={zipcode}
                    error={error}
                    helperText="CEP invalido"
                    onChange={handleZipcodeOnChange} />
            </Container>
        </Container>
    )
}


export default ZipcodeSearch
