// Libraries
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"

// Structure components
import { Container, Label } from "../../../structureComponents";

// Components
import StyledInput from "../../../components/StyledInput/StyledInput";

// Styles
import { PriceFilterContainer, PriceFilterOverlay } from "./styled"
import StyledButton from "../../../components/StyledButton/StyledButton";
import StyledSlider from "../../../components/StyledSlider/StyledSlider";
import Formatter from "../../../utils/Formatter";
import StyledChip from "../../../components/StyledChip/StyledChip";
import closeIcon from "../../../assets/icons/close.svg";
import {fetchActiveVentures, resetFilters} from "../../../store/modules/ventures/actions";


function PriceFilter({
    maxPriceAllowed,
    currentFilters,
    dispatchFetchActiveVentures,
    dispatchResetFilters,
    onLoading = () => ({})
}) {
    // States
    const [state, setState] = useState({
        active: false,
        minPriceDefault: 50000,
        maxPriceDefault: 10000000,
        minPrice: 50000,
        maxPrice: 10000000,
        isFilterApplied: false,
        priceRangeLabel: ""
    })

    const [loading, setLoading] = useState(false)

    // Watchers
    useEffect(() => {
        if (currentFilters && !currentFilters.minPrice) {
            setState({
                ...state,
                minPrice: 50000,
                maxPrice: maxPriceAllowed,
                isFilterApplied: false,
                active: false
            })
        }
    }, [currentFilters])

    useEffect(() => {
        if (maxPriceAllowed) {
            setState({
                ...state,
                maxPriceDefault: maxPriceAllowed,
                maxPrice: maxPriceAllowed
            })
        }
    }, [maxPriceAllowed])


    useEffect(() => {
        const bodyElement = document.querySelector("body")
        bodyElement.style.overflow = state.active ? "hidden" : null
    }, [state.active])


    // Methods
    const handlePriceRangeOnChange = (range) => {
        setState({
            ...state,
            minPrice: range[0],
            maxPrice: range[1]
        })
    }

    const handleClear = async () => {
        onLoading(true)
        setState({
            ...state,
            minPrice: 50000,
            maxPrice: maxPriceAllowed,
            isFilterApplied: false,
            active: false
        })

        dispatchResetFilters(["minPrice", "maxPrice"])

        await dispatchFetchActiveVentures({
            minPrice: null,
            maxPrice: null,
            searchValue: currentFilters.searchValue
        })

        onLoading(false)
    }

    const handleApplyFilter = async () => {
        setLoading(true)
        onLoading(true)

        setState({
            ...state,
            active: false,
            isFilterApplied: true,
            priceRangeLabel: `
                ${Formatter.toBrazilianCurrency(state.minPrice)} - ${Formatter.toBrazilianCurrency(state.maxPrice)}
            `
        })

        await dispatchFetchActiveVentures({
            minPrice: state.minPrice,
            maxPrice: state.maxPrice,
            searchValue: currentFilters.searchValue
        })


        onLoading(false)
        setLoading(false)
    }


    return (
        <>
            <PriceFilterOverlay active={state.active} onClick={() => setState({ ...state, active: false })}/>
            {!state.isFilterApplied ? (
                <StyledButton
                    kind="dark-outlined"
                    shape="rounded"
                    height="36px"
                    light
                    onClick={() => setState({ ...state, active: true })}>
                    Preço
                </StyledButton>
                ) : (
                    <StyledChip
                        label={state.priceRangeLabel}
                        kind="delete"
                        onClick={() => setState({ ...state, active: true })}
                        onDelete={handleClear}/>
            )}
            <PriceFilterContainer active={state.active}>
                <Container className="price-filter-header--mobile">
                    <Container>
                        <Label semiBold style={{ fontSize: "24px" }}>Preço</Label>
                    </Container>
                    <StyledButton
                        iconButton
                        icon={{ url: closeIcon }}
                        onClick={() => setState({ ...state, active: false })} />
                </Container>
                <Container>
                    <Container mt="20px">
                        <StyledSlider
                        minValue={state.minPriceDefault}
                        maxValue={state.maxPriceDefault}
                        value={[state.minPrice, state.maxPrice]}
                        range
                        step={50000}
                        onChange={handlePriceRangeOnChange} />
                    </Container>
                    <Container flex mt="20px">
                        <Container>
                            <Label>Preço mínimo</Label>
                            <Container mt="10px">
                                <StyledInput value={Formatter.toBrazilianCurrency(state.minPrice)} />
                            </Container>
                        </Container>
                        <Container ml="16px">
                            <Label>Preço máximo</Label>
                            <Container mt="10px">
                                <StyledInput value={Formatter.toBrazilianCurrency(state.maxPrice)} />
                            </Container>
                        </Container>
                    </Container>
                </Container>
                <Container flex alignCenter justifyBetween className="price-filter--action-container" mt="40px">
                    <StyledButton
                        className="price-filter--action-container__clean-btn"
                        kind="primary-transparent"
                        slimHeight
                        onClick={handleClear}>
                        Limpar
                    </StyledButton>
                    <StyledButton
                        className="price-filter--action-container__apply-btn"
                        kind="primary"
                        loading={loading}
                        slimHeight
                        onClick={handleApplyFilter}>
                        Aplicar
                    </StyledButton>
                </Container>
            </PriceFilterContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    currentFilters: state.ventures.currentFilters
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchFetchActiveVentures({ minPrice, maxPrice, searchValue }) {
        await dispatch(fetchActiveVentures({ minPrice, maxPrice, searchValue }))
    },

    dispatchResetFilters(filters) {
        dispatch(resetFilters(filters))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(PriceFilter)
