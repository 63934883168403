const Container = require('./modules/Container').default
const Title = require('./modules/Title').default
const TitleH1 = require('./modules/TitleH1').default
const TitleH2 = require('./modules/TitleH2').default
const TitleH3 = require('./modules/TitleH3').default
const TitleH4 = require('./modules/TitleH4').default
const Label = require('./modules/Label').default
const List = require('./modules/List').default
const ListItem = require('./modules/ListItem').default
const Span = require('./modules/Span').default
const Logo = require('./modules/Logo').default
const PageWrapper = require('./modules/PageWrapper').default
const Module = require('./modules/Module').default
const Text = require('./modules/Text').default


module.exports = {
    Container,
    Title,
    TitleH1,
    TitleH2,
    TitleH3,
    TitleH4,
    Span,
    Label,
    Logo,
    PageWrapper,
    Module,
    List,
    ListItem,
    Text
}
