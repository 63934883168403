import {SET_VENTURE_COMMON_QUESTIONS, SET_CORPORATION_COMMON_QUESTIONS} from "./mutations";

const initialState = {
    ventureCommonQuestions: [],
    corporationCommonQuestions: []
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_VENTURE_COMMON_QUESTIONS:
            return { ...state, ventureCommonQuestions: action.payload }

        case SET_CORPORATION_COMMON_QUESTIONS:
            return { ...state, corporationCommonQuestions: action.payload }    

        default:
            return state;
    }
}

export default reducer;
