// Libraries
import React, {useState} from "react"

// Styles
import {VentureGeneralInfoContainer} from "./styled"

// Structure components
import {Container} from "../../../../structureComponents";

// Components
import StyledTabs from "../../../../components/StyledTabs/StyledTabs";

// Constants
import {LOCATION_TAB, HEADER_TAB} from "../../../../utils/constants";

// Partials
import GeneralInfoLocation from "./GeneralInfoLocation/GeneralInfoLocation";
import GeneralInfoDescription from "./GeneralInfoDescription/GeneralInfoDescription";

function VentureGeneralInfo() {
    // States
    const [userHasChanges, setUserHasChanges] = useState(false)

    const generalInfoTabs = [
        {
            name: HEADER_TAB,
            label: "Descrição",
            children: <GeneralInfoDescription onUserChanges={(hasChanges) => setUserHasChanges(hasChanges)} />
        },
        {
            name: LOCATION_TAB,
            label: "Localização",
            children: <GeneralInfoLocation onUserChanges={(hasChanges) => setUserHasChanges(hasChanges)} />
        }
    ]


    return (
        <VentureGeneralInfoContainer>
            <Container>
                <StyledTabs tabs={generalInfoTabs} userHasChanges={userHasChanges} />
            </Container>
        </VentureGeneralInfoContainer>
    )
}

export default VentureGeneralInfo
