// Libraries
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import VentureDocuments from "../pages/Ventures/VentureDetails/VentureDocuments/VentureDocuments";

// Styles
import { Container } from "../structureComponents";
import Ventures from "./Ventures/Ventures";
import Navbar from "../businessComponents/Navbar/Navbar";
import Corporations from "./Corporations/Corporations";
import routes from "../routes";
import SideBar from "../businessComponents/SideBar/SideBar";
import VentureDetails from "./Ventures/VentureDetails/VentureDetails";
import VentureGeneralInfo from "./Ventures/VentureDetails/VentureGeneralInfo/VentureGeneralInfo";
import VentureImagesAndVideos from "./Ventures/VentureDetails/VentureImagesAndVideos/VentureImagesAndVideos";
import VentureAboutProject from "./Ventures/VentureDetails/VentureAboutProject/VentureAboutProject";
import VenturePropertyPlans from "./Ventures/VentureDetails/VenturePropertyPlans/VenturePropertyPlans";
import VentureAboutNeighborhood from "./Ventures/VentureDetails/VentureAboutNeighborhood/VentureAboutNeighborhood";
import VentureImageGroup from "./Ventures/VentureDetails/VentureImagesAndVideos/VentureImageGroups/VentureImageGroup/VentureImageGroup";
import Snackbar from "../components/Snackbar/Snackbar";
import CommonQuestions from "./Ventures/VentureDetails/CommonQuestions/CommonQuestions";
import CorporationDetails from "./Corporations/CorporationDetails/CorporationDetails";
import CorporationCommonQuestions from "./Corporations/CorporationDetails/CorporationCommonQuestions/CorporationCommonQuestions";
import CorporationGeneralInfo from "./Corporations/CorporationDetails/CorporationGeneralnfo/CorporationGeneralInfo";
import CorporationManagers from "./Corporations/CorporationDetails/CorporationManagers/CorporationManagers";
import VenturesModeration from "./VenturesModeration/VenturesModeration";
import CorporationSettings from "./Corporations/CorporationDetails/CorporationSettings/CorporationSettings";
import VentureLandingPage from "./Ventures/VentureDetails/VentureLandingPage/VentureLandingPage";
import TeamMembers from "./TeamMembers/TeamMembers";
import MyProfile from "./Profile/Profile";
import VentureMessages from "./Ventures/VentureDetails/VentureMessages/VentureMessages";

function Admin(props) {
  useEffect(() => {
    props.history.push(routes.ventures.url);
  }, []);

  return (
    <Container fluid>
      <Navbar {...props} />
      <Container>
        <SideBar {...props} />
        <Container
          ml="16em"
          fluid
          maxWidth="calc(100% - 16em)"
          p="40px"
          mt="60px"
          absolute
          top="0"
          left="0"
        >
          <Switch>
            <Route
              exact
              path={`${props.match.path}/perfil`}
              component={MyProfile}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras`}
              component={Corporations}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos`}
              component={Ventures}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id`}
              component={VentureDetails}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras/:id`}
              component={CorporationDetails}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/informacoes-gerais`}
              component={VentureGeneralInfo}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/imagens-e-videos`}
              component={VentureImagesAndVideos}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/imagens-e-videos/grupo-de-imagens/:imageGroupId`}
              component={() => <VentureImageGroup {...props} />}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/sobre-o-projeto`}
              component={VentureAboutProject}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/plantas`}
              component={VenturePropertyPlans}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/sobre-o-bairro`}
              component={VentureAboutNeighborhood}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/perguntas-frequentes`}
              component={CommonQuestions}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/documentos`}
              component={VentureDocuments}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/landing-page`}
              component={VentureLandingPage}
            />
            <Route
              exact
              path={`${props.match.path}/empreendimentos/:id/mensagens-empreendimentos`}
              component={VentureMessages}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras/:id/perguntas-frequentes`}
              component={CorporationCommonQuestions}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras/:id/informacoes-gerais`}
              component={CorporationGeneralInfo}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras/:id/gerente-da-incorporadora`}
              component={CorporationManagers}
            />
            <Route
              exact
              path={`${props.match.path}/incorporadoras/:id/configuracoes`}
              component={CorporationSettings}
            />
            <Route
              exact
              path={`${props.match.path}/moderacao`}
              component={VenturesModeration}
            />
            <Route
              exact
              path={`${props.match.path}/equipe`}
              component={TeamMembers}
            />
          </Switch>
        </Container>
      </Container>
      <Snackbar
        active={props.snackbarState.active}
        message={props.snackbarState.message}
        type={props.snackbarState.type}
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  snackbarState: state.global.snackbarState,
  showDiscardChangesDialog: state.global.showDiscardChangesDialog,
});

export default connect(mapStateToProps)(Admin);
