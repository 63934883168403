// Libraries
import React, { useState } from "react"
import StyledDialog from "../../../components/StyledDialog/StyledDialog";
import {APPROVE_ACTION, IGNORE_ACTION} from "../VentureModerationCard/VentureModerationCard";

import { Container, Label, Span } from "../../../structureComponents";
import StyledButton from "../../../components/StyledButton/StyledButton";
import VentureHandler from "../../../handlers/VentureHandler";


function ConfirmVentureModerationDialog({
    venture,
    moderationAction,
    currentPage,
    currentSearchValue,
    active = false, 
    onClose = () => ({})
}) {
    // States
    const [loading, setLoading] = useState(false)

    // Computed
    const getDialogTitle = () => {
        if (moderationAction === IGNORE_ACTION) {
            return "Ignorar empreendimento"
        } else if (moderationAction === APPROVE_ACTION) {
            return "Aprovar empreendimento"
        }
    }

    // Methods
    const handleArchiveVenture = async () => {
        try {
            setLoading(true)
            await VentureHandler.setArchiveVenture({
                ventureId: venture.id,
                corporationId: venture.corporationId
            }, {
                needModeration: 0
            })

            onClose()
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleApproveVenture = async () => {
        try {
            setLoading(true)
            await VentureHandler.approveVentureModeration({
                ventureId: venture.id,
                corporationId: venture.corporationId,
                currentPage,
                currentSearchValue
            })
            onClose()
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
            title={getDialogTitle()}
            active={active}
            maxWidth="sm"
            onClose={() => onClose()}>
            <Container>
                {moderationAction === IGNORE_ACTION ? (
                    <Label>Tem certeza que deseja arquivar o empreendimento <Span bold>{venture?.name}</Span>?</Label>
                ) : (
                    <Label>Tem certeza que deseja aprovar o empreendimento <Span bold>{venture?.name}</Span>?</Label>
                )}
            </Container>
            <Container flex alignCenter justifyEnd mt="40px">
                <Container>
                    <StyledButton kind="primary-outlined" onClick={() => onClose()}>Cancelar</StyledButton>
                </Container>
                <Container ml="10px">
                    {moderationAction === IGNORE_ACTION ? (
                        <StyledButton kind="primary" loading={loading} onClick={handleArchiveVenture}>
                            Arquivar
                        </StyledButton>
                    ) : (
                        <StyledButton kind="primary" loading={loading} onClick={handleApproveVenture}>
                            Aprovar
                        </StyledButton>
                    )}
                </Container>
            </Container>
        </StyledDialog>
    )
}

export default ConfirmVentureModerationDialog
