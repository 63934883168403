// Libraries
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

// Components
import StyledDialog from "../../../../../components/StyledDialog/StyledDialog"
import CommonQuestion from "../../../../../store/modules/commonQuestions/model"
import StyledInput from "../../../../../components/StyledInput/StyledInput"
import StyledButton from "../../../../../components/StyledButton/StyledButton"

// Structure components
import { Container, Label } from "../../../../../structureComponents"

// Store actions
import { setSnackbarState } from "../../../../../store/modules/global/actions"
import { createCommonQuestion } from "../../../../../store/modules/commonQuestions/actions"



function CreateCommonQuestionDialog({
    currentCorporation,
    active,
    onClose = () => ({}),
    dispatchSnackbar,
    dispatchCreateCommonQuestion
}) {
    // State
    const [commonQuestion, setCommonQuestion] = useState(new CommonQuestion({}))
    const [loading, setLoading] = useState(false)

    // Watchers
    useEffect(() => {
        if (active) {
            setCommonQuestion(new CommonQuestion({}))
        }
    }, [active])

    // Methods
    const handleQuestionOnChange = (e) => {
        setCommonQuestion({ ...commonQuestion, question: e.target.value })
    }

    const handelAnswerOnChange = (e) => {
        setCommonQuestion({ ...commonQuestion, answer: e.target.value })
    }

    const handleCreateCommonQuestion = async (e) => {
        try {
            e.preventDefault()
            setLoading(true)

            const corporationId = currentCorporation.id

            await dispatchCreateCommonQuestion({ corporationId }, {
                question: commonQuestion.question,
                answer: commonQuestion.answer
            })

            onClose()

            dispatchSnackbar({
                type: "success",
                active: true,
                message: "Pergunta frequente criada com sucesso"
            })
        } catch (error) {
            dispatchSnackbar({
                type: "error",
                active: true,
                message: "Infelizmente ocorreu um erro"
            })
        } finally {
            setLoading(false)
        }
    }


    return (
        <StyledDialog
        title="Criar pergunta frequente"
        active={active}
        onClose={() => onClose()} maxWidth="sm">
            <form onSubmit={handleCreateCommonQuestion}>
                <Container>
                    <Label bold>Pergunta</Label>
                    <Container mt="10px">
                        <StyledInput
                        value={commonQuestion.question}
                        placeholder="Digite a pergunta"
                        onChange={handleQuestionOnChange} />
                    </Container>
                </Container>
                <Container mt="20px">
                    <Label bold>Resposta</Label>
                    <Container mt="10px">
                        <StyledInput
                        value={commonQuestion.answer}
                        placeholder="Digite a resposta"
                        multiline
                        rows={8}
                        onChange={handelAnswerOnChange}/>
                    </Container>
                </Container>
                <Container flex alignCenter justifyEnd mt="40px">
                    <Container>
                        <StyledButton kind="primary-outlined" onClick={() => onClose()}>Cancelar</StyledButton>
                    </Container>
                    <Container ml="20px">
                        <StyledButton kind="primary" type="submit" loading={loading}>Salvar</StyledButton>
                    </Container>
                </Container>
            </form>
        </StyledDialog>
    )
}


const mapStateToProps = (state) => ({
    currentCorporation: state.corporations.currentCorporation
})

const mapDispatchToProps = (dispatch) => ({
    async dispatchCreateCommonQuestion({ corporationId }, { question, answer }) {
        await dispatch(createCommonQuestion({ corporationId }, {  question, answer}))
    },

    dispatchSnackbar({ message, type, active }) {
        dispatch(setSnackbarState({ message, type, active }))
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateCommonQuestionDialog)
