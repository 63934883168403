import axios from "axios"
import ApiConfig from "../apiConfig";

const headers = ApiConfig.generateHeaders()

export default class CorporationManager {
    constructor({
        id,
        email,
        phoneNumber,
        name,
        corporationId
    }) {
        this.id = id
        this.email = email
        this.phoneNumber = phoneNumber
        this.name = name
        this.corporationId = corporationId
    }

    static async create({ corporationId }, { email, phoneNumber, name }) {
        try {
            const payload = {
                email,
                phoneNumber,
                name
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/corporationManagers`,
                payload,
                headers
            )

            const corporationManager = response.data
            return corporationManager
        } catch (error) {
            throw error
        }
    }

    static async update({ corporationId, corporationManagerId }, { email, phoneNumber, name }) {
        try {
            const payload = {}
            if (email) payload.email = email
            if (name) payload.name = name
            if (phoneNumber) payload.phoneNumber = phoneNumber

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/corporationManagers/${corporationManagerId}`,
                payload,
                headers
            )

            const updatedCorporationManager = response.data
            return updatedCorporationManager
        } catch (error) {
            throw error
        }
    }

    static async findAllByCorporationId(corporationId) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/corporationManagers`,
                headers
            )

            const corporationManagers = response.data
            return corporationManagers
        } catch (error) {
            throw error
        }
    }
}
