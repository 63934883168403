import store from "../../index";

export const LAUNCH_VENTURE_STATUS = "LAUNCH_VENTURE_STATUS"
export const READY_VENTURE_STATUS = "READY_VENTURE_STATUS"
export const IN_CONSTRUCTION_VENTURE_STATUS = "IN_CONSTRUCTION_VENTURE_STATUS"


class Venture {
    constructor({
        id,
        name,
        zipcode,
        streetName,
        streetNumber,
        countryState,
        city,
        neighborhood,
        corporationId,
        coverImage,
        heroText,
        projectDescription,
        facilitiesIds = [],
        highlightsIds = [],
        landSize,
        amountOfApartments,
        amountOfTowers,
        amountOfFloors,
        heroDescription,
        logoUrl,
        ventureType,
        minBedroomsAmount,
        maxBedroomsAmount,
        minSuitesAmount,
        maxSuitesAmount,
        minGarageSpotsAmount,
        maxGarageSpotsAmount,
        minBathroomsAmount,
        maxBathroomsAmount,
        priceFrom,
        minSize,
        maxSize,
        active,
        aboutNeighborhood,
        routeId,
        images,
        status,
        deliveryDate,
        metaTitle,
        metaDescription,
        ventureGTMId,
        documents = [],
        customDomain,
        primaryColor,
        secondaryColor
    }) {
        this.id = id
        this.name = name
        this.zipcode = zipcode
        this.streetName = streetName
        this.streetNumber = streetNumber
        this.countryState = countryState
        this.city = city
        this.neighborhood = neighborhood
        this.corporationId = corporationId
        this.coverImage = coverImage
        this.heroText = heroText
        this.projectDescription = projectDescription
        this.facilitiesIds = facilitiesIds
        this.highlightsIds = highlightsIds
        this.landSize = landSize
        this.amountOfApartments = amountOfApartments
        this.amountOfTowers = amountOfTowers
        this.amountOfFloors = amountOfFloors
        this.heroDescription = heroDescription
        this.logoUrl = logoUrl
        this.ventureType = ventureType
        this.minBedroomsAmount = minBedroomsAmount
        this.maxBedroomsAmount = maxBedroomsAmount
        this.minSuitesAmount = minSuitesAmount
        this.maxSuitesAmount = maxSuitesAmount
        this.minGarageSpotsAmount = minGarageSpotsAmount
        this.maxGarageSpotsAmount = maxGarageSpotsAmount
        this.minBathroomsAmount = minBathroomsAmount
        this.maxBathroomsAmount = maxBathroomsAmount
        this.priceFrom = priceFrom
        this.minSize = minSize
        this.maxSize = maxSize
        this.active = active
        this.aboutNeighborhood = aboutNeighborhood
        this.routeId = routeId
        this.images = images
        this.status = status
        this.deliveryDate = deliveryDate
        this.metaTitle = metaTitle
        this.metaDescription = metaDescription
        this.ventureGTMId = ventureGTMId
        this.documents = documents
        this.customDomain = customDomain
        this.primaryColor = primaryColor
        this.secondaryColor = secondaryColor
    }

    get creationPayloadIsValid() {
        if (
            !this.name
            || !this.zipcode
            || !this.streetName
            || !this.streetNumber
            || !this.countryState
            || !this.city
            || !this.neighborhood
            || !this.corporationId
        ) {
            return false
        }

        return true
    }

    get contentPercentage() {
        const requiredAttributes = [this.name, this.zipcode, this.streetName, this.streetNumber, this.countryState,
            this.city, this.neighborhood, this.corporationId, this.coverImage, this.projectDescription, this.heroText]

        const missingAttributes = requiredAttributes.filter((attribute) => !attribute)

        let contentPercentage = 0
        if (missingAttributes.length === 0) {
            contentPercentage = 100
            return contentPercentage
        }

        contentPercentage = (requiredAttributes.length / missingAttributes.length) * 10
        return contentPercentage
    }

    get isReadyToPublish() {
        const contentPercentage = this.contentPercentage
        if (contentPercentage < 100) {
            return false
        }

        return true
    }

    get corporation() {
        const corporationInfo = store.getState().corporations.corporations.find((c) => c.id === this.corporationId)
        return corporationInfo
    }

    get publicUrl() {
        // const LI_URL = "https://imob.pro.br/imovel"
        const LI_URL = "https://staging.imob.pro.br/imovel"

        return `${LI_URL}/${this.routeId}`
    }

    static getVentureById(ventureId) {
        const venture = store.getState().ventures.ventures.find(v => v.id === parseInt(ventureId))
        return venture
    }

    static getStatusOptions() {
        return [
            { name: "Lançamento na planta", value: LAUNCH_VENTURE_STATUS },
            { name: "Pronto", value: READY_VENTURE_STATUS },
            { name: "Em obras", value:  IN_CONSTRUCTION_VENTURE_STATUS}
        ]
    }
}


export default Venture
