import { SET_CORPORATION_MANAGERS } from "./mutations";

const initialState = {
    corporationManagers: [],
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_CORPORATION_MANAGERS:
            return { ...state, corporationManagers: action.payload }

        default:
            return state;
    }
}

export default reducer;
