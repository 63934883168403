// Libraries
import React from "react";
import Drawer from "@material-ui/core/Drawer";

// Styles
import {
  StyledDrawerContainer,
  StyledDrawerHeaderContainer,
  StyledDrawerBodyContainer,
} from "./styled";

// Components
import StyledButton from "../StyledButton/StyledButton";

// Icons
import closeIcon from "../../assets/icons/close.svg";

export default function StyledDrawer({
  children,
  anchor,
  width,
  headerContentNode,
  active = false,
  className,
  onClose = () => ({}),
}) {
  return (
    <StyledDrawerContainer>
      <Drawer
        anchor={anchor}
        open={active}
        onClose={() => onClose()}
        classes={{ modal: `rx-drawer--modal ${className}` }}
      >
        <StyledDrawerHeaderContainer justifyBetween={headerContentNode}>
          {headerContentNode || null}
          <StyledButton
            iconButton
            icon={{ url: closeIcon }}
            onClick={() => onClose()}
          />
        </StyledDrawerHeaderContainer>
        <StyledDrawerBodyContainer width={width}>
          {children}
        </StyledDrawerBodyContainer>
      </Drawer>
    </StyledDrawerContainer>
  );
}
