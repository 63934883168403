// Libraries
import axios from "axios";

// Mutations
import {
  SET_CORPORATION_COMMON_nameS,
  SET_VENTURE_MESSAGES,
} from "./mutations";
import store from "../../index";
import Message from "./model";

export const createMessage =
  ({ ventureId }, { name, text }) =>
  async (dispatch) => {
    try {
      const payload = { name, text };
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/messages`;
      }

      const response = await axios.post(endpoint, payload, headers);

      const message = new Message({
        id: response.data.id,
        name: response.data.name,
        text: response.data.text,
        ventureId: response.data.ventureId,
      });

      if (ventureId) {
        const ventureMessages = [...store.getState().messages.ventureMessages];
        ventureMessages.push(message);

        dispatch({
          type: SET_VENTURE_MESSAGES,
          payload: ventureMessages,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const fetchMessage =
  ({ ventureId }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/messages`;
      }

      const response = await axios.get(endpoint, headers);

      const ventureMessages = response.data.map(
        (ventureMessages) =>
          new Message({
            id: ventureMessages.id,
            name: ventureMessages.name,
            text: ventureMessages.text,
            ventureId: ventureMessages.ventureId,
          })
      );

      if (ventureId) {
        dispatch({ type: SET_VENTURE_MESSAGES, payload: ventureMessages });
      }
    } catch (error) {
      throw error;
    }
  };

export const updateMessages =
  ({ ventureId, messageId }, { name, text }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      const payload = {};
      if (name) payload.name = name;
      if (text) payload.text = text;

      let endpoint = null;

      if (ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/messages/${messageId}`;
      }

      const response = await axios.put(endpoint, payload, headers);

      const ventureMessages = new Message({
        id: response.data.id,
        name: response.data.name,
        text: response.data.text,
        ventureId: response.data.ventureId,
      });

      if (ventureId) {
        const ventureMessage = [
          ...store.getState().messages.ventureMessages,
        ].map((c) => {
          if (c.id === messageId) {
            return ventureMessages;
          }

          return c;
        });

        dispatch({
          type: SET_VENTURE_MESSAGES,
          payload: ventureMessage,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const deleteMessages =
  ({ ventureId, messageId }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/messages/${messageId}`;
      }

      await axios.delete(endpoint, headers);

      if (ventureId) {
        const ventureMessage = [
          ...store.getState().messages.ventureMessages,
        ].filter((c) => {
          if (c.id !== messageId) {
            return true;
          }

          return false;
        });

        dispatch({
          type: SET_VENTURE_MESSAGES,
          payload: ventureMessage,
        });
      }
    } catch (error) {
      throw error;
    }
  };
