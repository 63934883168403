import styled from 'styled-components'

const PageWrapper = styled.div`
    padding: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
`

export default PageWrapper
