import ApiConfig from "../apiConfig";
import axios from "axios";
import TeamMember from "../models/TeamMember";

export default class TeamMemberHandler {
    static async createTeamMember({
      name,
      lastName,
      email,
      whatsappNumber,
      corporationIds,
      role,
      avatarUrl,
      opportunityRestriction
    }) {
        const headers = ApiConfig.generateHeaders()
        const { APPLY_OPPORTUNITY_RESTRICTION, ALLOW_ALL_CORPORATIONS } = TeamMember.getAllowOpportunityRestrictionValues()

        const payload = {}
        if (name) payload.name = name
        if (lastName) payload.lastName = lastName
        if (email) payload.email = email
        if (whatsappNumber) payload.whatsappNumber = whatsappNumber
        if (corporationIds) payload.corporationIds = corporationIds
        if (role) payload.role = role
        if (avatarUrl) payload.avatarUrl = avatarUrl
        if (opportunityRestriction === APPLY_OPPORTUNITY_RESTRICTION) {
            payload.opportunityRestriction = 1
        } else {
            payload.opportunityRestriction = 0
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/teamMembers`, payload, headers )

        const teamMember = new TeamMember({
            ...response.data,
            opportunityRestriction: response.data.opportunityRestriction ? APPLY_OPPORTUNITY_RESTRICTION : ALLOW_ALL_CORPORATIONS
        })

        return teamMember
    }

    static async fetchTeamMembers({}, { page }) {
        const headers = ApiConfig.generateHeaders()

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/teamMembers`, {
            ...headers,
            params: {
                page: page || 1
            }
        })

        const { APPLY_OPPORTUNITY_RESTRICTION, ALLOW_ALL_CORPORATIONS } = TeamMember.getAllowOpportunityRestrictionValues()

        return {
            meta: response.data.meta,
            teamMembers: response.data.teamMembers.map((teamMember) => new TeamMember({
                ...teamMember,
                opportunityRestriction: teamMember.opportunityRestriction ? APPLY_OPPORTUNITY_RESTRICTION : ALLOW_ALL_CORPORATIONS
            }))
        }
    }

    static async deleteTeamMember(teamMemberId) {
        const headers = ApiConfig.generateHeaders()

        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/admin/teamMembers/${teamMemberId}`,
            headers
        )

        return response.data
    }

    static async updateTeamMember({ teamMemberId }, {
      name,
      lastName,
      email,
      whatsappNumber,
      corporationIds,
      role,
      avatarUrl,
      avatarFile,
      opportunityRestriction
    }) {
    const headers = ApiConfig.generateHeaders()
    const { APPLY_OPPORTUNITY_RESTRICTION, ALLOW_ALL_CORPORATIONS } = TeamMember.getAllowOpportunityRestrictionValues()

    const formData = new FormData()
    if (avatarFile) formData.append("avatarFile", avatarFile)
    if (name) formData.append("name", name)
    if (lastName) formData.append("lastName", lastName)
    if (email) formData.append("email", email)
    if (whatsappNumber) formData.append("whatsappNumber", whatsappNumber)
    if (corporationIds) formData.append("corporationIds", JSON.stringify(corporationIds))
    if (role) formData.append("role", role)
    if (avatarUrl) formData.append("avatarUrl", avatarUrl)
    if (opportunityRestriction === APPLY_OPPORTUNITY_RESTRICTION) {
        formData.append("opportunityRestriction", 1)
    } else {
        formData.append("opportunityRestriction", 0)
    }

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/teamMembers/${teamMemberId}`, formData, headers )

    const teamMember = new TeamMember({
        ...response.data,
        opportunityRestriction: response.data.opportunityRestriction ? APPLY_OPPORTUNITY_RESTRICTION : ALLOW_ALL_CORPORATIONS,
    })

    if (avatarFile) {
        teamMember.avatarUrl = URL.createObjectURL(avatarFile)
    }

    return teamMember
    }

    static async updateWhatsappNumber({ whatsappNumber }) {
        const headers = ApiConfig.generateHeaders()
        const payload = { whatsappNumber }

        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/private/teamMember`,
            payload,
            headers
        )

        return response.data
    }
}