// Mutations
import {SET_CORPORATION_MANAGERS} from "./mutations"
import store from "../../index";
import CorporationManagerHandler from "../../../handlers/corporationManagerHandler";


export const createCorporationManager = ({ corporationId }, { name, email, phoneNumber }) => async (dispatch) => {
    try {
        const corporationManager = await CorporationManagerHandler.createCorporationManager({
            corporationId
        }, {
            name,
            email,
            phoneNumber
        })

        const corporationManagers = [...store.getState().corporationManagers.corporationManagers]
        corporationManagers.push(corporationManager)

        dispatch({ type: SET_CORPORATION_MANAGERS, payload: corporationManagers })
    } catch (error) {
        throw new Error(error)
    }
}

export const updateCorporationManager = ({ corporationId, corporationManagerId }, {
    name,
    email,
    phoneNumber
}) => async (dispatch) => {
    try {
        const updatedCorporationManager = await CorporationManagerHandler.updateCorporationManager({
            corporationId,
            corporationManagerId
        }, {
            name,
            email,
            phoneNumber
        })

        const corporationManagers = [...store.getState().corporationManagers.corporationManagers]
            .map((corporationManager) => {
                if (corporationManager.id === corporationManagerId) {
                    return updatedCorporationManager
                }

                return corporationManager
            })

        dispatch({ type: SET_CORPORATION_MANAGERS, payload: corporationManagers })
    } catch (error) {
        throw new Error(error)
    }
}


export const fetchCorporationManagers = (corporationId) => async (dispatch) => {
    try {
        const corporationManagers = await CorporationManagerHandler.findAllByCorporationId(corporationId)

        dispatch({ type: SET_CORPORATION_MANAGERS, payload: corporationManagers })
    } catch (error) {
        throw new Error(error)
    }
}

