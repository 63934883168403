// Libraries
import axios from "axios";

// Mutations
import {
  SET_CORPORATION_COMMON_QUESTIONS,
  SET_VENTURE_COMMON_QUESTIONS,
} from "./mutations";
import store from "../../index";
import CommonQuestion from "./model";

export const createCommonQuestion =
  ({ ventureId, corporationId }, { question, answer }) =>
  async (dispatch) => {
    try {
      const payload = { question, answer };
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId && !corporationId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/commonQuestions`;
      }

      if (corporationId && !ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/commonQuestions`;
      }

      const response = await axios.post(endpoint, payload, headers);

      const commonQuestion = new CommonQuestion({
        id: response.data.id,
        question: response.data.question,
        answer: response.data.answer,
        ventureId: response.data.ventureId,
        corporationId: response.data.corporationId,
      });

      if (ventureId && !corporationId) {
        const ventureCommonQuestions = [
          ...store.getState().commonQuestions.ventureCommonQuestions,
        ];
        ventureCommonQuestions.push(commonQuestion);

        dispatch({
          type: SET_VENTURE_COMMON_QUESTIONS,
          payload: ventureCommonQuestions,
        });
      }

      if (corporationId && !ventureId) {
        const corporationCommonQuestions = [
          ...store.getState().commonQuestions.corporationCommonQuestions,
        ];
        corporationCommonQuestions.push(commonQuestion);

        dispatch({
          type: SET_CORPORATION_COMMON_QUESTIONS,
          payload: corporationCommonQuestions,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const fetchCommonQuestions =
  ({ ventureId, corporationId }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId && !corporationId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/commonQuestions`;
      }

      if (corporationId && !ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/commonQuestions`;
      }

      const response = await axios.get(endpoint, headers);

      const commonQuestions = response.data.map(
        (commonQuestion) =>
          new CommonQuestion({
            id: commonQuestion.id,
            question: commonQuestion.question,
            answer: commonQuestion.answer,
            ventureId: commonQuestion.ventureId,
            corporationId: commonQuestion.corporationId,
          })
      );

      if (ventureId && !corporationId) {
        dispatch({
          type: SET_VENTURE_COMMON_QUESTIONS,
          payload: commonQuestions,
        });
      }

      if (corporationId && !ventureId) {
        dispatch({
          type: SET_CORPORATION_COMMON_QUESTIONS,
          payload: commonQuestions,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const updateCommonQuestion =
  ({ ventureId, corporationId, commonQuestionId }, { question, answer }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      const payload = {};
      if (question) payload.question = question;
      if (answer) payload.answer = answer;

      let endpoint = null;

      if (ventureId && !corporationId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/commonQuestions/${commonQuestionId}`;
      }

      if (corporationId && !ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/commonQuestions/${commonQuestionId}`;
      }

      const response = await axios.put(endpoint, payload, headers);

      const commonQuestion = new CommonQuestion({
        id: response.data.id,
        question: response.data.question,
        answer: response.data.answer,
        ventureId: response.data.ventureId,
        corporationId: response.data.corporationId,
      });

      if (ventureId && !corporationId) {
        const ventureCommonQuestions = [
          ...store.getState().commonQuestions.ventureCommonQuestions,
        ].map((c) => {
          if (c.id === commonQuestionId) {
            return commonQuestion;
          }

          return c;
        });

        dispatch({
          type: SET_VENTURE_COMMON_QUESTIONS,
          payload: ventureCommonQuestions,
        });
      }

      if (corporationId && !ventureId) {
        const corporationCommonQuestions = [
          ...store.getState().commonQuestions.corporationCommonQuestions,
        ].map((c) => {
          if (c.id === commonQuestionId) {
            return commonQuestion;
          }

          return c;
        });

        dispatch({
          type: SET_CORPORATION_COMMON_QUESTIONS,
          payload: corporationCommonQuestions,
        });
      }
    } catch (error) {
      throw error;
    }
  };

export const deleteCommonQuestion =
  ({ ventureId, corporationId, commonQuestionId }) =>
  async (dispatch) => {
    try {
      const headers = {
        headers: { "x-auth-token": localStorage.getItem("LI_AUTH_TOKEN") },
      };

      let endpoint = null;

      if (ventureId && !corporationId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/ventures/${ventureId}/commonQuestions/${commonQuestionId}`;
      }

      if (corporationId && !ventureId) {
        endpoint = `${process.env.REACT_APP_API_URL}/admin/corporations/${corporationId}/commonQuestions/${commonQuestionId}`;
      }

      await axios.delete(endpoint, headers);

      if (ventureId && !corporationId) {
        const ventureCommonQuestions = [
          ...store.getState().commonQuestions.ventureCommonQuestions,
        ].filter((c) => {
          if (c.id !== commonQuestionId) {
            return true;
          }

          return false;
        });

        dispatch({
          type: SET_VENTURE_COMMON_QUESTIONS,
          payload: ventureCommonQuestions,
        });
      }

      if (corporationId && !ventureId) {
        const corporationCommonQuestions = [
          ...store.getState().commonQuestions.corporationCommonQuestions,
        ].filter((c) => {
          if (c.id !== commonQuestionId) {
            return true;
          }

          return false;
        });

        dispatch({
          type: SET_CORPORATION_COMMON_QUESTIONS,
          payload: corporationCommonQuestions,
        });
      }
    } catch (error) {
      throw error;
    }
  };
