// Libraries
import styled from "styled-components"
import {primary} from "../../../assets/colorPallet";


export const ViewVentureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: ${props => props.active ? 1 : .4};
  pointer-events: ${props => props.active ? "all" : "none"};
  
  .svg-icon {
    cursor: pointer;
    
    svg {
      fill: ${props => props.active ? primary : "#888"};
    }
  }
`

export const VentureCoverImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 400px;
  height: 100%;
  
  .venture-card--update-cover-image__container {
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s ease-in-out;
  }

  &:hover {
    .venture-card--update-cover-image__container {
      opacity: 1;
      pointer-events: all;
    }
    
    img {
      opacity: .4;
    }
    
    .venture-photo-background--container {
      opacity: .4;
    }
  }
`

export const VentureCoverImage = styled.img`
  height: 300px;
  width: 400px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;
  transition: opacity .4s ease-in-out;
`

export const VentureCoverImageIconContainer = styled.div`
  height: 300px;
  width: 400px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #eee;
  transition: opacity .4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VentureCardContainer = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 300px;
  display: flex;   
`

export const VentureCorporationName = styled.label`
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: #eee;
  border-radius: 20em;
  
`
